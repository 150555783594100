
import { useEffect, useState, useContext } from "react";
import PlatformTaskCard from "../../platforms/tabs/PlatformTaskCard";
import { Tasks as GetTasks } from "../../../services/Tasks";
import { useSelector } from "react-redux";
import { LoadingMain } from "../../../components/LoadingModal";
import { RequestErrorHandlerContext } from "../../../components/Main";
import { RequestErrorHandlerContextNEW } from "../../../App";
import { ERRORS } from "../../../config";
import PlatformTaskModal from "../../platforms/tabs/PlatformTaskModal/PlatformTaskModal";
import PermissionError from "../../../components/PermissionError";
import { MainContainer } from "../../../components/Containers";

const TasksAssigned = () => {

    const userInfo = useSelector(state => state.auth.user)
    const project = useSelector(state => state.projects.selected);

    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [taskModal, setSelectedTask] = useState(null);
    const [permission, setPermission] = useState(true);


    const errorHandler = useContext(RequestErrorHandlerContextNEW);

    //    console.log(userInfo);

    useEffect(() => {
        GetTasks({ "assignee.user_id": userInfo.user_id, isApproved: false, projectId: project._id })
            .then(result => { return result.json() })
            .then(result => { return errorHandler(result) })
            .then(result => {
                setLoading(false);
                setTasks(result);
            })
            .catch(error => {
                if (error.message === ERRORS.PERMISSION) {
                    setLoading(false);
                    setPermission(false);
                }
            })
    }, [])

    const onTaskUpdate = (task) => {
        let updatedList = tasks.map(t => { if (t._id === task._id) { return task } else { return t } })
            .filter((t) => { return t.assignee.user_id === userInfo.sub });
        setTasks(updatedList);
        setSelectedTask(task);
    }

    return (
        <div style={{ backgroundColor: "#D9DDDB" }} className="h-screen pt-8"> 


            <MainContainer>
                {
                    loading ? <LoadingMain show={loading} /> :
                        <>
                            {!permission ? <PermissionError /> :
                                <div style={{ paddingTop: 10 }}>
                                    Total: {tasks.length}
                                    {tasks.map((t, i) => { return <PlatformTaskCard key={i} task={t} onTaskSelect={setSelectedTask} /> })}
                                    {taskModal ? <PlatformTaskModal onClose={() => { setSelectedTask(null) }} onTaskUpdate={onTaskUpdate} task={taskModal} /> : ''}
                                </div>}
                        </>
                }
            </MainContainer>
        </div>
    )
}

export default TasksAssigned;