import { Button, Box, Typography, Modal, Avatar, Card, TextField, MenuItem, Select, Paper, Accordion, AccordionSummary, AccordionDetails, InputLabel, InputAdornment, Input } from "@mui/material";
import ScheduleIcon from '@mui/icons-material/HandymanOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { AllUsers as GetAllUsers } from "../../../services/Users";
import { CreateTask } from "../../../services/Tasks";
import './PlatformPartModifyModal.css';
import { PlatformRepairPartTask, PlatformReplacePartTask, PlatformRemovePartTask, TASK_TYPE, PRIORITY_LEVEL, PlatformScheduledPartTask } from "../../../models/TasksModel";
import { Part as GetPart } from "../../../services/Parts";

const AssignUserComponent = ({ selected, modifyPartFormDispatch, users }) => {

    if (selected) {
        return (<>
            <Button variant="outlined" onClick={() => { modifyPartFormDispatch({ assignee: 0 }) }} sx={{ fontSize: 10 }}>Unassign</Button>
        </>)
    }

    return (

        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            //                                            value={task === null ? 0 : users.findIndex((u) => { return u.username === task.assigned.username })}
            value={0}
            //                                            onChange={(v) => { updateTask({ assigned: users[v.target.value] }) }}
            onChange={(v) => { modifyPartFormDispatch({ assignee: v.target.value }) }}
            sx={{ backgroundColor: '#0052cc', height: 30, color: '#fff', fontSize: 13 }}
        >
            <MenuItem value={0}>Assign</MenuItem>
            {users.map((user, index) => {
                return (
                    <MenuItem value={index + 1} key={index}><Avatar src={user.picture} sx={{ width: 30, height: 30, marginRight: 2 }} />{user.nickname}</MenuItem>
                )
            })}

        </Select>

    )
}

const MODIFICATION_TYPES = [
    "Repair",
    "Remove",
    "Replace"
];

const InitialFormState = { type: 0, assignee: 0, priority: 0, description: null, notes: null };
const ModifyPartFormReducer = (state, action) => {

    let _state = { ...state };
    Object.keys(action).forEach(k => {
        _state[k] = action[k];
    })
    return { ..._state };
}

const PlatformPartModifyModal = ({ part, onClose }) => {

    const platform = useSelector(state => state.platform);
    const userInfo = useSelector(state => state.auth.user);
    const [users, setUsers] = useState(null);
    const [awaitingResponse, setAwaitingResponse] = useState(false);
    const [modifyPartFormState, modifyPartFormDispatch] = useReducer(ModifyPartFormReducer, InitialFormState);

    useEffect(() => {
        GetAllUsers()
            .then((data) => {
                return data.json();
            })
            .then((data) => {
                setUsers(data);
            });
    }, [])

    const OnApply = () => {

        let task = {};//new PlatformPartTask(TASK_TYPE.PLATFORM_REPAIR_PART_TASK, platform.platform._id, part);
        //console.log(part);
        GetPart(part.partID)
            .then(res => { return res.json() })
            .then(res => {

                let _part = { ...part };
                _part.stock = res.stock;
                _part.suppliers = res.suppliers;

                if (modifyPartFormState.type === 0)
                    task = new PlatformRepairPartTask(platform.platform._id, _part);
                if (modifyPartFormState.type === 1)
                    task = new PlatformRemovePartTask(platform.platform._id, _part);
                if (modifyPartFormState.type === 2)
                    task = new PlatformReplacePartTask(platform.platform._id, _part);

                if (modifyPartFormState.assignee !== 0)
                    task.Assign(users[modifyPartFormState.assignee - 1]);

                task.SetPriority(modifyPartFormState.priority);
                task.SetDescription(modifyPartFormState.description);
                task.SetReporter(userInfo);
                task.SetNotes(modifyPartFormState.notes);

                setAwaitingResponse(true);
                return CreateTask(task);
            })
            .then((res) => {
                setAwaitingResponse(false);
                return res.json();
            })
            .then((res) => {
//                console.log(res);
                onClose();
            })

        /*
        if (modifyPartFormState.type === 0)
            task = new PlatformRepairPartTask(platform.platform._id, part);
        if (modifyPartFormState.type === 1)
            task = new PlatformRemovePartTask(platform.platform._id, part);
        if (modifyPartFormState.type === 2)
            task = new PlatformReplacePartTask(platform.platform._id, part);

        if (modifyPartFormState.assignee !== 0)
            task.Assign(users[modifyPartFormState.assignee - 1]);

        task.SetPriority(modifyPartFormState.priority);
        task.SetDescription(modifyPartFormState.description);
        task.SetReporter(userInfo);
        task.SetNotes(modifyPartFormState.notes);

        setAwaitingResponse(true);
        CreateTask(task)
            .then((res) => {
                setAwaitingResponse(false);
                return res.json();
            })
            .then((res) => {
                console.log(res);
                onClose();
            })*/
    }

    return (<>
        <Modal
            open={true}
            onClose={onClose}
            sx={{ border: '1px solid #000', outline: 'none' }}
            disableEnforceFocus={true}
            disableAutoFocus={true}

        >
            <Box>
                <Box id="platformPartModifyModalContainer">
                    <Box id="platformPartModifyModalLeft">
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Avatar sx={{ bgcolor: '#e0e0e0', width: 45, height: 45, fontSize: 10, margin: 0.5 }}><ScheduleIcon sx={{ fontSize: 30, color: 'green' }} /></Avatar>
                            <Typography sx={{ fontSize: 20, marginTop: 0.8, marginLeft: 2 }} color="text.primary">Create Task: <b>{ MODIFICATION_TYPES[ modifyPartFormState.type ] } #{part.serial}</b></Typography>
                        </Box>
                        <Box id="modSelectionMobile">
                            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 2 }}>
                                <Typography sx={{ fontSize: 15, marginTop: 0.5, marginRight: 1 }} color="text.primary">Modification</Typography>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={modifyPartFormState.type}
                                    onChange={(v) => { modifyPartFormDispatch({ type: v.target.value }) }}
                                    sx={{ backgroundColor: '#0052cc', height: 30, width: 100, color: '#fff', fontSize: 13 }}
                                >
                                    {MODIFICATION_TYPES.map((v, i) => { return <MenuItem key={i} value={i}>{v}</MenuItem> })}
                                </Select>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                                <Typography sx={{ fontSize: 15, marginTop: 0.5, marginRight: 1 }} color="text.primary">Priority</Typography>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={0}
                                    onChange={() => { }}
                                    sx={{ backgroundColor: '#542972', width: 100, height: 30, color: '#fff', fontSize: 13 }}
                                >
                                    <MenuItem value={0}>Low</MenuItem>
                                    <MenuItem value={1}>Medium</MenuItem>
                                    <MenuItem value={2}>High</MenuItem>
                                </Select>
                            </Box>
                        </Box>
                        <Typography sx={{ fontSize: 15, marginTop: 0.8 }} color="text.primary">Description</Typography>
                        <TextField
                            id="outlined-multiline-static"
                            label=""
                            rows={7}
                            defaultValue=""
                            sx={{ width: '100%' }}
                            onChange={(v) => { modifyPartFormDispatch({ description: v.target.value }) }}
                        />
                        <Typography sx={{ fontSize: 15, marginTop: 0.8 }} color="text.primary">Notes</Typography>
                        <TextField
                            id="outlined-multiline-static"
                            label=""
                            rows={7}
                            multiline
                            defaultValue=""
                            sx={{ width: '100%' }}
                            onChange={(v) => { modifyPartFormDispatch({ notes: v.target.value }) }}
                        />
                        <Box id="applyButtonContainerMobile">
                            <Button sx={{ marginLeft: 'auto', marginTop: 2, marginRight: 1 }} variant="contained" size="small">Apply</Button>
                        </Box>
                    </Box>
                    <Box id="platformPartModifyModalRight">
                        <Box sx={{ display: 'flex' }}>
                            <CloseIcon sx={{ marginLeft: 'auto' }} color="text.secondary" onClick={onClose} />
                        </Box>
                        <Box sx={{ display: 'flex', marginTop: 5, marginRight: 1 }}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={modifyPartFormState.type}
                                onChange={(v) => { modifyPartFormDispatch({ type: v.target.value }) }}
                                sx={{ backgroundColor: '#0052cc', marginLeft: 'auto', height: 30, color: '#fff', fontSize: 13 }}
                            >
                                {MODIFICATION_TYPES.map((v, i) => { return <MenuItem key={i} value={i}>{v}</MenuItem> })}
                            </Select>
                        </Box>

                        <Box sx={{ display: 'flex', marginTop: 4, marginRight: 1 }}>
                            <Paper sx={{ width: '100%', marginLeft: 2, backgroundColor: '#eee', paddingLeft: 1, paddingTop: 2, paddingRight: 1, paddingBottom: 4 }} elevation={0}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                                    <Box sx={{ width: '50%', alignContent: 'center' }}>
                                        <Typography color="text.secondary" sx={{ fontSize: 14 }}>Model: </Typography>
                                    </Box>
                                    <Typography color="text.secondary" sx={{ fontSize: 14 }}><b>{part.model}</b></Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                                    <Box sx={{ width: '50%', alignContent: 'center' }}>
                                        <Typography color="text.secondary" sx={{ fontSize: 14 }}>Type: </Typography>
                                    </Box>
                                    <Typography color="text.secondary" sx={{ fontSize: 14 }}><b>{part.type}</b></Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                                    <Box sx={{ width: '50%', alignContent: 'center' }}>
                                        <Typography color="text.secondary" sx={{ fontSize: 14 }}>Manufacturer: </Typography>
                                    </Box>
                                    <Typography color="text.secondary" sx={{ fontSize: 14 }}><b>{part.manufacturer}</b></Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 3 }}>

                                    <Box sx={{ width: '50%', alignContent: 'center' }}>
                                        <Typography color="text.secondary" sx={{ fontSize: 13 }}></Typography>
                                    </Box>
                                    {users !== null ? <AssignUserComponent selected={modifyPartFormState.assignee !== 0} modifyPartFormDispatch={modifyPartFormDispatch} users={users} /> : <CircularProgress size={15} />}
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 2 }}>
                                    <Box sx={{ width: '50%', alignContent: 'center' }}>
                                        <Typography color="text.secondary" sx={{ fontSize: 13 }}>Assignee:</Typography>
                                    </Box>
                                    {modifyPartFormState.assignee !== 0 ?
                                        <>
                                            <Avatar src={users[modifyPartFormState.assignee - 1].picture} sx={{ width: 25, height: 25, display: 'inline-block' }} />
                                            <Typography color="text.secondary" sx={{ fontSize: 13, marginTop: 0.2, marginLeft: 0.5 }}>{users[modifyPartFormState.assignee - 1].nickname}</Typography>
                                        </>
                                        :
                                        '-'
                                    }
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 3 }}>
                                    <Box sx={{ width: '50%', alignContent: 'center' }}>
                                        <Typography color="text.secondary" sx={{ fontSize: 13 }}>Priority</Typography>
                                    </Box>
                                    <Box sx={{ width: '50%' }}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={modifyPartFormState.priority}
                                            onChange={(v) => { modifyPartFormDispatch({ priority: v.target.value }) }}
                                            sx={{ backgroundColor: '#542972', marginLeft: 'auto', height: 30, color: '#fff', fontSize: 13 }}
                                        >
                                            {PRIORITY_LEVEL.map((v, i) => { return (<MenuItem key={i} value={i}>{v}</MenuItem>) })}
                                        </Select>
                                    </Box>
                                </Box>
                            </Paper>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Button sx={{ marginLeft: 'auto', marginTop: 2, marginRight: 1 }} variant="contained" size="small" onClick={OnApply} disabled={modifyPartFormState.description === null || modifyPartFormState.description === "" || awaitingResponse}>Apply</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    </>)
}

export default PlatformPartModifyModal;