import { API } from '../config';
import { GET, PATCH, POST, DELETE } from './Methods';

const Parts = (query) => {
    return GET(API.PARTS(query));
}

const GetParts = () => {
    return GET(API.PARTS())
}

const Part = (id) => {
    return GET(API.PART(id));
}

const CreatePart = (partData) => {
    return POST(API.PARTS(), partData);
};

const UpdatePart = (id, partData) => {
    return PATCH(API.PART(id), partData);
};

const DeletePart = (id) => {
    return DELETE(API.PART(id));
};

export { Parts, Part, GetParts, CreatePart, UpdatePart, DeletePart }