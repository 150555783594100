import { Input, Modal, TextField, Typography, Select, MenuItem, Button, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { Close, UploadFile } from '@mui/icons-material';
import { RemovePlatform } from "../../../../services/Platforms";

import { PlatformPart, ServiceInterval } from "../../../../models/PartsModel";
import { useReducer, useState } from "react";
import { DefaultReducer } from "../../../../components/DefaultReducer";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxWidth: 1000,
    maxHeight: '90%',
    bgcolor: 'white',
    border: '0px solid #000',
    boxShadow: 4,
    p: 1,
    px: 2
};

const removePartModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: 700,
    maxHeight: '90%',
    bgcolor: 'white',
    border: '0px solid #000',
    boxShadow: 4,
    p: 1,
    px: 2
};


const RemovePlatformPartModal = ({ part, onClose, removePlatformPart }) => {
    return (
        <Modal
            open={true}
            onClose={onClose}
            sx={{ border: '1px solid #000', outline: 'none' }}
            disableEnforceFocus={true}
            disableAutoFocus={true}
        >
            <Box sx={removePartModalStyle}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography sx={{ textAlign: 'center' }}>Remove part</Typography>
                    <Close color="text.secondary" onClick={onClose} />
                </Box>
                <Divider/>

                <Box sx={{ margin: 2 }} >
                    <Typography sx={{ textAlign: 'center' }}>Remove <b>{part.name}</b></Typography>
                    <Typography sx={{ textAlign: 'center' }}>Are you sure?</Typography>

                </Box>

                <Box sx={{display:'flex', justifyContent:'center'}}>
                    <Button variant="outlined" color="warning" sx={{margin:1}} onClick={onClose}>Cancel</Button>
                    <Button variant="contained" sx={{margin:1}} onClick={()=>{removePlatformPart(part)}}>REmove</Button>
                </Box>

            </Box>
        </Modal>
    )
}

const MODAL_STATE = {
    NONE: 'none',
    REMOVE_PART: 'remove_part',
}

const EditPlatformPartModal = ({ part, onClose, updatePlatformPart, removePlatformPart }) => {

    const [serviceInterval, serviceIntervalReducer] = useReducer(DefaultReducer, part.GetServiceInterval().GetDuration());
    const [partState, partStateDispatch] = useReducer(DefaultReducer, part);
    const [serviceFlightInterval, setServiceFlightInterval] = useState(part.GetServiceInterval().GetFlightInterval());

    const [modalState, setModalState] = useState({ modal: MODAL_STATE.NONE, data: null });

    const UpdatePlatformPart = () => {
        let interval = new ServiceInterval();
        interval.SetDuration(serviceInterval);
        interval.SetFlightInterval(serviceFlightInterval);

        let p = new PlatformPart(part);
        p.SetServiceInterval(interval);
        updatePlatformPart(p);
        onClose();
    }

    return (<>
        <Modal
            open={true}
            onClose={onClose}
            sx={{ border: '1px solid #000', outline: 'none' }}
            disableEnforceFocus={true}
            disableAutoFocus={true}
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: 1 }}>
                    <Typography sx={{ fontSize: 20, marginTop: 0.8 }} color="text.primary">Edit Platform Part ({part.GetSerial()})</Typography>
                    <Close sx={{ marginLeft: 'auto' }} color="text.secondary" onClick={onClose} />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>Installed: </Typography>
                    <Typography color="text.secondary" sx={{ fontSize: 13, paddingLeft: 1 }}>{new Date(part.dateInstalled).toDateString()}</Typography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>Name: </Typography>
                    <Typography color="text.secondary" sx={{ fontSize: 13, paddingLeft: 1 }}>{part.GetName()}</Typography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>Model: </Typography>
                    <Typography color="text.secondary" sx={{ fontSize: 13, paddingLeft: 1 }}>{part.GetModel()}</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: 1 }}>
                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>Manufacturer: </Typography>
                    <Typography color="text.secondary" sx={{ fontSize: 13, paddingLeft: 1 }}>{part.GetManufacturer()}</Typography>
                </Box>


                <Box sx={{ border: '1px solid #ccc', borderRadius: 1 }}>

                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <TextField
                            disabled={true}
                            label="Serial"
                            size="small"
                            sx={{ marginTop: 1, flexGrow: 1, marginLeft: 1 }}
                            value={partState.serial}
                            onChange={(v) => { partStateDispatch({ serial: v.target.value }) }}
                        />
                        <TextField
                            select
                            label="Active"
                            size="small"
                            sx={{ marginTop: 1, flexGrow: 1, marginLeft: 1, marginRight: 1 }}
                            value={partState.isActive}
                            onChange={(v) => { partStateDispatch({ isActive: v.target.value }) }}
                        >
                            <MenuItem value={true}>true</MenuItem>
                            <MenuItem value={false}>false</MenuItem>
                        </TextField>
                    </Box>

                    <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

                    <Box>

                        <Typography sx={{ textAlign: 'center', marginTop: 1 }}>Set Service Interval</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1 }}>

                            <Typography color="text.secondary">Duration: </Typography>
                            <TextField
                                label="Hours"
                                size="small"
                                sx={{ flexGrow: 1, marginLeft: 1 }}
                                value={serviceInterval.h}
                                onChange={(v) => { serviceIntervalReducer({ h: Number(v.target.value) }) }}
                            />
                            <TextField
                                label="Days"
                                size="small"
                                sx={{ flexGrow: 1, marginLeft: 1 }}
                                value={serviceInterval.d}
                                onChange={(v) => { serviceIntervalReducer({ d: Number(v.target.value) }) }}
                            />
                            <TextField
                                label="Weeks"
                                size="small"
                                sx={{ flexGrow: 1, marginLeft: 1 }}
                                value={serviceInterval.w}
                                onChange={(v) => { serviceIntervalReducer({ w: Number(v.target.value) }) }}
                            />
                            <TextField
                                label="Months"
                                size="small"
                                sx={{ flexGrow: 1, marginLeft: 1 }}
                                value={serviceInterval.m}
                                onChange={(v) => { serviceIntervalReducer({ m: Number(v.target.value) }) }}
                            />
                            <TextField
                                label="Years"
                                size="small"
                                sx={{ flexGrow: 1, marginLeft: 1 }}
                                value={serviceInterval.y}
                                onChange={(v) => { serviceIntervalReducer({ y: Number(v.target.value) }) }}
                            />
                        </Box>


                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
                            <Typography color="text.secondary">Flight hours: </Typography>

                            <TextField
                                label="Flight hours"
                                size="small"
                                sx={{ flexGrow: 1, marginLeft: 1 }}
                                value={serviceFlightInterval}
                                onChange={(v) => { setServiceFlightInterval(v.target.value) }}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: 2, paddingBottom: 1 }}>
                    <Box>
                        <Button variant="outlined" size="small" color="error" onClick={() => { setModalState({ modal: MODAL_STATE.REMOVE_PART }) }}>remove part</Button>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button variant="outlined" size="small" color="secondary" sx={{ marginRight: 1 }} onClick={onClose}>cancel</Button>
                        <Button variant="contained" size="small" onClick={UpdatePlatformPart}>Apply changes</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
        {modalState.modal === MODAL_STATE.REMOVE_PART ? <RemovePlatformPartModal part={part} onClose={() => { setModalState({ modal: MODAL_STATE.NONE }) }} removePlatformPart={(p)=>{removePlatformPart(p); onClose()}}/> : ''}
    </>)
}

export default EditPlatformPartModal;