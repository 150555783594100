import { API } from '../config';
import { GET, POST, PATCH } from './Methods';

const Efrs = (q={}) => {
   return GET(`${API.EFR()}${API.PARSE_QUERY(q)}`);
}


const AddEfr = (efr) => {
    return POST(API.EFR(), efr);
}


const UpdateEfr = (id, efr) => {
    return PATCH(API.EFR(id), efr);
}



export { Efrs, AddEfr, UpdateEfr }