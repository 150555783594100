import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IDIcon from '@mui/icons-material/Tag';
import ManufacturerIcon from '@mui/icons-material/Factory';
import LocationIcon from '@mui/icons-material/LocationOn';
import ModelIcon from '@mui/icons-material/DriveFileRenameOutline';
import ToggleIcon from '@mui/icons-material/ToggleOn';
import FlightIcon from '@mui/icons-material/Flight';
import { MenuItem } from '@mui/material';
import FilterIconButton from '@mui/icons-material/FilterAlt';
import Button from '@mui/material/Button';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import FilterIcon from '@mui/icons-material/TuneOutlined';
import { useEffect, useState, useReducer } from 'react';
import { Locations as GetLocations } from '../../services/Locations';
import { FilterReducer } from './PlatformFilterReducer';
//import { RequestErrorHandlerContext } from '../../components/Main';
import { RequestErrorHandlerContextNEW as RequestErrorHandlerContext } from '../../App';
import { useContext } from 'react';
import { ConstructionOutlined } from '@mui/icons-material';

import { locationsActions } from '../../store';
import { useDispatch } from 'react-redux';
import { API } from '../../config';
import { useAPI } from '../../hooks/APIRequest';


const PlatformFilter = ( {onApply, InitialFormState} ) => {

    // const [locations, SetLocations] = useState([]);
    const [filterState, filterDispatch] = useReducer(FilterReducer, InitialFormState);
    const dispatch = useDispatch();

    const errorHandler = useContext(RequestErrorHandlerContext);

    const IsFormChanged = () => {
        let empty = true;
        Object.keys(filterState).forEach(k => {
            if(filterState[k] !== InitialFormState[k])
                empty = false;
        });
        return empty;
    }

//     useEffect(() => {

//         GetLocations()
//             .then(result => {
//                 return result.json();
//             })
//             .then(result => {
//                 return errorHandler(result);
//             })
//             .then(result => {
//                 dispatch(locationsActions.setLocations(result));
//                 SetLocations(result);
//             })
//             .catch(err => {
//                 // handle error
// //                console.log(err);
//             })
//     }, [])


    const locations = useAPI(API.LOCATIONS())
    useEffect(()=>{
        if(locations.json)
        {
            dispatch(locationsActions.setLocations(locations.json));                
        }
    }, [locations.json])



    return (

        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<FilterIcon />}
                    aria-controls="panel1a-content"
                    id="platformFilterHeader"
                >
                    <Typography>Filter Platforms</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <hr />

                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div>
                            <TextField
                                label="Platform ID"
                                id="filterPlatformID"
                                sx={{ m: 1, width: '25ch' }}
                                size="small"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><FlightIcon /></InputAdornment>,
                                }}
                                value={filterState.platformID}
                                onChange={(v) => { filterDispatch({ platformID: v.target.value }) }}

                            />
                            <TextField
                                label="Manufacturer"
                                id="filterManufacturer"
                                sx={{ m: 1, width: '25ch' }}
                                size="small"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><ManufacturerIcon /></InputAdornment>,
                                }}
                                value={filterState.manufacturer}
                                onChange={(v) => { filterDispatch({ manufacturer: v.target.value }) }}
                            />
                            <TextField
                                label="Model"
                                id="filterModel"
                                sx={{ m: 1, width: '25ch' }}
                                size="small"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><ModelIcon /></InputAdornment>,
                                }}
                                value={filterState.model}
                                onChange={(v) => { filterDispatch({ model: v.target.value }) }}
                            />
                            {/** 
                             * 
                            <TextField
                                select
                                label="Status"
                                id="outlined-start-adornment"
                                sx={{ m: 1, width: '25ch' }}
                                size="small"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><ToggleIcon /></InputAdornment>,
                                }}
                            >
                                <MenuItem key={1} value={"Active"}>{"Active"}</MenuItem>
                                <MenuItem key={2} value={"Maintainence"}>{"Maintainence"}</MenuItem>
                            </TextField>
                                */}

                            <TextField
                                select
                                label="Location"
                                id="filterLocation"
                                sx={{ m: 1, width: '25ch' }}
                                size="small"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><LocationIcon /></InputAdornment>,
                                }}
                                value={filterState.location}
                                onChange={(v) => { filterDispatch({ location: v.target.value }) }}
                            >
                                {locations.json ? locations.json.map((v, i) => { return (<MenuItem key={i} value={v.name}>{v.name}</MenuItem>) }) : []}

                            </TextField>

                            <TextField
                                label="Serial"
                                id="filterSerial"
                                sx={{ m: 1, width: '25ch' }}
                                size="small"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><IDIcon /></InputAdornment>,
                                }}
                                value={filterState.serial}
                                onChange={(v) => { filterDispatch({ serial: v.target.value }) }}
                            />

                            <TextField
                                label="Results per page"
                                id="filterNumPerPage"
                                sx={{ m: 1, width: '25ch' }}
                                size="small"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><IDIcon /></InputAdornment>,
                                }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                type={"number"}
                                value={filterState.limit}
                                onChange={(v) => { filterDispatch({ limit: Number(v.target.value) }) }}
                            />


                        </div>
                    </Box>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div>
                            <Button id="filterApplyBtn" variant="contained" endIcon={<FilterIconButton />} sx={{ m: 1, width: '25ch' }} size="small" onClick={()=>{onApply(filterState)}}>
                                Apply
                            </Button>
                            <Button id="filterResetBtn" variant="contained" sx={{ m: 1, width: '25ch' }} size="small" color='secondary' disabled={IsFormChanged()} onClick={()=>{filterDispatch(InitialFormState)}}>
                                Reset
                            </Button>
                        </div>
                    </Box>
                </AccordionDetails>
            </Accordion>

        </>
    );
}

export default PlatformFilter;