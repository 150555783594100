import { Modal, Box } from "@mui/material";
import { useReducer, useState } from "react";
import { DefaultReducer } from "../../../../components/DefaultReducer";
import {
  TextFieldInput,
  // TextFieldInputInactive,
  // TextInputAreaInactive,
  TextInputArea,
} from "../../../../components/Inputs";
import { Button } from "../../../../components/Buttons";
import { UserRemoveSoftwareModal } from "./UserRemoveSoftwareModal";

const MODAL_STATE = {
  NONE: "none",
  REMOVE_SOFTWARE: "remove_software",
};

const UserUpdateSoftwareModal = ({ onClose, onRemove, onUpdate, data }) => {
  const [formState, formStateDispatch] = useReducer(DefaultReducer, data);
  const [modalState, setModalState] = useState(MODAL_STATE.NONE);

  const UserUpdateSoftwareDropdown = () => {
    const handleSelectChange = (event) => {
      const value = event.target.value;

      formStateDispatch({ active: value });
    };

    return (
      <div className="">
        <div className="text-xs font-medium pb-2">
          <h2 className="text-xs font-medium pb-2">Active</h2>
          <select
            className={`placeholder:text-slate-500 placeholder:font-medium block placeholder:text-xs  w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-sm `}
            value={formState.active}
            onChange={handleSelectChange}
          >
            <option>true</option>
            <option>false</option>
          </select>
        </div>
      </div>
    );
  };

  const getUpdate = () => {
    const fields = [
      "name",
      "company",
      "version",
      "size",
      "active",
      "notes",
      "documentation",
    ];
    let update = {};
    fields.forEach((k) => {
      if (formState[k] !== data[k]) {
        update[k] = formState[k];
      }
    });
    return update;
  };

  // console.log(data);

  return (
    <div>
      <Modal
        open={true}
        onClose={onClose}
        sx={{ outline: 0 }}
        disableEnforceFocus={true}
        disableAutoFocus={true}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "background.paper",
            border: "0px solid #000",
            boxShadow: 5,
            pt: 1,
            px: 2,
            pb: 3,
          }}
        >
          <div className="border-b">Update Software (UserView)</div>
          <div className="grid grid-cols-1 gap-5 pt-2">
            <TextFieldInput
              name={"Name"}
              placeholder={"name"}
              disabled={true}
              value={formState.name}
            />
          </div>
          <div className="grid grid-cols-2 gap-5 pt-4">
            <TextFieldInput
              name={"Company"}
              placeholder={"company"}
              disabled={true}
              onChange={(v) => {
                formStateDispatch({ company: v });
              }}
              value={formState.company}
            />
            <TextFieldInput
              name={"Version"}
              placeholder={"version"}
              onChange={(v) => {
                formStateDispatch({ version: v });
              }}
              disabled={true}
              value={formState.version}
            />
            <TextFieldInput
              name={"Size"}
              placeholder={"size"}
              onChange={(v) => {
                formStateDispatch({ size: v });
              }}
              disabled={true}
              value={formState.size}
            />
            <UserUpdateSoftwareDropdown />
          </div>
          <div className="grid grid-cols-1 gap-5 pt-4">
            <TextInputArea
              name={"Notes"}
              placeholder={"notes"}
              value={formState.notes}
              onChange={(v) => {
                formStateDispatch({ notes: v });
              }}
              rows={3}
            />
            <TextInputArea
              name={"Documentation"}
              placeholder={"documentation"}
              value={formState.documentation}
              onChange={(v) => {
                formStateDispatch({ documentation: v });
              }}
              rows={3}
            />
          </div>
          <div className="mt-4 flex flex-row justify-end">
            <div className="mr-4">
              <Button
                label={"Remove"}
                variant={"secondary"}
                onClick={() => {
                  setModalState(MODAL_STATE.REMOVE_SOFTWARE);
                }}
              />
            </div>
            <div className="mr-4">
              <Button
                label={"Cancel"}
                variant={"secondary"}
                onClick={onClose}
              />
            </div>
            <div>
              <Button
                label={"Update Software"}
                onClick={() => {
                  onUpdate(data.softwareId, getUpdate());
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>
      {modalState === MODAL_STATE.REMOVE_SOFTWARE ? (
        <UserRemoveSoftwareModal
          software={data}
          onClose={() => {
            setModalState(MODAL_STATE.NONE);
          }}
          onRemove={onRemove}
        />
      ) : null}
    </div>
  );
};

export default UserUpdateSoftwareModal;
