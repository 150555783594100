import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography, Button, InputAdornment } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { useState, useReducer } from 'react';
import FormLoading from './FormLoading';
import theme from './SignUpFormTheme';
import { FieldInputDefaultState, FormReducer, InitialFormStateInputFieldProps } from './SignUpFormReducer';
import { confirmPassword, getAccessToken } from './UserPool';
import { useDispatch } from 'react-redux';
import { logInActions } from '../../store';
import { User as GetUserProfile } from '../../services/Users';
import { TOKENS } from "../../config";


const InitialFormState = {
    verificationCode: FieldInputDefaultState('Verification code'),
    newPassword: FieldInputDefaultState('New password'),
    confirmPassword: FieldInputDefaultState('Confirm password'),
}

const InputField = ({ label, additionalProps = {} }) => {
    return (
        <Box className="form-input-field">
            <TextField
                required
                label={label}
                size='small'
                fullWidth={true}
                {...additionalProps}
            />
        </Box>
    )
}

const ERRORS = {
    CODE_MISMATCH: 'CodeMismatchException',
    INVALID_PASSWORD: 'InvalidPasswordException',
    LIMIT_EXCEEDED: 'LimitExceededException'
};

const PasswordResetVerification = ({ returnToLogin, userDetails }) => {

    const [loading, setLoading] = useState(false);
    const [resetComplete, setResetComplete] = useState(false);
    const [failureMessage, setFailureMessage] = useState('');
    const [formState, formStateDispatch] = useReducer(FormReducer, InitialFormState);
    const dispatch = useDispatch();

    const loginSuccess = (result) => {

        const user_id = result.getIdToken().payload.sub;
        localStorage.setItem(TOKENS.AUTH_TOKEN, String(result.getAccessToken().jwtToken));
        localStorage.setItem(TOKENS.REFRESH_TOKEN, String(result.getRefreshToken().token));                
        GetUserProfile(user_id)
        .then(r => r.json())
        .then(user => {
                setLoading(false);
                dispatch(logInActions.logIn({ token: result.getAccessToken().jwtToken, refreshToken: result.getRefreshToken().token, user: user }));
            });
    }

    const loginFailure = (result) => {
        setLoading(false);
    }

    const onSubmitSuccess = () => {
        setLoading(false);
        setResetComplete(true);
        setTimeout(() => {
            getAccessToken(userDetails.value, formState.newPassword.value, loginSuccess, loginFailure);
        }, 500);
    }

    const onSubmitFailure = (err) => {
        setLoading(false);
        switch (err.name) {
            case ERRORS.CODE_MISMATCH:
                {
                    formStateDispatch({ field: 'verificationCode', message: err.message, valid: false });
                }
                break;

            case ERRORS.INVALID_PASSWORD:
                {
                    formStateDispatch({ field: 'newPassword', message: err.message, valid: false });
                    formStateDispatch({ field: 'confirmPassword', valid: false, message:'' });
                }
                break;

            case ERRORS.LIMIT_EXCEEDED:
                {
                    setFailureMessage(err.message);
                }
                break;

            default:
                break;
        }
    }

    const onSubmit = () => {
        setLoading(true);
        confirmPassword(userDetails.value, formState.verificationCode.value, formState.confirmPassword.value, onSubmitSuccess, onSubmitFailure);
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {},
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <ThemeProvider theme={theme}>
                    <Typography variant='h1'>Reset Password</Typography>
                </ThemeProvider>
                <div className="signin-form-line-text">
                    A verification code has been sent to:
                    <div className="highlight">{userDetails.value}</div>
                </div>

                <InputField label={"Verification code"} additionalProps={{ ...InitialFormStateInputFieldProps(formState, formStateDispatch, 'verificationCode') }} />
                <InputField label={"New Password"} additionalProps={{ ...InitialFormStateInputFieldProps(formState, formStateDispatch, 'newPassword'), type: 'password' }} />
                <InputField label={"Confirm Password"} additionalProps={{
                    ...InitialFormStateInputFieldProps(formState, formStateDispatch, 'confirmPassword'), type: 'password',
                    onChange: (v) => {
                        if (v.target.value === formState.newPassword.value) {
                            formStateDispatch({ field: 'confirmPassword', value: v.target.value, valid: true });
                        } else {
                            formStateDispatch({ field: 'confirmPassword', value: v.target.value, message: 'Passwords dont match', valid: false });
                        }
                    }
                }} />

                {resetComplete ?
                    <div className="signin-form-line-text success">
                        Reset successful
                    </div> : ''
                }

                <div className="signin-form-line-text failure">
                    {failureMessage}
                </div>

                <Box className="form-input-field">
                    <Button variant='contained' sx={{ width: '100%' }} size='small' onClick={onSubmit}>submit</Button>
                </Box>

                <Box className="form-input-field signin-form-line-text">
                    Return to<span className="highlight-link cursor-on-hover" onClick={returnToLogin}> login</span>
                </Box>

            </div>
            {loading ? <FormLoading /> : ''}
        </Box>
    );
}

export default PasswordResetVerification;