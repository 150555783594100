const FieldInputDefaultState = (name) => {
    return {
        value: '',
        valid: true,
        message: `${name} required`,
        visible: true
    }
}

const InitialFormStateInputFieldProps = (state, dispatch, field) => {
    return {
        error: !state[field].valid,
        value: state[field].value,
        onChange: (v) => dispatch({ field: field, value: v.target.value }),
        helperText: (!state[field].valid ? state[field].message : '')
    }
}

const InitialFormState = {
    fullName: FieldInputDefaultState('Full Name'),
    email: FieldInputDefaultState('Email'),
    password: FieldInputDefaultState('Password'),
}


const FormReducer = (state, action) => {
    let newState = { ...state };
    if (action.event) {
        if (action.event === 'SUBMIT') {
            Object.keys(newState).forEach(k => {
                newState[k].valid = true;
                newState[k].message = InitialFormState[k].message;
            })
        }
    }
    else {
        // value/state update
        Object.keys(action).filter(k => (k !== 'field')).forEach(k => {
            newState[action.field][k] = action[k];
        });
    }

    return newState;
}

export { FieldInputDefaultState, InitialFormStateInputFieldProps, InitialFormState, FormReducer };