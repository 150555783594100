//import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';

import './LoadingModal.css';
import { useEffect } from 'react';
import { Dialog } from '@mui/material';

let style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

const LoadingModal = ({ show }) => {

    /*
    useEffect(()=>{
        console.log(window.innerWidth);
        
    }, [])*/

    return (
        <div>
            <Modal
                open={show}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                sx={{ outline: 0 }}
                disableEnforceFocus={true}
                disableAutoFocus={true}
            >
                <Box sx={{ ...style }}>
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress size={100} />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

const LoadingMain = ({ show }) => {
    return (
        <div>

                <Box>
                    <div className="main-item">

                        <div className="animated-background">
                            <div className="background-masker btn-divide-left"></div>
                        </div>

                        <div className="animated-background">
                            <div className="background-masker btn-divide-left"></div>
                        </div>
                        <div className="static-background">
                            <div className="background-masker btn-divide-left"></div>
                        </div>


                    </div>
                </Box>
        </div>
    );
}
export default LoadingModal;
export {LoadingModal, LoadingMain};