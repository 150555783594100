import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

const PoolData = {
    UserPoolId: process.env.REACT_APP_AUTH_USERPOOL_ID,
    ClientId: process.env.REACT_APP_AUTH_CLIENT_ID,
};

const UserPool = new AmazonCognitoIdentity.CognitoUserPool(PoolData);

const signUp = async (email, password, callback) => {
    UserPool.signUp(email, password, [], [], callback);
}

const getAccessToken = (email, password, onSuccess, onFailure) => {
    var authenticationData = {
        Username: email,
        Password: password,
    };

    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
    var userData = {
        Username: email,
        Pool: UserPool,
    };

    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, { onSuccess, onFailure });
}

const verifyUser = (email, code, callback) => {
    var userData = {
        Username: email,
        Pool: UserPool,
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.confirmRegistration(code, true, callback);
}

const resetPassword = (email, onSuccess, onFailure, inputVerificationCode) => {

    var userData = {
        Username: email,
        Pool: UserPool,
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.forgotPassword({onSuccess, onFailure, inputVerificationCode});
}

const confirmPassword = (email, verificationCode, newPassword, onSuccess, onFailure) => {

    var userData = {
        Username: email,
        Pool: UserPool,
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.confirmPassword(verificationCode, newPassword, {onSuccess,onFailure});
}

const signOut = (email, callback) => {

    var userData = {
        Username: email,
        Pool: UserPool,
    };

    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.signOut(callback);
}

const _refreshSession = (email, refreshToken, callback) => {

    var userData = {
        Username: email,
        Pool: UserPool,
    };

    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    let temp = new AmazonCognitoIdentity.CognitoRefreshToken({RefreshToken: refreshToken});

    cognitoUser.getSession((err, session)=>{
        console.log(err, session);
    });

//    cognitoUser.refreshSession( new AmazonCognitoIdentity.CognitoRefreshToken(refreshToken), callback, cognitoUser);
//    cognitoUser.refreshSession( temp, callback, cognitoUser);
}



// var refresh_token = session.getRefreshToken(); // receive session from calling cognitoUser.getSession()
// if (AWS.config.credentials.needsRefresh()) {
// 	cognitoUser.refreshSession(refresh_token, (err, session) => {
// 		if (err) {
// 			console.log(err);
// 		} else {
// 			AWS.config.credentials.params.Logins[
// 				'cognito-idp.<YOUR-REGION>.amazonaws.com/<YOUR_USER_POOL_ID>'
// 			] = session.getIdToken().getJwtToken();
// 			AWS.config.credentials.refresh(err => {
// 				if (err) {
// 					console.log(err);
// 				} else {
// 					console.log('TOKEN SUCCESSFULLY UPDATED');
// 				}
// 			});
// 		}
// 	});
// }




export {UserPool, signUp, getAccessToken, verifyUser, resetPassword, confirmPassword, signOut, _refreshSession}