import { TOKENS } from '../config'

const LOG_API_REQUESTS = true;

const Log = (txt) => {
    if(LOG_API_REQUESTS)
        console.log('REQUEST ', txt);
}

const GET = (url)=>{
    Log(`GET ${url}`);
    return fetch(url, {method: 'GET', headers:{'Authorization': `${localStorage.getItem(TOKENS.AUTH_TOKEN)}`}})
}

const POST = (url, body)=>{
    Log(`POST ${url}`);
    return fetch(url, {method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem(TOKENS.AUTH_TOKEN)}` }, 
    body: JSON.stringify(body)})
}

const FORM_POST = (url, body)=>{
    Log(`FORM_POST ${url}`);
    return fetch(url, {method: 'POST', headers: { 'Authorization': `${localStorage.getItem(TOKENS.AUTH_TOKEN)}` }, 
    body: body})
}

const DELETE = (url, body)=>{
    Log(`DELETE ${url}`);
    return fetch(url, {method: 'DELETE', headers: { 'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem(TOKENS.AUTH_TOKEN)}` }, 
    body: JSON.stringify(body)})
}

const PATCH = (url, body)=>{
    Log(`PATCH ${url}`);
    return fetch(url, {method: 'PATCH', headers: { 'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem(TOKENS.AUTH_TOKEN)}` }, 
        body: JSON.stringify(body)})
}

export { GET, POST, DELETE, PATCH, FORM_POST };