import { useEffect, useState } from "react";
import { Modal, TextField, Button, Divider, MenuItem, Card } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from "@mui/material";
import { Locations as GetLocations } from '../../../../services/Locations';

import { Delete } from "@mui/icons-material";
import { Clear } from "@mui/icons-material";
import { StockListing } from "../../../../models/PartsModel";



const StockItem = ({ serial, onRemove }) => {
    return (
        <Card sx={{ marginLeft: 0.5, marginRight: 0.5, paddingLeft: 0.5, paddingRight: 0.5, height: 40, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', color: '#555', marginTop: 0.2 }}>
            <Typography>{serial}</Typography>
            <Clear sx={{ fontSize: 18 }} className="cursor-on-hover" onClick={() => { onRemove(serial) }} />
        </Card>
    )
}

const AdminStockModal = ({ stock = null, onClose, onAddStock, onUpdateStock }) => {
    //    const [updatedStock, setUpdatedStock] = useState(stock);
    const [locations, setLocations] = useState([]);



    //    const [formState, setFormState] = useState({ location: '', items: [] })
    const [formState, setFormState] = useState(stock || new StockListing());
    const [serial, setSerial] = useState('')

    /*
    const handleSerialNumberChange = (index, value) => {
        const updatedStockCopy = [...updatedStock];
        updatedStockCopy[index].items = value;
        setUpdatedStock(updatedStockCopy);
    };
    
    const handleSave = () => {
        onUpdateStock(updatedStock);
        onClose();
    };
    */

    useEffect(() => {
        GetLocations()
            .then(r => r.json())
            .then(r => setLocations(r));
    }, []);

    //    console.log(locations);


    const addSerial = () => {
        let s = { ...formState };
        s.items.push(serial);
        setFormState(s);
        setSerial('');
    }


    const removeSerial = (serialToRemove) => {
        let s = { ...formState };
        s.items = s.items.filter(k => (k !== serialToRemove))
        setFormState(s);
    }

    const isSerialValid = () => (serial !== '' && !formState.items.includes(serial));


    return (
        <Modal open={true} onClose={onClose}>
            <div style={{
                background: "white",
                //                padding: 20,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                background: "white",
                width: 650,

            }}>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
                    <Typography>Edit Part Stock</Typography>
                    <CloseIcon sx={{ marginLeft: 'auto' }} color="text.secondary" onClick={onClose} />
                </div>
                <Divider />


                <div style={{ padding: 10 }}>

                    <TextField
                        select
                        label="Location"
                        size="small"
                        value={formState.location}
                        onChange={(e) => {
                            let s = { ...formState };
                            s.location = e.target.value;
                            setFormState(s);
                        }}
                        fullWidth
                        margin="normal"
                        disabled={stock !== null}
                    >
                        {locations.map((loc, i) => { return <MenuItem value={loc.name} key={i}>{loc.name}</MenuItem> })}
                    </TextField>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>

                        <TextField

                            label="Serial"
                            size="small"
                            value={serial}
                            onChange={(e) => {
                                setSerial(e.target.value);
                            }}
                            fullWidth
                            margin="normal"
                        />

                        <Button variant="contained" sx={{ height: 30, marginLeft: 1 }} onClick={addSerial} disabled={!isSerialValid()}>Add</Button>

                    </div>

                    <div style={{ height: 200, overflow: 'auto', border: '1px solid #aaa', borderRadius: 4 }}>
                        {formState.items.map((k, i) => { return <StockItem key={i} serial={k} onRemove={removeSerial} /> })}
                    </div>


                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="outlined" onClick={onClose} sx={{ marginTop: 1, marginRight: 1 }} color="secondary">
                            cancel
                        </Button>

                        {stock ?
                            <Button variant="contained" onClick={() => { onUpdateStock(formState) }} sx={{ marginTop: 1 }}>
                                Update
                            </Button> :
                            <Button variant="contained" onClick={() => { onAddStock(formState) }} sx={{ marginTop: 1 }}>
                                Add
                            </Button>
                        }





                    </div>

                </div>


            </div>
        </Modal>
    );
};

export default AdminStockModal;
