const FilterReducer = (state, action) => {

    let _state = { ...state };
    Object.keys(action).forEach(k => {
        _state[k] = action[k];
    })
    return { ..._state };
}

const InitialFormState = { location: '', platformID: '', model: '', serial: '', manufacturer: '', limit:10 };

export {FilterReducer, InitialFormState}