import { Modal, Box } from "@mui/material";
import { useReducer, useState, useEffect } from "react";
import { DefaultReducer } from "../../../../components/DefaultReducer";
import { TextFieldInput, TextInputArea } from "../../../../components/Inputs";
import { Button } from "../../../../components/Buttons";
import { Software } from "../../../../models/SoftwareModel";
import { GetSoftware } from "../../../../services/Software";
import UserAddSoftwareDropdown from "./UserAddSoftwareDropdown";
import { PlatformAddSoftwareTask } from "../../../../models/TasksModel";
import { useSelector } from "react-redux";
import { CreateTask } from "../../../../services/Tasks";

import { Spinner } from "../../../../components/Spinners";
import { SuccessNotification } from "../../../../components/Notifications";

const InitialFormState = new Software();

const UserAddSoftwareModal = ({ onClose, onAdd }) => {
  const platform = useSelector((state) => state.platform.platform);
  const user = useSelector((state) => state.auth.user);

  const [softwareList, setSoftwareList] = useState({ data: [] });
  const [selectedOption, setSelectedOption] = useState(InitialFormState);

  const [formState, formStateDispatch] = useReducer(
    DefaultReducer,
    InitialFormState
  );

  const [requestState, requestStateDispatch] = useReducer(DefaultReducer, {
    loading: false,
    requested: false,
  });

  const handleDropdownSelect = (objectName) => {
    const softwareArray = softwareList.data;

    const selected = softwareArray.find((item) => item.name === objectName);

    setSelectedOption(selected);
    console.log("SoftwareModal component value (search match): ", { selected });
  };

  const UserCreateSoftwareList = () => {
    GetSoftware()
      .then((r) => r.json())
      .then((res) => {
        setSoftwareList(res);
      });
  };

  const createAddSoftwareTask = (doc) => {
    const s = new PlatformAddSoftwareTask(platform._id, doc._id);
    s.SetReporter(user);
    s.SetDescription(`Add ${doc.name} ${doc.version} (${doc.company})`);
    requestStateDispatch({ loading: true, requested: true });
    CreateTask(s)
      .then((r) => r.json())
      .then((r) => {
        requestStateDispatch({ loading: false });
        setTimeout(() => {
          onClose();
        }, 1000);
        // onClose();
      });
  };

  useEffect(() => {
    UserCreateSoftwareList();
  }, []);

  return (
    <div>
      <Modal
        open={true}
        onClose={onClose}
        sx={{ outline: 0 }}
        disableEnforceFocus={true}
        disableAutoFocus={true}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "background.paper",
            border: "0px solid #000",
            boxShadow: 5,
            pt: 1,
            px: 2,
            pb: 3,
          }}
        >
          <div className="border-b">Add Software to {platform.name}</div>
          <UserAddSoftwareDropdown
            options={softwareList.data}
            onSelect={handleDropdownSelect}
          />
          <div className="grid grid-cols-1 gap-5 pt-2">
            <TextFieldInput
              name={"Name"}
              disabled={true}
              placeholder={"name"}
              onChange={(v) => {
                // formStateDispatch({ name: v });
              }}
              value={selectedOption.name}
            />
          </div>
          <div className="grid grid-cols-1 gap-5 pt-2">
            <TextFieldInput
              name={"Version"}
              disabled={true}
              placeholder={"version"}
              onChange={(v) => {
                // formStateDispatch({ name: v });
              }}
              value={selectedOption.version}
            />
          </div>
          <div className="grid grid-cols-1 gap-5 pt-2">
            <TextFieldInput
              name={"Company"}
              disabled={true}
              placeholder={"company name"}
              onChange={(v) => {
                // formStateDispatch({ name: v });
              }}
              value={selectedOption.company}
            />
          </div>

          {requestState.loading && requestState.requested ? (
            <div className="flex justify-center my-4">
              <Spinner />
            </div>
          ) : null}

          {!requestState.loading && requestState.requested ? (
            <div className="flex justify-center my-4">
              <SuccessNotification text={"Add software task created"} />
            </div>
          ) : null}

          <div className="mt-4 flex flex-row justify-center">
            <div className="px-2">
              <Button
                label={"Cancel"}
                variant={"secondary"}
                onClick={onClose}
              />
            </div>
            <div className="px-2">
              <Button
                label={"Add Software"}
                onClick={() => {
                  createAddSoftwareTask(selectedOption);
                }}
              />
            </div>
            {/* <div className="px-2">
              <Button
                label={"test"}
                onClick={() => {
                  console.log(softwareList.data);
                }}
              />
            </div> */}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default UserAddSoftwareModal;
