import AdminLocationList from "./AdminLocationsList"
import { Routes, Route } from "react-router-dom";


const AdminLocation = () => {


    return (

        <Routes>
            <Route path="/" element={<AdminLocationList />} />
        </Routes>

    )
}

export default AdminLocation;