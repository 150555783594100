import { Typography, Paper, Box } from "@mui/material";
import PartRequestCard from "./PartRequestCard";

const PartsRequestColumn = ({ name, parts, onSelect }) => {

    return (
        <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#ddd', width: 280, minWidth: 180, marginLeft: 0.8, paddingBottom: 0.2 }}>
            <Box sx={{ height: 25 }}>
                <Typography id="Heading" variant="h6" component="h6" color="text.secondary" style={{ fontSize: 12, paddingTop: 3, textAlign: 'center' }}>
                    {name}
                </Typography>
            </Box>
            {parts.map((p, i) => {return (<PartRequestCard key={i} task={p} onSelect={onSelect} type={name}/>)})}
        </Paper>
    )
}

export default PartsRequestColumn;