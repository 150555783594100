import { useEffect, useState, useContext, useReducer } from 'react';
import PlatformFilter from './PlatformFilter';
import { InitialFormState } from './PlatformFilterReducer';
import { useNavigate } from "react-router-dom";
import { platformActions, projectsActions } from '../../store';
import { useDispatch } from 'react-redux';
import PlatformPagination from './PlatformListPagination';
import { useSelector } from 'react-redux';
import { LoadingMain } from '../../components/LoadingModal';
import DynamicTable from '../../components/DynamicTable';
import { Avatar } from '@mui/material';
import { LocationIcon, ScheduleIcon, CheckCircleIcon, ExclaimationCircleIcon } from '../../components/Icons';
import { GetProject } from '../../services/Projects';
import { MainContainer } from '../../components/Containers';

const PlatformList = () => {

    const project = useSelector(state => state.projects.selected);
    const [platformFilter, SetFilter] = useState(InitialFormState);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [page, SetPage] = useState(0);

    const applyFilter = (params) => {
        SetPage(0);
        SetFilter(params);
    }

    const changePage = (value) => {
        SetPage(value - 1);
    }

    useEffect(() => {
        GetProject(project._id)
            .then(r => r.json())
            .then(r => dispatch(projectsActions.setSelectedProject(r)));
    }, [])

    const platformSelection = (platform) => {
        dispatch(platformActions.setPlatform(platform));
        navigate(`${String(platform._id)}/parts`);
    }

    // if (!project && !project?.platforms) {

    //     return (
    //         <div style={{ backgroundColor: "#F1F1F0" }} className="h-screen pt-6">
    //             <MainContainer className="border-none">
    //                 <LoadingMain />
    //             </MainContainer>
    //         </div>
    //     )
    // }


    const platformFilterFunc = (platform, key, value) => platform[key].includes(value);

    const filterKeys = (f) => Object.keys(f).filter(k => ((f[k] !== '') && (k !== 'limit')))

    const transformFilter = (filter, filterKeys) => {
        let f = {};
        filterKeys(filter).forEach(k => { f[k] = filter[k] });
        return f;
    }

    const createTableFilter = (filter, filterFunc, transformFilter) => (platform) => {
        const obj = transformFilter(filter, filterKeys);
        if (Object.keys(obj).length === 0) {
            return true;
        }
        return !Object.keys(obj).map(k => filterFunc(platform, k, obj[k])).includes(false);
    }

    const platformTableFilter = createTableFilter(platformFilter, platformFilterFunc, transformFilter);

    const platformSortByKey = (key) => (x, y) => {
        if(x[key] < y[key]) {
            return -1;
        }
        if(x[key] > y[key]) {
            return 1;
        }
        return 0;
    }

    return (

        <div style={{ backgroundColor: "#F1F1F0" }} className="h-screen pt-6">

            <MainContainer className="border-none">

                {/* <div className="platform-individual-document-container"> */}
                <div className='flex flex-row'>
                    <div className='flex flex-col justify-center'>
                        <Avatar src={project.img} />
                    </div>
                    <div className='flex flex-col font-semibold ml-4 border-r pr-6 justify-center'>
                        <div>
                            {project.name}
                        </div>
                    </div>
                    <div className='ml-4'>
                        <PlatformFilter onApply={applyFilter} InitialFormState={InitialFormState} />
                    </div>
                </div>
                {/* </div> */}
            </MainContainer>







            {(!project && !project?.platforms) ?
                <div style={{ backgroundColor: "#F1F1F0" }} className="h-screen pt-6">
                    <MainContainer className="border-none">
                        <LoadingMain />
                    </MainContainer>
                </div> :
                        
                <MainContainer className="border-slate-300 mt-4">

                    {/* <div className="platform-individual-document-container"> */}

                    <div className='mt-4'>
                        <div className=' font-normal text-sm pb-2'>
                            Total Platforms: {project.platforms.length}
                        </div>

                        <DynamicTable
                            data={project?.platforms.filter(platformTableFilter).sort( platformSortByKey('_id') ).map((platform, i) => [

                                <div key={`${i}user`} className="flex" onClick={() => { platformSelection(platform) }}>
                                    <Avatar src={platform.img} />
                                    <div className="flex flex-col ml-4">
                                        <div className=" text-sm font-medium text-slate-800">{platform.name}</div>
                                        <div className="text-slate-500">{platform.serial}</div>
                                    </div>
                                </div>,

                                <div className="flex flex-col" onClick={() => { platformSelection(platform) }}>
                                    <div>{platform.manufacturer}</div>
                                    <div>{platform.model}</div>
                                </div>,


                                <div className='flex flex-row' onClick={() => { platformSelection(platform) }}><LocationIcon className='w-4 h-4' strokeWidth={1.0} /> {platform.location}</div>,

                                <div className="flex flex-row" onClick={() => { platformSelection(platform) }}>
                                    <div><ScheduleIcon strokeWidth={1.0} className='w-4 h-4 mr-1' /></div>
                                    <div>{platform.parts.length}</div>
                                </div>,

                                <div onClick={() => { platformSelection(platform) }}>
                                    {
                                        platform.status === "ACTIVE" ?
                                            <div className="flex flex-row text-green-700 font-medium text-sm">
                                                <div><CheckCircleIcon strokeWidth={1.5} className='w-5 h-5 mr-1' /></div>
                                                <div>Active</div>
                                            </div> :
                                            <div className="flex flex-row text-red-700 font-medium text-sm">
                                                <div><ExclaimationCircleIcon strokeWidth={1.5} className='w-5 h-5 mr-1' /></div>
                                                <div>Maintenance</div>
                                            </div>
                                    }
                                </div>,

                            ])}
                            headerData={['Name / serial', 'Manufacturer / Model', 'Location', 'Tasks', 'Status']}
                        />
                    </div>

                    <div className='flex justify-center mt-4'>
                        <PlatformPagination total={project.platforms.length} pageLimit={platformFilter.limit} onChange={changePage} page={page + 1} />
                    </div>
                    {/* </div> */}
                </MainContainer>
            }
        </div>
    )
}

export default PlatformList;