import { Card, Typography } from "@mui/material"
import EyeIcon from '@mui/icons-material/Visibility';
import LinkIcon from '@mui/icons-material/Link';
import Avatar from '@mui/material/Avatar';
import { Box } from "@mui/system";
import './PartRequestCard.css';
import { useEffect, useState } from "react";
import { Platform as GetPlatform } from "../../../services/Platforms";
import LocationOn from "@mui/icons-material/LocationOn";
import Flight from "@mui/icons-material/Flight";
import { Settings } from "@mui/icons-material";

const getDate = (timestamp) => {
    if(timestamp)
    {
        let date = new Date(timestamp);
        let current = new Date();
        let diff_seconds = Math.floor((current - date)/(1000));
        let diff_minutes = Math.floor((current - date)/(1000*60));
        let diff_hours = Math.floor((current - date)/(1000*60*60));
        let diff_days = Math.floor((current - date)/(1000*60*60*24));
        if(diff_seconds < 60)
            return `${diff_seconds} seconds ago`
        if(diff_minutes < 60)
            return `${diff_minutes} mins ago`
        if(diff_hours < 24)
            return `${diff_hours} hours ago`
        else
            return `${diff_days} days ago`
    }
    return "12/12/12";
}


const PartRequestCard = ({task, onSelect, type}) => {

    const [platform, setPlatform] = useState({location:'', name:''});

//    console.log(task);

    useEffect(()=>{
        GetPlatform(task.platformID)
        .then(r=>{return r.json()})
        .then(r=>{
            setPlatform(r);
        });
    }, [task]);

    return (

        <Card sx={{}} onClick={() => { onSelect({part:task, type:type}) }} className="partRequestCard" draggable="true">
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Avatar sx={{ bgcolor: '#e0e0e0', width: 25, height: 25, fontSize: 10, margin: 0.5 }} src={task.reporter.picture}/>
                <Typography fontSize={13} sx={{ marginTop: 0.8, marginLeft: 0.5 }}>
                    {task.part.name}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', height: 19 }}>

                <Box>
                    <Typography fontSize={12} sx={{ marginTop: 0.5, marginLeft: 0.5 }} color="text.secondary">
                        <Flight sx={{ fontSize: 12, marginTop: -0.2 }} />{platform.name}
                    </Typography>

                </Box>
                <Box>
                    <Typography fontSize={12} sx={{ marginTop: 0.5, marginLeft: 2 }} color="text.secondary">
                        <Settings sx={{ fontSize: 12, marginTop: -0.2 }} />{task.part.type.toLowerCase()}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', height: 19 }}>
                <Box>

                    <Typography fontSize={12} sx={{ marginTop: 0.5, marginLeft: 0.5 }} color="text.secondary">
                        <LocationOn sx={{ fontSize: 12, marginTop: -0.2 }} />{platform.location}
                    </Typography>

                </Box>
                <Box>
                    {}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography fontSize={12} sx={{ marginTop: 0.5, marginLeft: 0.5 }} color="text.secondary">
                    {getDate(task.updatedAt)}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto', marginRight: 0.5 }}>
                {}
                    <LinkIcon sx={{ color: '#888', fontSize: 16, marginTop: 0.7, marginRight: 0.8 }} />
                    {/*
                        task.assignee ? <Avatar sx={{ width: 25, height: 25, display: 'flex' }} src={task.assignee.picture} /> :
                            <Avatar sx={{ bgcolor: deepOrange[500], width: 25, height: 25, fontSize: 10, display: 'flex' }}></Avatar>*/
                    }
                </Box>
            </Box>
        </Card>

    )
}

export default PartRequestCard;