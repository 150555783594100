import { Avatar, Box, Button, Card, Divider, Typography } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Projects as GetProjects } from "../../services/Projects";
import { projectsActions } from "../../store";
import { useNavigate } from "react-router-dom";
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import AirplanemodeActiveTwoToneIcon from '@mui/icons-material/AirplanemodeActiveTwoTone';
import KayakingSharpIcon from '@mui/icons-material/KayakingSharp';
import './Projects.css';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';
import ProjectsCreateModal from "./ProjectsCreateModal";
import ProjectsEditModal from "./ProjectsEditModal";
import { RequestErrorHandlerContextNEW } from "../../App";

import AnchorIcon from '@mui/icons-material/Anchor';
// import AttractionsIcon from '@mui/icons-material/Attractions';
// import AutoGraphIcon from '@mui/icons-material/AutoGraph';
// import CableIcon from '@mui/icons-material/Cable';
import CallSplitIcon from '@mui/icons-material/CallSplit';
// import ChairAltIcon from '@mui/icons-material/ChairAlt';
// import ConstructionIcon from '@mui/icons-material/Construction';
// import CycloneIcon from '@mui/icons-material/Cyclone';
import DiamondIcon from '@mui/icons-material/Diamond';

// import { useAPIGet } from "../../hooks/APIRequest";
import { useAPI } from "../../hooks/APIRequest";
import { API } from "../../config";
import ConnectionError from "../../components/ConnectionError";
import LoadingModal from "../../components/LoadingModal";

/* temporary icon, replace when picture upload is working */
// const getRandomIcon = () => {
//     const icons = [
//         () => { return <AnchorIcon /> },
//         () => { return <AttractionsIcon /> },
//         () => { return <AutoGraphIcon /> },
//         () => { return <CableIcon /> },
//         () => { return <CallSplitIcon /> },
//         () => { return <ChairAltIcon /> },
//         () => { return <ConstructionIcon /> },
//         () => { return <CycloneIcon /> },
//         () => { return <DiamondIcon /> },
//     ];
//     const rand = Math.floor(Math.random() * icons.length);
//     //    return icons[key]();
//     return icons[rand]();
// }

const CustomizedInputBase = () => {
    return (
        <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 300 }}
        >
            {/** 
         * 
      <IconButton sx={{ p: '10px' }} aria-label="menu">
        <MenuIcon />
      </IconButton>
    */}

            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Projects"
                inputProps={{ 'aria-label': 'search google maps' }}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                <FilterAltTwoToneIcon />
            </IconButton>
        </Paper>
    );
}

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: '#ff8080',

    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: 'rgba(100, 100, 100, 0.7)',
        '&.Mui-selected': {
            color: '#555',
            paddingBottom: 0
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(32, 128, 128, 0.32)',
            color: '#000',
            paddingBottom: 0
        },
        paddingBottom: 0
    }),
);

const CustomizedTabs = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ bgcolor: '#fcfcfc' }}>
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                >
                    <StyledTab label="All" />
                    <StyledTab label="Starred" />
                </StyledTabs>
            </Box>
        </Box>
    );
}

const ProjectCardBase = ({ children, onClick, className = "" }) => {

    const DefaultElevation = 1;
    const HoverElevation = 3;
    const [elevation, setElevation] = useState(DefaultElevation);

    return (
        <Card onClick={onClick}
            className={`${className}`}
            elevation={elevation}
            onMouseOver={() => { setElevation(HoverElevation) }}
            onMouseLeave={() => { setElevation(DefaultElevation) }}
        >
            {children}
        </Card>
    )
}

const ProjectCard = ({ project, onClick }) => {


    return (
        <ProjectCardBase className="project-card" onClick={() => { onClick(project) }}>

            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
                {
                    project.img ?
                        <Avatar src={project.img} /> :
                        <DiamondIcon />
                }
                <Typography className="primary" sx={{ paddingLeft: 1 }}>{project.name}</Typography>
            </div>
            <Typography className="secondary" sx={{ paddingLeft: 2 }}>{project.desc}</Typography>
            <Box className="project-detail">
                <Typography className="secondary"><PersonOutlineTwoToneIcon className="project-icon" /> {project.users.length}</Typography>
                <Typography className="secondary"><AirplanemodeActiveTwoToneIcon className="project-icon" /> {project.platforms.length}</Typography>
            </Box>
        </ProjectCardBase>
    )
}

const CreateProjectCard = ({ onClick }) => {
    return (

        <ProjectCardBase className="project-card create-project-card" onClick={onClick}>
            <Typography className="secondary">+ Create Project</Typography>
        </ProjectCardBase>

    )
}

const AllProjectsCard = ({ project, onClick }) => {
    return (
        <ProjectCardBase className="all-projects-card show-pointer-on-hover" onClick={() => { onClick(project) }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                {/* <AnchorIcon /> */}
                {
                    project.img ?
                        <Avatar src={project.img} /> :
                        <DiamondIcon sx={{height:40}}/>
                }
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 2 }}>
                <Typography className="primary"> {project.name}</Typography>
                <Typography className="secondary">{project.desc}</Typography>
            </Box>
        </ProjectCardBase>
    )
}

const RecentProjectContainer = ({ children }) => {
    return (
        <Box className="recent-project-container">
            {children}
        </Box>
    )
}

const ProjectsPage = () => {

    const dispatch = useDispatch();
    const projects = useSelector(state => state.projects);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    // const errorHandler = useContext(RequestErrorHandlerContextNEW);
    // useEffect(() => {
    //     GetProjects()
    //         .then(r => { return errorHandler(r) })
    //         .then(r => r.json())
    //         .then(result => {
    //             dispatch(projectsActions.setProjects(result));
    //         })
    //         .catch(error => {
    //             errorHandler(error);
    //         })
    // }, []);

    // const [getRes, setGetRes] = useState(false);

    // const [testState, initiateRequest] = useState(true);

    const projectRequest = useAPI(API.PROJECTS());


    // useEffect(()=>{
    //     setTimeout(()=>{
    //         const projectRequest = useAPI(API.PROJECTS(), 'GET', {}, getRes);
    //     }, 2000)
    // },[])

    // console.log(projects);
    // console.log(projectRequest);


    useEffect(()=>{
        if(projectRequest.done && projectRequest.json)
        {
            dispatch(projectsActions.setProjects(projectRequest.json));
        }
    },[projectRequest.done])

    // console.log(projectRequest);


    // console.log(_projects);
    const selectProject = (proj) => {
        dispatch(projectsActions.setSelectedProject(proj));
        navigate(proj._id);
    }

    const newProject = (proj) => {
        let p = [];
        Object.keys(projects.projects).forEach(k => p.push(projects.projects[k]));
        dispatch(projectsActions.setProjects([...p, proj]))
        selectProject(proj);
    }

    return (
        <>

            {projectRequest.error ? <ConnectionError error={projectRequest.error} message={`failed to GET ${API.PROJECTS()}`}/> : null}
            <Divider />
            <Box sx={{ backgroundColor: '#f7f7f7', paddingTop: 2, paddingBottom: 4, paddingLeft: 2 }}>
                <Typography variant="h6" sx={{ letterSpacing: -0.7 }}>Recent Projects</Typography>
                <RecentProjectContainer>
                    {Object.keys(projects.projects).map(k => { return projects.projects[k] }).map((p, i) => {
                        return <ProjectCard key={i} project={p} onClick={selectProject} />
                    })}
                    <CreateProjectCard onClick={() => { setShowModal(true) }} />
                </RecentProjectContainer>
            </Box>
            <Divider />
            <Box sx={{ padding: 1, backgroundColor: '#fcfcfc' }}>
                <CustomizedTabs />
                <Box sx={{ paddingLeft: 2, paddingTop: 2 }}>
                    <CustomizedInputBase />
                    <Box sx={{ paddingTop: 2 }}>
                        {Object.keys(projects.projects).map(k => { return projects.projects[k] }).map((p, i) => {
                            return <AllProjectsCard key={i} project={p} onClick={selectProject} />
                        })}
                    </Box>
                </Box>
            </Box>
            {/* {showModal ? <ProjectsCreateModal show={true} onClose={() => { setShowModal(false) }} onCreate={newProject} /> : ''} */}
            {showModal ? <ProjectsEditModal show={true} onClose={() => { setShowModal(false) }} onCreate={newProject} edit={false} /> : ''}
            {(!projectRequest.done && !projectRequest.error) ? <LoadingModal show={true}/> : null}
        </>
    )
}

export default ProjectsPage;