import DynamicTable from "../../../../components/DynamicTable";
import { LoadingMain } from "../../../../components/LoadingModal";
import { Button } from "../../../../components/Buttons";
import AdminAddSoftwareModal from "./AdminAddSoftwareModal";
import AdminUpdateSoftwareModal from "./AdminUpdateSoftwareModal";
import { useEffect, useState } from "react";
import {
  GetSoftware,
  AddSoftware,
  RemoveSoftware,
  UpdateSoftware,
} from "../../../../services/Software";
import { MainContainer } from "../../../../components/Containers";
import { CheckIcon, CloseIcon } from "../../../../components/Icons";

const MODAL_STATE = {
  NONE: "none",
  ADD_SOFTWARE: "add_software",
  REMOVE_SOFTWARE: "remove_software",
  UPDATE_SOFTWARE: "update_software",
};

const AdminSoftware = () => {
  const [softwareList, setSoftwareList] = useState({ data: [] });
  const [modalState, setModalState] = useState({
    state: MODAL_STATE.NONE,
    data: null,
  });
  const renderTable = () => Boolean(softwareList.total);

  const Cell = ({ data, onClick }) => <div onClick={onClick}>{data}</div>;

  const selectRow = (r) =>
    setModalState({ state: MODAL_STATE.UPDATE_SOFTWARE, data: r });

  const createDataArray = (data) =>
    data.map((s) => [
      <div
        // data={s.active}
        onClick={() => {
          selectRow(s);
        }}
        className={`${s.active === "true" ? "text-green-500" : "text-red-500"}`}
      >
        {s.active === "true" ? <div className="flex"><CheckIcon className="w-4 h-4 mr-1" />Active</div> : <div className="flex"><CloseIcon className="w-4 h-4 mr-1" />Inactive</div>}
      </div>,
      <Cell
        data={s.name}
        onClick={() => {
          selectRow(s);
        }}
      />,
      <Cell
        data={s.version}
        onClick={() => {
          selectRow(s);
        }}
      />,
      <Cell
        data={s.company}
        onClick={() => {
          selectRow(s);
        }}
      />,
      <Cell
        data={s.size}
        onClick={() => {
          selectRow(s);
        }}
      />,
      <Cell
        data={s.notes}
        onClick={() => {
          selectRow(s);
        }}
      />,
      <Cell
        data={s.documentation}
        onClick={() => {
          selectRow(s);
        }}
      />,
    ]);

  const tableHeader = [
    "Active",
    "Name",
    "Version",
    "Company",
    "Size",
    "Notes",
    "Documentation",
  ];

  /**
   * @description helper to refresh the list
   */
  const refreshSoftwareList = () => {
    GetSoftware()
      .then((r) => r.json())
      .then((res) => {
        setSoftwareList(res);
      });
  };

  /**
   * @description makes the API call to add software to the software collection
   */
  const addSoftware = (doc) => {
    AddSoftware(doc)
      .then((r) => r.json())
      .then((res) => {
        refreshSoftwareList();
        setModalState({ state: MODAL_STATE.NONE });
      });
  };

  /**
   * @description makes the API call to add software to remvoe software
   */
  const removeSoftware = (doc) => {
    RemoveSoftware(doc._id)
      .then((r) => r.json())
      .then((res) => {
        refreshSoftwareList();
        setModalState({ state: MODAL_STATE.NONE });
      });
  };

  /**
   * @description makes the API call to add software to update software
   */
  const updateSoftware = (id, doc) => {
    UpdateSoftware(id, doc)
      .then((r) => r.json())
      .then((res) => {
        refreshSoftwareList();
        setModalState({ state: MODAL_STATE.NONE });
      });
  };

  useEffect(() => {
    refreshSoftwareList();
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#D9DDDB" }} className="h-screen pt-8">
        {!renderTable() ? (
          <MainContainer>
            <LoadingMain />
          </MainContainer>
        ) : (


          <MainContainer>

            {/* <div className="platform-individual-document-container border border-slate-300"> */}
            <div className="mb-4 mx-2">
              <Button
                label={"Add Software"}
                onClick={() => {
                  setModalState({ state: MODAL_STATE.ADD_SOFTWARE });
                }}
              />
            </div>
            <div className="bg-gray-100 flex flex-row mx-2">
              <DynamicTable
                data={createDataArray(softwareList.data)}
                headerData={tableHeader}
              />
            </div>

            {modalState.state === MODAL_STATE.ADD_SOFTWARE ? (
              <AdminAddSoftwareModal
                onClose={() => {
                  setModalState({ state: MODAL_STATE.NONE });
                }}
                onAdd={addSoftware}
              />
            ) : null}
            {modalState.state === MODAL_STATE.UPDATE_SOFTWARE ? (
              <AdminUpdateSoftwareModal
                onClose={() => {
                  setModalState({ state: MODAL_STATE.NONE });
                }}
                onAdd={addSoftware}
                onRemove={removeSoftware}
                onUpdate={updateSoftware}
                data={modalState.data}
              />
            ) : null}
            {/* </div> */}
          </MainContainer>


        )}
      </div>
    </>
  );
};

export default AdminSoftware;
