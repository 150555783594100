import { API } from '../config';
import { GET, POST, FORM_POST, PATCH } from './Methods';

const Projects = () => {
    return GET(API.PROJECTS());
}

const GetProject = (id) => {
    return GET(API.PROJECT(id));
}

const CreateProject = (project) => {
    return POST(API.PROJECTS(), project);
}

const UpdateProject = (id, update) => {
    return PATCH(API.PROJECT(id), update);
}

const UpdateProjectProfile = (id, data) => {
    return FORM_POST(API.PROJECT_PROFILE(id), data);
}

export { Projects, CreateProject, GetProject, UpdateProject, UpdateProjectProfile }