import { useEffect, useReducer, useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import { useDispatch } from "react-redux";
import { logInActions } from "../store";
import { DefaultReducer } from "../components/DefaultReducer";

const LOG_API_REQUESTS = true;

const Log = (txt) => {
  if (LOG_API_REQUESTS)
    console.log(txt);
}

const GET = (url, body, auth) => {
  Log(`GET ${url}`);
  return fetch(url, { method: 'GET', headers: { 'Authorization': `${auth}` } })
}

const POST = (url, body, auth) => {
  Log(`POST ${url}`);
  return fetch(url, {
    method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `${auth}` },
    body: JSON.stringify(body)
  })
}

const FORM_POST = (url, body, auth) => {
  Log(`FORM_POST ${url}`);
  return fetch(url, {
    method: 'POST', headers: { 'Authorization': `${auth}` },
    body: body
  })
}

const DELETE = (url, body, auth) => {
  Log(`DELETE ${url}`);
  return fetch(url, {
    method: 'DELETE', headers: { 'Content-Type': 'application/json', 'Authorization': `${auth}` },
    body: JSON.stringify(body)
  })
}

const PATCH = (url, body, auth) => {
  Log(`PATCH ${url}`);
  return fetch(url, {
    method: 'PATCH', headers: { 'Content-Type': 'application/json', 'Authorization': `${auth}` },
    body: JSON.stringify(body)
  })
}

const Method = {
  GET: GET,
  POST: POST,
  FORM_POST: FORM_POST,
  DELETE: DELETE,
  PATCH: PATCH,
}

const REQUEST_STATE = {
  INIT: 'init',
  PENDING: 'pending',
  INIT_REFRESH_SESSION: 'init_refresh_session',
  REFRESHING_SESSION: 'refreshing_session',
  INIT_RETRY_REQUEST: 'init_retry_request',
  RETRY_REQUEST: 'retry_request',
  DONE: 'done',
  ERROR: 'error',
}

const InitialRequestState = {
  state: REQUEST_STATE.INIT,
  error: null,
  refreshSession: false,
  response: null,
  json: null,
  status: null,
  done: false,
}

const useAPI = (url, method = 'GET', body = {}, run = true) => {

  const [request, requestDispatch] = useReducer(DefaultReducer, InitialRequestState);
  const auth = useAuth();
  const dispatch = useDispatch();
  const HttpRequest = () => Method[method](url, body, auth.accessToken);

  const InitRequest = () => {
    HttpRequest()
      .then(res => {

        // console.log(res);
        requestDispatch({ response: res });
        if (res.ok) {
          return res.json()
        }
        else {
          throw res;
        }
      })
      .then(json => requestDispatch({ json: json, state: REQUEST_STATE.DONE, done: true }))
      .catch(err => {
        if ((err.status === 401) || (err.status === 404)) {
          return err.json();
        }
        // console.log(err);
        requestDispatch({ error: err })
      })
      .then(json => {
        if (json?.message === 'The incoming token has expired') {
          requestDispatch({ state: REQUEST_STATE.INIT_REFRESH_SESSION });
        }
        // else
        // {
        //   requestDispatch({ json: json });
        // }
      })
  }


  const RefreshSession = () => {
    // refresh API token here      
    auth.refreshSession((err, res) => {

      if (err) {
        return requestDispatch({ error: err });
      }
      console.log('................................................................TOKEN REFRESHED................................................................');
      dispatch(logInActions.updateTokens({ token: res.getAccessToken().jwtToken, refreshToken: res.getRefreshToken().token }));
      requestDispatch({ state: REQUEST_STATE.INIT_RETRY_REQUEST });
    })
  }


  const RetryRequest = () => {
    HttpRequest()
      .then(res => {
        requestDispatch({ response: res });
        if (res.ok) {
          return res.json()
        }
        else {
          throw res;
        }
      })
      .then(json => requestDispatch({ json: json, state: REQUEST_STATE.DONE, done: true }))
      .catch(err => {
        requestDispatch({ error: err })
      })
  }

  useEffect(() => {

    if (!run) {
      return;
    }
    // console.log(`useEffect ${request.state}`);
    if (request.state === REQUEST_STATE.INIT) {
      InitRequest();
    }

    if (request.state === REQUEST_STATE.INIT_REFRESH_SESSION) {
      RefreshSession();
    }

    if (request.state === REQUEST_STATE.INIT_RETRY_REQUEST) {
      RetryRequest();
    }
  }, [url, request.state, run]);


  return request;

};

const useAPIRequest = (url, method = 'GET', body = {}) => {

  const [request, requestDispatch] = useReducer(DefaultReducer, {
    state: REQUEST_STATE.INIT,
    error: null,
    refreshSession: false,
    response: null,
    json: null,
    status: null,
    done: false,
    run: false
  });

  const setRunRequest = () => {
    // setRun(true);
    requestDispatch({run: true});
  }

  const auth = useAuth();
  const dispatch = useDispatch();
  const HttpRequest = () => Method[method](url, body, auth.accessToken);

  const InitRequest = () => {
    HttpRequest()
      .then(res => {

        // console.log(res);
        requestDispatch({ response: res });
        if (res.ok) {
          return res.json()
        }
        else {
          throw res;
        }
      })
      .then(json => {console.log(json); requestDispatch({ json: json, state: REQUEST_STATE.DONE, done: true })})
      .catch(err => {
        if ((err.status === 401) || (err.status === 404)) {
          return err.json();
        }
        // console.log(err);
        requestDispatch({ error: err })
      })
      .then(json => {
        if (json?.message === 'The incoming token has expired') {
          requestDispatch({ state: REQUEST_STATE.INIT_REFRESH_SESSION });
        }
        // else
        // {
        //   requestDispatch({ json: json });
        // }
      })
  }


  const RefreshSession = () => {
    // refresh API token here      
    auth.refreshSession((err, res) => {

      if (err) {
        return requestDispatch({ error: err });
      }
      console.log('................................................................TOKEN REFRESHED................................................................');
      dispatch(logInActions.updateTokens({ token: res.getAccessToken().jwtToken, refreshToken: res.getRefreshToken().token }));
      requestDispatch({ state: REQUEST_STATE.INIT_RETRY_REQUEST });
    })
  }


  const RetryRequest = () => {
    HttpRequest()
      .then(res => {
        requestDispatch({ response: res });
        if (res.ok) {
          return res.json()
        }
        else {
          throw res;
        }
      })
      .then(json => requestDispatch({ json: json, state: REQUEST_STATE.DONE, done: true }))
      .catch(err => {
        requestDispatch({ error: err })
      })
  }

  useEffect(() => {

    if (!request.run) {
      return;
    }
    // console.log(`useEffect ${request.state}`);
    if (request.state === REQUEST_STATE.INIT) {
      InitRequest();
    }

    if (request.state === REQUEST_STATE.INIT_REFRESH_SESSION) {
      RefreshSession();
    }

    if (request.state === REQUEST_STATE.INIT_RETRY_REQUEST) {
      RetryRequest();
    }
  }, [url, request.state, request.run]);


  return [request, setRunRequest];

};



const useAPI2 = (url, method = 'GET', body = {}, delay = true) => {

  const [request, requestDispatch] = useReducer(DefaultReducer, InitialRequestState);
  const auth = useAuth();
  const dispatch = useDispatch();
  const HttpRequest = () => Method[method](url, body, auth.accessToken);

  const InitRequest = async () => {
    const res = await HttpRequest();
    let json = null;
    try {
      json = await res.json();
    }
    catch (err) {
      // not valid json response body
    }

    if (res.ok) {
      return requestDispatch({ json: json, response: res, state: REQUEST_STATE.DONE, done: true })
    }

    if (res.status === 401) {
      if (json?.message === 'The incoming token has expired') {
        // need to refresh access token
        return requestDispatch({ state: REQUEST_STATE.INIT_REFRESH_SESSION });
      }
    }

    if (res.status === 500) {
      requestDispatch({ error: res });
    }

  }


  const RefreshSession = () => {
    // refresh API token here      
    auth.refreshSession((err, res) => {

      if (err) {
        return requestDispatch({ error: err });
      }
      console.log('................................................................TOKEN REFRESHED................................................................');
      dispatch(logInActions.updateTokens({ token: res.getAccessToken().jwtToken, refreshToken: res.getRefreshToken().token }));
      requestDispatch({ state: REQUEST_STATE.INIT_RETRY_REQUEST });
    })
  }


  const RetryRequest = () => {
    HttpRequest()
      .then(res => {
        requestDispatch({ response: res });
        if (res.ok) {
          return res.json()
        }
        else {
          throw res;
        }
      })
      .then(json => requestDispatch({ json: json, state: REQUEST_STATE.DONE, done: true }))
      .catch(err => {
        requestDispatch({ error: err })
      })
  }

  useEffect(() => {

    if (delay) {
      return;
    }
    // console.log(`useEffect ${request.state}`);
    if (request.state === REQUEST_STATE.INIT) {
      InitRequest();
    }

    if (request.state === REQUEST_STATE.INIT_REFRESH_SESSION) {
      RefreshSession();
    }

    if (request.state === REQUEST_STATE.INIT_RETRY_REQUEST) {
      RetryRequest();
    }
  }, [url, request.state, delay]);


  return request;
  // return {...request, done: Boolean(request.state === REQUEST_STATE.DONE)};
  // return {...request, done:request.state};
};

// const useAPIEffect = (url, condition=false) => {

//   const projectRequest = useAPI(API.PROJECTS(), 'GET', {}, getRes);

//   useEffect(()=>{
//     if(condition)
//     {

//     }
//   }, condition)


// }


////// WORKING........................
// const useAPIGet = (url) => {

//   const [request, requestDispatch] = useReducer(DefaultReducer, InitialRequestState);
//   const auth = useAuth();
//   const dispatch = useDispatch();

//   useEffect(() => {

//     if (LOG_REQUESTS) {
//       console.log(`useAPIGet: GET ${url}`);
//     }

//     fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': `${auth.accessToken}` } })
//       .then(res => {
//         requestDispatch({ response: res, status: res.status });
//         return res;
//       })
//       .then(res => res.json())
//       .then(data => requestDispatch({ done:true, json: data }))
//       .catch(err => requestDispatch({ error: err }))
//   }, [url, request.refreshSession]);


//   useEffect(() => {

//     if ((request.status === 401) && (request.json?.message === 'The incoming token has expired')) {

//       // refresh API token here      
//       auth.refreshSession((err, res) => {

//         if (err) {
//           return requestDispatch({ error: err });
//         }

//         console.log('................................................................TOKEN REFRESHED................................................................');
//         dispatch(logInActions.updateTokens({ token: res.getAccessToken().jwtToken, refreshToken: res.getRefreshToken().token }));
//         requestDispatch({ refreshSession: true });

//       })
//     }
//   }, [request.status]);

//   return request;
// };

export { useAPI, useAPIRequest, REQUEST_STATE };
