import { Input, Modal, TextField, Typography, Select, MenuItem, Button, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { Close, UploadFile } from '@mui/icons-material';
import { RemovePlatform } from "../../../../services/Platforms";

import { GetParts } from "../../../../services/Parts";
import { useEffect, useState } from "react";

import PartsAllCard from "../../../parts/tabs/PartsAllCard";
import { PlatformPart } from "../../../../models/PartsModel";



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxWidth: 1000,
    //height: 150,
    maxHeight: '90%',
    bgcolor: 'white',
    border: '0px solid #000',
    boxShadow: 4,
    p: 2,
};


const AddPartsToPlatformModal = ({ onClose, onAddPart }) => {

    const [partsList, setPartsList] = useState({ data: [] });
    const [selectedPart, setSelectedPart] = useState(null);
    const [serial, setSerial] = useState('');

    useEffect(() => {
        GetParts()
            .then(r => r.json())
            .then(r => setPartsList(r));
    }, []);


    const onPartSelect = (part) => {
        setSelectedPart(part);
        setSerial('');
    }

    const onSubmit = () => {
        const platformPart = new PlatformPart(selectedPart);
        platformPart.SetSerial(serial);
        onAddPart(platformPart);
        onClose();
//        console.log(platformPart) ;
/*
        UpdatePlatform({op:"add_part", data:platformPart})
        .then(r => r.json())
        .then(r => {
            console.log(r);
        })*/

    }

    const isFormValid = () => (serial !== '');

    /*
    const submit = () => {
        const platformPart = new PlatformPart(selectedPart);
        platformPart.SetSerial(serial);
        console.log(platformPart)) ;
        UpdatePlatform({op:"add_part", data:platformPart})
        .then(r => r.json())
        .then(r => {
            console.log(r);
        })
    }*/


    return (<>
        <Modal
            open={true}
            onClose={onClose}
            sx={{ border: '1px solid #000', outline: 'none' }}
            disableEnforceFocus={true}
            disableAutoFocus={true}

        >
            <Box sx={style}>

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Typography sx={{ fontSize: 20, display: 'block' }} color="text.primary">Add Part to platform</Typography>
                    <Close sx={{}} color="text.secondary" onClick={onClose} />
                </Box>

                <Divider />

                <Box sx={{ overflow: 'auto', maxHeight: 400, marginTop: 1, paddingLeft: 0.5, paddingRight: 0.5, paddingBottom: 0.5 }}>
                    {partsList.data.map(p => <PartsAllCard key={p._id} part={p} showStock={false} select={onPartSelect} highlight={selectedPart?._id === p._id}/>)}
                </Box>

                <Divider sx={{marginTop:1, marginBottom:2}}/>


                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1, alignItems:'center' }}>
                    <Typography color="text.secondary" sx={{marginRight:2}}>{selectedPart?.name} </Typography>
                    <TextField
                        label="Serial"
                        size="small"
                        sx={{ width: '45%' }}
                        value={serial}
                        onChange={(v) => { setSerial(v.target.value) }}
                    />
                </Box>



                {/** 
                 * 
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1, alignItems:'center' }}>
                    <Typography color="text.secondary" sx={{marginRight:2}}>Last Service: </Typography>
                    <TextField
                        label="Service date"
                        size="small"
                        sx={{ width: '45%' }}
                        value={serial}
                        onChange={(v) => { setSerial(v.target.value) }}
                    />
                </Box>
                */}

                
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1 }}>
                    <Button variant="outlined" size="small" sx={{ marginRight: 1 }} onClick={onClose}>Cancel</Button>
                    <Button variant="contained" size="small" disabled={!isFormValid()} onClick={onSubmit}>Apply</Button>
                </Box>

            </Box>
        </Modal>
    </>)
}

export default AddPartsToPlatformModal;