/*
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
*/
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "../../config";
import AdminLocation from "./tabs/Location/AdminLocation";
import AdminParts from "./tabs/parts/AdminParts";
import AdminPlatforms from "./tabs/platforms/AdminPlatforms";
import { AdminUsers } from "./tabs/users/AdminUsers";
import AdminTabs from "./AdminTabs";
import { SUB_PATHS } from "../../config";
import AdminSoftware from "./tabs/software/AdminSoftware";


import { NavigationTab } from "../../components/NavigationTab";
import { CloseIcon, CogIcon, ComputerIcon, DocumentIcon, HistoryIcon, ListIcon, AdminCogIcon, BackIcon, GlobeIcon, UserCircleIcon, LocationIcon } from "../../components/Icons";

/*
const AdminTabs = ({activeTab, onTabChange})=> {
    return (
        <>
            <Box sx={{borderBottom: 1, borderColor:'divider'}}>
                <Tabs value={activeTab} onChange={(evt, v)=>{onTabChange(v)}} aria-label="basic tabs example">
                    {ADMIN_TABS.map((t, i)=>{ return (<Tab key={i} label={t} {...{ id: `simple-tab-${i}`, 'aria-controls': `simple-tabpanel-${i}`}} />)})}
                </Tabs>
            </Box>
        </>
    )
}
*/
const Admin = () => {

    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const changeTab = (tab) => {
        setActiveTab(SUB_PATHS.ADMIN_TABS.indexOf(tab));
        navigate(`${tab}`);
    }

    useEffect(() => {
        const URL = location.pathname.split('/');
        SUB_PATHS.ADMIN_TABS.forEach((t, i) => {
            if (URL.includes(t))
                setActiveTab(i);
        });
    }, [location.pathname]);

    return (
        <>


            {/* <AdminTabs activeTab={activeTab} onTabChange={changeTab} /> */}

            <div style={{ backgroundColor: "#F1F1F0" }}>

                <div className="flex flex-row justify-center content-center pt-6">
                    <div className="bg-white flex p-6 border rounded-md shadow-md mr-4 hover:cursor-pointer hover:bg-slate-50 hover:shadow-lg" onClick={()=>{navigate(`/`)}}>

                        <div className='text-green-600 flex flex-col justify-center'>
                            <BackIcon className="w-6 h-6"/>
                        </div>

                        <div className='font-normal text-slate-800 pl-2 flex flex-col justify-center'>
                            All projects
                        </div>

                    </div>
                    <div className="bg-white flex p-6 border rounded-md shadow-md">
                        <AdminCogIcon className="w-11 h-11" />
                        <div className="text-3xl text-slate-800 font-semibold ml-2 flex flex-col justify-center">
                            Admin
                        </div>
                    </div>
                </div>

                <div className="max-w-7xl m-auto translate-y-4">
                    <div className="">
                        <NavigationTab name={"Platforms"} icon={() => <GlobeIcon className="w-4 h-4" />} active={activeTab === 0} first={true} onClick={() => { changeTab("platforms") }} />
                        <NavigationTab name={"Parts"} icon={() => <CogIcon className="w-4 h-4" />} active={activeTab === 1} onClick={() => { changeTab("parts") }} />
                        <NavigationTab name={"Users"} icon={() => <UserCircleIcon className="w-4 h-4" />} active={activeTab === 2} onClick={() => { changeTab("users") }} />
                        <NavigationTab name={"Location"} icon={() => <LocationIcon className="w-4 h-4" />} active={activeTab === 3} onClick={() => { changeTab("location") }} />
                        <NavigationTab name={"Software"} icon={() => <ComputerIcon className="w-4 h-4" />} active={activeTab === 4} last={true} onClick={() => { changeTab("software") }} />
                    </div>
                </div>
            </div>


            <Routes>
                <Route path="/" element={<Navigate to={SUB_PATHS.ADMIN_TABS[0]} />} />
                <Route path={SUB_PATHS.ADMIN_TABS[0]} element={<AdminPlatforms />} />
                <Route path={SUB_PATHS.ADMIN_TABS[1]} element={<AdminParts />} />
                <Route path={SUB_PATHS.ADMIN_TABS[2]} element={<AdminUsers />} />
                <Route path={SUB_PATHS.ADMIN_TABS[3]} element={<AdminLocation />} />
                <Route path={SUB_PATHS.ADMIN_TABS[4]} element={<AdminSoftware />} />
            </Routes>
        </>
    )
}
export default Admin;