import { API } from '../config';
import { GET, POST, PATCH } from './Methods';

const Tasks = (query) => {
    return GET(API.TASKS(query));
}

/*const TaskHistory = (id) => {
    return GET(API.TASK_HISTORY(id));
}*/

const TaskHistory = (id) => {
    return GET(API.TASK_HISTORY(id));
}

const TaskHistoryAll = (query) => {
    return GET(API.TASK_HISTORY_ALL(query));
}

const CreateTask = (task) => {
    return POST(API.TASKS(), task);
}

const UpdateTask = (id, update) => {
    return PATCH(API.TASK(id), update);
}

export { Tasks, CreateTask, UpdateTask, TaskHistory, TaskHistoryAll }