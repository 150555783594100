import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { TaskHistory as GetTaskHistory } from "../../../services/Tasks";
// import { PlatformHistory as GetPlatformHistory } from '../../../services/Platforms';
import { TaskHistoryAll as GetAllTaskHistory } from '../../../services/Tasks';

import { Platforms as GetPlatforms } from '../../../services/Platforms';
import { Locations as GetLocations } from '../../../services/Locations';


import { Tasks as GetTasks } from '../../../services/Tasks';
import { useEffect, useReducer, useState, useContext, useRef, forwardRef } from 'react';
import { Button, Divider, Typography, Box, Card, Skeleton } from '@mui/material';

import { OverviewPlatformsTaskData, OverviewPlatformsTaskType } from './OverviewPlatformsTaskData';
import OverviewPlatformsLocationData from './OverviewPlatformsLocationData';
import OverviewPlatformsFlightData from './OverviewPlatformsFlightData';
import { DefaultReducer } from '../../../components/DefaultReducer';
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfTwoTone';

import { useSelector } from 'react-redux';
//import { RequestErrorHandlerContext } from '../../../components/Main';
import { RequestErrorHandlerContextNEW as RequestErrorHandlerContext } from '../../../App';
import ConnectionError from '../../../components/ConnectionError';
import { MainContainer } from '../../../components/Containers';

import DynamicTable from '../../../components/DynamicTable';
import { TASK_TYPE } from '../../../models/TasksModel';

import jsPDF from 'jspdf';

const OverviewPlatforms = () => {

    const [fromDate, setFromDate] = useState(dayjs(new Date()).subtract(1, 'month'));
    const [toDate, setToDate] = useState(dayjs(new Date()));

    //    const locationsData = useSelector(state => state.locations.locations);
    const [locations, locationsDispatch] = useReducer(DefaultReducer, { loading: true, data: [] })

    const [activeTasks, activeTaskDispatch] = useReducer(DefaultReducer, { loading: true, data: [] })
    const [historyData, historyTaskDispatch] = useReducer(DefaultReducer, { loading: true, data: [] })
    const [platformData, platformDispatch] = useReducer(DefaultReducer, { loading: true, data: [] })
    const [requestError, requestErrorDispatch] = useReducer(DefaultReducer, { error: false, type: null });
    const errorHandler = useContext(RequestErrorHandlerContext);



    const table1 = useRef();
    const table2 = useRef();
    const table3 = useRef();


    const getTaskHistory = () => {
        historyTaskDispatch({ loading: true });
        GetAllTaskHistory({ from: fromDate.toDate(), to: toDate.toDate() })
            .then(res => res.json())
            .then(result => errorHandler(result))
            .then(res => { historyTaskDispatch({ loading: false, data: res }) })
            .catch(err => { requestErrorDispatch({ error: true, type: err }) })
    }

    const getActiveTasks = () => {

        activeTaskDispatch({ loading: true });
        GetTasks({ from: fromDate.toDate(), to: toDate.toDate() })
            .then(res => res.json())
            .then(result => errorHandler(result))
            .then(res => { activeTaskDispatch({ loading: false, data: res }) })
            .catch(err => { requestErrorDispatch({ error: true, type: err }) })
    }


    const getAllPlatforms = () => {

        platformDispatch({ loading: true });
        GetPlatforms()
            .then(res => res.json())
            .then(result => errorHandler(result))
            .then(res => { platformDispatch({ loading: false, data: res }) })
            .catch(err => { requestErrorDispatch({ error: true, type: err }) })
    }

    const getLocations = () => {

        locationsDispatch({ loading: true });
        GetLocations()
            .then(res => res.json())
            .then(result => errorHandler(result))
            .then(res => { locationsDispatch({ loading: false, data: res }) })
            .catch(err => { requestErrorDispatch({ error: true, type: err }) })
    }

    const updateResults = () => {
        getTaskHistory();
        getActiveTasks();
        getLocations();
    }

    useEffect(() => {
        updateResults();
        getAllPlatforms();
    }, []);

    /**
     * 
     * @param {*} platformID 
     * @returns List of taskID's for platformID
     */
    const getPlatformTasks = (platformID) => { return activeTasks.data.filter(t => { return t.platformID === platformID }).map(t => { return t._id }) }

    /**
     * 
     * @param {*} taskID 
     * @returns all changes for taskID
     */
    const getTaskData = (taskID) => { return historyData.data.filter(t => { return t.taskID === taskID }) }

    /**
     * 
     * @param {*} taskID 
     * @returns list of status changes with timestamps
     */
    const getStatusChanges = (taskID) => { return historyData.data.filter(t => { return t.status !== undefined }).filter(t => { return t.taskID === taskID }) }

    /**
     * 
     * @returns list of platforms which have been worked on over the selected time interval 
     */
    const getPlatforms = () => {

        let temp = [];
        activeTasks.data.filter(t => { return t.platformID !== undefined }).map(p => { return p.platformID }).forEach(id => {
            if (!temp.includes(id))
                temp.push(id);
        });
        return temp;
    }

    /**
     * 
     * @param {*} status
     * @returns length of time taskID spent in progress 
    const totalDurationOfTask = (taskID, fromStatus, toStatus) => {
        let updates = historyData.filter(t => { return (t.taskID === taskID) });
        let start = updates.find(t => { return t.status === 'IN_PROGRESS'})?.updatedAt;
        let end = updates.find(t => { return t.status === 'DONE'})?.updatedAt;
        
        if(start || end)
        {
            if(!start)
                start = fromDate.toDate();
            if(!end)
                end = toDate.toDate();
                let diff = (new Date(end)) - (new Date(start));
                return Number(diff); 
            }
        }
        */
    const totalDurationOfTask = (taskID, fromStatus, toStatus) => {
        let updates = historyData.data.filter(t => { return (t.taskID === taskID) });
        let start = updates.find(t => { return t.status === fromStatus })?.updatedAt;
        let end = updates.find(t => { return t.status === toStatus })?.updatedAt;

        // console.log(fromStatus, toStatus);


        if (start || end) {
            if (!start)
                start = fromDate.toDate();
            if (!end)
                end = toDate.toDate();
            let diff = (new Date(end)) - (new Date(start));

            // const date1 = new Date('7/13/2010');
            // const date2 = new Date('12/15/2010');
            const diffTime = Math.abs(diff);
            // const hours = Math.abs(diff);
            const diffhours = Math.ceil(diffTime / (1000 * 60 * 60));
            // const diffdays = Math.ceil(diffTime / (1000 * 60 * 60 *24)); 
            // const diffhours = Math.ceil(diffTime / (1000 * 60 * 60 )); 
            // console.log(diffTime + " milliseconds");
            // console.log(start, end, diffhours);
            // console.log(start, end, diffhours, diffdays);

            return diffhours;
            return Number(diff);
        }
    }
    /*
        const totalDurationForPlatform = (platformID, startStatus, endStatus) => {
            return getPlatformTasks(platformID).reduce((total, curr) => {
                let temp = totalDurationOfTask(curr, 'IN_PROGRESS', 'DONE');
                if(temp === undefined) 
                    temp = 0;
                return total + temp;
            }, 0);
        }
    */
    const totalDurationForPlatform = (platformID, startStatus, endStatus) => {
        return getPlatformTasks(platformID).reduce((total, curr) => {
            let temp = totalDurationOfTask(curr, startStatus, endStatus);
            if (temp === undefined)
                temp = 0;
            return total + temp;
        }, 0);
    }


    const getPlatformName = (id) => { return activeTasks.data.find(t => { return t.platformID === id }).platform.name }

    /**
     * 
     * 
     */
    const getGraphDataTasks = () => {
        let graph = [];
        getPlatforms().forEach(p => {
            graph.push({
                name: getPlatformName(p),
                pending: totalDurationForPlatform(p, 'PENDING', 'IN_PROGRESS'),
                maintenance: totalDurationForPlatform(p, 'IN_PROGRESS', 'DONE')
            });
        });
        // console.log(graph);
        // return graph.map(x => ({ name:x.name, pending: Number(x.pending / (1000 * 60)) , maintenance: Number(x.maintenance / (1000 * 60))}));
        return graph;
    }

    /**
     * 
     * 
     */
    const getGraphDataTaskTypes = () => {

        let graph = [];
        getPlatforms().forEach(p => {
            graph.push({
                name: getPlatformName(p),
                software: activeTasks.data
                    .filter(t => (t.platformID === p))
                    .filter(t => (t.type === TASK_TYPE.PLATFORM_ADD_SOFTWARE_TASK || t.type === TASK_TYPE.PLATFORM_REMOVE_SOFTWARE_TASK)).length,
                scheduled: activeTasks.data
                    .filter(t => (t.platformID === p))
                    .filter(t => (t.type === TASK_TYPE.PLATFORM_SCHEDULED_PART_TASK)).length,
                modification: activeTasks.data
                    .filter(t => (t.platformID === p))
                    .filter(t => (t.type !== (TASK_TYPE.PLATFORM_SCHEDULED_PART_TASK || TASK_TYPE.PLATFORM_ADD_SOFTWARE_TASK || t.type === TASK_TYPE.PLATFORM_REMOVE_SOFTWARE_TASK))).length,
            });
        });
        return graph;
    }


    /**
     * 
     * 
     */
    const getNumPlatforms = (location) => { return (platformData.data.data.filter(t => { return (t.location === location) })).length }

    /**
     * 
     * 
     */
    const getGraphDataLocations = () => {
        let graph = [];
        let temp = [];

        locations.data.forEach(p => {
            graph.push({
                name: p.name,
                number: getNumPlatforms(p.name)
            });
        });

        return graph;
    }
    /**
     * 
     * 
     */
    const getGraphDataPlatformFlight = () => {
        let graph = [];
        let temp = [];


        //        console.log(platformData.data.data);

        platformData.data.data.forEach(t => {

            let _obj = { name: t.name, duration: 0 };
            /*
            let testsdgs = t.flightData.reduce((total, curr) => { return total + curr.duration }, 0);
            console.log(testsdgs);
            */
            _obj.duration = t.flightData.reduce((total, curr) => { return total + curr.duration }, 0);
            /*
            t.flightData.forEach(d => {
                console.log(d.duration);

            })*/
            graph.push(_obj);

        });
        // console.log(graph);
        return graph;
    }

    /*
    const getPlatformIdFromTask = (taskID) => {


        const temp = historyData.filter(t => { return t.taskID === taskID }).find(t => { return t.platformID !== undefined });
        console.log(temp);

        if (!temp) {

        }

        return "alksdjfn"

        return temp;
    }

    const TaskBreakdown = ({ taskID }) => {

        const taskUpdates = historyData.filter((t) => { return t.taskID === taskID })

        const SubTask = ({ update }) => {
            return (
                <div style={{ backgroundColor: 'green' }}>
                    {update.status}
                    {update.updatedAt}
                </div>
            )
        }

        return (
            <div style={{ border: '1px solid black', backgroundColor: 'red' }}>
                <div>id: {taskID}</div>
                <div>num update: {taskUpdates.length}</div>
                <div>platformID: {taskUpdates.find(d => { return d.platformID !== undefined })?.platformID}</div>
                {taskUpdates.map((k, i) => { return <SubTask key={i} update={k} /> })}
            </div>)
    }
    
    
    const PlatformTaskList = ({ platform }) => {
        
        console.log(platform);
        
        return (
            <div style={{ backgroundColor: 'red' }}>
                <Typography>platform: {platform}</Typography>
                <Typography>Tasks: </Typography>
                {getPlatformTasks(platform).map((t) => {
                    return <>
                    <div style={{ backgroundColor: 'green', paddingLeft:20 }}>
                    {t}
                    </div>
                    <div style={{backgroundColor:'pink', paddingLeft:40}}>
                    {totalDurationOfTask(t)}
                    </div>
                    </>
                })}
                <div style={{backgroundColor:'yellow', paddingLeft:60}}>
                Total: {totalDurationForPlatform(platform)}
                </div>
            </div>
        )
    }
    
    
    */


    const LoadingGraph = () => {
        return (
            <>
                <Skeleton variant="rectangular" height={80} sx={{ margin: 'auto', marginTop: 1, width: '90%' }} animation="wave" />
                <Skeleton variant="rectangular" height={80} sx={{ margin: 'auto', marginTop: 1, width: '90%' }} animation="wave" />
            </>
        )
    }


    const PDFTemplate = () => {
        return `
            <div>Generated report from ${fromDate.toISOString()} to ${toDate.toISOString()}</div>
            <div style="margin-top:100px"></div> 
            ${table1.current.outerHTML}
            <div style="margin-top:100px"></div> 
            ${table2.current.outerHTML} 
            <div style="margin-top:100px"></div> 
            ${table3.current.outerHTML}`
    }

    const GeneratePDF = () => {

        // console.log(exampleTable.current.outerHTML);

        var doc = new jsPDF();

        // doc.html('<div style="font-size:15px;">fakjshdkajshdfbkajshdbkfjashbdf</div>', {
        doc.html( PDFTemplate() , {
            callback: function (doc) {
                doc.save();
            },
            x: 10,
            y: 10,
            filename: 'VitaOpera - Platform report',
            width: 150,
            windowWidth: 700,
        });
    }

    // const ForwardRefTable = forwardRef((props, ref)=>{
    //     return <DynamicTable ref={ref}
    //         data={getGraphDataTaskTypes().map(x => [x.name, x.software, x.scheduled, x.modification])}
    //         headerData={['Platform', 'Software', 'Scheduled', 'Modification']}
    //     />
    // })


    return (
        // <div>

        <div className='' style={{ backgroundColor: "#D9DDDB" }} >
            <div className="max-w-7xl m-auto pt-8 pb-8">


                {/* <div className='bg-white border border-slate-300 rounded-lg'>

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                        <Box sx={{}}>
                            <Typography>From:</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker onChange={(v) => { setFromDate(v) }} value={fromDate} />
                            </LocalizationProvider>
                        </Box>

                        <Box sx={{ marginLeft: 1 }}>
                            <Typography>To:</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker onChange={(v) => { setToDate(v) }} value={toDate} />
                            </LocalizationProvider>
                        </Box>
                    </Box>
                </div> */}

                <MainContainer>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                        <Box sx={{}}>
                            <Typography>From:</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker onChange={(v) => { setFromDate(v) }} value={fromDate} />
                            </LocalizationProvider>
                        </Box>
                        <Box sx={{ marginLeft: 1 }}>
                            <Typography>To:</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker onChange={(v) => { setToDate(v) }} value={toDate} />
                            </LocalizationProvider>
                        </Box>
                    </Box>
                    <Box sx={{ marginTop: 1, display: 'flex', justifyContent: 'center' }}>
                        <Button variant='outlined' color='secondary' disabled={(activeTasks.loading || historyData.loading || platformData.loading)} onClick={GeneratePDF}><PictureAsPdfTwoToneIcon />Download Report</Button>
                        <Button variant='outlined' onClick={updateResults} sx={{ marginLeft: 1 }}>Update</Button>
                    </Box>
                </MainContainer>





                {/* <Divider sx={{ marginTop: 3 }} /> */}

                {/* <MainContainer className="mt-4">

                    {requestError.error ? <ConnectionError error={requestError.type} /> :

                        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', paddingTop: 1, paddingBottom: 2, backgroundColor: '#fafafa' }}>

                            <Card sx={{ width: 350, height: 225, marginTop: 2, marginLeft: 1, marginRight: 1 }}>
                                <Typography sx={{ textAlign: 'center' }}>Tasks</Typography>
                                {(activeTasks.loading || historyData.loading) ? <LoadingGraph /> : <OverviewPlatformsTaskData data={getGraphDataTasks()} />}
                            </Card>

                            <Card sx={{ width: 450, height: 225, marginTop: 2, marginLeft: 1, marginRight: 1 }}>
                                <Typography sx={{ textAlign: 'center' }}>Locations</Typography>
                                {(activeTasks.loading || historyData.loading || platformData.loading) ? <LoadingGraph /> : <OverviewPlatformsLocationData data={getGraphDataLocations()} />}
                            </Card>

                            <Card sx={{ width: 350, height: 225, marginTop: 2, marginLeft: 1, marginRight: 1 }}>
                                <Typography sx={{ textAlign: 'center' }}>Flight Data</Typography>
                                {(activeTasks.loading || historyData.loading || platformData.loading) ? <LoadingGraph /> : <OverviewPlatformsFlightData data={getGraphDataPlatformFlight()} />}
                            </Card>

                        </Box>
                    }
                </MainContainer> */}

                <MainContainer className="mt-4">

                    {/* <Typography sx={{ textAlign: 'center' }}>Tasks</Typography> */}
                    <div className='text-center font-semibold pb-3 '>Platform Task Overview</div>



                    <div className='flex'>
                        <div className='w-1/2'>

                            <DynamicTable
                                data={getGraphDataTaskTypes().map(x => [x.name, x.software, x.scheduled, x.modification])}
                                headerData={['Platform', 'Software', 'Scheduled', 'Modification']}
                                ref={table1}
                            />

                            {/* <ForwardRefTable ref={exampleTable} /> */}
                        </div>
                        <div className='w-1/2'>
                            <div className='flex justify-center'>
                                {(activeTasks.loading || historyData.loading) ? <LoadingGraph /> : <OverviewPlatformsTaskType width={500} height={400} data={getGraphDataTaskTypes()} />}
                            </div>

                        </div>
                    </div>

                </MainContainer>


                <MainContainer className="mt-4">

                    {/* <Typography sx={{ textAlign: 'center' }}>Tasks</Typography> */}
                    <div className='text-center font-semibold pb-3 '>Platform Task Duration</div>



                    <div className='flex'>
                        <div className='w-1/2'>

                            <DynamicTable
                                data={getGraphDataTasks().map(x => [x.name, x.pending, x.maintenance])}
                                headerData={['Platform', 'total hours (h)', 'total hours spent (h)',]}
                                ref={table2}
                            />
                        </div>
                        <div className='w-1/2'>
                            <div className='flex justify-center'>
                                {(activeTasks.loading || historyData.loading) ? <LoadingGraph /> : <OverviewPlatformsTaskData width={500} height={400} data={getGraphDataTasks()} />}
                            </div>

                        </div>
                    </div>

                </MainContainer>



                <MainContainer className="mt-4">

                    {/* <Typography sx={{ textAlign: 'center' }}>Tasks</Typography> */}
                    <div className='text-center font-semibold pb-3 '>Platform Locations</div>


                    {(locations.data && !(activeTasks.loading || historyData.loading || platformData.loading)) ?
                        <div className='flex'>
                            <div className='w-1/2'>
                                <DynamicTable
                                    data={getGraphDataLocations().map(x => [x.name, x.number])}
                                    headerData={['Location', 'Total Platforms',]}
                                    ref={table3}
                                />
                            </div>
                            <div className='w-1/2'>
                                <div className='flex justify-center'>
                                    {/* {(activeTasks.loading || historyData.loading) ? <LoadingGraph /> : <OverviewPlatformsLocationData width={500} height={400} data={getGraphDataLocations()} />} */}
                                    {(activeTasks.loading || historyData.loading || platformData.loading) ? <LoadingGraph /> : <OverviewPlatformsLocationData width={500} height={400} data={getGraphDataLocations()} />}
                                </div>

                            </div>
                        </div> : null
                    }

                </MainContainer>



                {/* <MainContainer className="mt-4">

                    <div className='text-center font-semibold pb-3 '>Platform Flight Data</div>


                    {(locations.data && !(activeTasks.loading || historyData.loading || platformData.loading)) ?
                        <div className='flex'>
                            <div className='w-1/2'>
                                <DynamicTable
                                    data={getGraphDataPlatformFlight().map(x => [x.name, x.duration])}
                                    headerData={['Platform', 'Flight hours',]}
                                />
                            </div>
                            <div className='w-1/2'>
                                <div className='flex justify-center'>
                                    {(activeTasks.loading || historyData.loading || platformData.loading) ? <LoadingGraph /> : <OverviewPlatformsFlightData data={getGraphDataPlatformFlight()} width={500} height={400} />}
                                </div>

                            </div>
                        </div> : null
                    }

                </MainContainer> */}




                {/**  
             * 
             * 1) Get list of platforms which have been worked on
             * 
             getPlatforms().map(p => { return <div>{p}</div> })
             */
                }


                {/**  
             * 
             * 2) Get List of Tasks for each platform
             * 
             getPlatforms().map(p => { return <div>{p} : <PlatformTaskList platform={p} /></div> })
             */
                }





                {/*historyData.map((p, i) => {return <div key={i}>{p._id}</div>})*/}
                {/*activeTasks.map((p, i) => {return <div key={i}>{p._id}</div>})*/}
                {/*historyData.filter((p) => {return p.platformID !== undefined}).map((p, i) => {return <div key={i}>{p._id}</div>})*/}
                {/*uniqueTasks(historyData).map((id,i) => {return <TaskBreakdown key={i} taskID={id}/>})*/}

                {/*getPlatformTasks('63fe2d0a16d7c89d61fe2dbb').map(p => {return <div>{p}</div>})*/}

                {/*getStatusChanges('6441420902c51c78e6abb857').map(p => { return <div>{p.status} : {p.updatedAt}</div> })*/}





            </div>
        </div>
    )
}

export default OverviewPlatforms;