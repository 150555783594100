import { API } from '../config';
import { GET, POST, FORM_POST, DELETE, PATCH } from './Methods';

const User = (userID) => {
    return GET(API.USER(userID));
}

const CreateUser = (id_token) => {
    return POST(API.USERS(), id_token);
}

const UpdateUser = (id, doc) => {
    return PATCH(API.USER(id), doc);
}

const UserRoles = (user) => {
    return GET(API.USER_ROLES(user));
}

const AllUsers = () => {
    return GET(API.USERS());
}

const AllRoles = () => {
    return GET(API.ALL_ROLES());
}

const UsersForRole = (role) => {
    return GET(API.USERS_FOR_ROLE(role));
}

const SetUserRoles = (userID, roles) => {
    return POST(API.USER_ROLES(userID), roles);
}

const UpdateUserProfile = (userID, data) => {
    return FORM_POST(API.USER_PROFILE(userID), data);
}

const RemoveUserRoles = (userID, roles) => {
    return DELETE(API.USER_ROLES(userID), roles);
}

export { User, CreateUser, UpdateUser, UserRoles, AllUsers, AllRoles, UsersForRole, SetUserRoles, UpdateUserProfile, RemoveUserRoles }