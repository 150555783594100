import { Platforms as GetPlatforms } from '../../../../services/Platforms';
import { Avatar } from "@mui/material";
import { useEffect, useState, useContext } from 'react';
import PlatformFilter from '../../../platforms/PlatformFilter';
import PlatformCard from '../../../platforms/PlatformCard';
import { InitialFormState } from '../../../platforms/PlatformFilterReducer';
import AddPlatformModal from './AdminPlatformAddModal';
import { RequestErrorHandlerContextNEW as RequestErrorHandlerContext } from '../../../../App';
import { ERRORS } from '../../../../config';
import { LoadingMain } from '../../../../components/LoadingModal';
import PermissionError from '../../../../components/PermissionError';
import PlatformPagination from '../../../platforms/PlatformListPagination';
import PlatformEditModal from './AdminPlatformEditModal';

import Platform from '../../../../models/PlatformModel';

import DynamicTable from '../../../../components/DynamicTable';
import { CheckCircleIcon, CheckIcon, ExclaimationCircleIcon, LocationIcon, ScheduleIcon } from '../../../../components/Icons';

import { Button } from '../../../../components/Buttons';
import { MainContainer } from '../../../../components/Containers';

const MODAL_STATE = {
    NONE: "no_modal",
    ADD_PLATFORM: "add_platform_modal",
    EDIT_PLATFORM: "edit_platform_modal",
}

const REQUEST_STATE = {
    NONE: "request_none",
    PENDING: "request_pending",
    PERMISSION_ERROR: "request_permission_error",
    COMPLETE: "request_complete",
}

const PlatformList = () => {

    const [platformData, SetPlatformData] = useState({ total: 0, data: [] });
    const [requestState, setRequestState] = useState(REQUEST_STATE.NONE);
    const [platformFilter, SetFilter] = useState(InitialFormState);
    const [modalState, setModalState] = useState({ modal: MODAL_STATE.NONE, platform: null });
    const errorHandler = useContext(RequestErrorHandlerContext);
    const [page, SetPage] = useState(0);
    //    const [total, SetTotal] = useState(0);

    const applyFilter = (params) => {
        SetPage(0);
        SetFilter(params);
    }

    const changePage = (value) => {
        SetPage(value - 1);
    }

    const onPlatformUpdate = (platform) => {
        //console.log(platform);
        let update = { ...platformData };
        update.data = platformData.data.map(t => {
            if (t._id === platform._id)
                return platform;
            return t;
        });

        //        console.log(platform);

        if (modalState.platform && (modalState.platform._id === platform._id)) {
            const modal = { ...modalState };
            modal.platform = platform;
            setModalState(modal);
        }

        SetPlatformData(update);
    }

    const onPlatformRemove = (res) => {
        let update = { ...platformData };
        update.data = platformData.data.filter(p => (p._id !== res._id));
        update.total = platformData.total - 1;
        SetPlatformData(update);
        setModalState({ modal: MODAL_STATE.NONE })
    }

    const onAddPlatform = (platform) => {
        let update = { ...platformData };
        update.data.push(platform);
        update.total = platformData.total + 1;
        SetPlatformData(update);
        setModalState({ modal: MODAL_STATE.NONE })
    }



    useEffect(() => {
        let query = { page: page };
        Object.keys(platformFilter).forEach(k => {
            if (platformFilter[k] !== '')
                query[k] = platformFilter[k];
        });

        setRequestState(REQUEST_STATE.PENDING);
        GetPlatforms(query)

            .then(result => {
                return result.json();
            })
            .then(result => errorHandler(result))
            .then(result => {
                //                SetTotal(result.total);
                SetPlatformData(result);
                // console.log(result);
                setRequestState(REQUEST_STATE.COMPLETE);
            })
            .catch(error => {
                if (error.message === ERRORS.PERMISSION) {
                    setRequestState(REQUEST_STATE.PERMISSION_ERROR);
                }
            })

    }, [platformFilter, page, errorHandler]);


    // console.log(platformData.data);

    const setPlatformModal = (platform) => { setModalState({ modal: MODAL_STATE.EDIT_PLATFORM, platform: platform }) }

    return (



        <div style={{ backgroundColor: "#D9DDDB" }} className="h-screen pt-7">



            <MainContainer>
                <PlatformFilter onApply={applyFilter} InitialFormState={InitialFormState} />
            </MainContainer>

            {/* <MainContainer>
                    <PlatformFilter onApply={applyFilter} InitialFormState={InitialFormState} />
            </MainContainer> */}

            <MainContainer className="mt-4">
                {/* <div className="platform-individual-document-container"> */}

                    {requestState === REQUEST_STATE.PENDING ? <LoadingMain show={true} /> : null}
                    {requestState === REQUEST_STATE.PERMISSION_ERROR ? <PermissionError /> : null}
                    {requestState === REQUEST_STATE.COMPLETE ?
                        <div>
                            <div>





                                <div>


                                    <Button label="Add Platform" onClick={() => { setModalState({ modal: MODAL_STATE.ADD_PLATFORM }) }} />
                                    <div className='mt-4'>
                                        <div className=' font-normal text-sm pb-2'>
                                            Total Platforms: {platformData.total}
                                        </div>

                                        <DynamicTable
                                            data={platformData.data.map((platform, i) => [

                                                // <div className='flex'>
                                                //     <Avatar src={platform.img} />
                                                //     {platform.name}
                                                // </div>,

                                                <div key={`${i}user`} className="flex" onClick={() => { setPlatformModal(platform) }}>
                                                    <Avatar src={platform.img} />
                                                    <div className="flex flex-col ml-4">
                                                        <div className=" text-sm font-medium text-slate-800">{platform.name}</div>
                                                        <div className="text-slate-500">{platform.serial}</div>
                                                    </div>
                                                </div>,



                                                <div className="flex flex-col" onClick={() => { setPlatformModal(platform) }}>
                                                    {/* <div className=" text-sm font-medium text-slate-800">{platform.name}</div>
                                            <div className="text-slate-500">{platform.serial}</div> */}
                                                    <div>{platform.manufacturer}</div>
                                                    <div>{platform.model}</div>
                                                </div>,


                                                <div className='flex flex-row' onClick={() => { setPlatformModal(platform) }}><LocationIcon className='w-4 h-4' strokeWidth={1.0} /> {platform.location}</div>,

                                                // <div className="flex flex-col">
                                                //     <div>{platform.manufacturer}</div>
                                                //     <div>{platform.model}</div>
                                                // </div>,

                                                <div className="flex flex-row" onClick={() => { setPlatformModal(platform) }}>
                                                    {/* <div>{platform.tasks.filter((t) => { return t.type === "PlatformScheduledPartTask" }).length}</div> */}
                                                    <div><ScheduleIcon strokeWidth={1.0} className='w-4 h-4 mr-1' /></div>
                                                    <div>{platform.parts.length}</div>
                                                </div>,

                                                <div onClick={() => { setPlatformModal(platform) }}>
                                                    {
                                                        platform.status === "ACTIVE" ?
                                                            <div className="flex flex-row text-green-700 font-medium text-sm">
                                                                <div><CheckCircleIcon strokeWidth={1.5} className='w-5 h-5 mr-1' /></div>
                                                                <div>Active</div>
                                                            </div> :
                                                            <div className="flex flex-row text-red-700 font-medium text-sm">
                                                                <div><ExclaimationCircleIcon strokeWidth={1.5} className='w-5 h-5 mr-1' /></div>
                                                                <div>Maintenance</div>
                                                            </div>
                                                    }
                                                </div>,


                                            ])}
                                            headerData={['Name / serial', 'Manufacturer / Model', 'Location', 'Tasks', 'Status']}
                                        />
                                    </div>
                                </div>



                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    {/* <div>
                                    <h4>Total results: {platformData.total}</h4>
                                </div> */}
                                    {/* <Button onClick={() => { setModalState({ modal: MODAL_STATE.ADD_PLATFORM }) }} sx={{ height: 35 }} color="success" variant='contained'>Add platform</Button> */}
                                </div>



                                {/* <div>
                                {platformData.data.map((p, i) => {
                                    const platform = new Platform(p);
                                    return <PlatformCard key={i} platform={platform} select={() => { setModalState({ modal: MODAL_STATE.EDIT_PLATFORM, platform: platform }) }} />
                                })}
                            </div> */}
                                <div className='flex justify-center mt-4'>
                                    <PlatformPagination total={platformData.total} pageLimit={platformFilter.limit} onChange={changePage} page={page + 1} />
                                </div>
                            </div>
                            {modalState.modal === MODAL_STATE.ADD_PLATFORM ? <AddPlatformModal onClose={() => { setModalState({ modal: MODAL_STATE.NONE }) }} onAddPlatform={onAddPlatform} /> : ''}
                            {modalState.modal === MODAL_STATE.EDIT_PLATFORM ? <PlatformEditModal platform={modalState.platform} onClose={() => { setModalState({ modal: MODAL_STATE.NONE }) }} onUpdate={{ onPlatformUpdate: onPlatformUpdate, onPlatformRemove: onPlatformRemove }} /> : ''}
                        </div>
                        : null}
                {/* </div> */}
            </MainContainer>

        </div>
    )
}

export default PlatformList;