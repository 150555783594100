import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PartCard from "./PartCard";
import { useSelector } from "react-redux";
import PlatformPartAddModal from "./PlatformPartAddModal";
import PlatformPartModifyModal from "./PlatformPartModifyModal";
import { useState } from "react";
import { MainContainer } from "../../../components/Containers";

const PlatformIndividualParts = () => {
  const platformData = useSelector((state) => state.platform);
  const [addPartModal, SetAddPartModalState] = useState(false);
  const [partModal, SetPartModalState] = useState({ open: false, part: null });

  return (

    <div style={{ backgroundColor: "#D9DDDB" }} className="pt-8 h-screen">

      <MainContainer>

        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <Typography>
            <b>Total: {platformData.platform.parts.length}</b>
          </Typography>
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          {platformData.platform.parts.map((p, i) => {
            return (
              <PartCard
                key={i}
                part={p}
                select={(item) => {
                  SetPartModalState({ open: true, part: item });
                }}
              />
            );
          })}
        </Box>

        <Button
          onClick={() => {
            SetAddPartModalState(true);
          }}
          color="success"
          variant="contained"
        >
          Add Part
        </Button>
        <PlatformPartAddModal
          visible={addPartModal}
          setAddModalState={SetAddPartModalState}
        />
        {partModal.open ? (
          <PlatformPartModifyModal
            part={partModal.part}
            onClose={() => {
              SetPartModalState({ open: false, part: null });
            }}
          />
        ) : (
          ""
        )}
      </MainContainer>
    </div>

  );
};

export default PlatformIndividualParts;
