import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography, Button } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { useState, useReducer } from 'react';


import { signUp, getAccessToken, resetPassword } from './UserPool';
// import { resetPassword } from './UserPool';
// import { useAuth } from '../../providers/AuthProvider';




import { useDispatch, useSelector } from 'react-redux';
import { logInActions } from '../../store';
import FormLoading from './FormLoading';
import { InitialFormState, FormReducer, InitialFormStateInputFieldProps } from './SignUpFormReducer';
import theme from './SignUpFormTheme';
import { User as GetUserProfile } from '../../services/Users';
import { TOKENS } from "../../config";
// import { API } from '../../config';
// import { useAPI } from '../../hooks/APIRequest';

const SignUpForm = ({ onUserSignUp, onForgotPassword }) => {

    const [formState, formStateDispatch] = useReducer(FormReducer, InitialFormState);
    const [signup, setSignup] = useState(true);
    const [loading, setLoading] = useState(false);
    const authState = useSelector(state => state.auth);

    //    const [retrieveProfile, setRetrieveProfile] = useState(false);


    useEffect(() => {
        if (authState.token) {
            GetUserProfile(authState.user_id)
                .then(r => r.json())
                .then(user => {
                    dispatch(logInActions.setUser({ user: user }));
                });
        }
    }, [authState.token])


    // const { getAccessToken, signUp } = useAuth();


    const dispatch = useDispatch();

    const resetForm = () => {
        Object.keys(formState).forEach(k => {
            formStateDispatch({ field: k, value: '', valid: true });
        });
    }

    const toggleSignup = () => {
        setSignup(Boolean(!signup));
        resetForm();
    }

    const validateForm = () => {
        // check form is valid
        let formValid = true;
        Object.keys(formState).forEach(k => {
            if (formState[k].value === '' && (k !== 'fullName' || !signup)) { // don't check 'fullName field if loging in
                formStateDispatch({ field: k, valid: false });
                formValid = false;
            }
        });
        return formValid;
    }

    const loginSuccess = (result) => {

        // console.log(result);
        // console.log(result.getRefreshToken().token);
        const user_id = result.getIdToken().payload.sub;

        // localStorage.setItem(TOKENS.AUTH_TOKEN, String(result.getAccessToken().jwtToken));
        // localStorage.setItem(TOKENS.REFRESH_TOKEN, String(result.getRefreshToken().token));

        resetForm();



        dispatch(logInActions.updateTokens({ token: result.getAccessToken().jwtToken, refreshToken: result.getRefreshToken().token, user_id: user_id }));
        return;


        GetUserProfile(user_id)
            .then(r => r.json())
            .then(user => {
                dispatch(logInActions.logIn({ token: result.getAccessToken().jwtToken, refreshToken: result.getRefreshToken().token, user: user }));
            });

        return;
        // dispatch(logInActions.logIn({ token: result.getAccessToken().jwtToken, user: { username: 'test', name: 'test', role: 'Admin' } }));
        // setLoading(false);
    }

    const loginFailure = (result) => {
        formStateDispatch({ field: 'email', valid: false, message: '' });
        formStateDispatch({ field: 'password', valid: false, message: result.message });
        setLoading(false);
    }

    const submitForm = async () => {

        formStateDispatch({ event: 'SUBMIT' });
        if (!validateForm())
            return;

        setLoading(true);
        if (!signup) {
            // submit signup request
            signUp(formState.email.value, formState.password.value, (err, data) => {

                setLoading(false);
                if (err?.message.match('Password')) {
                    formStateDispatch({ field: 'password', valid: false, message: err.message })
                }
                if (err?.message.match('email')) {
                    formStateDispatch({ field: 'email', valid: false, message: err.message })
                }
                if (!err)
                    onUserSignUp(formState);
            });
        } else {
            // log in
            getAccessToken(formState.email.value, formState.password.value, loginSuccess, loginFailure);
        }
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {},
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <ThemeProvider theme={theme}>
                    <Typography variant='h1'>Vita Opera (dev)</Typography>
                </ThemeProvider>
                {signup ?
                    <div id="toggle-signin-option-container" className="signin-form-line-text">
                        Not a registered user? <span className="highlight-link cursor-on-hover" onClick={toggleSignup}>Sign up</span>
                    </div> :
                    <div id="toggle-signin-option-container" className="signin-form-line-text">
                        Registered user? <span className="highlight-link cursor-on-hover" onClick={toggleSignup}>Log in</span>
                    </div>
                }
                {!signup ?
                    <Box className="form-input-field">
                        <TextField
                            required
                            label="Full name"
                            size='small'
                            fullWidth={true}
                            {...InitialFormStateInputFieldProps(formState, formStateDispatch, 'fullName')}
                        />
                    </Box> : ''
                }
                <Box className="form-input-field">
                    <TextField
                        required
                        label="Email"
                        size='small'
                        fullWidth={true}
                        {...InitialFormStateInputFieldProps(formState, formStateDispatch, 'email')}
                    />
                </Box>
                <Box className="form-input-field">
                    <TextField
                        required
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        sx={{ width: '100%' }}
                        size='small'
                        fullWidth={true}
                        {...InitialFormStateInputFieldProps(formState, formStateDispatch, 'password')}
                        onKeyDown={(evt) => {
                            // console.log(evt, evt.key);
                            if (evt.key === "Enter") {
                                evt.preventDefault();
                                submitForm();
                            }
                        }}
                    />



                </Box>
                <Box className="form-input-field">
                    <Button variant='contained' sx={{ width: '100%' }} size='small' onClick={submitForm}>submit</Button>
                </Box>
                <Box className="form-input-field signin-form-line-text">
                    Forgot <span className="highlight-link cursor-on-hover" onClick={onForgotPassword}>password?</span>
                </Box>
            </div>
            {loading ? <FormLoading /> : ''}
        </Box>
    );
}

export default SignUpForm;