import { Routes, Route } from "react-router-dom";
import AdminPartList from "./AdminPartList";

const AdminParts = () => {
    return (
        <Routes>
            <Route path="/" element={<AdminPartList />} />
        </Routes>
    )
}

export default AdminParts;