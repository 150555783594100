import { GetParts } from "../../../../services/Parts";
import { useState, useEffect } from "react";
import {LoadingMain} from "../../../../components/LoadingModal";
// import { Button } from "@mui/material";
import AdminPartCard from "./AdminPartCard";
import AdminNewPartModal from "./AdminNewPartModal";
import AdminRemovePartModal from "./AdminRemovePartModal";
import DynamicTable from "../../../../components/DynamicTable";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { Button } from "../../../../components/Buttons";
import { DeletePart } from "../../../../services/Parts";
import { MainContainer } from "../../../../components/Containers";
// import { Button } from "@mui/material";
// import ConfirmationPopup from "../../AdminConfirmationPopup";

const AdminPartList = () => {

    const MODAL_STATE = {
        NONE: "NONE",
        ADD_PART: "ADD_PART",
        EDIT_PART: "EDIT_PART",
        REMOVE_PART: "REMOVE_PART",
    };

    const [parts, setparts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalState, setModalState] = useState({ modal: MODAL_STATE.NONE, data: null });

    const updatePartsList = () => {
        GetParts()
            .then((r) => r.json())
            .then((result) => {
                setparts(result.data);
                setLoading(false);
            });
    };

    const removePart = (part) => {
        DeletePart(part._id)
            .then(r => r.json())
            .then(r => {
                updatePartsList();
                setModalState({ modal: MODAL_STATE.NONE });
            })
    }


    useEffect(() => {
        updatePartsList();
    }, []);

    // console.log(parts);

    const tableHeader = [
        'Name',
        'Model',
        'Manufacturer',
        'Type',
        'Stock',
        'Suppliers',
        'Actions',
    ]

    const editPart = (part) => setModalState({ modal: MODAL_STATE.EDIT_PART, data: part })


    // return <LoadingMain show={loading} />;

    return (
        <>
            <div style={{ backgroundColor: "#D9DDDB" }} className="h-screen pt-8">
                {loading ? (
                    <MainContainer>
                        <LoadingMain show={loading} />
                    </MainContainer>
                ) : (




                    <MainContainer>


                        {/* <div className="platform-individual-document-container"> */}


                        {/* <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
                            <Button onClick={() => { setModalState({ modal: MODAL_STATE.ADD_PART }) }} color="success" variant='contained'>Add Part</Button>
                        </div> */}



                        <Button label="Add Part" onClick={() => { setModalState({ modal: MODAL_STATE.ADD_PART }) }} />


                        <div className="mt-4">
                            <DynamicTable
                                data={parts.map(p => (
                                    [
                                        <div key={`${p._id}name`} onClick={() => { editPart(p) }}>{p.name}</div>,
                                        <div key={`${p._id}model`} onClick={() => { editPart(p) }}>{p.model}</div>,
                                        <div key={`${p._id}manufacturer`} onClick={() => { editPart(p) }}>{p.manufacturer}</div>,
                                        <div key={`${p._id}type`} onClick={() => { editPart(p) }}>{p.type}</div>,
                                        <div key={`${p._id}stock`} onClick={() => { editPart(p) }}>{p.stock.map((stock, i) => <div key={i}>{stock.location} : {stock.items.length}</div>)}</div>,
                                        <div key={`${p._id}suppliers`} onClick={() => { editPart(p) }}>{p.suppliers.map((stock, i) => <div key={i}>{stock.location} : {stock.items.length}</div>)}</div>,
                                        <div key={`${p._id}action`} onClick={() => { setModalState({ modal: MODAL_STATE.REMOVE_PART, data: p }) }} className="text-red-500"><DeleteForever /></div>,
                                    ])
                                )}
                                headerData={tableHeader}
                            />
                        </div>







                        {/* <div style={{ paddingTop: 10 }}>
                            {parts.map((part, i) => (
                                <AdminPartCard key={i} part={part} onClick={() => setModalState({ modal: MODAL_STATE.EDIT_PART, data: part })} reloadParts={updatePartsList} />
                            ))}
                        </div> */}
                        {(modalState.modal === MODAL_STATE.ADD_PART) && <AdminNewPartModal onClose={() => { setModalState({ modal: MODAL_STATE.NONE }) }} updatePartsList={updatePartsList} />}
                        {(modalState.modal === MODAL_STATE.EDIT_PART) && <AdminNewPartModal onClose={() => { setModalState({ modal: MODAL_STATE.NONE }) }} updatePartsList={updatePartsList} part={modalState.data} update={true} />}
                        {(modalState.modal === MODAL_STATE.REMOVE_PART) && <AdminRemovePartModal part={modalState.data} onClose={() => { setModalState({ modal: MODAL_STATE.NONE }) }} onRemove={removePart} />}
                        {/* </div> */}
                    </MainContainer>

                )}
            </div>
        </>
    );
};

export default AdminPartList;