import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TaskHistoryAll as GetAllTaskHistory } from '../../../services/Tasks';
import { Platforms as GetPlatforms } from '../../../services/Platforms';
import { Tasks as GetTasks } from '../../../services/Tasks';
import { useEffect, useReducer, useState, useContext, useRef } from 'react';
import { Button, Divider, Typography, Box, Card, Skeleton } from '@mui/material';
//import OverviewPlatformsTaskData from './OverviewPlatformsTaskData';
import OverviewPartsPlatformPartsData from './OverviewPartsPlatformPartsData';
import OverviewPartsStockData from './OverviewPartsStockData';
import OverviewPartsLocationData from './OverviewPartsLocationData';
import OverviewPartsOrdersData from './OverviewPartsOrdersData';
//import OverviewPlatformsLocationData from './OverviewPlatformsLocationData';
//import OverviewPlatformsFlightData from './OverviewPlatformsFlightData';
import { DefaultReducer } from '../../../components/DefaultReducer';
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfTwoTone';
import { useSelector } from 'react-redux';

import { Parts as GetParts } from '../../../services/Parts';
//import { RequestErrorHandlerContext } from '../../../components/Main';
import { RequestErrorHandlerContextNEW as RequestErrorHandlerContext } from '../../../App';
import { ERRORS } from '../../../config';
import ConnectionError from '../../../components/ConnectionError';
import { MainContainer } from '../../../components/Containers';

import DynamicTable from '../../../components/DynamicTable';
import jsPDF from 'jspdf';


const OverviewParts = () => {

    const [fromDate, setFromDate] = useState(dayjs(new Date()).subtract(1, 'month'));
    const [toDate, setToDate] = useState(dayjs(new Date()));
    const locationsData = useSelector(state => state.locations.locations);
    const [activeTasks, activeTaskDispatch] = useReducer(DefaultReducer, { loading: true, data: [] })
    const [historyData, historyTaskDispatch] = useReducer(DefaultReducer, { loading: true, data: [] })
    const [platformData, platformDispatch] = useReducer(DefaultReducer, { loading: true, data: [] })
    const [partsData, partsDispatch] = useReducer(DefaultReducer, { loading: true, data: [] })
    const errorHandler = useContext(RequestErrorHandlerContext);
    const [requestError, requestErrorDispatch] = useReducer(DefaultReducer, { error: false, type: null });


    const table1 = useRef();
    const table2 = useRef();
    const table3 = useRef();

    // console.log(partsData);

    const getTaskHistory = () => {
        historyTaskDispatch({ loading: true });
        GetAllTaskHistory({ from: fromDate.toDate(), to: toDate.toDate() })
            .then(res => res.json())
            .then(result => errorHandler(result))
            .then(res => { historyTaskDispatch({ loading: false, data: res }) })
            .catch(err => { requestErrorDispatch({ error: true, type: err }) })

    }

    const getActiveTasks = () => {

        activeTaskDispatch({ loading: true });
        GetTasks({ from: fromDate.toDate(), to: toDate.toDate() })
            .then(res => res.json())
            .then(result => errorHandler(result))
            .then(res => { activeTaskDispatch({ loading: false, data: res }) })
            .catch(err => { requestErrorDispatch({ error: true, type: err }) })
    }


    const getPartsData = () => {

        partsDispatch({ loading: true });
        GetParts()
            .then(res => res.json())
            .then(result => errorHandler(result))
            .then(res => { partsDispatch({ loading: false, data: res }) })
            .catch(err => { requestErrorDispatch({ error: true, type: err }) })
    }

    const getAllPlatforms = () => {

        platformDispatch({ loading: true });
        GetPlatforms()
            .then(res => res.json())
            .then(result => errorHandler(result))
            .then(res => { platformDispatch({ loading: false, data: res }) })
            .catch(err => { requestErrorDispatch({ error: true, type: err }) })
    }

    const updateResults = () => {
        getTaskHistory();
        getActiveTasks();
    }

    useEffect(() => {
        updateResults();
        getAllPlatforms();
        getPartsData();
    }, []);

    /**
     * 
     * @param {*} platformID 
     * @returns List of taskID's for platformID
     */
    const getPlatformTasks = (platformID) => { return activeTasks.data.filter(t => { return t.platformID === platformID }).map(t => { return t._id }) }

    /**
     * 
     * @returns list of platforms which have been worked on over the selected time interval 
     */
    const getPlatforms = () => {
        let temp = [];
        activeTasks.data.filter(t => { return t.platformID !== undefined }).map(p => { return p.platformID }).forEach(id => {
            if (!temp.includes(id))
                temp.push(id);
        });
        return temp;
    }

    const totalDurationOfTask = (taskID, fromStatus, toStatus) => {
        let updates = historyData.data.filter(t => { return (t.taskID === taskID) });
        let start = updates.find(t => { return t.status === fromStatus })?.updatedAt;
        let end = updates.find(t => { return t.status === toStatus })?.updatedAt;

        if (start || end) {
            if (!start)
                start = fromDate.toDate();
            if (!end)
                end = toDate.toDate();
            let diff = (new Date(end)) - (new Date(start));
            return Number(diff);
        }
    }

    const totalDurationForPlatform = (platformID, startStatus, endStatus) => {
        return getPlatformTasks(platformID).reduce((total, curr) => {
            let temp = totalDurationOfTask(curr, startStatus, endStatus);
            if (temp === undefined)
                temp = 0;
            return total + temp;
        }, 0);
    }


    const getPlatformName = (id) => { return activeTasks.data.find(t => { return t.platformID === id }).platform.name }

    /**
     * 
     * 
     */
    const getGraphDataTasks = () => {
        let graph = [];
        getPlatforms().forEach(p => {
            graph.push({
                name: getPlatformName(p),
                pending: totalDurationForPlatform(p, 'PENDING', 'IN_PROGRESS'),
                maintenance: totalDurationForPlatform(p, 'IN_PROGRESS', 'DONE')
            });
        });
        return graph;
    }

    /**
     * 
    */
    const getGraphPlatformParts = () => {
        let graph = [];
        platformData.data.data.forEach(p => {
            graph.push({
                name: p.name,
                parts: p.parts.length
            });
        })
        return graph;
    }

    /**
     * 
    */
    const getGraphStock = () => {
        let graph = [];

        let partTypes = partsData.data.data.map(t => { return t.type }).reduce((total, curr) => {
            let _total = { ...total };
            !_total[curr] ? _total[curr] = 1 : _total[curr] = _total[curr] + 1;
            return _total;
        }, {})

        Object.keys(partTypes).forEach(k => {
            graph.push({
                name: k,
                val: partTypes[k]
            })
        });

        return graph;
    }

    /**
     * 
    */
    const getGraphLocationData = () => {
        let graph = [];
        let result = partsData.data.data
            .map(p => { return p.stock })
            .reduce((total, curr) => {
                let t = [...total];
                t.push(...curr);
                return t;
            }, [])
            .reduce((total, curr) => {
                let t = { ...total };
                if (!t[curr.location])
                    t[curr.location] = curr.items.length;
                else
                    t[curr.location] += curr.items.length;
                return t;
            }, {});

        Object.keys(result).forEach(k => {
            graph.push({
                name: k,
                val: result[k]
            })
        });

        return graph;
    }

    const getGraphOrdersData = () => {
        return [];

        let graph = [];
        let result = historyData.data
            .filter(t => { return (t.partRequest && t.part) })
            .map(t => { return { part: t.part, task: t.taskID } })
            .reduce((total, curr) => {
                let _temp = { ...total };
                if (!_temp[curr.part._id])
                    _temp[curr.part._id] = 1;
                else
                    _temp[curr.part._id] += 1;
                return _temp;
            }, {});

        console.log(result);

        Object.keys(result).forEach(k => {
            console.log(k);
            graph.push({
                name: partsData.data.data.find(t => { return (t._id === k) }).name,
                value: result[k]
            })
        })

        return graph;
    }


    /**
     * 
     * 
     */
    const getNumPlatforms = (location) => { return (platformData.data.data.filter(t => { return (t.location === location) })).length }

    /**
     * 
     * 
    const getGraphDataLocations = () => {
        let graph = [];
        let temp = [];
        
        Object.keys(locationsData).forEach(k => { temp.push(locationsData[k]) });
        temp.forEach(p => {
            graph.push({
                name: p.name,
                number: getNumPlatforms(p.name)
            });
        });
        return graph;
    }
    */
    /**
     * 
     * 
    const getGraphDataPlatformFlight = () => {
        let graph = [];
        let temp = [];
        
        platformData.data.data.forEach(t => {
            
            let _obj = { name: t.name, duration: 0 };
            _obj.duration = t.flightData.reduce((total, curr) => { return total + curr.duration }, 0);
            graph.push(_obj);
            
        });
        
        return graph;
    }
    */

    const PDFTemplate = () => {
        return `
            <div>Generated report from ${fromDate.toISOString()} to ${toDate.toISOString()}</div>
            <div style="margin-top:100px"></div> 
            ${table1.current.outerHTML}
            <div style="margin-top:100px"></div> 
            ${table2.current.outerHTML} 
            <div style="margin-top:100px"></div> 
            ${table3.current.outerHTML}`
    }

    const GeneratePDF = () => {

        // console.log(exampleTable.current.outerHTML);

        var doc = new jsPDF();

        // doc.html('<div style="font-size:15px;">fakjshdkajshdfbkajshdbkfjashbdf</div>', {
        doc.html( PDFTemplate() , {
            callback: function (doc) {
                doc.save();
            },
            x: 10,
            y: 10,
            filename: 'VitaOpera - Platform report.pdf',
            width: 150,
            windowWidth: 700,
        });
    }



    const LoadingGraph = () => {
        return (
            <>
                <Skeleton variant="rectangular" height={80} sx={{ margin: 'auto', marginTop: 1, width: '90%' }} animation="wave" />
                <Skeleton variant="rectangular" height={80} sx={{ margin: 'auto', marginTop: 1, width: '90%' }} animation="wave" />
            </>
        )
    }

    return (

        <>
            <div className='py-8' style={{ backgroundColor: "#D9DDDB" }}>
                <MainContainer>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{}}>
                            <Typography>From:</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker onChange={(v) => { setFromDate(v) }} value={fromDate} />
                            </LocalizationProvider>
                        </Box>
                        <Box sx={{ marginLeft: 1 }}>
                            <Typography>To:</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker onChange={(v) => { setToDate(v) }} value={toDate} />
                            </LocalizationProvider>
                        </Box>
                    </Box>
                    <Box sx={{ marginTop: 1, display: 'flex', justifyContent: 'center' }}>
                        <Button variant='outlined' color='secondary' disabled={(activeTasks.loading || historyData.loading || platformData.loading)} onClick={GeneratePDF}><PictureAsPdfTwoToneIcon />Download Report</Button>
                        <Button variant='outlined' onClick={updateResults} sx={{ marginLeft: 1 }}>Update</Button>
                    </Box>
                </MainContainer>

                {/* <MainContainer className="mt-4">
                    {requestError.error ? <ConnectionError error={requestError.type} /> :
                        <>
                            <Divider sx={{ marginTop: 3 }} />
                            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', paddingTop: 1, paddingBottom: 2, backgroundColor: '#fafafa' }}>
                                <Card sx={{ width: 350, height: 225, marginTop: 2, marginLeft: 1, marginRight: 1 }}>
                                    <Typography sx={{ textAlign: 'center' }}>Platform Parts</Typography>
                                    {(activeTasks.loading || historyData.loading || platformData.loading) ? <LoadingGraph /> : <OverviewPartsPlatformPartsData data={getGraphPlatformParts()} />}
                                </Card>
                                <Card sx={{ width: 350, height: 225, marginTop: 2, marginLeft: 1, marginRight: 1 }}>
                                    <Typography sx={{ textAlign: 'center' }}>Part Stock</Typography>
                                    {(activeTasks.loading || historyData.loading || platformData.loading || partsData.loading) ? <LoadingGraph /> : <OverviewPartsStockData data={getGraphStock()} />}
                                </Card>
                                <Card sx={{ width: 350, height: 225, marginTop: 2, marginLeft: 1, marginRight: 1 }}>
                                    <Typography sx={{ textAlign: 'center' }}>Location Stock</Typography>
                                    {(activeTasks.loading || historyData.loading || platformData.loading || partsData.loading) ? <LoadingGraph /> : <OverviewPartsLocationData data={getGraphLocationData()} />}
                                </Card>
                           
                                <Card sx={{ width: 350, height: 225, marginTop: 2, marginLeft: 1, marginRight: 1 }}>
                                    <Typography sx={{ textAlign: 'center' }}>Part Orders</Typography>
                                    {(activeTasks.loading || historyData.loading || platformData.loading || partsData.loading) ? <LoadingGraph /> : <OverviewPartsOrdersData data={getGraphOrdersData()} />}
                                </Card>
                            </Box>
                        </>
                    }
                </MainContainer> */}







                <MainContainer className="mt-4">

                    <div className='text-center font-semibold pb-3 '>Platform Parts</div>

                    {(!(activeTasks.loading || historyData.loading || platformData.loading)) ?
                        <div className='flex'>
                            <div className='w-1/2'>
                                <DynamicTable
                                    data={getGraphPlatformParts().map(x => [x.name, x.parts])}
                                    headerData={['Platform', 'Num Parts',]}
                                    ref={table1}
                                />
                            </div>
                            <div className='w-1/2'>
                                <div className='flex justify-center'>
                                    {(activeTasks.loading || historyData.loading || platformData.loading) ? <LoadingGraph /> : <OverviewPartsPlatformPartsData width={500} height={400} data={getGraphPlatformParts()} />}
                                </div>

                            </div>
                        </div> : null
                    }

                </MainContainer>


                <MainContainer className="mt-4">

                    <div className='text-center font-semibold pb-3 '>Part Stock</div>

                    {(!(activeTasks.loading || historyData.loading || platformData.loading || partsData.loading)) ?
                        <div className='flex'>
                            <div className='w-1/2'>
                                <DynamicTable
                                    // data={getGraphStock().map(x => [x.name, x.val])}
                                    data={partsData.data.data.map(x => [x.name, x.stock.reduce((t, i) => (t + i.items.length), 0)])}
                                    headerData={['Part Name', 'Stock']}
                                    ref={table2}
                                />
                            </div>

                            <div className='w-1/2'>
                                <div className='flex justify-center'>
                                    {/* {(activeTasks.loading || historyData.loading || platformData.loading || partsData.loading) ? <LoadingGraph /> : <OverviewPartsStockData width={500} height={400}  data={getGraphStock()} />} */}
                                    {(activeTasks.loading || historyData.loading || platformData.loading || partsData.loading) ? <LoadingGraph /> :
                                        <OverviewPartsStockData
                                            width={500}
                                            height={400}
                                            data={partsData.data.data.map(x => { return { name: x.name, val: x.stock.reduce((t, i) => (t + i.items.length), 0) } })}
                                        />}
                                </div>

                            </div>
                        </div> : null
                    }

                </MainContainer>




                <MainContainer className="mt-4">

                    <div className='text-center font-semibold pb-3 '>Location Stock</div>

                    {(!(activeTasks.loading || historyData.loading || platformData.loading || partsData.loading)) ?
                        <div className='flex'>
                            <div className='w-1/2'>
                                <DynamicTable
                                    data={getGraphLocationData().map(x => [x.name, x.val])}
                                    // data={locationsData.data.map(x=>[x.name])}
                                    headerData={['Location', 'Stock']}
                                    ref={table3}
                                />
                            </div>

                            <div className='w-1/2'>
                                <div className='flex justify-center'>
                                    {(activeTasks.loading || historyData.loading || platformData.loading || partsData.loading) ? <LoadingGraph /> : <OverviewPartsLocationData data={getGraphLocationData()} />}
                                </div>

                            </div>
                        </div> : null
                    }

                </MainContainer>






            </div>






        </>


    )
}

export default OverviewParts;