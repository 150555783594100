import { createTheme } from "@mui/material";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Roboto',
            'Arial',
            '"Helvetica Neue"',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '-apple-system',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        fontSize: 5,
        textAlign: 'center',
        px: 3,
    },
});

theme.typography.h1 = {
    fontSize: '1.2rem',
    fontFamily: 'Arial',
    '@media (min-width:600px)': {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
    },
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
};

export default theme;