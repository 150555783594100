import { Button, Box, Typography, Modal, Avatar, Card, TextField, MenuItem, Select, Paper, Accordion, AccordionSummary, AccordionDetails, InputLabel, InputAdornment, Input } from "@mui/material";
import { useEffect, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddPlatformPartModalFilter from "./AddPlatformPartModalFilter";
import { AddCircleOutline, Factory, Flight } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import ModelIcon from '@mui/icons-material/DriveFileRenameOutline';
import { Parts as GetParts } from "../../../services/Parts";
import { CreateTask } from "../../../services/Tasks";
import AddPartCard from "./AddPartCard";
import { PlatformAddPartTask } from "../../../models/TasksModel";

import PartsAllCard from "../../parts/tabs/PartsAllCard";

const InitialState = { allParts: {total:0, data:[]}, selectedPart: null };

const formReducer = (state, action) => {
    if (action.input === 'PARTS_LIST_RECIVED') {
        return { ...state, partsListRecieved: true, allParts: action.data };
    }
    if (action.input === 'PART_SELECTION') {
        return { ...state, selectedPart: action.data };
    }
    if (action.input === 'RESET') {
        return InitialState;
    }
    return { ...state };
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 1200,
    height: 650,
    bgcolor: 'white',
    border: '0px solid #000',
    boxShadow: 4,
    p: 2,
};

const PlatformPartAddModal = ({ visible, setAddModalState }) => {

    const userInfo = useSelector(state => state.auth.user);
    const platformInfo = useSelector(state => state.platform.platform);
    const [formState, formDispatch] = useReducer(formReducer, InitialState);

    const onClose = () => {
        if (!formState.requested) {
            setAddModalState(false);
        }
    }

    const updatePartsList = (query) => {
        GetParts(query)
        .then(r => { return r.json() })
        .then(data => {
            formDispatch({ input: 'PARTS_LIST_RECIVED', data: data });
        })
    }

    const applyFilter = (query) => {
        let q = { };
        Object.keys(query).forEach(k => {
            if (query[k] !== '')
                q[k] = query[k];
        });
//        updatePartsList(query);
        updatePartsList(q);
    }

    useEffect(() => {
        if (visible) 
            updatePartsList({});
        else 
            formDispatch({ input: 'RESET' });
    }, [visible]);

    const sendRequest = () => {

        let task = new PlatformAddPartTask(platformInfo._id, formState.selectedPart);
        task.SetReporter(userInfo);
        task.SetDescription(`Add ${formState.selectedPart.name} - ${formState.selectedPart.model} to ${platformInfo.name}`);

        CreateTask(task)
        .then(r => {return r.json()})
        .then(r => {
            console.log(r);
            onClose();
        });

    }

    return (
        <>
            <Modal
                open={visible}
                onClose={onClose}
                sx={{ outline: 0 }}
                disableEnforceFocus={true}
                disableAutoFocus={true}
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Avatar sx={{ bgcolor: '#e0e0e0', width: 45, height: 45, fontSize: 10, margin: 0.5 }}><AddCircleOutline sx={{ fontSize: 30, color: 'green' }} /></Avatar>
                        <Typography sx={{ fontSize: 20, marginTop: 0.8, marginLeft: 2 }} color="text.primary">Add Part</Typography>
                        <CloseIcon sx={{ marginLeft: 'auto' }} color="text.secondary" onClick={onClose} />
                    </Box>
                    <Paper sx={{ marginTop: 1, marginBottom: 1, padding: 1 }}>
                        <AddPlatformPartModalFilter onApply={applyFilter}/>
                    </Paper>
                    <Typography color="text.secondary" >Total: {formState.allParts.total}</Typography>
                    <Box sx={{ height: 300, backgroundColor: '#d0dee1', overflow: 'scroll', overflowX: 'hidden', paddingBottom: 0.75, paddingLeft: 0.75, paddingRight: 0.75 }}>
                        {/* {formState.allParts.data.map((p, i) => { return <AddPartCard key={i} part={p} select={(x) => { formDispatch({ input: 'PART_SELECTION', data: x }) }} selected={formState.selectedPart === p} /> })} */}
                        {formState.allParts.data.map((p, i) => { return <PartsAllCard key={i} part={p} select={(x) => { formDispatch({ input: 'PART_SELECTION', data: x }) }} highlight={formState.selectedPart === p} /> })}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginTop: 2 }}>
                            <Paper elevation={1} sx={{ backgroundColor: '#fafafa', width: '100%', marginRight: 2, visibility: (!formState.selectedPart ? 'hidden' : 'visible') }}>
                                <Typography sx={{ fontSize: 14, margin: 1 }}>Selected:</Typography>
                                <Box sx={{ display: 'inline-block', width: '50%' }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                    }}>
                                        <Flight sx={{ color: '#006633', marginLeft: 1, marginRight: 0.5 }} />
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary">{formState.selectedPart?.type}</Typography>
                                    </div>
                                </Box>
                                <Box sx={{ display: 'inline-block', width: '50%' }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                    }}>
                                        <Factory sx={{ color: '#006633', marginLeft: 1, marginRight: 0.5 }} />
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary"> {formState.selectedPart?.manufacturer}</Typography>
                                    </div>
                                </Box>
                                <Box sx={{ display: 'inline-block', width: '50%' }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                    }}>
                                        <ModelIcon sx={{ color: '#006633', marginLeft: 1, marginRight: 0.5 }} />
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary"> {formState.selectedPart?.model}</Typography>
                                    </div>
                                </Box>
                            </Paper>
                            <Button variant="contained" onClick={sendRequest} sx={{ marginTop: 1.5 }} disabled={!formState.selectedPart}>Apply</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default PlatformPartAddModal;