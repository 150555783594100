import { useSelector } from "react-redux";
import PlatformTaskColumn from "./PlatformTaskColumn";
import { useEffect, useState } from "react";
// import { Tasks as GetTasks } from "../../../services/Tasks";
import { useDispatch } from "react-redux";
import { platformActions } from "../../../store";
import PlatformTaskModal from "./PlatformTaskModal/PlatformTaskModal";
import { LoadingMain } from "../../../components/LoadingModal";
import { Tasks as GetTasks, UpdateTask } from "../../../services/Tasks";
import { Platforms as GetPlatform } from "../../../services/Platforms";
import { STATUS_LIST } from "../../../models/TasksModel";
// import { Tasks as GetTasks } from "../../../services/Tasks";

import { API } from "../../../config";
import { useAPI } from "../../../hooks/APIRequest";
import { MainContainer } from "../../../components/Containers";

const PlatformIndividualTasks = () => {

    const platformData = useSelector(state => state.platform.platform);
    const [tasks, setTasks] = useState([]);

    const taskList = useSelector(state => state.platform.tasks);

    // console.log(taskList);

    // const [loading, setLoading] = useState(true);
    const [selectedTask, setSelectedTask] = useState(null);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     GetTasks({ platformID: platformData._id })
    //         .then(r => { return r.json() })
    //         .then(result => {
    //             setLoading(false);
    //             setTasks(result);
    //             dispatch(platformActions.setTasks(result));
    //         });
    // }, [])


    const _tasks = useAPI(API.TASKS({ platformID: platformData._id }));
    useEffect(() => {
        if (_tasks.json) {
            dispatch(platformActions.setTasks(_tasks.json));
            setTasks(_tasks.json);
        }
    }, [_tasks.json]);


    const refreshTasks = () => {
        GetTasks({ platformID: platformData._id })
            .then(r => r.json())
            .then(r => {
                dispatch(platformActions.setTasks(r));
                setTasks(r);
            })
    }

    const onTaskUpdate = (task, selected = true) => {
        setTasks(tasks.map(t => { if (t._id === task._id) { return task } else { return t } }));

        if (selected)
            setSelectedTask(task);


        if (task.isApproved) {
            refreshTasks();
        }

        // refreshPlatform();
        /*
        if(!task.isApproved)
            setSelectedTask(task);*/
    }

    const getStatusFromName = (columnName) => {
        if (columnName === "In Progress")
            return STATUS_LIST.TASK_STATUS_IN_PROGRESS;
        else if (columnName === "Pending / Awaiting Part")
            return STATUS_LIST.TASK_STATUS_PENDING;
        else if (columnName === "To do")
            return STATUS_LIST.TASK_STATUS_TODO;
        else if (columnName === "Done")
            return STATUS_LIST.TASK_STATUS_DONE;
    }

    const onTaskDrop = (taskID, column) => {

        const transitionToStatus = getStatusFromName(column);
        let task = tasks.find(t => { return t._id === taskID });
        if (task.status !== transitionToStatus) {
            UpdateTask(taskID, { status: transitionToStatus })
                .then(r => { return r.json() })
                .then(result => {
                    onTaskUpdate(result, false);
                });
        }


        //        if(task.status )
        /*
        UpdateTask(taskID, {status:"IN_PROGRESS"})
        .then(r => { return r.json() })
        .then(result => {
            onTaskUpdate(result, false);
        });*/

    }

    // console.log(tasks);

    return (



        <div style={{ backgroundColor: "#D9DDDB" }} className="pt-8 pb-2 h-screen">


            {/* <MainContainer>

                <div>
                <div className="font-semibold text-slate-700 text-center">Filter Platform Tasks</div>
                </div>
            </MainContainer> */}
            <MainContainer className="mt-4 pb-8">

                {/* <div className="mt-8 max-w-7xl m-auto bg-red-100"> */}
                <div>
                    <div className="font-semibold text-slate-700 text-center">Platform Tasks</div>
                    {!_tasks.done ? <LoadingMain show={true} /> : null}


                    {tasks.filter(x => !x.isApproved).length !== 0 ? 
                    <div style={{ display: 'flex', flexDirection: 'row', overflow: 'auto', whiteSpace: 'nowrap', marginTop: '20px', justifyContent: 'center' }}>
                        <PlatformTaskColumn onTaskSelect={setSelectedTask} name={"Pending / Awaiting Part"} tasks={tasks.filter((t) => { return (t.status === STATUS_LIST.TASK_STATUS_PENDING || t.status === STATUS_LIST.TASK_STATUS_AWAITING_PART) })} onTaskDrop={onTaskDrop} />
                        <PlatformTaskColumn onTaskSelect={setSelectedTask} name={"To do"} tasks={tasks.filter((t) => { return t.status === STATUS_LIST.TASK_STATUS_TODO })} onTaskDrop={onTaskDrop} />
                        <PlatformTaskColumn
                            onTaskSelect={setSelectedTask}
                            name={"In Progress"}
                            tasks={tasks.filter((t) => { return t.status === STATUS_LIST.TASK_STATUS_IN_PROGRESS })}
                            onTaskDrop={onTaskDrop}
                            />
                        <PlatformTaskColumn onTaskSelect={setSelectedTask} name={"Done"} tasks={tasks.filter((t) => { return (t.status === STATUS_LIST.TASK_STATUS_DONE && !t.isApproved) })} onTaskDrop={onTaskDrop} />
                    </div> : 
                    <div className="text-md text-slate-600 text-center mt-4">
                        No current tasks for platform
                    </div>}

                    {selectedTask ? <PlatformTaskModal onClose={() => { setSelectedTask(null) }} onTaskUpdate={onTaskUpdate} task={selectedTask} /> : ''}
                </div>
            </MainContainer>
        </div>
    )
}

export default PlatformIndividualTasks;