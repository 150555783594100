import { useState } from "react";
import { Modal, Box } from "@mui/material";
import { AddLocation, updateLocation } from "../../../../services/Locations";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from "react";
import { TextFieldInput } from "../../../../components/Inputs";
import { Button } from "../../../../components/Buttons";


const RemoveLocationModal = ({ onClose }) => {
    return (
        <Modal
            open={true}
            onClose={onClose}
            sx={{ outline: 0 }}
            disableEnforceFocus={true}
            disableAutoFocus={true}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 450,
                bgcolor: 'background.paper',
                border: '0px solid #000',
                boxShadow: 5,
                // pt: 1,
                // px: 2,
                // pb: 3
            }}>
                <div className="px-3 pb-3 pt-1">
                    <div className="flex justify-end">
                        <CloseIcon color="text.secondary" onClick={onClose} />
                    </div>
                </div>
            </Box>
        </Modal>

    )
}

const AdminNewLocationModal = ({ onClose, existingLocation, updateLocations }) => {
    const [name, setName] = useState("");
    const [country, setCountry] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [isFormValid, setFormValid] = useState(false);

    useEffect(() => {
        if (existingLocation !== "") {
            setName(existingLocation.name);
            setCountry(existingLocation.country);
            setLatitude(existingLocation.lat);
            setLongitude(existingLocation.long);
        }
    }, [existingLocation]);

    const handleFormChange = (field, value) => {
        if (field === "name") setName(value);
        if (field === "country") setCountry(value);
        if (field === "latitude") setLatitude(value);
        if (field === "longitude") setLongitude(value);
        setFormValid(name !== "" && country !== "" && latitude !== "" && longitude !== "");
    };

    const handleSubmit = () => {
        const lat = parseFloat(latitude);
        const long = parseFloat(longitude)
        let location = { name, country, lat, long }

        if (existingLocation == "") {
            AddLocation(location)
                .then(r => { return r.json() })
                .then(r => {
                    console.log(r);
                    onClose();
                });
            onClose();
        } else {
            const id = existingLocation._id
            updateLocation(id, location)
                .then(r => { return r.json() })
                .then(r => {
                    console.log(r);
                    onClose();
                });
        }
        updateLocations()
    };

    return (


        <Modal
            open={true}
            onClose={onClose}
            sx={{ outline: 0 }}
            disableEnforceFocus={true}
            disableAutoFocus={true}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 450,
                bgcolor: 'background.paper',
                border: '0px solid #000',
                boxShadow: 5,
                // pt: 1,
                // px: 2,
                // pb: 3
            }}>

                {/* <Modal open={true} onClose={onClose}> */}

                <div className="px-3 pb-3 pt-1">

                    <div className="flex justify-end">
                        <CloseIcon color="text.secondary" onClick={onClose} />
                    </div>

                    <div className="mt-3">
                        <TextFieldInput name={"Name"} placeholder={"name"} onChange={(v) => { handleFormChange("name", v) }} value={name} />
                    </div>
                    <div className="mt-3">
                        <TextFieldInput name={"Country"} placeholder={"country"} onChange={(v) => { handleFormChange("country", v) }} value={country} />
                    </div>
                    <div className="mt-3">
                        <TextFieldInput name={"Lat"} placeholder={"lat"} onChange={(v) => { handleFormChange("latitude", v) }} value={latitude} />
                    </div>
                    <div className="mt-3">
                        <TextFieldInput name={"Long"} placeholder={"long"} onChange={(v) => { handleFormChange("longitude", v) }} value={longitude} />
                    </div>
                    <div className="mt-3 flex flex-row justify-end">

                        {/* {existingLocation !== "" ?
                            <div className="mr-3">
                                <Button label={"Remove location"} onClick={() => { }} variant="warning" />
                            </div> : null
                        } */}
                        <Button label={existingLocation === "" ? "Submit" : "Update"} disabled={!isFormValid} onClick={handleSubmit} />
                    </div>


                    {/* <TextField
                    label="Name"
                    value={name}
                    onChange={(e) => handleFormChange("name", e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Country"
                    value={country}
                    onChange={(e) => handleFormChange("country", e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Latitude"
                    value={latitude.toString()}
                    onChange={(e) => handleFormChange("latitude", e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Longitude"
                    value={longitude.toString()}
                    onChange={(e) => handleFormChange("longitude", e.target.value)}
                    fullWidth
                    margin="normal"
                /> */}
                    {/* <Button variant="contained" onClick={handleSubmit} disabled={!isFormValid}>
                        {existingLocation === "" ? "Submit" : "Update"}
                    </Button> */}


                </div>
            </Box>
        </Modal>
    );
};

export default AdminNewLocationModal;
