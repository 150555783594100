import { API } from '../config';
import { GET, POST, DELETE, PATCH } from './Methods';

const Locations = () => {
    return GET(API.LOCATIONS());
}

const AddLocation = (location) => {
    return POST(API.LOCATIONS(), location)
}

const deleteLocation = (id) => {
    return DELETE(API.LOCATION(id))
}

const updateLocation = (id, update) => {
    return PATCH(API.LOCATION(id), update)
}

export { Locations, AddLocation, deleteLocation, updateLocation }