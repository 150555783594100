import PlatformTaskHistoryCard from "./PlatformTaskHistoryCard";
import { useSelector } from "react-redux";
import { Button, TextField } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import { Search } from "@mui/icons-material";

const PlatformTaskHistoryFilter = () => {
    return (
        <div style={{display:'flex', flexDirection:'row'}}>
            <TextField
                id="search"
                label="find"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                }}
                size="small"
            />
            <Button variant="outlined" sx={{marginLeft:'auto'}}>Advanced Search</Button>
        </div>)
}

const PlatformTaskHistory = () => {

    const tasks = useSelector(state => state.platform.tasks);
    return (
        <>
            {/* <div style={{ paddingTop: 20 }}>
                <PlatformTaskHistoryFilter />
            </div> */}

            <div style={{ paddingTop: 20 }}>
                {tasks.filter(((t) => { return t.isApproved })).map((t, i) => { return <PlatformTaskHistoryCard key={i} task={t} /> })}
            </div>
        </>

    )
}

export default PlatformTaskHistory;