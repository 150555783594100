import PartsRequestColumn from "./PartsRequestsColumn";
import { useEffect, useState } from "react";
import { Tasks as GetTasks } from "../../../services/Tasks";
import { STATUS_LIST } from "../../../models/TasksModel";
import { LoadingMain } from "../../../components/LoadingModal";

import PartRequestModal from "./PartRequestModal";
import PartProcessingModal from "./PartProcessingModal";
import PartInTransitModal from "./PartInTransitModal";
import PartOrderRequestModal from "./PartOrderRequestModal";
import { MainContainer } from "../../../components/Containers";
const PartsRequests = () => {

    const [partRequests, setPartRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedPartRequest, setSelectedPartRequest] = useState({ part: null, type: null });

    useEffect(() => {
        GetTasks({})
            .then(res => { return res.json() })
            .then(res => {
                setPartRequests(res.filter((t) => { return t.partRequest !== undefined }));
                setLoading(false);
            });
    }, []);

    const onPartRequestUpdate = (update) => {
        setPartRequests([...partRequests.map((req) => {
            if (req._id === update._id) return update;
            return req;
        })]);
    };

    const requestFilter = (status) => { return partRequests.filter((t) => { return t.partRequest.status === status }) };



    return (
        <>
            <div style={{ backgroundColor: "#D9DDDB" }} className="pt-8 pb-2 h-screen">
                <MainContainer>

                    <div className="font-semibold text-slate-700 text-center">Platform Part Requests</div>


                    <div className="flex justify-center pb-6">
                        {loading ? <LoadingMain show={loading} /> :
                            <div style={{ display: 'flex', flexDirection: 'row', overflow: 'auto', whiteSpace: 'nowrap', marginTop: '20px' }} className=" max-w-7xl m-auto pt-4">
                                <PartsRequestColumn name={"Requested"} parts={requestFilter(STATUS_LIST.PART_STATUS_REQUESTED)} onSelect={setSelectedPartRequest} />
                                <PartsRequestColumn name={"Order Requests"} parts={requestFilter(STATUS_LIST.PART_STATUS_ORDER_REQUESTED)} onSelect={setSelectedPartRequest} />
                                <PartsRequestColumn name={"Processing"} parts={requestFilter(STATUS_LIST.PART_STATUS_PROCESSING)} onSelect={setSelectedPartRequest} />
                                <PartsRequestColumn name={"In Transit"} parts={requestFilter(STATUS_LIST.PART_STATUS_IN_TRANSIT)} onSelect={setSelectedPartRequest} />
                                {/*<PartsRequestColumn name={"Ready"} parts={requestFilter(STATUS_LIST.PART_STATUS_READY)} onSelect={()=>{}}/>*/}
                            </div>
                        }
                        {selectedPartRequest.type === "Requested" ? <PartRequestModal open={true} onClose={() => { setSelectedPartRequest({ part: null, type: null }) }} part={selectedPartRequest.part} onUpdate={onPartRequestUpdate} /> : ''}
                        {selectedPartRequest.type === "Order Requests" ? <PartOrderRequestModal open={true} onClose={() => { setSelectedPartRequest({ part: null, type: null }) }} part={selectedPartRequest.part} onUpdate={onPartRequestUpdate} /> : ''}
                        {selectedPartRequest.type === "Processing" ? <PartProcessingModal open={true} onClose={() => { setSelectedPartRequest({ part: null, type: null }) }} part={selectedPartRequest.part} onUpdate={onPartRequestUpdate} /> : ''}
                        {selectedPartRequest.type === "In Transit" ? <PartInTransitModal open={true} onClose={() => { setSelectedPartRequest({ part: null, type: null }) }} part={selectedPartRequest.part} onUpdate={onPartRequestUpdate} /> : ''}
                    </div>
                </MainContainer>
            </div>
        </>
    )
}

export default PartsRequests;