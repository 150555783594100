import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
//import drone from '../../components/drone.webp';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

// import LocationIcon from '@mui/icons-material/LocationOn';
// import DurationIcon from '@mui/icons-material/FlightTakeoff';
// import IDIcon from '@mui/icons-material/Tag';
// import StatusIcon from '@mui/icons-material/Build';
import Grid from '@mui/material/Grid';
import { PATHS } from '../../config';
import './PlatformIndividualHeader.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { PATHS } from '../config';



const LocationIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
        </svg>
    )
}

const IDIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
        </svg>
    )
}

const StatusIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75a4.5 4.5 0 01-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 11-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 016.336-4.486l-3.276 3.276a3.004 3.004 0 002.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.867 19.125h.008v.008h-.008v-.008z" />
        </svg>
    )
}

const DurationIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
    )
}

const BackIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.7} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
    )
}


const PlatformIndividualHeader = ({ platformData }) => {

    const navigate = useNavigate();
    const project = useSelector(state => state.projects.selected);
    // console.log(project);

    return (
        <>

            <div className='flex flex-row justify-center'>




                {/* <div className='border rounded-md w-fit mx-auto flex flex-row shadow-md bg-white'> */}
                <div className='border shadow-md rounded-md flex flex-col justify-center mr-4 bg-white hover:cursor-pointer hover:bg-slate-50 hover:shadow-lg' onClick={()=>{navigate(`/${project._id}`)}}>
                    <div className='w-fit flex flex-row px-6'>
                        <div className='text-green-600'>
                            <BackIcon />
                        </div>
                        <div className='font-normal text-slate-800 pl-2'>
                            All platforms
                        </div>
                    </div>
                </div>


                {/* <div className='border rounded-md w-fit mx-auto flex flex-row shadow-md bg-white'> */}
                <div className='border rounded-md w-fit flex flex-row shadow-md bg-white ml-4'>
                    <div className='flex flex-col px-4 py-1'>
                        <img src={platformData.platform.img} className="w-20 h-20 rounded-full mx-auto" />
                        <div className='font-semibold pt-1 text-slate-800 text-center'>{platformData.platform.name}</div>
                    </div>
                    <div className='border-l grow flex flex-col px-4 py-2 text-slate-600 text-sm justify-center'>
                        <div className='flex flex-row pt-1'>
                            <LocationIcon /> {platformData.platform.location}
                        </div>
                        <div className='flex flex-row pt-1 text-center'>
                            <IDIcon /> {platformData.platform.model}
                        </div>
                        <div className='flex flex-row pt-1'>
                            <DurationIcon /> 10h
                        </div>
                        <div className='flex flex-row pt-1'>
                            <StatusIcon /> {platformData.platform.status}
                        </div>
                    </div>
                </div>


            </div>
            {/* <Card id="platform-header">
                <Box id="platform-header-info-container">

                    <Typography component="div" variant="h5" sx={{ marginTop: 1.5 }}>
                        {platformData.platform.name}
                    </Typography>

                    <Box className="detail-container">
                        <div className='detail-container-item'>
                            <div className="platform-detail">
                                <div style={{ marginTop: 2 }}><IDIcon style={{ color: '#555', fontSize: 18 }} /></div>
                                <Typography color="text.secondary">{platformData.platform.model}</Typography>
                            </div>
                        </div>
                        <div className='detail-container-item'>
                            <div className="platform-detail">
                                <div style={{ marginTop: 2 }}><LocationIcon style={{ color: '#555', fontSize: 18 }} /></div>
                                <Typography color="text.secondary">{platformData.platform.location}</Typography>
                            </div>
                        </div>
                        <div className='detail-container-item'>
                            <div className="platform-detail">
                                <div style={{ marginTop: 2 }}><DurationIcon style={{ color: '#555', fontSize: 18 }} /></div>
                                <Typography color="text.secondary">10h</Typography>
                            </div>
                        </div>
                        <div className='detail-container-item'>
                            <div className="platform-detail" style={platformData.platform.status !== "ACTIVE" ? { color: 'red' } : { color: 'green' }}>
                                <div style={{ marginTop: 2 }}><StatusIcon style={{ fontSize: 18 }} /></div>
                                <Typography>{platformData.platform.status}</Typography>
                            </div>
                        </div>

                    </Box>
                </Box>
                <Box id="platform-header-image-container">
                    <CardMedia
                        component="img"
                        image={`${platformData.platform.img}`}
                        alt="image of drone"
                    />
                </Box>
            </Card> */}
        </>
    );
}

export default PlatformIndividualHeader;