class Project
{
    constructor(p={}) {
        this.name = p.name || "";
        this.desc = p.desc || "";
        this.users = [];
        if(p.users)
        {
            p.users.forEach(user => {
                this.AddUser(user);
            });
        }
        this.platforms = [];
        if(p.platforms)
        {
            p.platforms.forEach(p => {
                this.AddPlatform(p);
            });
        }
        this.img = p.img || "";
        this.projectOwner = p.projectOwner ||"";
    }
    SetName(name) { this.name = name; }
    SetDescription(desc) { this.desc = desc; }
    AddUser(user) { this.users.push(user); }
    AddPlatform(platform) { this.platforms.push(platform); }
    RemoveUser(user) { this.users = this.users.filter(u => { return u !== user; }) }
    SetImage(img){ this.img = img; }
    SetProjectOwner(user){ this.projectOwner = user; }


    SetPlatforms(platforms){this.platforms = platforms;}
    SetUsers(users){this.users = users;}
}

export {Project}