import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useReducer, useState } from 'react';
import { Dialog, TextField, Typography, Button, MenuItem, Accordion, Divider } from '@mui/material';
import { Close, UploadFile } from '@mui/icons-material';
import { Platforms as GetPlatforms } from '../../services/Platforms';
import { UpdateProject, CreateProject } from '../../services/Projects';
import { AllUsers } from '../../services/Users';
import { useDispatch, useSelector } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { Chip } from '@mui/material';
import { AccordionSummary, AccordionDetails } from '@mui/material';
import FilterIcon from '@mui/icons-material/FilterAltTwoTone';
import Popover from '@mui/material/Popover';
import { Project } from '../../models/ProjectModel';
import { DefaultReducer } from '../../components/DefaultReducer';
import { projectsActions } from '../../store';
import { TOKENS } from '../../config';
import { UpdateProjectProfile } from '../../services/Projects';

const BasicPopover = ({ name, children }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Button aria-describedby={id} variant="outlined" onClick={handleClick} size='small'>
                {name}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {children}
            </Popover>
        </div>
    );
}

const UsersChecklist = ({ usersList, checkList, onCheck }) => {

    return (
        <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {usersList.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                return (
                    <ListItem
                        key={value.name}
                        secondaryAction={
                            <Checkbox
                                edge="end"
                                //                                onChange={handleToggle(value.name)}
                                onChange={() => { onCheck(value.name) }}
                                checked={checkList.indexOf(value.name) !== -1}
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        }
                        disablePadding
                    >
                        <ListItemButton>
                            <ListItemAvatar>
                                <Avatar
                                    alt={`Avatar n°${value + 1}`}
                                    src={value.picture}
                                />
                            </ListItemAvatar>
                            <ListItemText id={value.name} primary={value.name} />
                        </ListItemButton>
                    </ListItem>
                )
            })}
        </List>
    );
}


const PlatformsChecklist = ({ platformsList, checkList, onCheck }) => {

    return (
        <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {platformsList.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                return (
                    <ListItem
                        key={value.name}
                        secondaryAction={
                            <Checkbox
                                edge="end"
                                onChange={() => { onCheck(value.name) }}
                                checked={checkList.indexOf(value.name) !== -1}
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        }
                        disablePadding
                    >
                        <ListItemButton>
                            <ListItemAvatar>
                                <Avatar
                                    alt={`Avatar n°${value + 1}`}
                                    src={value.img}
                                />
                            </ListItemAvatar>
                            <ListItemText id={value.name} primary={value.name} />
                        </ListItemButton>
                    </ListItem>
                )
            })}
        </List>
    );
}



let style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',

    pt: 1,
    px: 1,
    pb: 3,

    maxHeight: '90%',
    overflow: 'auto'
};

const ProjectsEditModal = ({ show, onClose, onUpdate, onCreate, edit = true }) => {

    const [platformList, setPlatformList] = useState({ data: [] });
    const [loading, setLoading] = useState(false);
    let project = useSelector(state => state.projects.selected);
    if (!edit)
        project = new Project();

    // console.log(project);


    // console.log(project);

    const [imageData, setImage] = useState(null);
    const [formState, formDispatch] = useReducer(DefaultReducer, { name: project.name, desc: project.desc, img: project.img })
    const [usersList, setUsersList] = useState([]);
    const [usersCheckList, setUsersCheckList] = useState(project.users.map(u => u.name));
    const [platformsCheckList, setPlatformsCheckList] = useState(project.platforms.map(p => p.name));
    const dispatch = useDispatch();


    //    console.log(project);

    const checkUser = (name) => {
        if (usersCheckList.includes(name)) {
            let checkList = [...usersCheckList];
            checkList = checkList.filter(n => n !== name);
            setUsersCheckList(checkList);
        } else {
            let checkList = [...usersCheckList];
            checkList.push(name);
            setUsersCheckList(checkList);
        }
    }

    const checkPlatform = (name) => {
        if (platformsCheckList.includes(name)) {
            let checkList = [...platformsCheckList];
            checkList = checkList.filter(n => n !== name);
            setPlatformsCheckList(checkList);
        } else {
            let checkList = [...platformsCheckList];
            checkList.push(name);
            setPlatformsCheckList(checkList);
        }
    }


    const updateProject = () => {


        let _project = new Project({ ...project });
        _project.SetName(formState.name);
        _project.SetDescription(formState.desc);
        _project.SetImage(formState.img);
        _project.SetPlatforms([]);
        _project.SetUsers([]);

        usersList.filter(u => usersCheckList.includes(u.name)).forEach(user => { _project.AddUser(user._id) })
        platformList.data.filter(p => platformsCheckList.includes(p.name)).forEach(platform => { _project.AddPlatform(platform._id) })

        UpdateProject(project._id, _project)
            .then(r => r.json())
            .then(r => {
                dispatch(projectsActions.setSelectedProject(r))
                onClose();
            })
        if (imageData !== null)
            postImage();
    }

    const createProject = async () => {


        let _project = new Project({ ...project });
        _project.SetName(formState.name);
        _project.SetDescription(formState.desc);
        _project.SetImage(formState.img);
        _project.SetPlatforms([]);
        _project.SetUsers([]);

        usersList.filter(u => usersCheckList.includes(u.name)).forEach(user => { _project.AddUser(user._id) })
        platformList.data.filter(p => platformsCheckList.includes(p.name)).forEach(platform => { _project.AddPlatform(platform._id) })

        // console.log(_project);
        // return;
        setLoading(true);
        CreateProject(_project)
            .then(r => r.json())
            .then(r => {
                dispatch(projectsActions.setSelectedProject(r))

                if(imageData)
                {
                    const formData = new FormData();
                    formData.append('image', imageData);
                    return UpdateProjectProfile(r._id, formData)
                }
                throw r; 
                // return new Promise(r);
            })
            .then(r => r.json())
            .then(r => {
//                dispatch(projectsActions.setSelectedProject(r));
                onCreate(r);
                setLoading(false);
            })
            .catch(r => {
                console.log(r)
//                dispatch(projectsActions.setSelectedProject(r));
                setLoading(false);
                onCreate(r);
            })


        //     if (imageData !== null)
        // {
        //     let res = await postImage();
        //     console.log(res);
        // }    

        return;


    }

    useEffect(() => {
        GetPlatforms()
            .then(r => r.json())
            .then(r => {
                setPlatformList(r);
            })


        AllUsers()
            .then(r => r.json())
            .then(r => {
                //            console.log(r);
                setUsersList(r);
            })

    }, []);

    /*
    const onImageUpload = () => {
        var fileToLoad = document.getElementById("project-image-upload-source").files[0];

        var fileReader = new FileReader();
        fileReader.onload = function(fileLoadedEvent){
            var textFromFileLoaded = fileLoadedEvent.target.result;
            document.getElementById("inputTextToSave").value = textFromFileLoaded;
        };
      
        fileReader.readAsText(fileToLoad, "UTF-8");
    }*/







    const postImage = () => {

        setLoading(true);
        const formData = new FormData();
        formData.append('image', imageData);
        return UpdateProjectProfile(project._id, formData)
        // .then(response => response.json())
        // .then(response => {
        //     // console.log(response);
        //     onUpdate();
        // })
        // .catch(error => {
        //     // console.error('Error:', error);
        //     setLoading(false);
        // });
    }


    // const updateImage = (input) => {

    //     // setLoading(true);
    //     const formData = new FormData();
    //     formData.append('image', imageData);
    //     fetch('http://localhost:8080/api/users/3d7e6e47-cc6c-4f3d-88a3-7a182976e290/profile', {
    //         method: 'POST',
    //         headers: { 'Authorization': `${localStorage.getItem(TOKENS.AUTH_TOKEN)}` },
    //         body: formData
    //     })
    //         .then(response => response.json())
    //         .then(response => {
    //             console.log(response);
    //             onUpdate();
    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //             // setLoading(false);
    //         });
    // }


    //     const readURL = (input) => {

    //         // console.log(input.target.files[0]);

    //         if (input.target.files && input.target.files[0]) {

    //             // const formData = new FormData();
    //             // formData.append('image', input.target.files[0]);

    //             const send = (data) => {

    //                 const formData = new FormData();
    //                 formData.append('image', data);
    //                 //              formData.set('image', data);

    //                 // console.log(typeof(data), data);

    //                 fetch('http://localhost:8080/api/users/3d7e6e47-cc6c-4f3d-88a3-7a182976e290/profile', {
    //                     method: 'POST',
    //                     // body: {k:'alskdjflaksjdnf'},
    //                     headers: { 'Authorization': `${localStorage.getItem(TOKENS.AUTH_TOKEN)}` },
    //                     // body: JSON.stringify({ k: 'value' })
    //                     body: formData
    //                 })
    //                     .then(response => response.text())
    //                     .then(response => console.log(response))
    //                     .catch(error => {
    //                         console.error('Error:', error);
    //                     });
    //             }

    //             var reader = new FileReader();
    //             reader.onload = function (e) {
    //                 // document.getElementById('#project-image-upload').attr('src', e.target.result).width(150).height(200);
    //                 send(e.target.result);
    //             };
    // //            reader.readAsDataURL(input.target.files[0]);
    // //            reader.readAsArrayBuffer(input.target.files[0])
    //             send(input.target.files[0])

    //         }
    //     }


    // const uploadImage = () => {
    //     // Select the file input element
    //     const fileInput = document.getElementById('#project-image-upload-source');

    //     // Get the selected file from the input element
    //     const file = fileInput.files[0];

    //     // Create a new FormData object
    //     const formData = new FormData();

    //     // Append the file to the FormData object
    //     formData.append('image', file);

    //     console.log(formData);

    //     // Make the POST request
    //     fetch('your_endpoint_url', {
    //         method: 'POST',
    //         body: formData
    //     })
    //         .then(response => {
    //             // Handle the response
    //             if (response.ok) {
    //                 // Request was successful
    //                 console.log('Image sent successfully');
    //             } else {
    //                 // Request failed
    //                 console.error('Image send failed');
    //             }
    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //         });

    // }

    const verifyForm = () => {
        return formState.name !== "";
    }

    return (
        <div>
            <Modal
                open={show}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                sx={{ outline: 0, padding: 4 }}
                disableEnforceFocus={true}
                disableAutoFocus={true}
                onClose={onClose}
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', marginBottom: 1, paddingLeft: 1 }}>

                        {edit ?
                            <Typography>Edit Project: <b>{project.name}</b></Typography> :
                            <Typography>Create Project</Typography>
                        }


                        <Close sx={{ marginLeft: 'auto' }} onClick={onClose} />
                    </Box>

                    {edit ?

                        <>
                            <Box sx={{ display: 'flex', paddingLeft: 1 }}>
                                <Typography color="text.secondary" sx={{ fontSize: 12 }}>Created: {new Date(project.createdAt).toDateString()}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', marginBottom: 2, paddingLeft: 1 }}>
                                <Typography color="text.secondary" sx={{ fontSize: 12 }}>Last Update: {new Date(project.updatedAt).toDateString()}</Typography>
                            </Box>
                        </> : null}


                    <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: 1, paddingTop: 2 }}>
                        <Box sx={{ width: '75%', paddingRight: 1 }}>
                            <TextField
                                label="Project Name"
                                id="outlined-size-small"
                                size="small"
                                sx={{ width: '100%' }}
                                value={formState.name}
                                onChange={(v) => { formDispatch({ name: v.target.value }) }}

                            />
                            <TextField
                                label="Description"
                                id="outlined-size-small"
                                size="small"
                                sx={{ width: '100%', marginTop: 2 }}
                                value={formState.desc}
                                onChange={(v) => { formDispatch({ desc: v.target.value }) }}
                                multiline
                                rows={6}
                            />

                        </Box>
                        <Box sx={{ width: '25%', display: 'flex', flexDirection: 'column', justifyContent: 'right', alignItems: 'right', paddingRight: 2 }}>

                            {imageData ?

                                <Box sx={{ width: 170, height: 170, textAlign: 'center', marginLeft: 'auto' }}>
                                    <img id="project-image-upload" src={URL.createObjectURL(imageData)} style={{ width: 170, height: 170 }} />
                                </Box> : null
                            }

                            {((imageData === null) && project.img === '') ?
                                <Box sx={{ backgroundColor: '#eaeaea', width: 170, height: 170, textAlign: 'center', marginLeft: 'auto' }}>
                                    <UploadFile sx={{ paddingTop: 5, color: '#555' }} />
                                    <Typography color="text.secondary">Project image</Typography>
                                </Box> : null

                            }

                            {((imageData === null) && project.img !== '') ?
                                <Box sx={{ backgroundColor: '#eaeaea', width: 170, height: 170, textAlign: 'center', marginLeft: 'auto' }}>
                                    <img id="project-image-upload" src={project.img} style={{ width: 170, height: 170 }} />
                                </Box>
                                : null
                            }




                            <input type="file" name="image" id="project-image-upload-source" style={{ display: 'none' }} onChange={(e) => { setImage(e.target.files[0]) }} />
                            <Button type="file" variant='outlined' size='small' sx={{ width: 100, marginLeft: 'auto', marginTop: 1 }} onClick={() => { document.getElementById("project-image-upload-source").click() }}>set image</Button>
                            {/* <Button type="file" variant='outlined' size='small' sx={{ width: 100, marginLeft: 'auto', marginTop: 1 }} onClick={uploadImage}>set image</Button> */}
                        </Box>
                    </Box>
                    <Box>

                        <Box sx={{ marginTop: 4, paddingLeft: 1 }}>
                            <BasicPopover name="edit users">
                                <UsersChecklist usersList={usersList} checkList={usersCheckList} onCheck={(v) => { checkUser(v) }} />
                            </BasicPopover>
                            <Box sx={{ border: '1px solid #bbb', borderRadius: 2, padding: 0.8, fontSize: 9, marginRight: 1, marginTop: 1, display: (usersCheckList.length === 0 ? 'none' : '') }}>
                                {usersCheckList.map((k, i) => { return <Chip key={i} label={k} variant="filled" onDelete={() => { checkUser(k) }} sx={{ marginRight: 0.5, marginBottom: 0.5 }} /> })}
                            </Box>
                        </Box>

                        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

                        <Box sx={{ marginTop: 2, paddingLeft: 1 }}>
                            <BasicPopover name="edit platforms">
                                <PlatformsChecklist platformsList={platformList.data} checkList={platformsCheckList} onCheck={(v) => { checkPlatform(v) }} />
                            </BasicPopover>
                            <Box sx={{ border: '1px solid #bbb', borderRadius: 2, padding: 0.8, fontSize: 9, marginRight: 1, marginTop: 1, display: (platformsCheckList.length === 0 ? 'none' : '') }}>
                                {platformsCheckList.map((k, i) => { return <Chip key={i} label={k} variant="filled" onDelete={() => { checkPlatform(k) }} sx={{ marginRight: 0.5 }} /> })}
                            </Box>
                        </Box>

                        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                        {loading ? 
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <CircularProgress />
                        </div> : null
                        }

                        <Box sx={{ marginTop: 2, textAlign: 'right', paddingRight: 1 }}>
                            {edit ?
                                <Button variant='contained' size='small' onClick={updateProject} disabled={!verifyForm() || loading}>Update project</Button> :
                                <Button variant='contained' size='small' onClick={createProject} disabled={!verifyForm() || loading}>Create project</Button>
                            }
                        </Box>

                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default ProjectsEditModal;