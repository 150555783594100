import { Box, Typography, Popover } from '@mui/material';
import './NavBar.css';
import logo from './SNCLOGO.webp';
import Avatar from '@mui/material/Avatar';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from '../config';
import { useContext, useEffect, useState } from 'react';
import { Logout } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsSharpIcon from '@mui/icons-material/NotificationsSharp';
import LogoutModal from './LogoutModal';
import { useDispatch } from 'react-redux';

//import { useAuth0 } from '@auth0/auth0-react';
//import { AuthProviderContext } from '../AuthProvider';
import { useAuth } from '../providers/AuthProvider';

import { logInActions } from '../store';
import ProjectsEditModal from '../pages/projects/ProjectsEditModal';
import ProfileModal from './ProfileModal';

const NavBar = ({ showTabs = true, projectName = '' }) => {

    //    const user = useSelector(state => state.auth.user);
    const auth = useSelector(state => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { logout } = useAuth();
    const [selected, setSelected] = useState(PATHS.PLATFORMS);
    // const [logoutModal, setLogoutModal] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [projectSettings, setProjectSettings] = useState(false);
    const [profilePopover, setProfilePopover] = useState(null);
    const profilePopoverOpen = Boolean(profilePopover);
    const profilePopoverId = profilePopoverOpen ? 'simple-popover' : undefined;
    const location = useLocation();
    const PAGE_INDEX = 2;

    const onLogOut = () => {
        logout({ logoutParams: { returnTo: window.location.origin } });
        dispatch(logInActions.logOut());
    }

    const navTabSelection = (page) => {
        navigate(page);
        setSelected(page);
    }

    const NavTab = ({ route, name }) => {
        const className = "navbar-item-holder cursor-on-hover" + (route === selected ? " nav-selected-navigation" : "");
        return (
            <Box className={className} onClick={() => { navTabSelection(route) }}>
                <Typography className="main-navbar-navigation-tab">{name}</Typography>
            </Box>
        )
    }

    const filterNavTabs = () => {
        return <>
            <NavTab route={PATHS.PLATFORMS} name={"Platforms"} />
            <NavTab route={PATHS.TASKS} name={"Tasks"} />
            <NavTab route={PATHS.PARTS} name={"Parts"} />
            {(auth.user.roles.includes('ADMIN') || auth.user.roles.includes('OPERATIONS_LOGISTICS')) ? 
                <>
                    <NavTab route={PATHS.OVERVIEW} name={"Overview"} /> 
                </>
            : null}
            {(auth.user.roles.includes('ADMIN')) ? <NavTab route={PATHS.ADMIN} name={"Admin"} /> : null}
        </>
    }

    useEffect(() => {
        Object.keys(PATHS).forEach(k => {
            if (PATHS[k] === location.pathname.split('/')[PAGE_INDEX])
                setSelected(PATHS[k]);
        });
    });

    return (
        <nav id="main-navbar">
            <Box className="navbar-item-holder cursor-on-hover" onClick={() => { navigate(`/`) }}>
                <img src={logo} style={{ height: 30, marginRight: 20 }} alt={"logo"} />
            </Box>

            {showTabs ? filterNavTabs()
                // <>
                //     <NavTab route={PATHS.PLATFORMS} name={"Platforms"} />
                //     <NavTab route={PATHS.TASKS} name={"Tasks"} />
                //     <NavTab route={PATHS.PARTS} name={"Parts"} />
                //     <NavTab route={PATHS.OVERVIEW} name={"Overview"} />
                //     <NavTab route={PATHS.ADMIN} name={"Admin"} />
                // </>
                : ''}
            <Box className="settings">
                <Box className="navbar-item-holder cursor-on-hover" onClick={() => { setProjectSettings(true) }}>
                    <Typography variant='subtitle2'>{projectName}</Typography>
                </Box>
                <SettingsIcon className='navbar-item-holder cursor-on-hover' sx={projectName === '' ? { visibility: 'hidden' } : {}} onClick={() => { setProjectSettings(true) }} />
                {/* <NotificationsSharpIcon className='settings-icon navbar-item-holder cursor-on-hover' /> */}
                <NotificationsSharpIcon className='navbar-item-holder cursor-on-hover' />
                <Box className="navbar-item-holder cursor-on-hover" onClick={(event) => { setProfilePopover(event.currentTarget); }}>
                    <Avatar src={auth.user.picture} sx={{ height: 30, width: 30 }} />
                </Box>

                <Popover
                    id={profilePopoverId}
                    open={profilePopoverOpen}
                    anchorEl={profilePopover}
                    onClose={() => { setProfilePopover(null) }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Box sx={{ padding: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 1 }} className="cursor-on-hover" onClick={() => { setProfilePopover(null); setProfileModal(true) }}>
                            <SettingsIcon sx={{ marginRight: 1, color: '#555' }} />
                            <Typography color="text.secondary">Settings</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} className="cursor-on-hover" onClick={() => { setProfilePopover(null); dispatch(logInActions.requestLogout(true)) }}>
                            <Logout sx={{ marginRight: 1, color: '#555' }} />
                            <Typography color="text.secondary">Logout</Typography>
                        </Box>
                    </Box>
                </Popover>

            </Box>
            <ProfileModal open={profileModal} onClose={() => { setProfileModal(false) }} />
            {projectSettings ? <ProjectsEditModal show={true} onClose={() => { setProjectSettings(false) }} /> : ''}
            <LogoutModal open={auth.requestLogout} onClose={() => { /*setLogoutModal(false)*/dispatch(logInActions.requestLogout(false)) }} onLogOut={onLogOut} />
        </nav>
    );
}


export default NavBar;