import { Avatar, Box, Divider, Typography, TextField, Button, Modal } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { AllUsers, AllRoles, UsersForRole, UserRoles, SetUserRoles, RemoveUserRoles } from "../../../../services/Users";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import './AdminUsers.css';

import UserModal from "./AdminUsersPermissionsModal";
import DynamicTable from "../../../../components/DynamicTable";

import { AccountCircle } from "@mui/icons-material";
import { TextFieldInput } from "../../../../components/Inputs";
import { MainContainer } from "../../../../components/Containers";
import { CheckCircleIcon, CheckIcon } from "../../../../components/Icons";

const lastActive = (timestamp) => {
    if (timestamp) {
        let date = new Date(timestamp);
        let current = new Date();
        let diff_seconds = Math.floor((current - date) / (1000));
        let diff_minutes = Math.floor((current - date) / (1000 * 60));
        let diff_hours = Math.floor((current - date) / (1000 * 60 * 60));
        let diff_days = Math.floor((current - date) / (1000 * 60 * 60 * 24));
        if (diff_seconds < 60)
            return `${diff_seconds} seconds ago`
        if (diff_minutes < 60)
            return `${diff_minutes} mins ago`
        if (diff_hours < 24)
            return `${diff_hours} hours ago`
        else
            return `${diff_days} days ago`
    }
    return "12/12/12";
}

const lastActiveDisplay = (timestamp) => {
    if (timestamp) {
        let date = new Date(timestamp);
        let current = new Date();
        let diff_seconds = Math.floor((current - date) / (1000));
        let diff_minutes = Math.floor((current - date) / (1000 * 60));
        let diff_hours = Math.floor((current - date) / (1000 * 60 * 60));
        let diff_days = Math.floor((current - date) / (1000 * 60 * 60 * 24));




        if (diff_seconds < 60) {
            return (
            <div className="flex text-green-500">
                {/* <CheckCircleIcon className="w-4 h-4"/> */}
                <div>{lastActive(timestamp)}</div>
            </div>)
        }
        if (diff_minutes < 60) {
            return (
                <div className="flex text-green-500">
                    {/* <CheckCircleIcon className="w-4 h-4 text-green-700" /> */}
                    <div>{lastActive(timestamp)}</div>
                </div>)
    
        }
        if (diff_hours < 24) {
            return (
                <div className="flex text-orange-500">
                    {/* <CheckCircleIcon className="w-4 h-4 text-green-700" /> */}
                    <div>{lastActive(timestamp)}</div>
                </div>)
    
        }
        else {
            return (
                <div className="flex text-red-400">
                    {/* <CheckCircleIcon className="w-4 h-4 text-green-700" /> */}
                    <div>{lastActive(timestamp)}</div>
                </div>)
    
        }
    }
    return "12/12/12";
}

const UserInfo = ({ user, select }) => {

    return (
        <>
            <Box className="admin-userprofile-card" onClick={() => { select(user) }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Avatar src={user.picture} />
                </Box>
                <Box sx={{ marginLeft: 1 }}>
                    <Typography sx={{ fontSize: 14 }}>{user.email}</Typography>
                    <Typography sx={{ fontSize: 12, color: '#888' }}>{user.nickname}</Typography>
                    <Typography sx={{ fontSize: 12, color: '#888' }}>Last login: {lastActive(user.last_login)}</Typography>
                </Box>
                <Box sx={{ marginLeft: 'auto', textAlign: 'right' }}>
                    <Typography sx={{ fontSize: 12, color: '#888' }}>{user.last_ip}</Typography>
                    <Typography sx={{ fontSize: 12, color: '#888' }}>{user.user_id}</Typography>
                </Box>
            </Box>
            <Divider />
        </>
    )
}

const AdminUsers = () => {

    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [userModal, setUserModal] = useState(null);
    const [userSearch, setUserSearch] = useState("");

    const refreshUsers = () => {
        AllUsers()
            .then(r => r.json())
            .then(result => {
                setUsers(result);
            });
    }

    const onUpdate = () => {
        refreshUsers();
        setUserModal(null);
    }

    useEffect(() => {
        refreshUsers();
        AllRoles()
            .then(r => r.json())
            .then(result => {
                setRoles(result);
            });
    }, []);


    return (


        <div style={{ backgroundColor: "#D9DDDB" }} className="h-screen pt-8">


            <MainContainer>

                {/* <div className="platform-individual-document-container"> */}

                <div className="w-1/2 pb-2">
                    <TextFieldInput name="Search user" placeholder="user name" onChange={(v) => { setUserSearch(v) }} />
                </div>

                {/* </div> */}
            </MainContainer>

            <MainContainer className="mt-4">
                {/* <div className="platform-individual-document-container"> */}
                    <div className="mt-3">
                        <DynamicTable
                            data={
                                users.filter((u) => { return u.email.includes(userSearch) }).map((user, i) => {
                                    return [

                                        <div key={`${i}user`} className="flex" onClick={() => { setUserModal(user) }}>
                                            <Avatar src={user.picture} />
                                            <div className="flex flex-col ml-4">
                                                <div className=" text-sm font-normal text-black">{user.nickname}</div>
                                                <div className="text-slate-500">{user.email}</div>
                                            </div>
                                        </div>,



                                        <div key={`${i}roles`} className="flex flex-col" onClick={() => { setUserModal(user) }}>
                                            <div>
                                                {user.roles.length === 0 ? '' : user.roles[0]}
                                            </div>
                                        </div>,

                                        <div key={`${i}login`} className="flex flex-col" onClick={() => { setUserModal(user) }}>
                                            <div>
                                                {user.last_active}
                                            </div>
                                            <div className="flex">
                                                {/* <CheckCircleIcon className="w-4 h-4"/>
                                                {lastActive(user.last_active)} */}

                                                {lastActiveDisplay(user.last_active)}

                                            </div>
                                        </div>,

                                        <div key={`${i}location`} className="flex flex-col" onClick={() => { setUserModal(user) }}>
                                            <div>
                                                {user.last_ip}
                                            </div>
                                        </div>,
                                    ]
                                })
                            }
                            headerData={['User', 'Roles', 'Last active', 'Location']}
                        />
                    </div>




                    {/* <Box sx={{ margin: 3 }}>
                    <TextField
                        label="Search"
                        id="outlined-size-small"
                        size="small"
                        sx={{ marginRight: 1 }}
                        value={userSearch}
                        onChange={(v) => { setUserSearch(v.target.value) }}
                    />
                </Box> 
                <Divider />
                {users.filter((u) => { return u.email.includes(userSearch) }).map((user, i) => { return <UserInfo key={i} user={user} select={setUserModal} /> })}
                */}
                    {userModal ? <UserModal user={userModal} open={true} onClose={() => { setUserModal(null) }} roles={roles} onUpdate={onUpdate} /> : ''}
                {/* </div> */}
            </MainContainer>
        </div>
    )
}

export { AdminUsers };