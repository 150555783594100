import { Typography, Paper, Box } from "@mui/material";
import PlatformTaskCard from "./PlatformTaskCard";
import { useState } from "react";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';

const PlatformTaskColumn = ({ onTaskSelect, name, tasks, onTaskDrop }) => {


    const  [onDragHover, setOnDragHover] = useState(false);

    /*
    const onTaskDropOver = (t) => {

        const taskID = t.dataTransfer.getData("Text");
        console.log(taskID);
    }*/


    return (
        <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'column', backgroundColor: ( onDragHover ? '#eee' : '#ddd'), width: 280, minWidth: 180, marginLeft: 0.8, paddingBottom: 0.2 }} onDragOver={(t)=>{t.preventDefault();setOnDragHover(true) }} onDragLeave={(t)=>{t.preventDefault();setOnDragHover(false)}} onDragEnter={(t)=>{t.preventDefault();setOnDragHover(true)}} onDrop={(t)=>{onTaskDrop(t.dataTransfer.getData("Text"), name);setOnDragHover(false)}}>
            <Box sx={{ height: 25 }}>
                <Typography id="Heading" variant="h6" component="h6" color="text.secondary" style={{ fontSize: 12, paddingTop: 3, textAlign: 'center' }}>
                    {name}
                </Typography>
            </Box>
            {tasks.map((t, index) => { return (<PlatformTaskCard task={t} key={index} onTaskSelect={onTaskSelect}/>) })}

            <AddCircleOutlineOutlinedIcon sx={{margin:'auto', color:'#999', paddingTop:1, paddingBottom:1, display:(onDragHover ? 'block' : 'none')}}/>
        </Paper>
    )
}

export default PlatformTaskColumn;