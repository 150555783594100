import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useNavigate, Routes, Route, Navigate } from "react-router-dom";
import { useState } from "react";
import { SUB_PATHS } from "../../../config";
import PlatformTaskHistory from "./history/PlatformTaskHistory";
import PlatformLocationHistory from "./history/PlatformLocationHistory";
import PlatformIncidentReports from "./history/PlatformIncidentReports";
import { MainContainer } from "../../../components/Containers";

const PlatformIndividualHistory = () => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  const changeTab = (tab) => {
    setActiveTab(tab);
    navigate(`${SUB_PATHS.PLATFORM_HISTORY_TABS[tab]}`);
  };

  return (
    <div className="pt-8 h-screen" style={{ backgroundColor: "#D9DDDB" }}>
      <MainContainer>
        {/* <div className="mt-8 max-w-7xl m-auto"> */}
        <Box sx={{ padding: 1 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={activeTab}
              onChange={(evt, v) => {
                changeTab(v);
              }}
              aria-label="basic tabs example"
            >
              {SUB_PATHS.PLATFORM_HISTORY_TABS.map((t, i) => {
                return (
                  <Tab
                    key={i}
                    label={t}
                    {...{
                      id: `simple-tab-${i}`,
                      "aria-controls": `simple-tabpanel-${i}`,
                    }}
                    sx={{ fontSize: 12, width: 10 }}
                  />
                );
              })}
            </Tabs>
          </Box>
          <Routes>
            <Route path="/" element={<PlatformTaskHistory />} />
            <Route
              path={SUB_PATHS.PLATFORM_HISTORY_TABS[0]}
              element={<PlatformTaskHistory />}
            />
            <Route
              path={SUB_PATHS.PLATFORM_HISTORY_TABS[1]}
              element={<PlatformLocationHistory />}
            />
            <Route
              path={SUB_PATHS.PLATFORM_HISTORY_TABS[2]}
              element={<PlatformIncidentReports />}
            />
          </Routes>
        </Box>
        {/* </div> */}
      </MainContainer>
    </div>
  );
};
export default PlatformIndividualHistory;
