import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Close from '@mui/icons-material/Close';
import Flight from '@mui/icons-material/Flight';
import LocationOn from '@mui/icons-material/LocationOn';
import { TextField, InputLabel, Select, MenuItem, FormHelperText, FormControl, Divider } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import LocationIcon from '@mui/icons-material/LocationOn';
import { useEffect, useState, useReducer } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { useSelector } from 'react-redux';
import Chip from '@mui/material/Chip';
import { Paper, Avatar } from '@mui/material';
import { EFR, EFR_STATUS } from '../../../../models/EFRModel';
import dayjs from 'dayjs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 5,
    pt: 1,
    px: 0,
    overflow: 'scroll',
    maxHeight: '90%',
    overflowX: 'hidden'
    //    pb: 2
};

const PlatformIncidentReportModal = ({ formState, onClose, onApply, onUpdate }) => {

    const [form, setForm] = useState(new EFR(formState));
    const update = (k, v) => {
        let cpy = { ...form };
        cpy[k] = v;
        setForm(cpy);
    }

    const isValid = () => {
        let valid = true;

        Object.keys(form).forEach(k => {
            if ((form[k] === null || form[k] === '') && (k !== 'platformID') && (k !== 'extRef')) { // removed ext ref field VOW-321
                valid = false;
            }
        });
        return valid;
    }

    return (
        <Modal
            open={true}
            onClose={onClose}
            sx={{ outline: 0 }}
            disableEnforceFocus={true}
            disableAutoFocus={true}
        >
            <Box sx={style}>

                <Box sx={{ display: 'flex', paddingLeft: 1, paddingRight: 1, paddingBottom: 0.5 }}>
                    <Typography>Incident Report Form</Typography>
                    <div style={{ marginLeft: 'auto', alignSelf: 'flex-start' }} onClick={onClose}><Close /></div>

                </Box>

                <Divider />

                <Box sx={{ display: 'flex', paddingLeft: 2, paddingRight: 2, marginTop: 2 }}>

                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            required
                            id="outlined-required"
                            label="Incident Reference"
                            value={form.incidentRef}
                            onChange={(v) => { update('incidentRef', v.target.value) }}
                            size='small'
                            sx={{ width: 500 }}
                        />
                    </FormControl>

                    <FormControl sx={{ m: 1, marginLeft: 'auto' }}>
                        <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                        <Select
                            required
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={form.status}
                            onChange={(v) => { update('status', v.target.value) }}
                            label="Status"
                            size='small'

                        >
                            {Object.keys(EFR_STATUS).map((k, i) => { return <MenuItem key={i} value={EFR_STATUS[k]}>{EFR_STATUS[k]}</MenuItem> })}
                        </Select>

                    </FormControl>

                </Box>

                <Box sx={{ display: 'flex', paddingLeft: 2, paddingRight: 2, marginTop: 1 }}>
                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            required
                            id="outlined-required"
                            label="Occured on job"
                            //                        defaultValue="Hello World"
                            value={form.occuredOnjob}
                            onChange={(v) => { update('occuredOnjob', v.target.value) }}
                            size='small'
                            sx={{ width: 370 }}
                        />
                    </FormControl>
                </Box>

                <Box sx={{ display: 'flex', paddingLeft: 2, paddingRight: 2, marginTop: 1, justifyContent: 'space-between' }}>

                    <FormControl sx={{ m: 1 }}>


                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDateTimePicker
                                label="Incident date"
                                slotProps={{ textField: { size: 'small' } }}
                                value={dayjs(form.incidentDate)}
                                onChange={(v) => { update('incidentDate', v.$d) }}
                            />
                        </LocalizationProvider>
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDateTimePicker
                                label="Reported date"
                                slotProps={{ textField: { size: 'small' } }}
                                value={dayjs(form.reportedDate)}
                                onChange={(v) => { update('reportedDate', v.$d) }}
                            />
                        </LocalizationProvider>
                    </FormControl>

                </Box>
                <Box sx={{ display: 'flex', paddingLeft: 2, paddingRight: 2, marginTop: 1, justifyContent: 'space-between' }}>

                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            required
                            id="outlined-required"
                            label="Person Involved"
                            value={form.personInvolved}
                            onChange={(v) => { update('personInvolved', v.target.value) }}
                            size='small'
                            sx={{ width: 370 }}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            required
                            id="outlined-required"
                            label="Reporter"
                            value={form.reporter}
                            onChange={(v) => { update('reporter', v.target.value) }}
                            size='small'
                            sx={{ width: 370 }}
                        />
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', paddingLeft: 2, paddingRight: 2, marginTop: 1, justifyContent: 'space-between' }}>

                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            required
                            id="outlined-required"
                            label="Incident details"
                            value={form.details}
                            onChange={(v) => { update('details', v.target.value) }}
                            size='small'
                            sx={{ width: 770 }}
                            multiline
                            rows={2}
                        />
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', paddingLeft: 2, paddingRight: 2, marginTop: 1, justifyContent: 'space-between' }}>

                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            required
                            id="outlined-required"
                            label="Injuries and/or damages sustained"
                            value={form.injuries}
                            onChange={(v) => { update('injuries', v.target.value) }}
                            size='small'
                            sx={{ width: 770 }}
                            multiline
                            rows={2}
                        />
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', paddingLeft: 2, paddingRight: 2, marginTop: 1, justifyContent: 'space-between' }}>

                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            required
                            id="outlined-required"
                            label="Action taken"
                            value={form.actionTaken}
                            onChange={(v) => { update('actionTaken', v.target.value) }}
                            size='small'
                            sx={{ width: 770 }}
                            multiline
                            rows={2}
                        />
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', paddingLeft: 2, paddingRight: 2, marginTop: 1, justifyContent: 'space-between' }}>

                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            required
                            id="outlined-required"
                            label="Additional notes"
                            value={form.additionalNotes}
                            onChange={(v) => { update('additionalNotes', v.target.value) }}
                            size='small'
                            sx={{ width: 770 }}
                            multiline
                            rows={2}
                        />
                    </FormControl>
                </Box>

                <Divider />
                <Box sx={{ display: 'flex', paddingLeft: 2, paddingRight: 2, paddingTop: 2, paddingBottom: 2, justifyContent: 'space-between', backgroundColor: '#efefef' }}>
                    {/* <FormControl sx={{ m: 1 }}>
                        <TextField
                            required
                            id="outlined-required"
                            label="External ref"
                            value={form.extRef}
                            onChange={(v) => { update('extRef', v.target.value) }}
                            size='small'
                            sx={{ width: 370 }}
                        />
                    </FormControl> */}
                    <Box sx={{ marginLeft: 'auto', paddingRight: 1, marginTop: 2.5 }}>
                        <Button variant='contained' size='small' color='secondary' sx={{ marginRight: 2 }} onClick={onClose}>Cancel</Button>
                        <Button variant='contained' size='small' disabled={!isValid()} onClick={() => {
                            if (formState === "new")
                                onApply(form);
                            else
                                onUpdate(form);
                        }}>Apply</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default PlatformIncidentReportModal;