import { Routes, Route } from "react-router-dom";
import PlatformList from "./PlatformListContainer";
import PlatformIndividual from "./PlatformIndividual";

const Platforms = ()=>{
    
    return (
        <Routes>
            <Route path="/" element={<PlatformList />} />
            <Route path=":id/*" element={<PlatformIndividual />} />
        </Routes>
    )
}

export default Platforms;