import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Logout } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import Flight from '@mui/icons-material/Flight';
import LocationOn from '@mui/icons-material/LocationOn';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { MenuItem } from '@mui/material';
import LocationIcon from '@mui/icons-material/LocationOn';
import { useEffect, useState, useReducer } from 'react';
import { Part as GetPart } from '../../../services/Parts';
import { PartRequest, PartRequestAssign, PartRequestOrderNew, STATUS_LIST } from '../../../models/TasksModel';
import { UpdateTask } from '../../../services/Tasks';
import { useSelector } from 'react-redux';

import { Paper, Avatar } from '@mui/material';

import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import KeyboardDoubleArrowUp from '@mui/icons-material/KeyboardDoubleArrowUp';
import { KeyboardArrowDown } from '@mui/icons-material';

import { PRIORITY_LEVEL } from '../../../models/TasksModel';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 5,
    pt: 1,
    px: 2,
    pb: 2
};

//const InitialRequestState = {serial:null, status: STATUS_LIST.PART_STATUS_REQUESTED, origin:null, partAssignedBy: null};


const RequestReducer = (state, action) => {

    //    console.log(action);

    let _state = { ...state };
    Object.keys(action).forEach(k => {
        _state[k] = action[k];
    })
    return { ..._state };
}

const AtLocationSelection = ({ part, select, setTaskStatus, enabled }) => {

    const current = part.part.stock.find((inv) => { return inv.location === part.platform.location });
    const [selectedPart, setSelectedPart] = useState(0);
    const userInfo = useSelector(state => state.auth.user);


    if (!current) {
        return (
            <TextField
                label="Location"
                id="filterLocation"
                sx={{ m: 1, width: '25ch' }}
                size="small"
                InputProps={{
                    startAdornment: <InputAdornment position="start"><LocationIcon /></InputAdornment>,
                }}
                value={0}
                onChange={(v) => { }}
                disabled={true}
            >
            </TextField>
        )
    }

    const partSelection = (index) => {
        setSelectedPart(index);
        if (index === 0) {
            select(new PartRequest());
        }
        else {
            let partRequest = new PartRequestAssign();
            partRequest.AssignSerial(current.items[index - 1]);
            partRequest.SetStatus(STATUS_LIST.PART_STATUS_READY);
            partRequest.SetOrigin(current.location);
            partRequest.SetAssignedBy(userInfo);
            setTaskStatus(STATUS_LIST.TASK_STATUS_TODO);
            select(partRequest);
        }
    }

    return (
        <TextField
            select
            label="Serial"
            id="filterLocation"
            sx={{ m: 1, width: '25ch' }}
            size="small"
            InputProps={{
                startAdornment: <InputAdornment position="start"><LocationIcon /></InputAdornment>,
            }}
            value={selectedPart}
            onChange={(v) => { setSelectedPart(v.target.value); partSelection(v.target.value); }}
            disabled={!enabled}
        >
            <MenuItem value={0}></MenuItem>
            {current.items.map((t, i) => { return <MenuItem key={i} value={i + 1}>{t}</MenuItem> })}
        </TextField>
    )
}

const AtOtherSelection = ({ part, select, enabled }) => {

    const userInfo = useSelector(state => state.auth.user);
    const current = part.part.stock.filter((inv) => { return inv.location !== part.platform.location });

    const [selectedPart, setSelectedPart] = useState(0);
    const [selectedLocation, setSelectedLocation] = useState(0);

    if (current.length === 0) {

        return (
            <TextField
                label="Location"
                id="filterLocation"
                sx={{ m: 1, width: '25ch' }}
                size="small"
                InputProps={{
                    startAdornment: <InputAdornment position="start"><LocationIcon /></InputAdornment>,
                }}

                onChange={(v) => { }}
                disabled={true}
            >
            </TextField>
        )
    }

    const partSelection = (index) => {
        setSelectedPart(index);
        if (index === 0 || selectedLocation === 0) {
            select(new PartRequest());
        }
        else {
            let partRequest = new PartRequestAssign();
            partRequest.AssignSerial(current[selectedLocation - 1].items[index - 1]);
            partRequest.SetStatus(STATUS_LIST.PART_STATUS_PROCESSING);
            partRequest.SetOrigin(current[selectedLocation - 1].location);
            partRequest.SetAssignedBy(userInfo);
            select(partRequest);

        }
    }

    return (
        <>

            <TextField
                select
                label="Location"
                id="filterLocation"
                sx={{ m: 1, width: '25ch' }}
                size="small"
                InputProps={{
                    startAdornment: <InputAdornment position="start"><LocationIcon /></InputAdornment>,
                }}
                value={selectedLocation}
                onChange={(v) => { setSelectedLocation(v.target.value); partSelection(0); }}
                disabled={!enabled}
            >
                <MenuItem value={0}></MenuItem>
                {/*current.items.map((t, i) => { return <MenuItem key={i} value={i + 1}>{t}</MenuItem> })*/}
                {current.map((t, i) => { return <MenuItem key={i} value={i + 1}>{t.location}</MenuItem> })}
            </TextField>
            <TextField
                select
                label="Serial"
                id="partSerial"
                sx={{ m: 1, width: '25ch' }}
                size="small"
                InputProps={{
                    startAdornment: <InputAdornment position="start"><LocationIcon /></InputAdornment>,
                }}
                value={selectedPart}
                onChange={(v) => { /*setSelectedPart(v.target.value); select({serial: current.items[v.target.value-1]});*/partSelection(v.target.value) }}
                disabled={!enabled}

            >
                <MenuItem value={0}></MenuItem>
                {selectedLocation !== 0 ? current[selectedLocation - 1].items.map((t, i) => { return <MenuItem key={i} value={i + 1}>{t}</MenuItem> }) : ''}
            </TextField>
        </>
    )
}

const GetNumAvailablePartsElsewhere = (part) => {
    return part.part.stock.filter((inv) => { return inv.location !== part.platform.location })
        .reduce((total, current) => { return total + current.items.length }, 0);
}

const PartRequestModal = ({ open, onClose, part, onUpdate }) => {


    console.log(part);

    const userInfo = useSelector(state => state.auth.user);
    const [requestState, requestDispatch] = useReducer(RequestReducer, new PartRequest());
    const [taskStatus, setTaskStatus] = useState(part.status);
    const [newPartCheckbox, setNewPartCheckbox] = useState(false);


    const RequestOrderNewPart = () => {

        //PartRequestOrderNew
        let order = Object.assign(new PartRequestOrderNew(), requestState);
        order.SetOrderRequestedBy(userInfo);
        order.SetStatus(STATUS_LIST.PART_STATUS_ORDER_REQUESTED);

        const request = { partRequest: order };

        console.log(request);
        UpdateTask(part._id, { op: "assign_part", data: request })
            .then(res => { return res.json() })
            .then(res => {
                onUpdate(res);
                onClose();
            });

    }

    const sendRequest = () => {

        if (newPartCheckbox)
            return RequestOrderNewPart();

        // assign part from current location 
        // PATCH {partRequest: {serial:'123', status:'READY'}} to tasks/:taskID

        // relocate part
        // PATCH {partRequest: {serial:'123', status:'PROCESSING'}} to tasks/:taskID

        // order new part
        // PATCH {partRequest: {status:'PROCESSING'}} to tasks/:taskID

        const request = { partRequest: requestState, status: taskStatus };
        UpdateTask(part._id, { op: "assign_part", data: request })
            .then(res => { return res.json() })
            .then(res => {
                onUpdate(res);
                onClose();
            });
    }


    const Row = ({ left, right }) => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                <Box sx={{ width: '50%', alignContent: 'center' }}>
                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>{left}</Typography>
                </Box>
                <Box sx={{ width: '50%' }}>
                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>{right}</Typography>
                </Box>
            </Box>
        )
    }


    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{ outline: 0 }}
            disableEnforceFocus={true}
            disableAutoFocus={true}
        >
            <Box sx={style}>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '100%' }}>
                        <Typography>{part.part.model}</Typography>
                    </div>
                    <div style={{ marginLeft: 'auto', alignSelf: 'flex-start' }} onClick={onClose} ><Close /></div>
                </Box>

                <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                    <Box sx={{ width: '65%' }}>

                        <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                            <Box>
                                <Typography sx={{ fontSize: 12 }} color="text.secondary">{part.part.type}</Typography>
                                <Typography sx={{ fontSize: 12 }} color="text.secondary">{part.part.manufacturer}</Typography>
                            </Box>
                            <Box style={{ marginLeft: 20 }}>
                                <Typography sx={{ fontSize: 12 }} color="text.secondary"><Flight sx={{ fontSize: 12 }} /> {part.platform.name}</Typography>
                                <Typography sx={{ fontSize: 12 }} color="text.secondary"><LocationOn sx={{ fontSize: 12 }} /> {part.platform.location}</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ marginTop: 2, border: '1px solid #aeaeae', borderRadius: 1, padding: 1, marginRight: 1 }}>

                            <Box sx={{ marginTop: 1 }}>
                                <Typography>Available parts at {part.platform.location}: {part.part.stock.find((inv) => { return inv.location === part.platform.location })?.items.length}</Typography>
                                <AtLocationSelection part={part} select={requestDispatch} setTaskStatus={setTaskStatus} enabled={!newPartCheckbox} />
                            </Box>

                            <Box sx={{ marginTop: 2 }}>
                                <Typography>Available parts elsewhere: {GetNumAvailablePartsElsewhere(part)}</Typography>
                                <AtOtherSelection part={part} select={requestDispatch} enabled={!newPartCheckbox} />
                            </Box>

                            <Box sx={{ marginTop: 2 }}>
                                {/** 
                                 * 
                                <Button variant='outlined' onClick={RequestOrderNewPart}>Request order new part</Button>
                                */}

                                <Typography>Request Order new part:
                                    <Checkbox
                                        checked={newPartCheckbox}
                                        onChange={(v) => {
                                            setNewPartCheckbox(!newPartCheckbox);
                                            requestDispatch(new PartRequest());
                                        }}
                                        disabled={requestState.serial !== null}
                                    />
                                </Typography>

                            </Box>



                        </Box>

                        <Box sx={{ marginTop: 2 }}>
                            <Button variant='outlined' onClick={sendRequest} disabled={requestState.serial === null && !newPartCheckbox}>Apply</Button>


                        </Box>

                    </Box>
                    <Box sx={{ width: '35%', backgroundColor: '#eee', borderRadius: 1 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Chip sx={{ marginLeft: 'auto', backgroundColor: '#ff4d4d', color: 'white', marginRight: 1, marginTop: 2, marginBottom: 2 }} label="Requested" />
                        </Box>





                        <Box sx={{ display: 'flex', marginRight: 1 }}>
                            <Paper sx={{ width: '100%', marginLeft: 0.5, marginRight: 1, backgroundColor: '#eee' }} elevation={0}>
                                <Row left={'Task Request:'} right={
                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        <Avatar sx={{ width: 20, height: 20 }} src={part.reporter?.picture} />
                                        <Typography color="text.secondary" sx={{ fontSize: 13, marginLeft: 0.5 }}><b>{part.reporter?.nickname}</b></Typography>
                                    </Box>
                                } />
                                <Row left={'Task Assignee:'} right={
                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        <Avatar sx={{ width: 20, height: 20 }} src={part.assignee?.picture} />
                                        <Typography color="text.secondary" sx={{ fontSize: 13, marginLeft: 0.5 }}><b>{part.assignee?.nickname}</b></Typography>
                                    </Box>
                                } />
                                <Row left={'Task Priority:'} right={
                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        {part.priority === 0 ? <KeyboardArrowDown sx={{width:15, height:18, color:'green' }}/> : ''}
                                        {part.priority === 1 ? <KeyboardDoubleArrowUp sx={{width:15, height:18, color:'orange' }}/> : ''}
                                        {part.priority === 2 ? <KeyboardDoubleArrowUp sx={{width:15, height:18, color:'red' }}/> : ''}

                                        <Typography color="text.secondary" sx={{ fontSize: 13, marginLeft: 0.5 }}><b>{PRIORITY_LEVEL[part.priority]}</b></Typography>
                                    </Box>
                                } />
                                <Row left={'Task Status:'} right={
                                    <Chip sx={{ backgroundColor: 'orange', color: 'white', height:22, fontSize:10   }} label={part.status} />

                                } />
                            </Paper>
                        </Box>
                    </Box>



                </Box>


            </Box>
        </Modal>
    )
}

export default PartRequestModal;