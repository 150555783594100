import Pagination from '@mui/material/Pagination';


const PlatformPagination = ({ total, pageLimit, onChange }) => {

    let count = Math.floor(total / pageLimit) + (total % pageLimit === 0 ? 0 : 1);
    return (
        <div style={{  }}>
            <Pagination count={count} onChange={(e, v) => { onChange(v) }}>
            </Pagination>
        </div>
    )
}

export default PlatformPagination;