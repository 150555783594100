import { Paper } from '@mui/material';
import { DoNotDisturb } from '@mui/icons-material';

const PermissionError = () => {
    return (
        <Paper sx={{ paddingTop: 2, textAlign: 'center' }}>
            <DoNotDisturb sx={{ color: 'red' }} />
            <h4>Permission Denied</h4>
            <div>Insufficient Permissions</div>
            <div>Please contact your administrator</div>
        </Paper>
    );
}

export default PermissionError;