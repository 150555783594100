import { API } from '../config';
import { GET, POST, FORM_POST, PATCH, DELETE } from './Methods';

export const GetSoftware = (q={}) => GET(API.SOFTWARE(q));

export const GetSoftwareIndividual = (id) => GET(API.SOFTWARE_INDIVIDUAL(id));

export const AddSoftware = (doc) => POST(API.SOFTWARE(), doc);

export const RemoveSoftware = (softwareId) => DELETE(API.SOFTWARE_INDIVIDUAL(softwareId),{});

export const UpdateSoftware = (softwareId, doc) => PATCH(API.SOFTWARE_INDIVIDUAL(softwareId),doc);


