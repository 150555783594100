import { Modal, Box } from "@mui/material"
import { Button } from "../../../../components/Buttons"


export const RemoveLocationModal = ({ location, onRemove, onClose }) => {
    return (
        <Modal
            open={true}
            onClose={onClose}
            sx={{ outline: 0 }}
            disableEnforceFocus={true}
            disableAutoFocus={true}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 450,
                bgcolor: 'background.paper',
                border: '0px solid #000',
                boxShadow: 5,
                // pt: 1,
                // px: 2,
                // pb: 3
            }}>
                <div className="px-3 py-3">
                    <div className="font-normal text-center">
                        Remove <b>{location.name} ({location.country})</b> from database
                    </div>
                    <div className="font-semibold text-center">
                        Are you sure?
                    </div>
                    <div className="flex justify-center mt-4">
                        <div className="mr-4">
                            <Button label={"Cancel"} onClick={onClose}/>
                        </div>
                        <div>
                            <Button label={"Remove"} variant="warning" onClick={()=>{onRemove(location)}}/>
                        </div>
                    </div>
                </div>



            </Box>
        </Modal>
    )
}