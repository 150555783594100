import { useEffect, useState } from "react";
import PlatformTaskCard from "../../platforms/tabs/PlatformTaskCard";
import { Tasks as GetTasks } from "../../../services/Tasks";
import { LoadingMain } from "../../../components/LoadingModal";

import PlatformTaskModal from "../../platforms/tabs/PlatformTaskModal/PlatformTaskModal";
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IDIcon from '@mui/icons-material/Tag';
import ManufacturerIcon from '@mui/icons-material/Factory';
import LocationIcon from '@mui/icons-material/LocationOn';
import ModelIcon from '@mui/icons-material/DriveFileRenameOutline';
import FlightIcon from '@mui/icons-material/Flight';
import { Info, Person } from "@mui/icons-material";
import { MenuItem } from '@mui/material';
import FilterIconButton from '@mui/icons-material/FilterAlt';
import Button from '@mui/material/Button';
import FilterIcon from '@mui/icons-material/TuneOutlined';
import { useReducer } from 'react';
import { useSelector } from "react-redux";
import { MainContainer } from "../../../components/Containers";



const InitialFilterState = {};
const FilterReducer = (state, action) => {

    let _state = { ...state };
    Object.keys(action).forEach(k => {
        _state[k] = action[k];
    })
    return { ..._state };
}

const TasksAllFilter = () => {

    //const [filterState, setFilterState] = useState(InitialFilterState)
    const [filterState, filterDispatch] = useReducer(FilterReducer, InitialFilterState);

    return (



            // <MainContainer>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<FilterIcon />}
                        aria-controls="panel1a-content"
                        id="platformFilterHeader"
                    >
                        <Typography>Filter All Tasks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <hr />

                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div>
                                <TextField
                                    label="Platform ID"
                                    id="filterPlatformID"
                                    sx={{ m: 1, width: '25ch' }}
                                    size="small"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><FlightIcon /></InputAdornment>,
                                    }}
                                    value={filterState.platformID}
                                    onChange={(v) => { filterDispatch({ platformID: v.target.value }) }}

                                />
                                <TextField
                                    select
                                    label="Status"
                                    id="filterStatus"
                                    sx={{ m: 1, width: '25ch' }}
                                    size="small"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Info /></InputAdornment>,
                                    }}
                                    value={filterState.location}
                                    onChange={(v) => { filterDispatch({ location: v.target.value }) }}
                                >
                                    <MenuItem value={"PENDING"}>{"PENDING"}</MenuItem>
                                    <MenuItem value={"TODO"}>{"TODO"}</MenuItem>
                                    <MenuItem value={"IN_PROGRESS"}>{"IN_PROGRESS"}</MenuItem>
                                    <MenuItem value={"DONE"}>{"DONE"}</MenuItem>
                                </TextField>

                                <TextField
                                    select
                                    label="Assigned"
                                    id="filterStatus"
                                    sx={{ m: 1, width: '25ch' }}
                                    size="small"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Person /></InputAdornment>,
                                    }}
                                    value={filterState.location}
                                    onChange={(v) => { filterDispatch({ location: v.target.value }) }}
                                >
                                    <MenuItem value={"PENDING"}>{"PENDING"}</MenuItem>
                                    <MenuItem value={"TODO"}>{"TODO"}</MenuItem>
                                    <MenuItem value={"IN_PROGRESS"}>{"IN_PROGRESS"}</MenuItem>
                                    <MenuItem value={"DONE"}>{"DONE"}</MenuItem>
                                </TextField>

                                <TextField
                                    label="Results per page"
                                    id="filterNumPerPage"
                                    sx={{ m: 1, width: '25ch' }}
                                    size="small"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><IDIcon /></InputAdornment>,
                                    }}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    type={"number"}
                                    value={filterState.limit}
                                    onChange={(v) => { filterDispatch({ limit: Number(v.target.value) }) }}
                                />


                            </div>
                        </Box>

                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div>
                                <Button id="filterApplyBtn" variant="contained" endIcon={<FilterIconButton />} sx={{ m: 1, width: '15ch' }} size="small" onClick={() => { }}>
                                    Apply
                                </Button>
                                <Button id="filterResetBtn" variant="contained" sx={{ m: 1, width: '15ch' }} size="small" color='secondary' disabled={false} onClick={() => { }}>
                                    Reset
                                </Button>
                            </div>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            // </MainContainer>
    )

}

const TasksAll = () => {

    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [taskModal, setSelectedTask] = useState(null);
    const project = useSelector(state => state.projects.selected);

    useEffect(() => {
        GetTasks({ isApproved: false, projectId: project._id })
            .then((res) => { return res.json() })
            .then(result => {
                setLoading(false);
                setTasks(result);
            })
    }, [])

    const onTaskUpdate = (task) => {
        setTasks(tasks.map(t => { if (t._id === task._id) { return task } else { return t } }));
        setSelectedTask(task);
    }

    return (
        
        <div style={{ backgroundColor: "#D9DDDB" }} className="h-screen pt-8">

            <MainContainer>
                <TasksAllFilter />           
            </MainContainer>


            <MainContainer className="mt-4">
            {
                loading ? <LoadingMain show={loading} /> :
                
                <div>
                        <Typography sx={{ marginTop: 1, marginBottom: 1 }}>Results: {tasks.length}</Typography>
                        {tasks.map((t, i) => { return <PlatformTaskCard key={i} task={t} onTaskSelect={setSelectedTask} /> })}
                        {taskModal ? <PlatformTaskModal onClose={() => { setSelectedTask(null) }} onTaskUpdate={onTaskUpdate} task={taskModal} /> : ''}
                    </div>
            }
            </MainContainer>             
        </div>
    )
}

export default TasksAll;