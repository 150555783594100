import { Modal, Box } from "@mui/material";
import { useReducer, useState } from "react";
import { DefaultReducer } from "../../../../components/DefaultReducer";
import { TextFieldInput, TextInputArea } from "../../../../components/Inputs";
import { Button } from "../../../../components/Buttons";
import { RemoveSoftwareModal } from "./AdminRemoveSoftwareModal";

const MODAL_STATE = {
    NONE: "none",
    REMOVE_SOFTWARE: "remove_software",
}

const AdminUpdateSoftwareModal = ({ onClose, onAdd, onRemove, onUpdate, data }) => {

    const [formState, formStateDispatch] = useReducer(DefaultReducer, data);
    const [modalState, setModalState] = useState(MODAL_STATE.NONE);

    const getUpdate = () => {
        const fields = ["name", "company", "version", "size", "active", "notes", "documentation"];
        let update = {}
        fields.forEach(k => {
            if(formState[k] !== data[k]) {
                update[k] = formState[k];
            }
        });
        return update;
    }

    return (
        <div>
            <Modal
                open={true}
                onClose={onClose}
                sx={{ outline: 0 }}
                disableEnforceFocus={true}
                disableAutoFocus={true}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 450,
                    bgcolor: 'background.paper',
                    border: '0px solid #000',
                    boxShadow: 5,
                    pt: 1,
                    px: 2,
                    pb: 3
                }}>
                    <div className="border-b">Update Software</div>
                    <div className="grid grid-cols-1 gap-5 pt-2">
                        <TextFieldInput name={"Name"} placeholder={"name"} onChange={(v) => { formStateDispatch({ name: v }) }} value={formState.name} />
                    </div>
                    <div className="grid grid-cols-2 gap-5 pt-4">
                        <TextFieldInput name={"Company"} placeholder={"company"} onChange={(v) => { formStateDispatch({ company: v }) }} value={formState.company} />
                        <TextFieldInput name={"Version"} placeholder={"version"} onChange={(v) => { formStateDispatch({ version: v }) }} value={formState.version} />
                        <TextFieldInput name={"Size"} placeholder={"size"} onChange={(v) => { formStateDispatch({ size: v }) }} value={formState.size} />
                        <TextFieldInput name={"Active"} placeholder={"true"} onChange={(v) => { formStateDispatch({ active: v }) }} value={formState.active} />
                    </div>
                    <div className="grid grid-cols-1 gap-5 pt-4">
                        <TextInputArea name={"Notes"} placeholder={"notes"} onChange={(v) => { formStateDispatch({ notes: v }) }} value={formState.notes} rows={3} />
                        <TextInputArea name={"Documentation"} placeholder={"documentation"} onChange={(v) => { formStateDispatch({ documentation: v }) }} value={formState.documentation} rows={3} />
                    </div>
                    <div className="mt-4 flex flex-row justify-end">
                        <div className="mr-4">
                            <Button label={"Remove"} variant={"secondary"} onClick={() => { setModalState(MODAL_STATE.REMOVE_SOFTWARE) }} />
                        </div>
                        <div className="mr-4">
                            <Button label={"Cancel"} variant={"secondary"} onClick={onClose} />
                        </div>
                        <div>
                            <Button label={"Update Software"} onClick={() => { onUpdate(data._id, getUpdate()) }} />
                        </div>
                    </div>
                </Box>
            </Modal>
            {modalState === MODAL_STATE.REMOVE_SOFTWARE ? <RemoveSoftwareModal software={data} onClose={()=>{setModalState(MODAL_STATE.NONE)}} onRemove={onRemove} /> : null}
        </div>
    )
}

export default AdminUpdateSoftwareModal;