import { Button, Box, Typography, Modal, Avatar, Card, TextField, MenuItem, Select, Paper, Accordion, AccordionSummary, AccordionDetails, InputLabel, InputAdornment, Input } from "@mui/material";
import ScheduleIcon from '@mui/icons-material/Schedule';
import BuildIcon from '@mui/icons-material/Build';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { AttachFile } from "@mui/icons-material";
import { useEffect, useReducer, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AllUsers as GetAllUsers } from "../../../../services/Users";
import { PRIORITY_LEVEL, STATUS_LIST, TASK_TYPE } from "../../../../models/TasksModel";
import { UpdateTask } from "../../../../services/Tasks";
import Divider from '@mui/material/Divider';
import { Send } from "@mui/icons-material";
import { Platform as GetPlatform } from "../../../../services/Platforms";
import { platformActions } from "../../../../store";

import './PlatformTaskModal.css';

const Comment = ({ data }) => {

    const user = useSelector(state => state.auth.user);

    // console.log(data, user.user_id);


    const style = (isUser) => {
        if(isUser)
            return {backgroundColor:'white', flexDirection: 'row-reverse'};

        return {};
    }

    return (
        <Paper className="comment" elevation={1}>
            <Box sx={{...style(data.user.user_id === user.user_id),  display: 'flex', paddingRight:1 }}>
                <Box sx={{ width: 38 }}>
                    <Avatar sx={{ width: 30, height: 30, fontSize: 10, margin: 1 }} src={data.user.picture} />
                </Box>
                <Box sx={{ paddingTop: 0.5, width: '100%', marginLeft: 1 }}>
                    <Typography sx={{ fontSize: 14 }}>{data.text}</Typography>
                    <Typography color="text.secondary" sx={{ fontSize: 11 }}>{data.updatedAt}</Typography>
                </Box>
            </Box>
        </Paper>
    )
}

const NewPartRequest = ({ task }) => {

    const Row = ({ left, right }) => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                <Box sx={{ width: '50%', alignContent: 'center' }}>
                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>{left}</Typography>
                </Box>
                <Box sx={{ width: '50%' }}>
                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>{right}</Typography>
                </Box>
            </Box>
        )
    }

    return (
        <Box sx={{ display: 'flex', marginRight: 1 }}>
            <Paper sx={{ width: '100%', marginLeft: 2, backgroundColor: '#eee', padding: 1 }} elevation={0}>
                <Typography color="text.secondary" sx={{ fontSize: 13 }}><b>New Part</b></Typography>
                <Divider />
                <Row left={'Model'} right={task.part.model} />
                <Row left={'Type'} right={task.part.type} />
                <Row left={'Serial'} right={task.partRequest.serial ? task.partRequest.serial : '-'} />
                <Row left={'Part request status'} right={<Button sx={{ marginLeft: 'auto', height: 30, fontSize: 10 }} style={{ color: 'white', backgroundColor: '#ff4d4d' }}>{task.partRequest.status}</Button>} />
            </Paper>
        </Box>

    )
}

const PartModRequest = ({ task }) => {
    return (
        <Box sx={{ display: 'flex', marginRight: 1 }}>
            <Paper sx={{ width: '100%', marginLeft: 2, backgroundColor: '#eee', padding: 1 }} elevation={0}>
                <Typography color="text.secondary" sx={{ fontSize: 13 }}><b>Part</b></Typography>
                <Divider />
                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                    <Box sx={{ width: '50%', alignContent: 'center' }}>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}>Model</Typography>
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}>{task.part.model}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                    <Box sx={{ width: '50%', alignContent: 'center' }}>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}>Type</Typography>
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}>{task.part.type}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                    <Box sx={{ width: '50%', alignContent: 'center' }}>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}>serial</Typography>
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}>{task.part.serial ? task.part.serial : '-'}</Typography>
                    </Box>
                </Box>

            </Paper>
        </Box>

    )
}

const PartSchedRequest = ({ task }) => {

    let lastService = new Date(task.part.lastService);
    let nextService = new Date(task.part.lastService);
    nextService.setDate(nextService.getDate() + task.part.serviceInterval.duration.d + (task.part.serviceInterval.duration.w * 7 + (task.part.serviceInterval.duration.m * 30)));
    return (
        <Box sx={{ display: 'flex', marginRight: 1 }}>
            <Paper sx={{ width: '100%', marginLeft: 2, backgroundColor: '#eee', padding: 1 }} elevation={0}>
                <Typography color="text.secondary" sx={{ fontSize: 13 }}><b>Scheduled Maintenance</b></Typography>
                <Divider />
                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                    <Box sx={{ width: '50%', alignContent: 'center' }}>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}>Model</Typography>
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}>{task.part.model}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                    <Box sx={{ width: '50%', alignContent: 'center' }}>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}>Type</Typography>
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}>{task.part.type}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                    <Box sx={{ width: '50%', alignContent: 'center' }}>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}>serial</Typography>
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}>{task.part.serial ? task.part.serial : '-'}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                    <Box sx={{ width: '50%', alignContent: 'center' }}>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}>Last Service</Typography>
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}><b>{lastService.toDateString()}</b></Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                    <Box sx={{ width: '50%', alignContent: 'center' }}>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}>Service Due</Typography>
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <Typography color="text.secondary" sx={{ fontSize: 13 }}><b>{nextService.toDateString()}</b> or <b>{task.part.serviceInterval.afterFlightHours - task.part.flightHoursSinceLastService} h. flight</b> </Typography>
                    </Box>
                </Box>

            </Paper>
        </Box>

    )
}

const TaskIcon = (task) => {

    switch (task.type) {
        case TASK_TYPE.PLATFORM_ADD_PART_TASK:
        case TASK_TYPE.PLATFORM_REPLACE_PART_TASK:
            return <AddIcon sx={{ fontSize: 30, color: 'green' }} />

        case TASK_TYPE.PLATFORM_SCHEDULED_PART_TASK:
            return <ScheduleIcon sx={{ fontSize: 30, color: 'green' }} />

        case TASK_TYPE.PLATFORM_REPAIR_PART_TASK:
        case TASK_TYPE.PLATFORM_REMOVE_PART_TASK:
            return <BuildIcon sx={{ fontSize: 30, color: 'green' }} />
    }

}

const FormReducer = (state, action) => {
    let _state = { ...state };
    Object.keys(action).forEach(k => {
        _state[k] = action[k];
    })
    return { ..._state };
}

const PlatformTaskModal = ({ onClose, onTaskUpdate, task }) => {

    const platform = useSelector(state => state.platform.platform);
    const dispatch = useDispatch();

    const [expanded, setExpanded] = useState("NOTES");
    const userInfo = useSelector(state => state.auth.user);
    const [users, setUsers] = useState(null);
    const [signOff, setSignOff] = useState(false);
    const [formState, formDispatch] = useReducer(FormReducer, { ...task, addComment: '' });

    const updateModalTask = (patch) => {
        // check field
        UpdateTask(task._id, patch)
            .then(res => { return res.json() })
            .then(res => { onTaskUpdate(res) });
    }

    const refreshPlatform = () => {
        // GetTasks({ platformID: platformData._id })
        GetPlatform(platform._id)
        .then(r => r.json())
        .then(r => dispatch(platformActions.setPlatform(r)))
    }


    const signOffTask = () => {
        UpdateTask(task._id, {op:"sign_off"})
        .then(res => { return res.json() })
        .then(res => { 
            onTaskUpdate(res);
//            console.log(res);
            onClose();
            refreshPlatform();
//            UpdatePlatform( {op:"add_part", data:{ partID:task.part._id }} );
//            console.log(task.part, task.partRequest);
        });
    }

    const isWatching = () => {
        return task.subscribers.find((w) => { return w === userInfo.user_id });
    }

    useEffect(() => {
        GetAllUsers()
            .then(r => { return r.json() })
            .then(result => {
                // console.log(result);
                setUsers(result);
            })
    }, [])

    const assigneeIndex = (assignee) => {
        let r = 0;
        users.forEach((user, index) => {
            if (assignee?.nickname === user.nickname)
                r = index + 1;
        })
        return r;
    }

    const getNextStatus = (task) => {
        switch(task.status)
        {
            case STATUS_LIST.TASK_STATUS_TODO:
            return STATUS_LIST.TASK_STATUS_IN_PROGRESS;
            break;

            case STATUS_LIST.TASK_STATUS_IN_PROGRESS:
            return STATUS_LIST.TASK_STATUS_DONE;
            break;

        }   
        return STATUS_LIST.TASK_STATUS_DONE;
    }

    const enableSignOff = () => (userInfo.roles.includes('ADMIN') || userInfo.roles.includes('LEAD_ENGINEER'));

    return (<>

        <Modal
            open={true}
            onClose={onClose}
            disableEnforceFocus={true}
            disableAutoFocus={true}

        >
            <Box id="modalContainer" sx={{ bgcolor: 'background.paper' }}>
                <Box sx={{ width: '65%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Avatar sx={{ bgcolor: '#e0e0e0', width: 45, height: 45, fontSize: 10, margin: 0.5 }}>{TaskIcon(task)}</Avatar>
                        <Typography sx={{ fontSize: 25, marginTop: 0.8, marginLeft: 2 }} color="text.primary">  {task.description}</Typography>
                    </Box>
                    <Box sx={{ marginTop: 3 }}>
                        <Accordion expanded={expanded === 'NOTES'} onChange={() => { setExpanded('NOTES') }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Notes</Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                <TextField
                                    id="outlined-multiline-static"
                                    label=""
                                    multiline
                                    rows={15}
                                    value={formState.notes}
                                    onChange={(v) => { formDispatch({ notes: v.target.value }) }}
                                    sx={{ width: '100%' }}
                                />

                                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1, marginBottom: 2 }}>
                                    <Button sx={{ backgroundColor: '#351893', marginLeft: 'auto', height: 30, color: '#fff', fontSize: 10 }} onClick={() => { updateModalTask({ notes: formState.notes }) }}>Update</Button>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>

                    <Accordion expanded={expanded === "COMMENTS"} onChange={() => { setExpanded('COMMENTS') }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="addComment"
                        >
                            <Typography>Comments {task.comments.length > 0 ? `(${task.comments.length})` : ''}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box id="commentsContainer">
                                {task.comments.map((c, i) => { return (<Comment key={i} data={c} />) })}
                            </Box>

                            <TextField
                                id="addComment" label="Add comment" variant="standard"
                                sx={{ width: '80%', marginTop: 1 }}
                                value={formState.addComment}
                                onChange={(v) => { formDispatch({ addComment: v.target.value }) }}
                                onKeyDown={(key) => { if (key.key === "Enter") { updateModalTask({ op: "comment", data: formState.addComment }); formDispatch({ addComment: '' }) } }}

                            />
                            <AttachFile sx={{ color: '#aaa', marginTop: 3.5, marginLeft: 1, width: 30, display: 'inline-block' }} />
                            <Send id="sendCommentButton" sx={{ color: formState.addComment ? '#6666ff' : '#aaa' }} onClick={() => { updateModalTask({ op: "comment", data: formState.addComment }); formDispatch({ addComment: '' }) }} />
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Box sx={{ width: '35%' }}>

                    <Box sx={{ display: 'flex' }}>
                        <CloseIcon sx={{ marginLeft: 'auto' }} color="text.secondary" onClick={onClose} />
                    </Box>
                    <Box sx={{ display: 'flex', marginTop: 2, marginRight: 1 }}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={task.status}
                            onChange={(v) => { updateModalTask({ status: v.target.value }) }}
                            disabled={task.isApproved || task.status === STATUS_LIST.TASK_STATUS_PENDING || task.status === STATUS_LIST.TASK_STATUS_AWAITING_PART}
                            sx={{
                                backgroundColor: '#0052cc', marginLeft: 'auto', height: 30, color: '#fff', fontSize: 13,
                                fill: "white",
                            }}
                        >
                            <MenuItem value={task.status}>{task.status}</MenuItem>
                            <MenuItem value={getNextStatus(task)}>{getNextStatus(task)}</MenuItem>
                            {/*task.statusList.filter((s) => { return s !== task.status }).map((s, i) => { return (<MenuItem key={i} value={s}>{s}</MenuItem>) })*/}
                        </Select>
                    </Box>
                    <Box sx={{ display: 'flex', marginTop: 2, marginRight: 1, paddingBottom: 2 }}>
                        <Paper sx={{ width: '100%', marginLeft: 2, backgroundColor: '#eee', paddingLeft: 1, paddingTop: 2, paddingRight: 1 }} elevation={0}>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box sx={{ width: '50%', alignContent: 'center' }}>
                                </Box>
                                {
                                    !isWatching() ?
                                        <Box sx={{ width: '50%' }}>
                                            <Button sx={{ marginLeft: 'auto', height: 30, fontSize: 10 }} style={{ color: 'white', backgroundColor: 'green' }} onClick={() => { updateModalTask({ op: "watch" }) }}>Watch</Button>
                                        </Box> :
                                        <Box sx={{ width: '50%' }}>
                                            <Button sx={{ backgroundColor: '#0052cc', marginLeft: 'auto', height: 30, color: '#fff', fontSize: 10 }} onClick={() => { updateModalTask({ op: "unwatch" }) }} variant="outlined">Unwatch</Button>
                                        </Box>
                                }
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                                <Box sx={{ width: '50%', alignContent: 'center' }}>
                                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>Assignee</Typography>
                                </Box>
                                {users !== null ?
                                    <Select
                                        labelId="assignee"
                                        id="assignee"
                                        value={assigneeIndex(task.assignee)}
                                        onChange={(v) => {
                                            let u = v.target.value === 0 ? null : users[v.target.value - 1];
                                            updateModalTask({ assignee: u })
                                        }}
                                        sx={{ backgroundColor: '#0052cc', height: 30, color: '#fff', fontSize: 13, width: 120 }}
                                        renderValue={(value) => {
                                            return <div>{value !== 0 ? users[value - 1].nickname : 'Assign'}</div>
                                        }}
                                    >
                                        <MenuItem value={0} disabled={assigneeIndex(task.assignee) === 0}>Unassign</MenuItem>
                                        {users.map((user, index) => { return (<MenuItem value={index + 1} key={index}><Avatar src={user.picture} sx={{ width: 30, height: 30, marginRight: 2 }} />{user.nickname}</MenuItem>) })}
                                    </Select> : ''
                                }
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1.5 }}>
                                <Box sx={{ width: '50%', alignContent: 'center' }}>
                                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>Priority</Typography>
                                </Box>
                                <Box sx={{ width: '50%' }}>
                                    <Select
                                        labelId="priority"
                                        id="priority"
                                        value={task.priority}
                                        onChange={(v) => { updateModalTask({ priority: v.target.value }) }}
                                        sx={{ backgroundColor: '#542972', marginLeft: 'auto', height: 30, color: '#fff', fontSize: 13 }}
                                    >
                                        {PRIORITY_LEVEL.map((p, i) => { return (<MenuItem value={i} key={i}>{p}</MenuItem>) })}
                                    </Select>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1.5 }}>
                                <Box sx={{ width: '50%', alignContent: 'center' }}>
                                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>Reported by</Typography>
                                </Box>
                                <Box sx={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
                                    <Avatar sx={{ width: 20, height: 20 }} src={task.reporter?.picture} />
                                    <Typography color="text.secondary" sx={{ fontSize: 13, marginLeft: 0.5 }}><b>{task.reporter?.nickname}</b></Typography>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1.5 }}>
                                <Box sx={{ width: '50%', alignContent: 'center' }}>
                                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>Child Task</Typography>
                                </Box>
                                <Box sx={{ width: '50%' }}>
                                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>na</Typography>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1.5 }}>
                                <Box sx={{ width: '50%', alignContent: 'center' }}>
                                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>Parent Task</Typography>
                                </Box>
                                <Box sx={{ width: '50%' }}>
                                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>na</Typography>
                                </Box>
                            </Box>
                            <Divider sx={{ marginTop: 1.5 }} />
                            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1.5 }}>
                                <Box sx={{ width: '50%', alignContent: 'center' }}>
                                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>Created</Typography>
                                </Box>
                                <Box sx={{ width: '50%' }}>
                                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>{(new Date(task.createdAt)).toDateString()}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1, marginBottom: 2 }}>
                                <Box sx={{ width: '50%', alignContent: 'center' }}>
                                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>Last update</Typography>
                                </Box>
                                <Box sx={{ width: '50%' }}>
                                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>{(new Date(task.updatedAt)).toDateString()}, {(new Date(task.updatedAt)).toLocaleTimeString()}</Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>

                    {task.type === TASK_TYPE.PLATFORM_ADD_PART_TASK ? <NewPartRequest task={task} /> : ''}
                    {task.type === TASK_TYPE.PLATFORM_REPLACE_PART_TASK ? <NewPartRequest task={task} /> : ''}
                    {task.type === TASK_TYPE.PLATFORM_SCHEDULED_PART_TASK ? <PartSchedRequest task={task} /> : ''}
                    {task.type === TASK_TYPE.PLATFORM_REMOVE_PART_TASK ? <PartModRequest task={task} /> : ''}
                    {task.type === TASK_TYPE.PLATFORM_REPAIR_PART_TASK ? <PartModRequest task={task} /> : ''}

                    <Box sx={{ display: 'flex', marginTop: 2, marginRight: 1, paddingBottom: 2 }}>
                        {!task.isApproved ? 
                        // <Button variant="contained" sx={{ marginLeft: 'auto', height: 30, fontSize: 10 }} style={{ color: 'white' }} disabled={(task.status !== STATUS_LIST.TASK_STATUS_DONE) || !userInfo.roles.includes('ADMIN')} onClick={() => {setSignOff(true) }}>Sign Off</Button>:
                        <Button variant="contained" sx={{ marginLeft: 'auto', height: 30, fontSize: 10 }} style={{ color: 'white' }} disabled={(task.status !== STATUS_LIST.TASK_STATUS_DONE) || !enableSignOff()} onClick={() => {setSignOff(true) }}>Sign Off</Button>:
                        <Button variant="contained" sx={{ marginLeft: 'auto', height: 30, fontSize: 10 }} style={{ color: 'white' }} disabled={true}>Signed Off by Lead</Button>
                        }
                    </Box>


                    <Modal
                        open={signOff}
                        onClose={()=>{setSignOff(false)}}
                        disableEnforceFocus={true}
                        disableAutoFocus={true}

                    >
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 350,
                            height: 100,
                            //display: 'flex',
                            bgcolor: 'background.paper'
                        }}>
                            <Box>
                                <Typography sx={{ textAlign: 'center', fontSize:20, marginTop:1 }}>Sign off task?</Typography>
                            </Box>
                            <Box sx={{display:'flex', justifyContent:'center', marginTop:2}}>
                                <Button variant="contained" sx={{ height: 30, fontSize: 10, marginRight:1 }} onClick={()=>{setSignOff(false)}}>Cancel</Button>
                                <Button variant="contained" sx={{ height: 30, fontSize: 10 }} onClick={signOffTask}>Approve</Button>
                            </Box>
                        </Box>
                    </Modal>


                </Box>
            </Box >
        </Modal >

    </>)
}

export default PlatformTaskModal;