import { Card } from "@mui/material"
import { Person, EventNote, Info, Send } from "@mui/icons-material";
import './PlatformIncidentReportCard.css';

const PlatformIncidentReportCard = ({ efr, onClick }) => {
    return (
        <Card className="cursor-on-hover" id="platform-incident-report-card" onClick={onClick}>
            <div className="incident-ref">Ref: {efr.incidentRef}</div>
            <div style={{ display: 'flex' }}>
                <div>
                    <div className="incident-detail">
                        <Person className="incident-icon" />
                        <div>{efr.personInvolved}</div>
                    </div>
                    <div className="incident-detail">
                        <EventNote className="incident-icon"/>
                        <div>{new Date(efr.incidentDate).toDateString()}</div>
                    </div>
                </div>
                <div>
                    <div className="incident-detail middle">
                        <Info className="incident-icon"/>
                        <div>{efr.status}</div>
                    </div>
                    <div className="incident-detail middle">
                        <Send className="incident-icon"/>
                        <div>{efr.extRef}</div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export { PlatformIncidentReportCard };