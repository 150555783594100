import PartsAllFilter from "./PartsAllFilter";
import { Parts as GetParts } from '../../../services/Parts';
import { useEffect, useContext, useState, useReducer } from "react";
//import { RequestErrorHandlerContext } from '../../../components/Main';
import { RequestErrorHandlerContextNEW as RequestErrorHandlerContext } from "../../../App";
import PartsAllCard from "./PartsAllCard";
import { Box, Typography } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import { DefaultReducer } from "../../../components/DefaultReducer";
import { LoadingMain } from "../../../components/LoadingModal";
import PartsAllModal from "./PartsAllModal";
import { MainContainer } from "../../../components/Containers";

const InitialPage = { page: 0, limit: 10, manufacturer: null, model: null, type: null, 'stock.location': null };

const PartsAll = () => {

    const errorHandler = useContext(RequestErrorHandlerContext);
    const [parts, SetParts] = useState([]);
    const [selectedPart, SetSelectedPart] = useState(null);
    const [loading, SetLoading] = useState(true);
    const [pagination, setPage] = useReducer(DefaultReducer, InitialPage);

    const parseQuery = (query) => {
        let obj = {};
        Object.keys(query).forEach((k) => {
            if (query[k] && query[k] !== '')
                obj[k] = query[k];
        });
        return obj;
    }

    useEffect(() => {

        SetLoading(true);
        GetParts(parseQuery(pagination))
            .then(result => {
                return result.json();
            })
            .then(result => {
                return errorHandler(result);
            })
            .then(result => {
                console.log(result);
                SetLoading(false);
                SetParts(result);
            })
            .catch(err => {
                // handle error
            })

    }, [pagination]);


    const GetPageCount = () => {
        let val = 0;
        if (parts.total)
            val = Math.floor(parts.total / pagination.limit) + (parts.total % pagination.limit === 0 ? 0 : 1);
        return val;
    }

    return (
        <div style={{ backgroundColor: "#D9DDDB" }} className="pt-8 h-screen">


            <MainContainer>
                <PartsAllFilter onApply={(v) => { setPage(Object.assign({ ...v }, { page: 0, limit: 10 })) }} />
            </MainContainer>


            <MainContainer className="mt-4">

                {loading ? <LoadingMain /> :
                    <>



                        <Box sx={{ }}>

                            <Box sx={{ padding: 0.5, marginTop: 1 }}>
                                <Typography variant="body2" display="block" fontSize={14} gutterBottom>
                                    Total Parts: {parts.total}
                                </Typography>
                                <Typography variant="body2" display="inline" fontSize={12} color="text.secondary" gutterBottom>
                                    Page: {pagination.page + 1}
                                </Typography>
                                <Typography variant="body2" display="inline" fontSize={12} color="text.secondary" sx={{ marginLeft: 2 }} gutterBottom>
                                    Showing {(pagination.page * pagination.limit) + 1} - {(pagination.page * pagination.limit) + parts.data?.length}
                                </Typography>
                            </Box>
                            {parts.data?.map((part, i) => { return <PartsAllCard key={i} part={part} select={SetSelectedPart} /> })}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: 2, paddingBottom: 2 }}>
                            <Pagination
                                page={pagination.page + 1}
                                sx={{ display: 'inline-block', margin: 'auto' }}
                                count={GetPageCount()}
                                onChange={(e, v) => { setPage({ page: v - 1 }); }}
                            />
                        </Box>
                        {selectedPart !== null ? <PartsAllModal open={true} onClose={() => { SetSelectedPart(null) }} part={selectedPart} onUpdate={() => { console.log('on update') }} /> : ''}
                    </>
                }
            </MainContainer>

        </div>
    )
}

export default PartsAll;