import React from "react";

import { forwardRef } from "react";

const DynamicTable = forwardRef(({
  data,
  headerData,
},ref) => {
  const headers = headerData;

  // console.log(headers);

  return (
    <table ref={ref} className="w-full ">
      <thead className="bg-slate-100 h-10">
        <tr>
          {headers.map((header, index) => (
            <th
              className={`${
                index === 0
                  ? //if the index is 0, left corner is rounded
                    "rounded-tl-lg text-slate-700 text-xs font-medium text-left px-2"
                  : "text-slate-700 text-xs font-medium text-left px-2"
              }${index === headers.length - 1 ? "rounded-tr-lg" : ""}`}
              key={index}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr
            key={rowIndex}
            className="border-b hover:cursor-pointer bg-white hover:bg-slate-200 hover:ease-in-out "
          >
            {row.map((cell, cellIndex) => (
              <td
                key={cellIndex}
                className=" text-xs text-gray-700 pt-2 pb-2 pl-2"
              >
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
});

export default DynamicTable;
