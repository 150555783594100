import * as React from 'react';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ManufacturerIcon from '@mui/icons-material/Factory';
import ModelIcon from '@mui/icons-material/DriveFileRenameOutline';
import FlightIcon from '@mui/icons-material/Flight';
import FilterIconButton from '@mui/icons-material/FilterAlt';
import Button from '@mui/material/Button';
import { useReducer } from 'react';

const formReducer = (state, action) => {

    let _state = { ...state };
    Object.keys(action).forEach(k => {
        _state[k] = action[k];
    });

    return { ..._state };
}

const InitialFormState = { manufacturer: '', type: '', model: '' };

const AddPlatformPartModalFilter = ({ onApply }) => {

    const [filterState, filterDispatch] = useReducer(formReducer, InitialFormState);
    const formEmpty = () => { return Object.keys(filterState).filter(v => { return filterState[v] !== '' }).length === 0; }
    return (
        <>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <TextField
                    label="Part Type"
                    id="PartType"
                    sx={{ m: 1, width: '25ch' }}
                    size="small"
                    value={filterState.type}
                    onChange={(v) => { filterDispatch({ type: v.target.value }) }}
                    InputProps={{startAdornment: <InputAdornment position="start"><FlightIcon /></InputAdornment>}}
                />
                <TextField
                    label="Manufacturer"
                    id="Manufacturer"
                    sx={{ m: 1, width: '25ch' }}
                    size="small"
                    value={filterState.manufacturer}
                    onChange={(v) => { filterDispatch({ manufacturer: v.target.value }) }}
                    InputProps={{startAdornment: <InputAdornment position="start"><ManufacturerIcon /></InputAdornment>}}
                />
                <TextField
                    label="Model"
                    id="Model"
                    sx={{ m: 1, width: '25ch' }}
                    size="small"
                    value={filterState.model}
                    onChange={(v) => { filterDispatch({ model: v.target.value }) }}
                    InputProps={{startAdornment: <InputAdornment position="start"><ModelIcon /></InputAdornment>}}
                />
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Button variant="contained" endIcon={<FilterIconButton />} sx={{ m: 1, width: '25ch' }} size="small" disabled={formEmpty()} onClick={() => { onApply(filterState) }}>Apply</Button>
                <Button variant="contained" sx={{ m: 1, width: '25ch' }} size="small" color='secondary' disabled={formEmpty()} onClick={() => { onApply(InitialFormState); filterDispatch(InitialFormState) }}>Reset</Button>
            </Box>
        </>
    );
}

export default AddPlatformPartModalFilter;