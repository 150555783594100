const TASK_TYPE = {
    TASK: "Task",
    PLATFORM_TASK: "PlatformTask",
    PLATFORM_ADD_SOFTWARE_TASK: "PlatformAddSoftwareTask",
    PLATFORM_REMOVE_SOFTWARE_TASK: "PlatformRemoveSoftwareTask",
    PLATFORM_PART_TASK: "PlatformPartTask",
    PLATFORM_SCHEDULED_PART_TASK: "PlatformScheduledPartTask",
    PLATFORM_ADD_PART_TASK: "PlatformAddPartTask",
    PLATFORM_REPAIR_PART_TASK: "PlatformRepairPartTask",
    PLATFORM_REPLACE_PART_TASK: "PlatformReplacePartTask",
    PLATFORM_REMOVE_PART_TASK: "PlatformRemovePartTask"
};

const PRIORITY_LEVEL = [
    "Low",
    "Medium",
    "High"
];

const STATUS_LIST = {

    TASK_STATUS_TODO: "TODO",
    TASK_STATUS_PENDING: "PENDING",
    TASK_STATUS_IN_PROGRESS: "IN_PROGRESS",
    TASK_STATUS_DONE: "DONE",
    TASK_STATUS_AWAITING_PART: "AWAITING_PART",

    PART_STATUS_REQUESTED: "REQUESTED",
    PART_STATUS_PROCESSING: "PROCESSING",
    PART_STATUS_ORDER_REQUESTED: "PROCESSING_ORDER_REQUEST",
    PART_STATUS_IN_TRANSIT: "IN_TRANSIT",
    PART_STATUS_READY: "READY"
};

class Task {
    constructor() {
        this.title = "";
        this.description = "";
        this.notes = "";
        this.dueDate = null;
        this.isApproved = false;
        this.comments = [];
        this.children = [];
        this.assignee = null;
        this.reporter = null;
        this.priority = 0;
        this.subscribers = [];
        this.type = TASK_TYPE.TASK;
        this.status = "TODO";
        this.statusList = ["PENDING", "TODO", "IN_PROGRESS", "DONE"];
    }

    Assign(user) { this.assignee = user; }
    SetPriority(val) { this.priority = val; }
    SetDescription(val) { this.description = val; }
    SetReporter(val) { this.reporter = val; }
    SetNotes(val) { this.notes = val; }
    SetStatus(val) {
        if (!this.statusList.includes(val))
            throw Error(`${val} is invalid status`);
        this.status = val;
    }
    AddComment(user, text) {
        this.comments.push({ user: user, text: text });
    }
}

class PlatformTask extends Task {
    constructor(platformID) {
        super();
        this.type = TASK_TYPE.PLATFORM_TASK;
        this.platformID = platformID;
    }
}

class PlatformAddSoftwareTask extends PlatformTask {
    constructor(platformID, softwareID) {
        super(platformID);
        this.type = TASK_TYPE.PLATFORM_ADD_SOFTWARE_TASK;
        this.softwareID = softwareID;
    }
}

class PlatformRemoveSoftwareTask extends PlatformTask {
    constructor(platformID, softwareID) {
        super(platformID);
        this.type = TASK_TYPE.PLATFORM_REMOVE_SOFTWARE_TASK;
        this.softwareID = softwareID;
    }
}

class PlatformPartTask extends PlatformTask {
    constructor(platformID, part) {
        super(platformID);
        this.type = TASK_TYPE.PLATFORM_PART_TASK;
        this.part = part;
    }
}

class PlatformRepairPartTask extends PlatformPartTask {
    constructor(platformID, part) {
        super(platformID, part);
        this.type = TASK_TYPE.PLATFORM_REPAIR_PART_TASK;
    }
}

class PlatformReplacePartTask extends PlatformPartTask {
    constructor(platformID, part) {
        super(platformID, part);
        this.type = TASK_TYPE.PLATFORM_REPLACE_PART_TASK;
        this.status = "PENDING";
        this.statusList = ["PENDING", "AWAITING_PART", "TODO", "IN_PROGRESS", "DONE"];
        this.partRequest = {
            status: "REQUESTED",
            statusList: ["REQUESTED", "PROCESSING_ORDER_REQUEST", "PROCESSING", "IN_TRANSIT", "READY"],
            serial: null
        }
    }
}

class PlatformRemovePartTask extends PlatformPartTask {
    constructor(platformID, part) {
        super(platformID, part);
        this.type = TASK_TYPE.PLATFORM_REMOVE_PART_TASK;
    }
}


class PartRequest {
    constructor() {
        this.status = "REQUESTED";
        this.serial = null;
        this.statusList = ["REQUESTED", "PROCESSING_ORDER_REQUEST", "PROCESSING", "IN_TRANSIT", "READY"];
    }

    AssignSerial(serial){ this.serial = serial; }
    SetStatus(status) { this.status = status; }
}


class PlatformAddPartTask extends PlatformPartTask {
    constructor(platformID, part) {
        super(platformID, part);
        this.type = TASK_TYPE.PLATFORM_ADD_PART_TASK;
        this.status = "PENDING";
        this.statusList = ["PENDING", "AWAITING_PART", "TODO", "IN_PROGRESS", "DONE"];
        /*
        this.partRequest = {
            status: "REQUESTED",
            statusList: ["REQUESTED", "PROCESSING_ORDER_REQUEST", "PROCESSING", "IN_TRANSIT", "READY"],
            serial: null
        }*/
        this.partRequest = new PartRequest();
    }
}

/*
class PartRequestRelocate extends PartRequest{
    constructor() {
        super();
        this.origin = null;
        this.partAssignedBy = null;
    }
}*/

class PartRequestAssign extends PartRequest{
    constructor() {
        super();
        this.origin = null;
        this.partAssignedBy = null;
    }

    SetOrigin(origin) {this.origin = origin; }
    SetAssignedBy(user) {this.partAssignedBy = user; }
}

class PartRequestOrderNew extends PartRequest{
    constructor() {
        super();
        this.partOrderRequestBy = null;
        this.partOrderMadeBy = null;
        this.orderDate = null;
    }

    SetOrderRequestedBy(user){ this.partOrderRequestBy = user; }
    SetOrderMadeBy(user){ this.partOrderMadeBy = user; }
    SetOrderDate(date){ this.orderDate = date; }
}






class PlatformScheduledPartTask extends PlatformPartTask {
    constructor(platformID, part) {
        super(platformID, part);
        this.type = TASK_TYPE.PLATFORM_SCHEDULED_PART_TASK;
    }
}

export {
    PlatformTask,
    PlatformAddSoftwareTask,
    PlatformRemoveSoftwareTask,
    PlatformPartTask,
    PlatformRepairPartTask,
    PlatformReplacePartTask,
    PlatformRemovePartTask,
    PlatformAddPartTask,
    PlatformScheduledPartTask,
    Task,
    PartRequest,
    PartRequestAssign,
    PartRequestOrderNew,
    TASK_TYPE,
    PRIORITY_LEVEL,
    STATUS_LIST
}