import { API } from "../config";
import { GET, POST, DELETE, PATCH, FORM_POST } from "./Methods";

const Platforms = (query) => {
  return GET(API.PLATFORMS(query));
};

const Platform = (id) => {
  return GET(API.PLATFORM(id));
};

// const PlatformHistory = (id) => {
//     return GET(API.PLATFORM_HISTORY(id));
// }

const PlatformDocuments = (id) => {
  return GET(API.PLATFORM_DOCUMENTS(id));
};

const PlatformSoftware = (id) => {
  return GET(API.PLATFORM_SOFTWARE(id));
};

const AddPlatformSoftware = (id, doc) => {
  return POST(API.PLATFORM_SOFTWARE(id), doc);
};

const RemovePlatformSoftware = (id, softwareId) => {
  return DELETE(API.PLATFORM_SOFTWARE_INDIVIDUAL(id, softwareId), {});
};

const UpdatePlatformSoftware = (id, softwareId, doc) => {
  return PATCH(API.PLATFORM_SOFTWARE_INDIVIDUAL(id, softwareId), doc);
};

const CreatePlatformDocument = (id, body) => {
  return FORM_POST(API.PLATFORM_DOCUMENTS(id), body);
};

const CreatePlatform = (Platform) => {
  return POST(API.PLATFORMS(), Platform);
};

const RemovePlatform = (id) => {
  return DELETE(API.PLATFORM(id), {});
};

const UpdatePlatform = (id, body) => {
  return PATCH(API.PLATFORM(id), body);
};

//export { Platform, Platforms, PlatformHistory, PlatformDocuments, CreatePlatform, RemovePlatform, UpdatePlatform }
export {
  Platform,
  Platforms,
  PlatformDocuments,
  PlatformSoftware,
  AddPlatformSoftware,
  RemovePlatformSoftware,
  UpdatePlatformSoftware,
  CreatePlatformDocument,
  CreatePlatform,
  RemovePlatform,
  UpdatePlatform,
};
