import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import PlatformIndividualParts from "./tabs/PlatformIndividualParts";
import PlatformIndividualTasks from "./tabs/PlatformIndividualTasks";
import PlatformIndividualHistory from "./tabs/PlatformIndividualHistory";
import PlatformIndividualDocuments from "./PlatformIndividualDocuments";

import UserSoftware from "./tabs/software/UserSoftware";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PATHS } from "../../config";
import { platformActions } from "../../store";

/*
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
*/
import PlatformIndividualHeader from "./PlatformIndividualHeader";
import PlatformIndividualTabs from "./PlatformIndividualTabs";
import { SUB_PATHS } from "../../config";

import { Platform as GetPlatform } from "../../services/Platforms";
import { NavigationTab } from "../../components/NavigationTab";
import {
  CloseIcon,
  CogIcon,
  ComputerIcon,
  DocumentIcon,
  HistoryIcon,
  ListIcon,
} from "../../components/Icons";

/*
const PLATFORM_TABS = [
    "parts",
    "tasks",
    "history"
];

const PlatformIndividualTabs = ({activeTab, onTabChange})=> {

    return (
        <>
            <Box sx={{borderBottom: 1, borderColor:'divider'}}>
                <Tabs value={activeTab} onChange={(evt, v)=>{onTabChange(v)}} aria-label="basic tabs example">
                    {PLATFORM_TABS.map((t, i)=>{ return (<Tab key={i} label={t} {...{ id: `simple-tab-${i}`, 'aria-controls': `simple-tabpanel-${i}`}} />)})}
                </Tabs>
            </Box>
        </>
    )
}*/

const PlatformIndividual = () => {
  const [activeTab, setActiveTab] = useState(0);
  const platformData = useSelector((state) => state.platform);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    const URL = location.pathname.split("/");
    SUB_PATHS.PLATFORM_TABS.forEach((t, i) => {
      if (URL.includes(t)) setActiveTab(i);
    });
  }, [location.pathname]);

  //    console.log(platformData);

  useEffect(() => {
    //        const platformID = location.pathname.split('/')[2];
    const platformID = location.pathname.split("/")[3];
    if (platformData.platform === null) {
      GetPlatform(platformID)
        .then((r) => {
          return r.json();
        })
        .then((result) => {
          dispatch(platformActions.setPlatform(result));
          setRefresh(false);
        });
    } else {
      setRefresh(false);
    }
  }, []);

  const changeTab = (tab) => {
    console.log(tab);
    setActiveTab(tab);
    //        console.log(`${PATHS.PLATFORMS}/${platformData.platform._id}/${SUB_PATHS.PLATFORM_TABS[tab]}`);
    //        navigate(`${PATHS.PLATFORMS}/${platformData.platform._id}/${SUB_PATHS.PLATFORM_TABS[tab]}`);
    //        console.log(`${SUB_PATHS.PLATFORM_TABS[tab]}`);
    navigate(`${SUB_PATHS.PLATFORM_TABS[tab]}`);
  };

  return (
    <>
      {!refresh ? (
        <>
          <div className="pt-8" style={{ backgroundColor: "#F1F1F0" }}>
            <PlatformIndividualHeader platformData={platformData} />

            {/* <PlatformIndividualTabs
              tabs={SUB_PATHS.PLATFORM_TABS}
              activeTab={activeTab}
              onTabChange={changeTab}
            /> */}

            <div className="max-w-7xl m-auto translate-y-4">
              <div className="mt-4">
                <NavigationTab
                  name={"Parts"}
                  icon={() => <CogIcon className="w-4 h-4" />}
                  active={activeTab === 0}
                  first={true}
                  onClick={() => {
                    changeTab(0);
                  }}
                />
                <NavigationTab
                  name={"Tasks"}
                  icon={() => <ListIcon className="w-4 h-4" />}
                  active={activeTab === 1}
                  onClick={() => {
                    changeTab(1);
                  }}
                />
                <NavigationTab
                  name={"History"}
                  icon={() => <HistoryIcon className="w-4 h-4" />}
                  active={activeTab === 2}
                  onClick={() => {
                    changeTab(2);
                  }}
                />
                <NavigationTab
                  name={"Documents"}
                  icon={() => <DocumentIcon className="w-4 h-4" />}
                  active={activeTab === 3}
                  onClick={() => {
                    changeTab(3);
                  }}
                />
                <NavigationTab
                  name={"Software"}
                  icon={() => <ComputerIcon className="w-4 h-4" />}
                  active={activeTab === 4}
                  last={true}
                  onClick={() => {
                    changeTab(4);
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <Routes>
              <Route
                path={SUB_PATHS.PLATFORM_TABS[0] + "/*"}
                element={<PlatformIndividualParts />}
              />
              <Route
                path={SUB_PATHS.PLATFORM_TABS[1] + "/*"}
                element={<PlatformIndividualTasks />}
              />
              <Route
                path={SUB_PATHS.PLATFORM_TABS[2] + "/*"}
                element={<PlatformIndividualHistory />}
              />
              <Route
                path={SUB_PATHS.PLATFORM_TABS[3] + "/*"}
                element={<PlatformIndividualDocuments />}
              />
              <Route
                path={SUB_PATHS.PLATFORM_TABS[4] + "/*"}
                element={<UserSoftware />}
              />
            </Routes>
          </div>
        </>
      ) : (
        "...loading"
      )}
    </>
  );
};

export default PlatformIndividual;
