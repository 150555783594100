const EFR_STATUS = 
{
    NONE: "none",
    ACTIVE: "active",
    RESOLVED: "resolved",
    // INACTIVE: "inactive",
}

class EFR 
{
    constructor(efr={})
    {
        if(efr._id)
            this._id = efr._id;

        this.platformID = efr.platformID || null;
        this.incidentRef = efr.incidentRef || null;
        this.status = efr.status || EFR_STATUS.NONE;
        this.occuredOnjob = efr.occuredOnjob || '';
        this.incidentDate = efr.incidentDate || new Date();
//        this.incidentTime = efr.incidentTime || new Date();
        this.reportedDate = efr.reportedDate || new Date();
//        this.reportedTime = efr.reportedTime || new Date();
        this.personInvolved = efr.personInvolved || null;
        this.reporter = efr.reporter || null;
        this.details = efr.details || '';
        this.injuries = efr.injuries || '';
        this.actionTaken = efr.actionTaken || '';
        this.additionalNotes = efr.additionalNotes || '';
        this.extRef = efr.extRef || null;

    }
}

export { EFR, EFR_STATUS };