import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Tag from '@mui/icons-material/Tag';
import { LocalShippingTwoTone } from '@mui/icons-material';
import { LocationOnTwoTone } from '@mui/icons-material';
import BatteryCharging80TwoToneIcon from '@mui/icons-material/BatteryCharging80TwoTone';
import VideocamTwoToneIcon from '@mui/icons-material/VideocamTwoTone';
import SportsEsportsTwoToneIcon from '@mui/icons-material/SportsEsportsTwoTone';
import HandymanTwoToneIcon from '@mui/icons-material/HandymanTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';


const GetTypeIcon = (type) => {
    const size = { width: 17, height: 17 };
    if (type === "BATTERY")
        return <BatteryCharging80TwoToneIcon sx={size} />
    if (type === "FLIGHT_CONTROLLER")
        return <SportsEsportsTwoToneIcon sx={size} />
    if (type === "CAMERA")
        return <VideocamTwoToneIcon sx={size} />
    if (type === "HARDWARE")
        return <HandymanTwoToneIcon sx={size} />
    
    return <SettingsTwoToneIcon sx={size} />
}

const PartsAllCard = ({ part, select, showStock=true, highlight=false }) => {
    return (
        <>
            <Card sx={{ display: 'flex', marginTop: 0.8, padding: 1, paddingRight: 2, paddingLeft: 2, backgroundColor:(highlight ? '#ccc' : '') }} onClick={() => {select(part) }}>
                <Box sx={{ flexDirection: 'column' }}>
                    <Box sx={{ display:'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Box sx={{marginTop:0.4, marginRight:0.7}}>
                            {GetTypeIcon(part.type)}
                        </Box>
                        <Box>
                            <Typography sx={{ fontSize: 15 }} color="text.primary">{part.name}</Typography>
                        </Box>
                    </Box>
                    <Typography sx={{ fontSize: 12 }} color="text.secondary">{part.manufacturer}: {part.model}</Typography>
                    <Typography sx={{ fontSize: 12 }} color="text.secondary">{part.type}</Typography>
                </Box>
                <Box sx={{ flexDirection: 'column', marginLeft: 'auto', textAlign: 'right', display:( showStock ? '' : 'none') }}>
                    <Typography sx={{ fontSize: 13 }} color="text.secondary">Inventory <Tag sx={{ width: 12, height: 13 }} />: <b>{part.stock.reduce((total, current) => { return total + current.items.length }, 0)}</b></Typography>
                    <Typography sx={{ fontSize: 13 }} color="text.secondary">Locations <LocationOnTwoTone sx={{ width: 12, height: 13 }} />: <b>{part.stock.length}</b></Typography>
                    <Typography sx={{ fontSize: 13 }} color="text.secondary">Suppliers <LocalShippingTwoTone sx={{ width: 12, height: 13 }} />: <b>{part.suppliers.length}</b></Typography>
                </Box>
            </Card>
        </>
    )
}

export default PartsAllCard;