//import * as React from 'react';
//import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Logout } from '@mui/icons-material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 250,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 5,
    pt: 2,
    px: 4,
    pb: 3
};

const LogoutModal = ({ open, onClose, onLogOut }) => {

    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                sx={{outline:0}}
                disableEnforceFocus={true}
                disableAutoFocus={true}                
            >
                <Box sx={style}>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'center' }}>
                        <Logout />
                        <Typography sx={{ textAlign: 'center', marginLeft:1 }} variant='h6'>Log out?</Typography>
                    </Box>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'center', marginTop:15 }}>
                        <Button variant='outlined' sx={{ marginLeft: 1, marginRight: 1 }} color='secondary' onClick={onClose}>Cancel</Button>
                        <Button variant='outlined' sx={{ marginLeft: 1, marginRight: 1 }} onClick={onLogOut}>Log Out</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default LogoutModal;