import * as React from 'react';
import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
import { Divider, Typography, Paper, Pagination, Accordion, AccordionSummary, AccordionDetails, TextField, InputLabel, Select, MenuItem, FormHelperText, FormControl } from '@mui/material';
import FilterIconButton from '@mui/icons-material/FilterAlt';
import Button from '@mui/material/Button';
import { useEffect, useState, useReducer } from 'react';
// import { Locations as GetLocations } from "../../../../services/Locations";
import { DefaultReducer } from '../../../../components/DefaultReducer';
import PlatformIncidentReportModal from './PlatformIncidentReportModal';
import { Efrs as GetEFR } from '../../../../services/Efrs';
import { AddEfr, UpdateEfr } from '../../../../services/Efrs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PlatformIncidentReportCard } from './PlatformincidentReportCard';
import { useDispatch, useSelector } from 'react-redux';
import FilterIcon from '@mui/icons-material/TuneOutlined';
import { EFR_STATUS } from '../../../../models/EFRModel';

const IncidentFilter = ({ onApply, onFileReport }) => {

    const InitialFormState = { from: '', to: '', status: '' };
    const [filterState, filterDispatch] = useReducer(DefaultReducer, InitialFormState);

    const IsFormChanged = () => {
        let empty = true;
        Object.keys(filterState).forEach(k => {
            if (filterState[k] !== InitialFormState[k])
                empty = false;
        });
        return empty;
    }

    const getQuery = () => {
        let r = {};
        Object.keys(filterState).forEach(k => {
            if (filterState[k] !== '')
                r[k] = filterState[k];
        });
        return r;
    }

    const isQuerySet = () => {
        let r = false;
        Object.keys(filterState).forEach(k => {
            if (filterState[k] !== '')
                r = true;
        });
        return r;
    }

    const queryReset = () => {
        filterDispatch(InitialFormState);
        onApply({});
    }

    return (

        <>
            <Paper sx={{ display: 'flex', flexDirection: 'row',  alignItems:'center' }} elevation={0}>

                <div style={{marginRight:14}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker label="from date" onChange={(v) => { filterDispatch({ from: new Date(v.$d).toISOString() }) }} slotProps={{ textField: { size: 'small' } }} />
                    </LocalizationProvider>
                </div>

                <div style={{marginRight:14}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker label="to date" onChange={(v) => { filterDispatch({ to: new Date(v.$d).toISOString() }) }} slotProps={{ textField: { size: 'small' } }} />
                    </LocalizationProvider>
                </div>

                <div style={{marginRight:14}}>
                    <FormControl sx={{width:200}}>
                        <InputLabel>Status</InputLabel>
                        <Select
                            required
                            value={filterState.status}
                            label="Status"
                            onChange={(v) => { filterDispatch({status: v.target.value}) }}
                            size='small'
                        >
                        {Object.keys(EFR_STATUS).map((k, i) => { return <MenuItem key={i} value={EFR_STATUS[k]}>{EFR_STATUS[k]}</MenuItem> })}
                        </Select>
                    </FormControl>
                </div>


            </Paper>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <div>
                    <Button id="filterApplyBtn" variant="contained" endIcon={<FilterIconButton />} sx={{ m: 1, width: '15ch' }} size="small" onClick={() => { onApply(getQuery()) }} disabled={!isQuerySet()}>
                        Apply
                    </Button>
                    {/* <Button id="filterResetBtn" variant="contained" sx={{ m: 1, width: '15ch' }} size="small" color='secondary' disabled={IsFormChanged()} onClick={() => { filterDispatch(InitialFormState) }}> */}
                    <Button id="filterResetBtn" variant="contained" sx={{ m: 1, width: '15ch' }} size="small" color='secondary' disabled={IsFormChanged()} onClick={queryReset}>
                        Reset
                    </Button>
                </div>
                {/* <Box sx={{ margin: 'auto' }}>
                    <Button variant='contained' color='secondary' onClick={onFileReport}>File Incident Report</Button>
                </Box> */}
            </Box>

        </>
    );
}

const PlatformIncidentReports = () => {

    const platform = useSelector(state => state.platform.platform);
    const [inputIncident, setInputIncident] = useState({ form: null });
    const [incidentReportList, setIncidentReportList] = useState({ total: 0, data: [] });
    const [query, setQuery] = useState({ limit: 10, offset: 0 });

    const updateIncidentReportList = (q) => {
        // GetEFR({ platformID: platform._id })
        GetEFR({ ...q, platformID: platform._id })
            .then(r => r.json())
            .then(r => {
                console.log(r);
                setIncidentReportList(r);
            });
    }

    useEffect(() => {
        updateIncidentReportList(query)
    }, []);


    const onApply = (efr) => {
        efr.platformID = platform._id;
        AddEfr(efr)
            .then(r => r.json())
            .then(r => {
                updateIncidentReportList(query);
                setInputIncident({ form: null });
            })
    }

    const onEFRUpdate = (efr) => {
        UpdateEfr(efr._id, efr)
            .then(r => r.json())
            .then(r => {
                updateIncidentReportList(query);
                setInputIncident({ form: null });
            })
    }

    const onPageChange = (e, v) => {
        let q = { ...query };
        q.offset = q.limit * (v - 1);
        setQuery(q);
        updateIncidentReportList(q);
    }

    const onDateFilter = (q) => {
        console.log(q);
        updateIncidentReportList(q);
        //        console.log(new Date(q.from).toISOString());
    }

    return <>
        <Accordion sx={{ marginTop: 1 }}>
            <AccordionSummary
                expandIcon={<FilterIcon />}
            // aria-controls="panel1a-content"
            // id="platformFilterHeader"
            >
                <Typography>EFR's ({incidentReportList.total})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <IncidentFilter onApply={onDateFilter} onFileReport={() => { setInputIncident({ form: 'new' }) }} />

            </AccordionDetails>
        </Accordion>
        <Box sx={{ marginTop: 1, marginBottom: 1 }}>
            <Button variant='contained' color='secondary' onClick={() => { setInputIncident({ form: 'new' }) }}>File Incident Report</Button>
        </Box>

        <Divider sx={{ marginTop: 1 }} />

        {incidentReportList.total === 0 ?
            <Box sx={{ marginTop: 2 }}>
                <Typography sx={{ textAlign: 'center', fontSize: 18 }}>No Reports found</Typography>
            </Box> :
            <>
                {/* <div>Total: {incidentReportList.total}</div> */}
                {incidentReportList.data.map((r, i) => <PlatformIncidentReportCard efr={r} key={i} onClick={() => { setInputIncident({ form: r }) }} />)}
                <div style={{ display: 'flex', marginTop: 20 }}>
                    <Pagination count={Math.floor(incidentReportList.total / query.limit) + 1} onChange={onPageChange} sx={{ margin: 'auto' }} />
                </div>
            </>
        }
        {inputIncident.form ? <PlatformIncidentReportModal formState={inputIncident.form} onClose={() => { setInputIncident(false) }} onApply={onApply} onUpdate={onEFRUpdate} /> : ''}

    </>
}

export default PlatformIncidentReports;