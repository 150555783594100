import { useState, useEffect, useReducer } from "react";
import { Modal, TextField, Button, Box, Divider, Card } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
//import { addPart, updatePart } from "../../../../services/Parts";
import { Typography, MenuItem } from "@mui/material";
import { UpdatePart, CreatePart } from "../../../../services/Parts";
import AdminStockModal from "./AdminStockModal";
import { Part, PART_TYPE, PlatformPart, ServiceInterval } from "../../../../models/PartsModel";

//import { Part, ServiceInterval } from "../../../../models/PartsModel";
//import { DefaultReducer } from '../../../../components/DefaultReducer';
//import {  } from "../../../../models/PartsModel";



const AdminNewPartModal = ({ onClose, updatePartsList, part = new Part(), update = false }) => {



    //    const [partState, setPartState] = useState(new Part());
    const [partState, setPartState] = useState(part);


    const MODAL_STATE = {
        NONE: "NONE",
        ADD_STOCK: "ADD_STOCK",
        EDIT_STOCK: "EDIT_STOCK",
    }

    const [stockModal, setStockModal] = useState({ modal: MODAL_STATE.NONE, data: null });




    const isStateValid = () => {
        let valid = true;
        const required = ['model', 'manufacturer', 'name', 'type'];
        required.forEach(k => {
            if (partState[k] === '')
                valid = false;
        });

        let sericeDurationSet = false;
        Object.keys(partState.serviceInterval.duration).forEach(k => {
            if (partState.serviceInterval.duration[k] !== 0)
                sericeDurationSet = true;
        });

        if (!sericeDurationSet)
            valid = false;

        if (partState.serviceInterval.afterFlightHours === 0) {
            valid = false;
        }

        return valid;
    }

    const addPart = () => {
        CreatePart(partState)
            .then(r => r.json())
            .then(r => {
                updatePartsList();
                onClose()
            });
    }

    const updatePart = () => {

        //        const update = new Part(partState);
        //        console.log(update);
        //        console.log(partState);
        //        return ;
        //        let temp = {...partState};
        //        delete temp._id;

        UpdatePart(partState._id, partState)
            .then(r => r.json())
            .then(r => {
                updatePartsList();
                onClose()
            });
    }

    const onAddStock = (stock) => {

        let s = { ...partState };
        if (stock.location) {
            let k = s.stock.find(p => (p.location === stock.location));
            if (k) {
                k.items = k.items.concat(stock.items);
            } else {
                s.stock.push(stock);
            }
            setPartState(s);
        }
        setStockModal({ modal: MODAL_STATE.NONE });
    }

    const onUpdateStock = (stock) => {
        let s = { ...partState };
        s.stock = s.stock.map(k => {
            if(k.location === stock.location)
                return stock;
            else
                return k;
        });

        setPartState(s);
        setStockModal({ modal: MODAL_STATE.NONE });
    }

    return (
        <>
            <Modal open={true} onClose={onClose}>
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    background: "white",
                    width: 700,
                }}>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>


                        {update ?
                            <Typography>Update database part</Typography> :
                            <Typography>Add New Part to database</Typography>
                        }


                        <CloseIcon sx={{ marginLeft: 'auto' }} color="text.secondary" onClick={onClose} />
                    </div>

                    <Divider />

                    <div style={{ padding: 10 }}>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ flexGrow: 1 }}>
                                <TextField
                                    label="Name"
                                    size="small"
                                    value={partState.name}
                                    onChange={(e) => {
                                        let s = { ...partState };
                                        s.name = e.target.value;
                                        setPartState(s)
                                    }
                                    }
                                    fullWidth
                                    margin="normal"
                                />
                            </div>
                            <div style={{ flexGrow: 1, paddingLeft: 10 }}>

                                <TextField
                                    label="Model"
                                    size="small"
                                    value={partState.model}
                                    onChange={(e) => {
                                        let s = { ...partState };
                                        s.model = e.target.value;
                                        setPartState(s)
                                    }
                                    }
                                    fullWidth
                                    margin="normal"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                            <div style={{ flexGrow: 1 }}>
                                <TextField
                                    label="Manufacturer"
                                    size="small"
                                    value={partState.manufacturer}
                                    onChange={(e) => {
                                        let s = { ...partState };
                                        s.manufacturer = e.target.value;
                                        setPartState(s)
                                    }
                                    }
                                    fullWidth
                                    margin="normal"
                                />
                            </div>
                            <div style={{ flexGrow: 1, paddingLeft: 10 }}>
                                <TextField
                                    select
                                    label="Type"
                                    size="small"
                                    value={partState.type}
                                    onChange={(e) => {
                                        let s = { ...partState };
                                        s.type = e.target.value;
                                        setPartState(s)
                                    }
                                    }
                                    fullWidth
                                    margin="normal"
                                >
                                    {Object.keys(PART_TYPE).map((k, i) => { return <MenuItem key={i} value={PART_TYPE[k]}>{PART_TYPE[k]}</MenuItem> })}
                                </TextField>

                            </div>
                        </div>

                        <Box sx={{ border: '1px solid #ccc', borderRadius: 1 }}>
                            <Box>

                                <Typography sx={{ textAlign: 'center', marginTop: 1 }}>Set Service Interval</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1 }}>

                                    <Typography color="text.secondary">Duration: </Typography>
                                    <TextField
                                        label="Hours"
                                        size="small"
                                        sx={{ flexGrow: 1, marginLeft: 1 }}
                                        value={partState.serviceInterval.duration.h}
                                        onChange={(e) => {
                                            let s = { ...partState };
                                            s.serviceInterval.duration.h = Number(e.target.value);
                                            setPartState(s)
                                        }
                                        }
                                    />
                                    <TextField
                                        label="Days"
                                        size="small"
                                        sx={{ flexGrow: 1, marginLeft: 1 }}
                                        value={partState.serviceInterval.duration.d}
                                        onChange={(e) => {
                                            let s = { ...partState };
                                            s.serviceInterval.duration.d = Number(e.target.value);
                                            setPartState(s)
                                        }
                                        }
                                    />
                                    <TextField
                                        label="Weeks"
                                        size="small"
                                        sx={{ flexGrow: 1, marginLeft: 1 }}
                                        value={partState.serviceInterval.duration.w}
                                        onChange={(e) => {
                                            let s = { ...partState };
                                            s.serviceInterval.duration.w = Number(e.target.value);
                                            setPartState(s)
                                        }
                                        }
                                    />
                                    <TextField
                                        label="Months"
                                        size="small"
                                        sx={{ flexGrow: 1, marginLeft: 1 }}
                                        value={partState.serviceInterval.duration.m}
                                        onChange={(e) => {
                                            let s = { ...partState };
                                            s.serviceInterval.duration.m = Number(e.target.value);
                                            setPartState(s)
                                        }
                                        }
                                    />
                                    <TextField
                                        label="Years"
                                        size="small"
                                        sx={{ flexGrow: 1, marginLeft: 1 }}
                                        value={partState.serviceInterval.duration.y}
                                        onChange={(e) => {
                                            let s = { ...partState };
                                            s.serviceInterval.duration.y = Number(e.target.value);
                                            setPartState(s)
                                        }
                                        }
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
                                    <Typography color="text.secondary">Flight hours: </Typography>

                                    <TextField
                                        label="Flight hours"
                                        size="small"
                                        sx={{ flexGrow: 1, marginLeft: 1 }}
                                        value={partState.serviceInterval.afterFlightHours}
                                        onChange={(e) => {
                                            let s = { ...partState };
                                            s.serviceInterval.afterFlightHours = Number(e.target.value);
                                            setPartState(s)
                                        }
                                        }
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{ border: '1px solid #ccc', borderRadius: 1, marginTop: 1 }}>
                            <Box>

                                <Typography sx={{ textAlign: 'center', marginTop: 1 }}>Stock</Typography>
                                <Box sx={{ padding: 1, height: 150, marginLeft: 1, marginRight: 1, backgroundColor: '#f5f5f5', overflow: 'auto' }}>

                                    {partState.stock.map((val, i) => {
                                        return (
                                            <Card key={i} sx={{ height: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 0.5 }} onClick={() => { setStockModal({ modal: MODAL_STATE.ADD_STOCK, data: val }) }}>
                                                <Typography color="text.secondary">{val.location}: {val.items.length}</Typography>
                                            </Card>
                                        )
                                    })}

                                    <Card sx={{ height: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} className="cursor-on-hover" onClick={() => { setStockModal({ modal: MODAL_STATE.ADD_STOCK }) }}>
                                        <Typography color="text.secondary">Add +</Typography>
                                    </Card>


                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1 }}>


                                </Box>
                            </Box>
                        </Box>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingTop: 10 }}>
                            <Button variant="outlined" sx={{ marginRight: 1 }} color="secondary" onClick={onClose}>cancel</Button>


                            {update ?
                                <Button variant="contained" disabled={!isStateValid()} onClick={updatePart}>update part</Button> :
                                <Button variant="contained" disabled={!isStateValid()} onClick={addPart}>add part</Button>
                            }
                        </div>
                    </div>
                </div>
            </Modal>
            {stockModal.modal === MODAL_STATE.ADD_STOCK ? <AdminStockModal onClose={() => { setStockModal({ modal: MODAL_STATE.NONE }) }} onAddStock={onAddStock} stock={stockModal.data} onUpdateStock={onUpdateStock} /> : null}
        </>
    );
}

export default AdminNewPartModal;