import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { AuthInterface } from "./AuthInterface";
// import * as AWS from 'aws-sdk/global';

const PoolData = {
    UserPoolId: process.env.REACT_APP_AUTH_USERPOOL_ID,
    ClientId: process.env.REACT_APP_AUTH_CLIENT_ID,
};

const UserPool = new AmazonCognitoIdentity.CognitoUserPool(PoolData);

const signUp = async (email, password, callback) => {
    UserPool.signUp(email, password, [], [], callback);
}

const getAccessToken = (email, password, onSuccess, onFailure) => {
    var authenticationData = {
        Username: email,
        Password: password,
    };

    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
    var userData = {
        Username: email,
        Pool: UserPool,
    };

    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, { onSuccess, onFailure });
}

const verifyUser = (email, code, callback) => {
    var userData = {
        Username: email,
        Pool: UserPool,
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.confirmRegistration(code, true, callback);
}

const resetPassword = (email, onSuccess, onFailure, inputVerificationCode) => {

    var userData = {
        Username: email,
        Pool: UserPool,
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.forgotPassword({onSuccess, onFailure, inputVerificationCode});
}

const confirmPassword = (email, verificationCode, newPassword, onSuccess, onFailure) => {

    var userData = {
        Username: email,
        Pool: UserPool,
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.confirmPassword(verificationCode, newPassword, {onSuccess,onFailure});
}

const signOut = (email, callback) => {

    var userData = {
        Username: email,
        Pool: UserPool,
    };

    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.signOut(callback);
}

const _refreshSession = (email, refreshToken, callback) => {

    var userData = {
        Username: email,
        Pool: UserPool,
    };

    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    let temp = new AmazonCognitoIdentity.CognitoRefreshToken({RefreshToken: refreshToken});

    cognitoUser.refreshSession(temp, (err, data) => {
        callback(err, data);
    })

    // cognitoUser.getSession((err, session)=>{
    //     console.log(err, session);
    // });

    // console.log(refreshToken);
//    cognitoUser.refreshSession( refreshToken, callback, cognitoUser);
//    cognitoUser.refreshSession( cognitoUser.getSession().getRefreshToken(), callback, cognitoUser);
//    cognitoUser.refreshSession( cognitoUser.getSession().getRefreshToken(), (err, session)=>{

    // cognitoUser.getSession((err, session)=>{
    //     console.log(err, session);
    // });

}


class CognitoAuth extends AuthInterface 
{
    constructor(store) {

        super();
        const update =  () => {
            this.setUserDetails(store.getState().auth.user);
            // this.setAccessToken(store.getState().auth.accessToken);
            this.setAccessToken(store.getState().auth.token);
            this.setRefreshToken(store.getState().auth.refreshToken);
        }
        store.subscribe(update);
        update();
    }

    logout = (callback) => {
        signOut(this.user.email, callback);
    }

    refreshSession = (callback) => {
        _refreshSession(this.user.email, this.getRefreshToken(), callback);
    }

    getAccessToken = getAccessToken;
    signUp = signUp;
    signOut = signOut;
}

export { CognitoAuth };