import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import {  } from "react-redux";

// import { AddDocument } from "../../services/Documents";
import { CreatePlatformDocument } from "../../services/Platforms";
// import { Platform as GetPlatform } from "../../services/Platforms";
// import { platformActions } from "../../store";


const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
    )
}

const UploadIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
        </svg>
    )
}

const CheckIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.3} stroke="currentColor" className="w-3 h-3">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
        </svg>
    );
}

const FileBadge = ({ name, onRemove }) => {
    return (
        <div className="bg-gray-200 text-gray-500 text-center justify-center items-center content-center px-2 py-0.5 border border-slate-300 shadow-sm rounded-sm font-medium flex flex-row">
            {name}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 hover:cursor-pointer" onClick={onRemove} >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </div>
    )
}

const ProgressBar = ({ percentage }) => {
    return (
        <div className="border rounded-full h-3 bg-slate-200">
            <div className="border rounded-full h-3 bg-green-500" style={{ width: `${percentage}%` }}></div>
        </div>
    )
}

const SuccessNotification = ({name}) => {
    return (
        <div className="bg-green-300 rounded-md ml-6 mr-6 pl-4 pr-4 pt-3 pb-3 flex flex-row">
            <div className="bg-green-600 rounded-full w-5 h-5 text-white text-center flex flex-col justify-center align-middle">
                <div className="flex flex-row justify-center">
                    <CheckIcon />
                </div>
            </div>
            <div className="pl-4 text-green-800 font-medium text-sm">
                Successfully uploaded {name}
            </div>
        </div>
    )
}

const UPLOAD_STATE = {
    NONE: "none",
    IN_PROGRESS: "in_progress",
    SUCCESS: "success",
    FAIL: "fail",
};

const PlatformDocumentUploadModal = ({ onClose, onDocumentUpload }) => {


    const [fileList, setFileList] = useState([]);
    const [uploadState, setUploadState] = useState(UPLOAD_STATE.NONE);
    const [uploadedFiles, setUploadedFiles] = useState("");
    const platform = useSelector(state => state.platform.platform);
    const [hoverFileState, sethoverFileState] = useState(false);

    /*****  progress bar animation  ******/
    const [progress, setProgress] = useState(0);
    const animateProgress = (p) => {
        if (p < 100) {
            setProgress(p + 2);
            setTimeout(() => { animateProgress(p + 2) }, 17);
        }
    }
    useEffect(()=>{
        if(uploadState === UPLOAD_STATE.IN_PROGRESS)
            animateProgress(0);
    },[uploadState]);
    /********************************** */

    const appendFile = (file) => {
        let list = [...fileList];
        list.push(file);
        setFileList(list);
        setUploadState(UPLOAD_STATE.NONE);
    }

    const removeFile = (file) => {
        let list = [...fileList].filter((f) => { return (f.name !== file.name) });
        setFileList(list);
    }

    const uploadFiles = () => {
        const formData = new FormData();
        formData.append('file', fileList[0]);
        setUploadState(UPLOAD_STATE.IN_PROGRESS);

        // AddDocument(formData)
        CreatePlatformDocument(platform._id, formData)
            .then(r => r.json())
            .then(r => {
                console.log(r);
                setUploadState(UPLOAD_STATE.SUCCESS);
                setUploadedFiles(fileList[0].name);
                setFileList([]);
                onDocumentUpload();
                // refreshPlatform();
            })
            .catch(err => {
                console.log(err);
                setUploadState(UPLOAD_STATE.FAIL);
            })
    }

    return (
        <>
            <Modal
                open={true}
                sx={{ outline: 0 }}
                disableEnforceFocus={true}
                disableAutoFocus={true}
                onClose={onClose}
            >
                <div className="absolute w-128 h-78 bg-white top-1/2 left-1/2 -translate-y-64 -translate-x-64 p-3">
                    <div className="block">
                        <div className="ml-auto mr-0 w-7 hover:cursor-pointer" onClick={onClose}>
                            <CloseIcon />
                        </div>
                        <div className="text-sm font-medium text-slate-800">
                            Select file
                        </div>
                        <div className={`border border-dashed border-blue-500 rounded-sm mt-2 ${hoverFileState ? 'bg-blue-200' : ''}`}
                            onDrop={(evt)=>{
                                appendFile(evt.dataTransfer.files[0])                              
                                evt.preventDefault();
                                sethoverFileState(false);
                            }}
                            onDragEnter={(t)=>{t.preventDefault(); sethoverFileState(true)}} 
                            onDragOver={(t)=>{t.preventDefault(); sethoverFileState(true)}} 
                            onDragLeave={(t)=>{t.preventDefault(); sethoverFileState(false)}} 
                        >
                            <div 
                                className={`flex flex-row justify-center mt-10 mb-10 ${hoverFileState ? 'bg-blue-200' : ''}`}
                            >
                                <div className="flex flex-col justify-center">
                                    <div className="mx-auto text-blue-600">
                                        <UploadIcon />
                                    </div>
                                    <div className="mt-2 text-slate-500">Drag & drop or <span className="underline text-blue-600 hover:cursor-pointer" onClick={() => { document.getElementById("file-upload-source").click() }}>browse</span></div>
                                </div>
                            </div>
                        </div>



                        <input type="file" name="file" id="file-upload-source" style={{ display: 'none' }} onChange={(e) => {
                            if (e.target.files && e.target.files[0])
                                appendFile(e.target.files[0])
                        }} />


                        {fileList.length > 0 ? 
                        <div className="placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-2 pr-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm flex flex-wrap mt-2 ">
                            {fileList.map((f, i) => <FileBadge key={i} name={f.name} onRemove={() => { removeFile(f) }} />)}
                        </div> : null}


                        {uploadState === UPLOAD_STATE.IN_PROGRESS ? 
                        <div className="mt-2">
                            <ProgressBar percentage={progress} />
                        </div> : null }

                        {uploadState === UPLOAD_STATE.SUCCESS ? 
                        <div className="mt-4">
                            <SuccessNotification name={uploadedFiles}/>
                        </div> : null}

                        <button
                            type="file"
                            className="m-auto mt-5 mb-2 flex justify-center items-center content-center rounded-full bg-white border border-blue-600 px-4 py-2 text-md font-semibold leading-4 text-gray-100 shadow-sm hover:bg-blue-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            onClick={uploadFiles}
                        >
                            <div className="text-sm text-blue-600">Upload file</div>
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default PlatformDocumentUploadModal;