import { Pagination } from "@mui/material";
import PlatformIndividualDocumentCard from "./PlatformIndividualDocumentCard";
import PlatformDocumentUploadModal from "./PlatformDocumentUploadModal";
import PlatformDocumentDetailModal from "./PlatformDocumentDetailModal";
import { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { PlatformDocuments } from "../../services/Platforms";
import { GetDocument } from "../../services/Documents";
import { DefaultReducer } from "../../components/DefaultReducer";
import { MainContainer } from "../../components/Containers";

const TextFieldInput = ({ name = "", placeholder = "", onChange, value }) => {
  return (
    <div>
      <div className="text-xs font-medium pb-2">{name}</div>
      <input
        className="placeholder:text-slate-500 placeholder:font-medium block placeholder:text-xs bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm "
        placeholder={placeholder}
        type="text"
        name="search"
        value={value}
        onChange={(e) => {
          let update = {};
          update[name] = e.target.value;
          onChange(update);
        }}
      />
    </div>
  );
};

const FilterParam = ({ name, onRemove }) => {
  return (
    <div
      className="bg-gray-200 text-gray-500 text-center justify-center items-center content-center px-1.5 py-0.5 border border-slate-300 shadow-sm rounded-sm font-medium text-xs flex flex-row mr-1"
      onClick={() => {
        onRemove(name);
      }}
    >
      {name}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-4 h-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </div>
  );
};

const PlatformDocumentsFilter = ({ onFilter, onUpload }) => {
  const [query, setQuery] = useState({});
  const InitialFormState = { Name: "", Author: "", Date: "" };
  const [formState, formStateDispatch] = useReducer(
    DefaultReducer,
    InitialFormState
  );

  const removeFilterParam = (name) => {
    let q = { ...query };
    let t = { ...InitialFormState };

    Object.keys(query).forEach((k) => {
      if (query[k] === name) delete q[k];
    });

    Object.keys(t).forEach((k) => {
      if (q[k]) t[k] = q[k];
    });

    // console.log(q);
    setQuery(q);
    formStateDispatch(t);
  };

  return (

    <MainContainer>

      {/* <div className="platform-individual-document-container"> */}
      <div className="grid grid-cols-3 gap-4">
        <div>
          <TextFieldInput
            name={"Name"}
            placeholder={"Document name"}
            onChange={formStateDispatch}
            value={formState.Name}
          />
        </div>
        <div>
          <TextFieldInput
            name={"Author"}
            placeholder={"Author name"}
            onChange={formStateDispatch}
            value={formState.Author}
          />
        </div>
        <div>
          <TextFieldInput
            name={"Date"}
            placeholder={"Document date"}
            onChange={formStateDispatch}
            value={formState.Date}
          />
        </div>

        <div className="col-span-2">
          <div className="text-xs font-medium pb-2">Filter Params</div>
          <div className="placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-1 pl-2 pr-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm flex h-8">
            {/* 
                        <div className="bg-gray-200 text-gray-500 text-center justify-center items-center content-center px-1.5 py-0.5 border border-slate-300 shadow-sm rounded-sm font-medium text-xs flex flex-row">
                            Doc Name
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        */}

            {Object.keys(query).map((k, i) => (
              <FilterParam
                name={query[k]}
                key={i}
                onRemove={removeFilterParam}
              />
            ))}
            {/* FilterParam */}
          </div>
        </div>

        <div className="flex flex-col justify-end">
          <button
            type="submit"
            className="m-auto mr-0 mb-0 flex justify-center items-center content-center rounded-full bg-green-500 px-5 py-2 text-md font-semibold leading-4 text-gray-100 shadow-sm hover:bg-green-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            onClick={() => {
              // console.log(Object.keys(formState));
              // console.log(Object.keys(query));

              // let q = { ...formState };
              let q = { ...query };
              Object.keys(formState).forEach((k) => {
                if (formState[k] !== "") {
                  if (formState[k] !== query[k]) {
                    q[k] = formState[k];
                  }
                }
              });

              // console.log( q );

              // Object.keys(query).forEach(k => {
              //     if((q[k] !== query[k]))
              //         q[k] = query[k];
              // });

              // // console.log( q);

              setQuery(q);
              // formStateDispatch(InitialFormState);
              // onFilter(query)
              onFilter(q);
            }}
          >
            <div className="text-xs font-medium">Apply Filter</div>
          </button>
        </div>
      </div>

      <div className="border-b py-4"></div>

      <div className="pt-4 flex justify-center items-center content-center text-center">
        <button
          type="file"
          className="flex justify-center items-center content-center rounded-full bg-purple-500 px-4 py-2 text-md font-semibold leading-4 text-gray-100 shadow-sm hover:bg-purple-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          // onClick={() => { document.getElementById("document-upload-source").click() }}
          onClick={onUpload}
        >
          <div className="text-xs font-medium">Upload Document</div>
        </button>
      </div>
      {/* </div> */}
    </MainContainer>

  );
};

// const PlatformDocumentListHeader = () => {
//     return (
//         <div className="platform-individual-document-card header h-10" >
//             <div className="platform-individual-document-card header col col-first col1 ">TYPE</div>
//             <div className="platform-individual-document-card header col col4">NAME</div>
//             <div className="platform-individual-document-card header col col4">AUTHOR</div>
//             <div className="platform-individual-document-card header col col2"></div>
//             <div className="platform-individual-document-card header col col2"></div>
//         </div>
//     )
// }

const MODAL_STATE = {
  NONE: "none",
  UPLOAD: "upload",
  DOC_INFO: "doc_info",
};

const PlatformIndividualDocuments = () => {
  const [modalState, setModalState] = useState({
    modal: MODAL_STATE.NONE,
    data: null,
  });
  const [documents, setDocuments] = useState([]);
  const [query, setQuery] = useState({});
  const platform = useSelector((state) => state.platform.platform);

  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const NUM_PER_PAGE = 10;

  const parseResponse = (res) => {
    return res.documents.map((r) => {
      let t = { ...r };
      t.createdBy = res.userInfo.find((x) => x.user_id === r.createdBy);
      return t;
    });
  };

  const getPlatformDocuments = () => {
    PlatformDocuments(platform._id)
      .then((r) => r.json())
      .then((r) => {
        setDocuments(parseResponse(r));
      });
  };

  const onDocumentUpload = () => {
    getPlatformDocuments();
  };

  useEffect(() => {
    getPlatformDocuments();
  }, []);

  const DocRow = ({ doc }) => {
    const download = (e) => {
      e.stopPropagation();
      GetDocument(doc._id)
        .then((r) => r.blob())
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = doc.name;
          a.click();
        });
    };

    const view = (e) => {
      e.stopPropagation();
      GetDocument(doc._id)
        .then((r) => r.blob())
        .then((data) => {
          let file = window.URL.createObjectURL(data, {
            type: "application/pdf",
          });
          document.querySelector("iframe").src = file;
        });
    };

    return (
      <tr
        className="border-b hover:cursor-pointer hover:bg-slate-100 hover:ease-in-out"
        onClick={() => {
          setModalState({ modal: MODAL_STATE.DOC_INFO, data: doc });
        }}
      >
        <td className="flex justify-center text-slate-500 pt-4 pb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
            />
          </svg>
        </td>
        <td>
          <div>
            <div className="text-sm font-semibold text-gray-700">
              {doc.name}
            </div>
            <div className="text-xs text-gray-500">
              Last updated: {new Date(doc.updated).toDateString()}
            </div>
          </div>
        </td>
        <td>
          <div>
            <div className="text-xs text-gray-500">{doc.createdBy.name}</div>
            <div className="text-xs text-gray-500">{doc.createdBy.email}</div>
          </div>
        </td>
        <td>
          <button
            type="submit"
            className="flex justify-center rounded-full bg-green-100 px-3 py-1.5 text-sm font-semibold leading-3 text-green-500 shadow-sm hover:bg-green-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:cursor-pointer"
          >
            {/* <div className="text-xs" onClick={(e) => {
                            e.stopPropagation();
                            console.log(doc);
                            GetDocument(doc._id)
                                .then(r => r.blob())
                                .then(data => {
                                    var a = document.createElement("a");
                                    a.href = window.URL.createObjectURL(data);
                                    a.download = doc.name;
                                    a.click();
                                });
                        }}>Download</div> */}
            <div className="text-xs" onClick={download}>
              Download
            </div>
          </button>
        </td>
        <td>
          {/* <div className="text-sm font-medium text-blue-600 hover:cursor-pointer" onClick={(e) => { e.stopPropagation(); console.log('view') }}>View</div> */}
          <div
            className="text-sm font-medium text-blue-600 hover:cursor-pointer"
            onClick={view}
          >
            View
          </div>
        </td>
      </tr>
    );
  };

  const CreateDocTable = () => {
    const filterName = (_docs) => {
      if (query.Name) return _docs.filter((d) => d.name.includes(query.Name));
      return _docs;
    };

    const filterAuthor = (_docs) => {
      if (query.Author)
        return _docs.filter((d) => d.createdBy.name.includes(query.Author));
      return _docs;
    };

    const filterResults = (_docs) => {
      const r = filterName(filterAuthor(_docs));
      setTotal(r.length);
      return r.filter((d, i) => {
        const offset = page * NUM_PER_PAGE;
        return i >= offset && i < offset + NUM_PER_PAGE;
      });
    };

    return (
      <table className="w-full">
        <thead className="bg-slate-100 h-10 border-b">
          <th className="rounded-tl-lg text-slate-800 text-xs font-medium px-2">
            TYPE
          </th>
          <th className="text-slate-700 text-xs font-medium text-left">NAME</th>
          <th className="text-slate-700 text-xs font-medium text-left">
            AUTHOR
          </th>
          <th></th>
          <th className="rounded-tr-lg"></th>
        </thead>

        {filterResults(documents).map((d, i) => (
          <DocRow doc={d} key={i} />
        ))}
      </table>
    );
  };

  // console.log(page);

  const GetPageCount = () => {
    let val = 0;
    if (total)
      val =
        Math.floor(total / NUM_PER_PAGE) + (total % NUM_PER_PAGE === 0 ? 0 : 1);
    return val;
  };

  return (
    
    <div style={{ backgroundColor: "#D9DDDB" }} className="pt-6 pb-2 h-screen">
      <div className="mt-2">

        <PlatformDocumentsFilter
          onFilter={(q) => {
            setQuery(q);
            setPage(0);
          }}
          onUpload={() => {
            setModalState({ modal: MODAL_STATE.UPLOAD });
          }}
        />
      </div>
      <MainContainer className="mt-4">
        {documents.length > 0 ? (



          <div>
            {/* <div className="text-xs font-medium leading-none pb-4">Showing 1 - {(documents.length <= 10) ? documents.length : 10} of {documents.length}</div> */}
            <div className="text-xs font-medium leading-none pb-4">
              Showing {page * NUM_PER_PAGE + 1} -{" "}
              {Math.min(page * NUM_PER_PAGE + NUM_PER_PAGE, total)} of {total}
            </div>
            <CreateDocTable />
            <div className="pt-4 flex flex-row justify-center">
              <Pagination
                page={page + 1}
                count={GetPageCount()}
                onChange={(e, v) => {
                  setPage(v - 1);
                }}
              />
            </div>
          </div>
        ) : (
          <div className="platform-individual-document-container text-slate-800 font-medium">
            No documents for platform
          </div>
        )}
      </MainContainer>

      {modalState.modal === MODAL_STATE.UPLOAD ? (
        <PlatformDocumentUploadModal
          onClose={() => {
            setModalState({ modal: MODAL_STATE.NONE });
          }}
          onDocumentUpload={onDocumentUpload}
        />
      ) : null}
      {modalState.modal === MODAL_STATE.DOC_INFO ? (
        <PlatformDocumentDetailModal
          onClose={() => {
            setModalState({ modal: MODAL_STATE.NONE });
          }}
          doc={modalState.data}
        />
      ) : null}

      <div id="document-container">
        <iframe
          src=""
          type="application/pdf"
          width={"100%"}
          height={"100%"}
          style={{ overflow: "auto" }}
        ></iframe>
      </div>
    </div>
  );
};

export default PlatformIndividualDocuments;
