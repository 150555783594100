import { useSelector } from "react-redux";
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IDIcon from '@mui/icons-material/Tag';
import ManufacturerIcon from '@mui/icons-material/Factory';
import LocationIcon from '@mui/icons-material/LocationOn';
import ModelIcon from '@mui/icons-material/DriveFileRenameOutline';
import ToggleIcon from '@mui/icons-material/ToggleOn';
import FlightIcon from '@mui/icons-material/Flight';
import { MenuItem } from '@mui/material';
import FilterIconButton from '@mui/icons-material/FilterAlt';
import Button from '@mui/material/Button';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import FilterIcon from '@mui/icons-material/TuneOutlined';
import { useEffect, useState, useReducer } from 'react';
import { Divider } from '@mui/material';


//import { Locations  } from '../../../services/Locations';
import { Locations as GetLocations } from "../../../../services/Locations";
//import { FilterReducer } from './PlatformFilterReducer';
//import { RequestErrorHandlerContext } from '../../components/Main';
import { useContext } from 'react';
import { ConstructionOutlined } from '@mui/icons-material';


const FilterReducer = (state, action) => {

    let _state = { ...state };
    Object.keys(action).forEach(k => {
        _state[k] = action[k];
    })
    return { ..._state };
}


const LocationFilter = ({ onApply, }) => {

    const InitialFormState = { location: '', from: '', to: '' };
    const [locations, SetLocations] = useState([]);
    const [filterState, filterDispatch] = useReducer(FilterReducer, InitialFormState);

    const IsFormChanged = () => {
        let empty = true;
        Object.keys(filterState).forEach(k => {
            if (filterState[k] !== InitialFormState[k])
                empty = false;
        });
        return empty;
    }

    useEffect(() => {

        GetLocations()
            .then(result => {
                return result.json();
            })
            .then(result => {
                SetLocations(result);
            })
            .catch(err => {
                // handle error
                //                console.log(err);
            })
    }, [])

    return (

        <>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: 2 }}>
                <div>


                    <TextField
                        select
                        label="Location"
                        id="filterLocation"
                        sx={{ m: 1, width: '20ch' }}
                        size="small"
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><LocationIcon /></InputAdornment>,
                        }}
                        value={filterState.location}
                        onChange={(v) => { filterDispatch({ location: v.target.value }) }}
                    >
                        {locations.map((v, i) => { return (<MenuItem key={i} value={v.name}>{v.name}</MenuItem>) })}
                    </TextField>
                    <TextField
                        id="dateFrom"
                        label="From"
                        sx={{ m: 1, width: '20ch' }}
                        size="small"
                        value={filterState.from}
                        onChange={(v) => { filterDispatch({ from: v.target.value }) }}

                    />
                    <TextField
                        id="dateTo"
                        label="To"
                        sx={{ m: 1, width: '20ch' }}
                        size="small"
                        value={filterState.to}
                        onChange={(v) => { filterDispatch({ to: v.target.value }) }}
                    />
                </div>
            </Box>

            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <div>
                    <Button id="filterApplyBtn" variant="contained" endIcon={<FilterIconButton />} sx={{ m: 1, width: '15ch' }} size="small" onClick={() => { }}>
                        Apply
                    </Button>
                    <Button id="filterResetBtn" variant="contained" sx={{ m: 1, width: '15ch' }} size="small" color='secondary' disabled={IsFormChanged()} onClick={() => { filterDispatch(InitialFormState) }}>
                        Reset
                    </Button>
                </div>
            </Box>
        </>
    );
}













const BasicTable = ({ data }) => {
    return (
        <TableContainer component={Paper} sx={{ marginTop: 3 }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell><b>Location</b></TableCell>
                        <TableCell><b>Enter</b></TableCell>
                        <TableCell><b>Leave</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow
                            key={row.location}

                        >
                            <TableCell component="th" scope="row">
                                {row.location}
                            </TableCell>
                            <TableCell>{new Date(row.enter).toDateString()}</TableCell>
                            <TableCell>{new Date(row.leave).toDateString()}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const PlatformLocationHistory = () => {
    const platform = useSelector(state => state.platform.platform);
    return <>
        <LocationFilter />
        <Divider sx={{marginTop:1}}/>
        <BasicTable data={platform.history} />
    </>
}

export default PlatformLocationHistory;