/*
"nickname": "luc.dalton",
"name": "Luc Dalton",
"picture": "https://s.gravatar.com/avatar/1f5b221a2ae0d9112ecf72523a9a7517?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Flu.png",
"updated_at": "2023-03-15T10:56:31.077Z",
"email": "luc.dalton@snc-ms.uk",
"email_verified": true,
"sub": "auth0|63eb979817db485112885257",
"role": "Admin"
*/

const TOKENS = {
  AUTH_TOKEN: "AUTH_TOKEN",
  REFRESH_TOKEN: "REFRESH_TOKEN",

  /*
        USER_USERNAME: "USER_USERNAME",
        USER_NAME: "USER_NAME",
        USER_EMAIL: "USER_EMAIL",
        USER_ID: "USER_ID",
        USER_ROLE: "USER_ROLE",
        USER_PROFILE_IMG: "USER_PROFILE_IMG",
        USER_UPDATED: "USER_UPDATED",
        USER_NICKNAME:"USER_NICKNAME" 
    */
};

const USER_FIELDS = [
  "nickname",
  "name",
  "picture",
  "updated_at",
  "email",
  "email_verified",
  "sub",
  "roles",
  "user_id",
];

/*
const getAPIAddr = () => {

    let addr = process.env.REACT_APP_API_DEV;
    switch(process.env.NODE_ENV)
    {
        default:
        case 'test':
        addr = process.env.REACT_APP_API_DEV;
        break;
        
        case 'production':
        addr = process.env.REACT_APP_API_PROD;
        break;
    }
    return addr;
}
*/

/*
const createQueryString = (data) => {
    return Object.keys(data).map(key => {
      let val = data[key]
      if (val !== null && typeof val === 'object') val = createQueryString(val)

      return new URLSearchParams({key:val}).toString()
//      return `${key}=${encodeURIComponent(`${val}`.replace(/\s/g, '_'))}`
    }).join('&')
  }
*/

//const URL_PARTS = {};
/*
const PATHS = {
    URL: process.env.REACT_APP_URL,
    PLATFORMS: "/platforms",
    TASKS: "/tasks",
    PARTS: "/parts",
    OVERVIEW: "/overview",
    ADMIN: "/admin",
    HISTORY: "/history"
}*/

const PATHS = {
  URL: process.env.REACT_APP_URL,
  PLATFORMS: "platforms",
  TASKS: "tasks",
  PARTS: "parts",
  OVERVIEW: "overview",
  ADMIN: "admin",
  HISTORY: "history",
  DOCUMENTS: "documents",
  SOFTWARE: "software",
};

const API = {
  URL: process.env.REACT_APP_API,
  PARSE_QUERY: (query) => {
    /*
        console.log(query);
        Object.keys(query).forEach(k => {
            console.log(encodeURI( JSON.stringify(query[k]) ));
        })
        */

    return !query || Object.keys(query).length === 0
      ? ""
      : "?" + new URLSearchParams(query).toString();
  },

  LOGIN: () => {
    return API.URL + "/login";
  },
  PLATFORMS: (query) => {
    const qs = !query ? "" : "?" + new URLSearchParams(query).toString();
    return API.URL + "/platforms" + qs;
  },

  PLATFORM: (id) => {
    return API.URL + "/platforms/" + id;
  },

  // PLATFORM_HISTORY: (id) => { return `${API.URL}${PATHS.PLATFORMS}${PATHS.HISTORY}/${id}`; },
  PLATFORM_DOCUMENTS: (id) => {
    return `${API.URL}/${PATHS.PLATFORMS}/${id}/${PATHS.DOCUMENTS}`;
  },

  PLATFORM_SOFTWARE: (id) => {
    return `${API.URL}/${PATHS.PLATFORMS}/${id}/${PATHS.SOFTWARE}`;
  },

  PLATFORM_SOFTWARE_INDIVIDUAL: (id, softwareId) =>
    `${API.PLATFORM_SOFTWARE(id)}/${softwareId}`,

  TASKS: (query) => {
    const qs = !query ? "" : "?" + new URLSearchParams(query).toString();
    return `${API.URL}/tasks${qs}`;
  },
  TASK: (uuid) => {
    return API.URL + "/tasks/" + uuid;
  },
  TASK_WATCH: (uuid) => {
    return API.URL + "/tasks/" + uuid + "/watch";
  },
  TASK_HISTORY: (id) => {
    return `${API.URL}/${PATHS.TASKS}/${PATHS.HISTORY}/${id}`;
  },
  TASK_HISTORY_ALL: (query) => {
    return `${API.URL}/${PATHS.TASKS}/${PATHS.HISTORY}${API.PARSE_QUERY(
      query
    )}`;
  },
  PARTS: (query) => {
    return `${API.URL}/${PATHS.PARTS}${API.PARSE_QUERY(query)}`;
  },
  PART: (id) => {
    return API.URL + "/parts/" + id;
  },
  PART_REQUEST: () => {
    return API.URL + "/parts/requests";
  },
  UPDATE_PART_REQUEST: (uuid) => {
    return API.URL + "/parts/requests/" + uuid;
  },
  ACTIVE_PLATFORMS: () => {
    return API.URL + "/activeplatforms";
  },
  USERS: () => {
    return API.URL + "/users";
  },
  USER: (id) => {
    return `${API.URL}/users/${id}`;
  },
  USER_PROFILE: (id) => {
    return `${API.URL}/users/${id}/profile`;
  },
  USER_ROLES: (userId) => {
    return `${API.URL}/users/${userId}/roles`;
  },

  ALL_ROLES: () => {
    return `${API.URL}/users/roles`;
  },
  USERS_FOR_ROLE: (role) => {
    return `${API.URL}/users/roles/${role}`;
  },

  LOCATIONS: () => {
    return `${API.URL}/locations`;
  },
  LOCATION: (id) => {
    return `${API.URL}/locations/${id}`;
  },
  REQUEST: () => {
    return API.URL + "/requests";
  },

  //DOCUMENTS: () => {return `${API.URL}/documents`;},
  DOCUMENT: (id) => {
    return `${API.URL}/documents/${id}`;
  },
  DOCUMENTS: (query = {}) => {
    return `${API.URL}/documents${API.PARSE_QUERY(query)}`;
  },

  PROJECTS: () => {
    return `${API.URL}/projects`;
  },
  PROJECT: (id) => {
    return `${API.URL}/projects/${id}`;
  },

  SOFTWARE_INDIVIDUAL: (id) => `${API.URL}/software/${id}`,

  SOFTWARE: (q = {}) => `${API.URL}/software${API.PARSE_QUERY(q)}`,

  PROJECT_PROFILE: (id) => {
    return `${API.URL}/projects/${id}/profile`;
  },
  EFR: (id = "") => {
    return `${API.URL}/efr/${id}`;
  },
  //    EFR: (query)=>{return `${API.URL}/efr${API.PARSE_QUERY(query)}`;},
};

const ERRORS = {
  PERMISSION: "PERMISSION",
  REQUEST_FAIL: "Failed to fetch",
  TOKEN_ERROR: "invalid_token",
};

const SUB_PATHS = {
  PLATFORM_TABS: ["parts", "tasks", "history", "documents", "software"],
  PLATFORM_HISTORY_TABS: [
    "task history",
    "location history",
    "incident reports",
  ],
  ADMIN_TABS: ["platforms", "parts", "users", "location", "software"],
};

export { TOKENS, USER_FIELDS, API, PATHS, SUB_PATHS, ERRORS };
