import { PlatformPart } from "./PartsModel";

class Platform {
    constructor(params = {}) {
        if(params._id) 
            this._id = params._id;
        this.name = params.name || '';
        this.location = params.location || '';
        this.model = params.model || '';
        this.manufacturer = params.manufacturer || '';
        this.serial = params.serial || '';
//        this.img = params.img || '';
        this.img = 'https://dev.snc.vitaopera.net/images/platforms/drone150.png';
        this.status = params.status || '';
        this.parts = params.parts?.map(p => new PlatformPart(p)) || [];
        this.history = params.history || [];
        this.flightData = params.flightData || [];
        this.updatedAt = params.updatedAt || new Date();
        this.createdAt = params.createdAt || new Date();
     }

    AddPart(part) { this.parts.push(part) };
    GetName() { return this.name };
    /*
    GetModel() { return this.model };
    GetManufacturer() { return this.manufacturer };
    GetSerial() { return this.serial };
    GetImage() { return this.img };
    GetStatus() { return this.status };    
    GetParts() { return this.parts };
    GetHistory() { return this.history };
    GetFlightData() { return this.flightData };
    */
}

export default Platform;