import { Paper } from '@mui/material';
import { DoNotDisturb } from '@mui/icons-material';

const ConnectionError = ({error, message}) => {
    return (
        <Paper sx={{ paddingTop: 2, textAlign: 'center' }}>
            <DoNotDisturb sx={{ color: 'red' }} />
            <h4>Connection Error</h4>
            <div>{error.message}</div>
            <div>{message}</div>
        </Paper>
    );
}

export default ConnectionError;