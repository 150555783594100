import { useState, useEffect, useReducer } from "react";
import { Modal, TextField, Box, Divider, Card } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
//import { addPart, updatePart } from "../../../../services/Parts";
import { Typography, MenuItem } from "@mui/material";
import { UpdatePart, CreatePart } from "../../../../services/Parts";
import AdminStockModal from "./AdminStockModal";
import { Part, PART_TYPE, PlatformPart, ServiceInterval } from "../../../../models/PartsModel";
import { Button } from "../../../../components/Buttons";

//import { Part, ServiceInterval } from "../../../../models/PartsModel";
//import { DefaultReducer } from '../../../../components/DefaultReducer';
//import {  } from "../../../../models/PartsModel";



const AdminRemovePartModal = ({ part, onClose, onRemove }) => {

    return (
        <>
            <Modal open={true} onClose={onClose}>
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    background: "white",
                    width: 700,
                }}>

                    <div className="flex justify-end py-1 px-1">
                        <CloseIcon sx={{ marginLeft: 'auto' }} color="text.secondary" onClick={onClose} />
                    </div>

                    <Divider />

                    <div className="text-center mt-2">
                        Remove part {part.name} from database
                    </div>

                    <div className="text-center">
                        Are you sure?
                    </div>

                    <div className="flex justify-center my-4">
                        <div className="mr-4">
                            <Button label="Cancel" onClick={onClose}/>
                        </div>
                        <Button label="Remove" variant="warning" onClick={()=>{onRemove(part)}}/>
                    </div>

                </div>
            </Modal>
            {/* {stockModal.modal === MODAL_STATE.ADD_STOCK ? <AdminStockModal onClose={() => { setStockModal({ modal: MODAL_STATE.NONE }) }} onAddStock={onAddStock} stock={stockModal.data} onUpdateStock={onUpdateStock} /> : null} */}
        </>
    );
}

export default AdminRemovePartModal;