import './Main.css'
import ProjectsPage from '../pages/projects/Projects';
import Main from "./Main";
import { Routes, Route } from 'react-router-dom';
import NavBar from './NavBar';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetProject } from '../services/Projects';
import { projectsActions } from '../store';
import { LoadingMain } from './LoadingModal';
import { useContext, useState } from 'react';
import { RequestErrorHandlerContextNEW as RequestErrorHandlerContext } from '../App';
import NotFound from '../pages/NotFound';

const NavWrapper = ({ children, showTabs = true, projectName = '' }) => {
    const navEl = document.getElementById('navigation-wrapper');
    return (
        <>
            {createPortal(<NavBar showTabs={showTabs} projectName={projectName} />, navEl)}
            <div id="navbar-wrapper-content">
                {children}
            </div>
        </>
    )
}

const urlTokens = (url) => url.pathname.split('/').filter(r => r);
const getProjectID = (url) => urlTokens(url)[0];

const ProjectsWrapper = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const project = useSelector(state => state.projects.selected);
    const errorHandler = useContext(RequestErrorHandlerContext);
    const [requestError, setRequestError] = useState(false);

    const reloadProjectState = (id) => {
        GetProject(id)
        .then(r => r.json())
        .then(r => errorHandler(r))
        .then(r => {
            dispatch(projectsActions.setSelectedProject(r))
        })
        .catch(err => setRequestError(true) )
    }

    if(requestError)
        return <NavWrapper><NotFound /><div>ProjectId: { getProjectID(location)}</div></NavWrapper>;

    if(project === null && getProjectID(location))
    {
        reloadProjectState(getProjectID(location));
        return <NavWrapper showTabs={false}><LoadingMain show={true}/></NavWrapper>
    }

    return (
        <Routes>
            <Route path="/" element={<NavWrapper showTabs={false}> <ProjectsPage /> </NavWrapper>} />
            <Route path=":id/*" element={<NavWrapper projectName={project?.name}><Main /></NavWrapper>} />
        </Routes>
    );
}

export default ProjectsWrapper;