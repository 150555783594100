import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Close from '@mui/icons-material/Close';
import Flight from '@mui/icons-material/Flight';
import LocationOn from '@mui/icons-material/LocationOn';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { MenuItem } from '@mui/material';
import LocationIcon from '@mui/icons-material/LocationOn';
import { useEffect, useState, useReducer } from 'react';
import { Part as GetPart } from '../../../services/Parts';
import { STATUS_LIST } from '../../../models/TasksModel';
import { UpdateTask } from '../../../services/Tasks';
import Chip from '@mui/material/Chip';
import { Paper, Avatar } from '@mui/material';
import KeyboardDoubleArrowUp from '@mui/icons-material/KeyboardDoubleArrowUp';
import { KeyboardArrowDown } from '@mui/icons-material';
import { PRIORITY_LEVEL } from '../../../models/TasksModel';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 5,
    pt: 1,
    px: 2,
    pb: 2
};

const PartProcessingModal = ({ open, onClose, part, onUpdate }) => {

    const [status, setStatus] = useState(0);
    const STATUS_TRANSITIONS = [STATUS_LIST.PART_STATUS_PROCESSING, STATUS_LIST.PART_STATUS_IN_TRANSIT];

    const UpdatePartRequest = () => {
        let request = { ...part };
        part.partRequest.status = STATUS_LIST.PART_STATUS_IN_TRANSIT;
        UpdateTask(request._id, { partRequest: part.partRequest })
            .then(res => { return res.json() })
            .then(res => { onUpdate(res); onClose() })
    }

    const Row = ({ left, right }) => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                <Box sx={{ width: '50%', alignContent: 'center' }}>
                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>{left}</Typography>
                </Box>
                <Box sx={{ width: '50%' }}>
                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>{right}</Typography>
                </Box>
            </Box>
        )
    }


    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{ outline: 0 }}
            disableEnforceFocus={true}
            disableAutoFocus={true}
        >
            <Box sx={style}>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '100%' }}>
                        <Typography>{part.part.model}</Typography>
                    </div>
                    <div style={{ marginLeft: 'auto', alignSelf: 'flex-start' }}><Close /></div>
                </Box>

                <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                    <Box sx={{ width: '65%' }}>

                        <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                            <Box>
                                <Typography sx={{ fontSize: 12 }} color="text.secondary">{part.part.type}</Typography>
                                <Typography sx={{ fontSize: 12 }} color="text.secondary">{part.part.manufacturer}</Typography>
                            </Box>
                            <Box style={{ marginLeft: 20 }}>
                                <Typography sx={{ fontSize: 12 }} color="text.secondary"><Flight sx={{ fontSize: 12 }} /> {part.platform.name}</Typography>
                                <Typography sx={{ fontSize: 12 }} color="text.secondary"><LocationOn sx={{ fontSize: 12 }} /> {part.platform.location}</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ marginTop: 2, border: '1px solid #aeaeae', borderRadius: 1, padding: 1, marginRight: 1 }}>
                            <Typography sx={{ fontSize: 14 }} color="text.primary">Assigned Part: {part.partRequest.serial}</Typography>
                            <Typography sx={{ fontSize: 14 }} color="text.primary">Current Location: {part.partRequest.origin}</Typography>
                            <Typography sx={{ fontSize: 14 }} color="text.primary">Assigned By: {part.partRequest.partAssignedBy?.name}</Typography>

                            <TextField
                                select
                                label="Request status"
                                id="requestStatus"
                                sx={{ m: 1, width: '25ch', marginTop: 2 }}
                                size="small"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><LocationIcon /></InputAdornment>,
                                }}
                                value={status}
                                onChange={(v) => { setStatus(v.target.value) }}
                            >
                                <MenuItem value={0}>{STATUS_TRANSITIONS[0]}</MenuItem>
                                <MenuItem value={1}>{STATUS_TRANSITIONS[1]}</MenuItem>
                            </TextField>
                        </Box>

                        <Box>
                            <Button variant='outlined' onClick={UpdatePartRequest} disabled={status === 0} sx={{ marginTop: 1 }}>Update</Button>
                        </Box>

                    </Box>
                    <Box sx={{ width: '35%', backgroundColor: '#eee', borderRadius: 1 }}>

                        <Box sx={{ display: 'flex' }}>
                            <Chip sx={{ marginLeft: 'auto', backgroundColor: '#00b359', color: 'white', marginRight: 1, marginTop: 2, marginBottom: 2 }} label="Processing" />
                        </Box>


                        <Box sx={{ display: 'flex', marginRight: 1 }}>
                            <Paper sx={{ width: '100%', marginLeft: 0.5, marginRight: 1, backgroundColor: '#eee' }} elevation={0}>
                                <Row left={'Task Request'} right={
                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        <Avatar sx={{ width: 20, height: 20 }} src={part.reporter.picture} />
                                        <Typography color="text.secondary" sx={{ fontSize: 13, marginLeft: 0.5 }}><b>{part.reporter?.nickname}</b></Typography>
                                    </Box>
                                } />
                                <Row left={'Task Assignee'} right={
                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        <Avatar sx={{ width: 20, height: 20 }} src={part.assignee?.picture} />
                                        <Typography color="text.secondary" sx={{ fontSize: 13, marginLeft: 0.5 }}><b>{part.assignee?.nickname}</b></Typography>
                                    </Box>
                                } />

                                <Row left={'Task Priority:'} right={
                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>

                                        {part.priority === 0 ? <KeyboardArrowDown sx={{width:15, height:18, color:'green' }}/> : ''}
                                        {part.priority === 1 ? <KeyboardDoubleArrowUp sx={{width:15, height:18, color:'orange' }}/> : ''}
                                        {part.priority === 2 ? <KeyboardDoubleArrowUp sx={{width:15, height:18, color:'red' }}/> : ''}
                                        
                                        <Typography color="text.secondary" sx={{ fontSize: 13, marginLeft: 0.5 }}><b>{PRIORITY_LEVEL[part.priority]}</b></Typography>
                                    </Box>
                                } />

                                <Row left={'Task Status'} right={
                                    <Chip sx={{ backgroundColor: 'orange', color: 'white', height: 22, fontSize: 10 }} label={part.status} />

                                } />
                            </Paper>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default PartProcessingModal;