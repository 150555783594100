import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography, Button, InputAdornment } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { useState, useReducer } from 'react';
import FormLoading from './FormLoading';
import theme from './SignUpFormTheme';
import { DefaultReducer } from '../../components/DefaultReducer';
import { resetPassword } from './UserPool';

const PasswordResetForm = ({ returnToLogin, onPasswordReset }) => {
    
    const [loading, setLoading] = useState(false);
    const [formState, formStateDispatch] = useReducer(DefaultReducer, { value: '', valid: true, message: '' });

    const onSuccess = (data) => {
        // successfully initiated reset password request
        setLoading(false);
        onPasswordReset(formState);
        console.log('CodeDeliveryData from forgotPassword: ' + data);
    };

    const onFailure = (err) => {
        setLoading(false);
    };

    const onSubmit = () => {
        setLoading(true);
        resetPassword(formState.value, onSuccess, onFailure);
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {},
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <ThemeProvider theme={theme}>
                    <Typography variant='h1'>Reset Password</Typography>
                </ThemeProvider>

                <div className="signin-form-line-text">
                    Account email address:
                </div>

                <Box className="form-input-field">
                    <TextField
                        required
                        label="Email"
                        size='small'
                        fullWidth={true}
                        value={formState.value}
                        onChange={(v) => { formStateDispatch({ value: v.target.value }) }}
                        helperText={''}
                        error={!formState.valid}
                    />
                </Box>
                <Box className="form-input-field">
                    <Button variant='contained' sx={{ width: '100%' }} size='small' onClick={onSubmit}>submit</Button>
                </Box>
                <Box className="form-input-field signin-form-line-text">
                    Return to<span className="highlight-link cursor-on-hover" onClick={returnToLogin}> login</span>
                </Box>
            </div>
            {loading ? <FormLoading /> : ''}
        </Box>
    );
}

export default PasswordResetForm;