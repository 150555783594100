import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Close from "@mui/icons-material/Close";
import Flight from "@mui/icons-material/Flight";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 5,
  pt: 1,
  px: 2,
  pb: 2,
};

const PartsAllModal = ({ open, onClose, part, onUpdate }) => {
  useEffect(() => {
    console.log("PartsAllModal");
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ outline: 0 }}
      disableEnforceFocus={true}
      disableAutoFocus={true}
    >
      <Box sx={style}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            <Typography>{part.model}</Typography>
          </div>
          <div
            onClick={onClose}
            className="cursor-default"
            style={{ marginLeft: "auto", alignSelf: "flex-start" }}
          >
            <Close />
          </div>
        </Box>
        <Box sx={{ flexDirection: "row", display: "flex" }}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ flexDirection: "row", display: "flex" }}>
              <Box>
                <Typography sx={{ fontSize: 12 }} color="text.secondary">
                  {part.type}
                </Typography>
                <Typography sx={{ fontSize: 12 }} color="text.secondary">
                  {part.manufacturer}
                </Typography>
              </Box>
              <Box style={{ marginLeft: 20 }}>
                <Typography sx={{ fontSize: 12 }} color="text.secondary">
                  <Flight sx={{ fontSize: 12 }} />
                  {part.name}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: 2,
                border: "1px solid #aeaeae",
                borderRadius: 1,
                padding: 1,
                marginRight: 1,
              }}
            >
              <Box sx={{ marginBottom: 2 }}>
                <Typography>Current:</Typography>
                <Box
                  sx={{
                    maxHeight: 150,
                    overflow: "scroll",
                    overflowX: "hidden",
                    backgroundColor: "#fff",
                    marginTop: 0.5,
                    border: "1px solid #eee",
                    borderRadius: 1,
                  }}
                >
                  {part.stock.map((k, i) => {
                    return (
                      <Box
                        key={i}
                        sx={{
                          height: 40,
                          borderBottom: "1px solid #eee",
                          paddingLeft: 2,
                          paddingTop: 1.2,
                        }}
                      >
                        <Typography key={i}>
                          {k.location}: {k.items.length}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box sx={{ marginBottom: 1 }}>
                <Typography>Suppliers:</Typography>
                <Box
                  sx={{
                    maxHeight: 150,
                    overflow: "scroll",
                    overflowX: "hidden",
                    backgroundColor: "#fff",
                    marginTop: 0.5,
                    border: "1px solid #eee",
                    borderRadius: 1,
                  }}
                >
                  {part.suppliers.map((k, i) => {
                    return (
                      <Box
                        sx={{
                          height: 40,
                          borderBottom: "1px solid #eee",
                          paddingLeft: 2,
                          paddingTop: 1.2,
                        }}
                      >
                        <Typography key={i}>{k}</Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PartsAllModal;
