import { useEffect, useState } from "react";
import PlatformTaskCard from "../../platforms/tabs/PlatformTaskCard";
import { Tasks as GetTasks } from "../../../services/Tasks";
import { useSelector } from "react-redux";
import { LoadingMain } from "../../../components/LoadingModal";

import PlatformTaskModal from "../../platforms/tabs/PlatformTaskModal/PlatformTaskModal";
import { MainContainer } from "../../../components/Containers";


const TasksAssigned = () => {

    const userInfo = useSelector(state => state.auth.user);
    const project = useSelector(state => state.projects.selected);
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [taskModal, setSelectedTask] = useState(null);

    useEffect(() => {
        GetTasks({ "subscribers": [userInfo.user_id], projectId: project._id })
            .then((res) => { return res.json() })
            .then(result => {
                setLoading(false);
                setTasks(result);
            })
    }, [])

    const onTaskUpdate = (task) => {
        console.log(task);

        setTasks(tasks.map(t => { if (t._id === task._id) { return task } else { return t } }));
        setSelectedTask(task);
    }

    return (
        <div style={{ backgroundColor: "#D9DDDB" }} className="h-screen pt-8">
            <MainContainer>

                {
                    loading ? <LoadingMain show={loading} /> :
                        <div style={{ paddingTop: 10 }}>
                            Total: {tasks.length}
                            {tasks.map((t, i) => { return <PlatformTaskCard key={i} task={t} onTaskSelect={setSelectedTask} /> })}
                            {taskModal ? <PlatformTaskModal onClose={() => { setSelectedTask(null) }} onTaskUpdate={onTaskUpdate} task={taskModal} /> : ''}
                        </div>
                }
            </MainContainer>
        </div>
    )
}

export default TasksAssigned;