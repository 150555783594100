import { useState, useEffect } from "react";
import { LoadingMain } from "../../../../components/LoadingModal";
import AdminLocationCard from "./AdminLocationCard";
import AdminNewLocationModal from "./AdminNewLocationModal";
import { Locations as GetLocations, deleteLocation } from '../../../../services/Locations'
import { Button } from "../../../../components/Buttons";
// import { Button } from "@mui/material";
import DynamicTable from "../../../../components/DynamicTable";
import { DeleteForever } from "@mui/icons-material";
import { RemoveLocationModal } from "./AdminRemoveLocationModal";
import { MainContainer } from "../../../../components/Containers";

const AdminLocationList = () => {
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showNewLocationModal, setShowNewLocationModal] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState("")

    const [removeModal, setRemoveModal] = useState(null);

    const updateLocationsList = () => {
        GetLocations()
            .then((r) => r.json())
            .then((result) => {
                setLocations(result);
                setLoading(false);
            });
    };

    const handleOpenNewLocationModal = (location) => {
        setSelectedLocation(location)
        setShowNewLocationModal(true);
    };

    const handleCloseNewLocationModal = () => {
        setSelectedLocation("")
        setShowNewLocationModal(false);
    };

    useEffect(() => {
        updateLocationsList();
    }, []);

    const onRemoveLocation = (location) => {
        // console.log(location);
        deleteLocation(location._id)
            .then(r => r.json())
            .then(r => {
                updateLocationsList();
                setRemoveModal(null);
            })
    }


    const tableHeader = [
        "Location",
        "County",
        "Lat",
        "Long",
        "Action"
    ];


    return (
        <>
            <div style={{ backgroundColor: "#D9DDDB" }} className="h-screen pt-8">
                {loading ? (
                    <MainContainer>
                        <LoadingMain show={loading} />
                    </MainContainer>
                ) : (

                    <MainContainer>


                        {/* <div className="platform-individual-document-container"> */}

                        <Button label={"Add Location"} onClick={() => { handleOpenNewLocationModal("") }} />


                        <div className="mt-4">
                            <DynamicTable
                                data={locations.map(l => (
                                    [
                                        <div key={`${l._id}name`} onClick={() => { handleOpenNewLocationModal(l) }}><b>{l.name}</b></div>,
                                        <div key={`${l._id}country`} onClick={() => { handleOpenNewLocationModal(l) }}>{l.country}</div>,
                                        <div key={`${l._id}lat`} onClick={() => { handleOpenNewLocationModal(l) }}>{l.lat}</div>,
                                        <div key={`${l._id}long`} onClick={() => { handleOpenNewLocationModal(l) }}>{l.long}</div>,
                                        <div key={`${l._id}action`} onClick={() => { setRemoveModal(l) }} className="text-red-500"><DeleteForever /></div>,
                                    ])
                                )}
                                headerData={tableHeader}
                            />
                        </div>

                        {/* <div className="mt-4">
                            {locations.map((location, i) => (
                                <AdminLocationCard key={i} location={location} onClick={() => handleOpenNewLocationModal(location)} reloadLocations={updateLocationsList} />
                            ))}
                        </div> */}
                        {showNewLocationModal && <AdminNewLocationModal onClose={handleCloseNewLocationModal} updateLocations={updateLocationsList} existingLocation={selectedLocation} />}
                        {removeModal ? <RemoveLocationModal location={removeModal} onClose={() => { setRemoveModal(null) }} onRemove={onRemoveLocation} /> : null}
                        {/* </div> */}
                    </MainContainer>

                )}
            </div>
        </>
    );
};

export default AdminLocationList;
