import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IDIcon from '@mui/icons-material/Tag';
import ManufacturerIcon from '@mui/icons-material/Factory';
import LocationIcon from '@mui/icons-material/LocationOn';
import ModelIcon from '@mui/icons-material/DriveFileRenameOutline';
import ToggleIcon from '@mui/icons-material/ToggleOn';
import FlightIcon from '@mui/icons-material/Flight';
import { Divider, MenuItem } from '@mui/material';
import FilterIconButton from '@mui/icons-material/FilterAlt';
import Button from '@mui/material/Button';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import FilterIcon from '@mui/icons-material/TuneOutlined';
import { useEffect, useState, useReducer } from 'react';
import { Locations as GetLocations } from '../../../services/Locations';
import { Parts as GetParts } from '../../../services/Parts';
import { DefaultReducer } from '../../../components/DefaultReducer';
//import { RequestErrorHandlerContext } from '../../../components/Main';
import { RequestErrorHandlerContextNEW as RequestErrorHandlerContext } from '../../../App';
import { useContext } from 'react';
import { ConstructionOutlined } from '@mui/icons-material';
import Chip from '@mui/material/Chip';


const InitialFormState = { manufacturer: '', model: '', type: '', 'stock.location': '' };


const PartsAllFilter = ({ onApply }) => {

    const [locations, SetLocations] = useState([]);
    const [filterState, filterDispatch] = useReducer(DefaultReducer, InitialFormState);
    const [filterIcons, filterIconsDispatch] = useReducer(DefaultReducer, InitialFormState);
    const errorHandler = useContext(RequestErrorHandlerContext);

    const IsFormChanged = () => {
        let empty = true;
        Object.keys(filterState).forEach(k => {
            if (filterState[k] !== InitialFormState[k])
                empty = false;
        });
        return empty;
    }


    useEffect(() => {

        GetLocations()
            .then(result => {
                return result.json();
            })
            .then(result => {
                return errorHandler(result);
            })
            .then(result => {

//                console.log(locations);

                SetLocations(result);
            })
            .catch(err => {
                // handle error
                //                console.log(err);
            })

    }, [])

    const getFilterKeys = () => { return Object.keys(filterIcons).filter((k) => { return (filterIcons[k] !== '') }) }

    return (

        <>
            <Accordion sx={{marginTop:1}}>
                <AccordionSummary
                    expandIcon={<FilterIcon />}
                    aria-controls="panel1a-content"
                    id="platformFilterHeader"
                >
                    <Typography>Filter Parts</Typography>
                </AccordionSummary>
                <AccordionDetails>


                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div>
                            <TextField
                                label="Manufacturer"
                                id="filterManufacturer"
                                sx={{ m: 1, width: '25ch' }}
                                size="small"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><ManufacturerIcon /></InputAdornment>,
                                }}
                                value={filterState.manufacturer}
                                onChange={(v) => { filterDispatch({ manufacturer: v.target.value }) }}
                            />
                            <TextField
                                label="Model"
                                id="filterModel"
                                sx={{ m: 1, width: '25ch' }}
                                size="small"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><ModelIcon /></InputAdornment>,
                                }}
                                value={filterState.model}
                                onChange={(v) => { filterDispatch({ model: v.target.value }) }}
                            />
                            <TextField
                                label="Type"
                                id="filterType"
                                sx={{ m: 1, width: '25ch' }}
                                size="small"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><ModelIcon /></InputAdornment>,
                                }}
                                value={filterState.type}
                                onChange={(v) => { filterDispatch({ type: v.target.value }) }}
                            />
                            <TextField
                                select
                                label="Location"
                                id="filterLocation"
                                sx={{ m: 1, width: '25ch' }}
                                size="small"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><LocationIcon /></InputAdornment>,
                                }}
                                //                                value={filterState.location? filterState.location : 0}
                                value={filterState['stock.location'] ? filterState['stock.location'] : 0}
                                onChange={(v) => { filterDispatch({ 'stock.location': v.target.value }) }}
                            >
                                <MenuItem value={0}></MenuItem>
                                {locations.map((v, i) => { return (<MenuItem key={i} value={v.name}>{v.name}</MenuItem>) })}

                            </TextField>

                        </div>
                    </Box>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div>
                            <Button id="filterApplyBtn" variant="contained" endIcon={<FilterIconButton />} sx={{ m: 1, width: '15ch' }} size="small" onClick={() => { filterIconsDispatch(filterState); onApply(filterState) }}>
                                Apply
                            </Button>
                            <Button id="filterResetBtn" variant="contained" sx={{ m: 1, width: '15ch' }} size="small" color='secondary' disabled={IsFormChanged()} onClick={() => { filterDispatch(InitialFormState) }}>
                                Reset
                            </Button>
                        </div>
                    </Box>
                </AccordionDetails>
            </Accordion>


            
            <Box sx={{ border: '1px solid #bbb', borderRadius: 2, padding: 0.8, fontSize: 9, marginRight: 1, marginTop:1, display: (getFilterKeys().length === 0 ? 'none' : '') }}>
                {getFilterKeys().map((k,i) => { return <Chip key={i} label={filterIcons[k]} variant="filled" onDelete={() => { let obj = {}; obj[`${k}`] = InitialFormState[`${k}`]; filterDispatch(obj); filterIconsDispatch(obj) }} sx={{ marginRight: 0.5 }} /> })}
            </Box>
        </>
    );
}

export default PartsAllFilter;