import React, { useState } from "react";
import { Button } from "../../../../components/Buttons";

const UserAddSoftwareDropdown = ({ options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    console.log("Dropdown component value: ", value);
    onSelect(value);
  };

  return (
    <div>
      <div className="flex flex-col items-center mx-10 my-2">
        <h2>Select a software:</h2>
        <select value={selectedOption} onChange={handleSelectChange}>
          <option>Select a software...</option>
          {options.map((option) => (
            <option key={option._id} value={option.name}>
              {option.name} {option.version}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default UserAddSoftwareDropdown;
