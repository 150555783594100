import {Route, Routes, Navigate, useNavigate, useLocation} from "react-router-dom";

import TasksAssigned from "./tabs/TasksAssigned";
import TasksWatching from "./tabs/TasksWatching";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useEffect, useState} from "react";
import TasksAll from "./tabs/TasksAll";

import { PATHS } from "../../config";
import { PATCH } from "../../services/Methods";

import { NavigationTab } from "../../components/NavigationTab";
import { ComputerIcon, CogIcon, TaskStackIcon, ClipBoardIcon, WalletIcon, ListIcon } from "../../components/Icons";
import { useSelector } from "react-redux";

const TASKS_TABS = [
    "assigned",
    "watching",
    "all"
]
/*

const TaskNavigation = ()=>{
    return (
        <div>TaskNav</div>
    )
}*/

const TaskTabs = ({activeTab, onTabChange})=> {

    return (
        <>
            <Box sx={{borderBottom: 1, borderColor:'divider'}}>
                <Tabs value={activeTab} onChange={(evt, v)=>{onTabChange(v)}} aria-label="basic tabs example">
                    {TASKS_TABS.map((t, i)=>{ return (<Tab key={i} label={t} {...{ id: `simple-tab-${i}`, 'aria-controls': `simple-tabpanel-${i}`}} />)})}
                </Tabs>
            </Box>
        </>
    )
}

const Tasks = ()=>{

    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector(state => state.auth.user);

    const changeTab = (tab) => {
        setActiveTab(tab);
//        navigate(`${PATHS.TASKS}/${TASKS_TABS[tab]}`);
        console.log(`${TASKS_TABS[tab]}`);
        navigate(`${TASKS_TABS[tab]}`);
    }

    useEffect(()=>{
        const URL = location.pathname.split('/');
        TASKS_TABS.forEach((t,i) => {
            if(URL.includes(t))
                setActiveTab(i);
        });
    }, [location.pathname]);

    return (
        <>
            {/* <TaskTabs activeTab={activeTab} onTabChange={changeTab}/> */}


            <div style={{ backgroundColor: "#F1F1F0" }}>

                <div className="flex flex-row justify-center content-center pt-6">
                    {/* <div className="bg-white flex p-6 border rounded-md shadow-md mr-4 hover:cursor-pointer hover:bg-slate-50 hover:shadow-lg" onClick={() => { navigate(`/`) }}>

                        <div className='text-green-600 flex flex-col justify-center'>
                            <BackIcon className="w-6 h-6" />
                        </div>

                        <div className='font-normal text-slate-800 pl-2 flex flex-col justify-center'>
                            All projects
                        </div>

                    </div> */}
                    <div className="bg-white flex p-6 border rounded-md shadow-md">
                        <TaskStackIcon className="w-11 h-11" />
                        <div className="text-3xl text-slate-800 font-semibold ml-2 flex flex-col justify-center">
                            Platform Tasks
                        </div>
                    </div>
                </div>

                <div className="max-w-7xl m-auto translate-y-4">
                    <NavigationTab name={`Assigned to ${user.name}`} icon={() => <ClipBoardIcon className="w-4 h-4" />} active={activeTab === 0} first={true} onClick={() => { changeTab(0) }} />
                    <NavigationTab name={"Watching"} icon={() => <WalletIcon className="w-4 h-4" />} active={activeTab === 1} onClick={() => { changeTab(1) }} />
                    <NavigationTab name={"All"} icon={() => <ListIcon className="w-4 h-4" />} active={activeTab === 2} last={true} onClick={() => { changeTab(2) }} />
                </div>
            </div>

            <Routes>
                <Route path="/" element={<Navigate to={TASKS_TABS[0]} />} />
                <Route path={TASKS_TABS[0]} element={<TasksAssigned />} />
                <Route path={TASKS_TABS[1]} element={<TasksWatching />} />
                <Route path={TASKS_TABS[2]} element={<TasksAll />} />
            </Routes>

        </>
    )
}

export default Tasks;