import { Avatar, Box, Divider, Typography, TextField, Button, Modal } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { UpdateUser } from "../../../../services/Users";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import './AdminUsers.css';
import { AccountCircle } from "@mui/icons-material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 250,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 5,
    pt: 2,
    px: 3,
    pb: 3
};

const UserModal = ({ user, open, onClose, roles, onUpdate }) => {

    const [checkedRoles, setCheckedRoles] = useState(user.roles);

    const toggleRole = (role) => {
        let cpy = [...checkedRoles]
        if(cpy.find(r => (r === role)))
            cpy = cpy.filter(r => (r !== role))
        else
            cpy.push(role);
        setCheckedRoles(cpy);
    }

    const roleIsChecked = (role) => { return (checkedRoles.find(r => { return r === role }) !== undefined) }

    const setRoles = () => {


        UpdateUser(user.user_id, {roles:checkedRoles})
        .then(r => r.json())
        .then(r => {
            console.log(r);
//            onClose();
            onUpdate();
        });
        // roles to remove
        // let toRemove = [];
        // userRoles.forEach(r => {
        //     if (!roleIsChecked(r))
        //         toRemove.push(r);
        // });

        // if (toRemove.length !== 0) {
        //     RemoveUserRoles(user.user_id, toRemove)
        //         .then(r => r.json())
        //         .then(result => {
        //             console.log(result);
        //         })
        // }

        // // roles to add
        // const toAdd = checkedRoles.reduce((tot, curr) => { return [...tot, curr.id] }, []);
        // if (toAdd.length !== 0) {
        //     SetUserRoles(user.user_id, toAdd)
        //         .then(r => r.json())
        //         .then(result => {
        //             console.log(result);
        //         })
        // }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                sx={{ outline: 0 }}
                disableEnforceFocus={true}
                disableAutoFocus={true}
            >
                <Box sx={style}>

                    <Box style={{ display: 'flex', flexDirection: 'row', color: '#555', paddingBottom:2 }}>
                        <AccountCircle />
                        <Typography sx={{ marginLeft: 1 }}>{user.email}</Typography>
                    </Box>

                    <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                    <FormGroup>
                        {roles.map((k, i) => {return <FormControlLabel key={i} control={<Checkbox checked={roleIsChecked(k._id)} onChange={(v) => { toggleRole(k._id) }} />} label={k._id} />})}
                    </FormGroup>
                    <Box sx={{paddingTop:1}}>
                        <Button /*disabled={!userRolesChanged()}*/ variant='contained' onClick={setRoles}>Apply</Button>
                        <Button variant='contained' onClick={onClose} color='secondary' sx={{ marginLeft: 1 }}>Close</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default UserModal;