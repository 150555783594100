import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import BuildIcon from "@mui/icons-material/Build";
import InstallIcon from "@mui/icons-material/Download";
import ScheduleIcon from "@mui/icons-material/Schedule";
import FlightIcon from "@mui/icons-material/Flight";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import "./PartCard.css";
import BatteryCharging80TwoToneIcon from "@mui/icons-material/BatteryCharging80TwoTone";
import VideocamTwoToneIcon from "@mui/icons-material/VideocamTwoTone";
import SportsEsportsTwoToneIcon from "@mui/icons-material/SportsEsportsTwoTone";
import HandymanTwoToneIcon from "@mui/icons-material/HandymanTwoTone";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";

const GetTypeIcon = (type) => {
  const size = { width: 17, height: 17 };
  if (type === "BATTERY") return <BatteryCharging80TwoToneIcon sx={size} />;
  if (type === "FLIGHT_CONTROLLER")
    return <SportsEsportsTwoToneIcon sx={size} />;
  if (type === "CAMERA") return <VideocamTwoToneIcon sx={size} />;
  if (type === "HARDWARE") return <HandymanTwoToneIcon sx={size} />;

  return <SettingsTwoToneIcon sx={size} />;
};

const getDate = (timedate) => {
  const d = new Date(timedate);
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
};

const getScheduledServiceDate = (timedate, interval) => {
  const d = new Date(timedate);
  d.setDate(d.getDate() + interval.d);
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
};

const PartCard = ({ part, select }) => {
  //    console.log(part);

  return (
    <>
      <Card
        sx={{
          display: "flex",
          marginTop: 0.8,
          padding: 1,
          paddingRight: 2,
          paddingLeft: 2,
          backgroundColor: part.isActive ? "" : "#ffcccc",
        }}
        onClick={() => {
          select(part);
        }}
        className="partCard"
      >
        <Box sx={{ flexDirection: "column" }}>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Box sx={{ marginTop: 0.4, marginRight: 0.7 }}>
              {GetTypeIcon(part.type)}
            </Box>
            <Box>
              <Typography sx={{ fontSize: 15 }} color="text.primary">
                {part.name}
              </Typography>
            </Box>
          </Box>
          <Typography sx={{ fontSize: 12 }} color="text.secondary">
            {part.model}
          </Typography>
          <Typography sx={{ fontSize: 12 }} color="text.secondary">
            {part.type}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", marginLeft: "auto", alignItems: "center" }}>
          <Typography variant="subtitle1" component="div" fontSize={13}>
            {part.serial}
          </Typography>
        </Box>
        <Box
          sx={{ flexDirection: "column", textAlign: "right", marginLeft: 2 }}
        >
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            fontSize={12}
            lineHeight={1.5}
          >
            <InstallIcon style={{ fontSize: 15 }} />
            {getDate(part.dateInstalled)}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            fontSize={12}
            lineHeight={1.5}
          >
            <BuildIcon style={{ fontSize: 15 }} />
            {getDate(part.lastService)}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            fontSize={12}
            lineHeight={1.5}
          >
            <FlightIcon style={{ fontSize: 15 }} />
            {part.totalFlightHours}h
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: 4 }}>
          <Box sx={{}}>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              fontSize={12}
              lineHeight={1.5}
            >
              <ScheduleIcon style={{ fontSize: 15 }} />
              {getScheduledServiceDate(
                part.lastService,
                part.serviceInterval.duration
              )}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              fontSize={12}
              lineHeight={1.5}
            >
              <MiscellaneousServicesIcon style={{ fontSize: 15 }} />
              {part.serviceInterval.afterFlightHours -
                part.flightHoursSinceLastService}
              h
            </Typography>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default PartCard;
