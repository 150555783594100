class Software
{
    constructor(software = {}){

        // this.softwareId = software.softwareId || null;
        if(software.softwareId)
            this.softwareId = software.softwareId;
        this.active = software.active || false;
        this.name = software.name || "";
        this.version = software.version || 0;
        this.company = software.company || "";
        this.size = software.size || "";
        this.notes = software.notes || "";
        this.documentation = software.documentation || "";
        this.createdBy = software.createdBy || "";
    }
}

// module.exports = { Software };
export { Software };