import { Modal, Box } from "@mui/material";
import { useReducer } from "react";
import { DefaultReducer } from "../../../../components/DefaultReducer";
import { TextFieldInput, TextInputArea } from "../../../../components/Inputs";
import { Button } from "../../../../components/Buttons";
import { Software } from "../../../../models/SoftwareModel";
const InitialFormState = new Software();

const AdminAddSoftwareModal = ({ onClose, onAdd }) => {

    const [formState, formStateDispatch] = useReducer(DefaultReducer, InitialFormState);

    return (
        <div>
            <Modal
                open={true}
                onClose={onClose}
                sx={{ outline: 0 }}
                disableEnforceFocus={true}
                disableAutoFocus={true}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 450,
                    bgcolor: 'background.paper',
                    border: '0px solid #000',
                    boxShadow: 5,
                    pt: 1,
                    px: 2,
                    pb: 3
                }}>
                    <div className="border-b">Add Software</div>
                    <div className="grid grid-cols-1 gap-5 pt-2">
                        <TextFieldInput name={"Name"} placeholder={"name"} onChange={(v) => { formStateDispatch({ name: v }) }} value={formState.name} />
                    </div>
                    <div className="grid grid-cols-2 gap-5 pt-4">
                        <TextFieldInput name={"Company"} placeholder={"company"} onChange={(v) => { formStateDispatch({ company: v }) }} value={formState.company} />
                        <TextFieldInput name={"Version"} placeholder={"version"} onChange={(v) => { formStateDispatch({ version: v }) }} value={formState.version} />
                        <TextFieldInput name={"Size"} placeholder={"size"} onChange={(v) => { formStateDispatch({ size: v }) }} value={formState.size} />
                        <TextFieldInput name={"Active"} placeholder={"true"} onChange={(v) => { formStateDispatch({ active: v }) }} value={formState.active} />
                    </div>
                    <div className="grid grid-cols-1 gap-5 pt-4">
                        <TextInputArea name={"Notes"} placeholder={"notes"} onChange={(v) => { formStateDispatch({ notes: v }) }} value={formState.notes} rows={3} />
                        <TextInputArea name={"Documentation"} placeholder={"documentation"} onChange={(v) => { formStateDispatch({ documentation: v }) }} value={formState.documentation} rows={3} />
                    </div>
                    <div className="mt-4 flex flex-row justify-end">
                        <div className="mr-4">
                            <Button label={"Cancel"} variant={"secondary"} onClick={onClose} />
                        </div>
                        <div>
                            <Button label={"Add Software"} onClick={() => { onAdd(formState) }} />
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default AdminAddSoftwareModal;