import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const OverviewPartsPlatformPartsData = ({data, width=300, height=200}) => {

    return (
        <BarChart
          width={width}
          height={height}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="parts" stackId="a" fill="#8884d8" />
        </BarChart>
    );
  }



export default OverviewPartsPlatformPartsData;