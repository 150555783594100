export const Button = ({ label, onClick, variant = "primary", disabled=false }) => {

    const getBg = () => {

        if(disabled)
            return "bg-slate-300"

        if (variant === "primary")
            return "bg-green-500";

        if (variant === "secondary")
            return "bg-purple-400";

        if (variant === "warning")
            return "bg-red-400";

        return "bg-green-500";

    }

    const getTxtCol = () => {

        if(disabled)
            return "text-slate-500"

        if (variant === "primary")
            return "text-green-100";

        if (variant === "secondary")
            return "text-white";

        if (variant === "warning")
            return "text-white";

        return "text-green-100";
    }

    return (
        <button
            type="submit"
            className={`flex justify-center rounded ${getBg()} px-3 py-1.5 text-sm font-semibold leading-3 ${getTxtCol()} shadow-sm hover:bg-green-200 hover:text-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:cursor-pointer`}
        >
            <div className="text-xs" onClick={onClick}>
                {label}
            </div>
        </button>
    )
}