//import LoginPage from "./pages/login/LoginPage";
import LoginPage from "./pages/login/CognitoLoginPage";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ProjectsWrapper from "./components/ProjectsWrapper";
//import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from "./providers/AuthProvider";
import { createContext } from "react";
import { useDispatch } from 'react-redux';
import { logInActions } from './store';
import './App.css';
//import { refreshSession } from "./pages/login/UserPool";


export const RequestErrorHandlerContextNEW = createContext();

const App = () => {

  const loggedIn = useSelector(state => state.auth.loggedIn);
  //  const { logout } = useAuth0();
  //  const { logout, getRefreshToken } = useAuth();
  const Auth = useAuth();
  const dispatch = useDispatch();




  /**
   * 
   * @param {function} request API request 
   */
  const APIRequestErrorHandlerWithRefreshLogic = (request) => {

    const RefreshTokenHandler = (resolve, reject) => {
      Auth.refreshSession((err, res) => {
        if(err) {
          reject(err);
        } else {
          resolve(res);
        }
      })
    };

    const RunRequest = (resolve, reject) => {
      request()
      .then(r=>r.json())
      .then(r => {
        if(r.message === "The incoming token has expired") {
          reject(r);
        } else {
          resolve(r);
        }
      }, err => reject(err));
    }

    const MakeRequest = (resolve, reject) => {
      const initialRequest = new Promise(RunRequest);
      initialRequest.then(res => {
        resolve(res);
      }, err => {
        const refreshToken = new Promise(RefreshTokenHandler);
        refreshToken.then(res => {
          dispatch(logInActions.updateTokens({ token: res.getAccessToken().jwtToken, refreshToken: res.getRefreshToken().token }));
          const retryRequest = new Promise(RunRequest);
          retryRequest.then(res => resolve(res))
        })
      })      
    }

    return new Promise(MakeRequest);

  }



  const APIRequestErrorHandler = (response) => {
    
    if (response.error) {

      if (response.error === 'invalid_token') {
        // token has expired
        console.log('token has expired');
        Auth.logout();
        dispatch(logInActions.logOut());
      }

      if (response.error?.name === 'TokenExpiredError') {
        // token has expired
        console.log('token has expired');
        //        console.log(Auth.getRefreshToken());       

        // Auth.refreshSession((err, result) => {
        //   console.log(err, result);
        // })

        Auth.logout();
        dispatch(logInActions.logOut());

        // refresh token
        //        logout({ logoutParams: { returnTo: window.location.origin } });
        //        dispatch(logInActions.logOut());
      }


      
      throw Error(response.error);
    }

    if (response.message === 'Failed to fetch') {
      Auth.logout();
      dispatch(logInActions.logOut());      
    }

    if (response.message === 'The incoming token has expired') {
      console.log('refresh token here...');

      const RefreshHandler = (resolve, reject) => {
        Auth.refreshSession((err, res) => {
          console.log('.......TOKEN REFRESHED......');
          dispatch(logInActions.updateTokens({ token: res.getAccessToken().jwtToken, refreshToken: res.getRefreshToken().token }));
          resolve(response);
        })
      }

      return new Promise(RefreshHandler);
    }

    return response;
  }


  return (
    <RequestErrorHandlerContextNEW.Provider value={APIRequestErrorHandler}>
      <BrowserRouter>
        {!loggedIn ?
          <Routes>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" element={<LoginPage />} />
          </Routes>
          : <ProjectsWrapper />
        }
      </BrowserRouter>
    </RequestErrorHandlerContextNEW.Provider>
  );
}

export default App;
