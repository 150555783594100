import { Routes, Route } from "react-router-dom";
import PlatformList from "./AdminPlatformListContainer";
//import PlatformIndividual from "./AdminPlatformIndividual";


const AdminPlatforms = () => {

    return (
        <Routes>
            <Route path="/" element={<PlatformList />} />
        </Routes>
    )
}

export default AdminPlatforms;