import { Modal, Box } from "@mui/material";
import { Button } from "../../../../components/Buttons";

export const UserRemoveSoftwareModal = ({ software, onClose, onRemove }) => {
  return (
    <Modal
      open={true}
      onClose={onClose}
      sx={{ outline: 0 }}
      disableEnforceFocus={true}
      disableAutoFocus={true}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 450,
          bgcolor: "background.paper",
          border: "0px solid #000",
          boxShadow: 5,
          pt: 1,
          px: 2,
          pb: 3,
        }}
      >
        <div className="border-b mb-4">
          <h5>Remove Software</h5>
        </div>

        <div className="text-sm">Name: {software.name}</div>
        <div className="text-sm">Version: {software.version}</div>
        <div className="text-sm">Company: {software.company}</div>

        <div className="border-b mt-4 mb-4"></div>

        <div className="text-md font-semibold text-center">Create task to remove software</div>
        <div className="text-md font-semibold text-center">Are you sure?</div>

        <div className="flex flex-row justify-center mt-4">
          <div className="mr-4">
            <Button label={"Cancel"} variant={"secondary"} onClick={onClose} />
          </div>
          <Button
            label={"Create Task"}
            variant={"warning"}
            onClick={() => {
              onRemove(software);
            }}
          />
        </div>
      </Box>
    </Modal>
  );
};
