class AuthInterface
{
    constructor()
    {
        this.refreshToken = null;
        this.accessToken = null;
    }

    logout = (callback)=>{ throw new Error("logout not implemented") };
    refreshSession = (callback)=>{ throw new Error("logout not implemented") };
    setUserDetails = (user) => { this.user = user; };
    getRefreshToken = () => { return this.refreshToken; }
    getAccessToken = () => { throw this.accessToken; }
    setRefreshToken = (token) => { this.refreshToken = token; }
    setAccessToken = (token) => { this.accessToken = token; }
}

export { AuthInterface }