import { Box } from "@mui/material";
import { useState } from "react";
import bg from './hangarBG.jpg';
import logo from './SNCLOGO.webp';
import './LoginPage.css';
import SignUpForm from "./SignUpForm";
import VerificationForm from "./VerificationForm";
import PasswordResetForm from "./PasswordResetForm";
import PasswordResetVerification from "./PasswordResetVerification";

const LOGIN_STATE = {
    LOG_IN: 'log_in',
    VERIFY_ACCOUNT: 'verify_account',
    FORGOT_PASSWORD: 'forgot_password',
    VERIFY_PASSWORD_RESET: 'verify_password_reset',
    ENTER_NEW_PASSWORD: 'enter_new_password',
}

const LoginPage = () => {

    const [loginState, setLoginState] = useState(LOGIN_STATE.LOG_IN);
    const [userDetails, setUserDetails] = useState(null);

    const onUserSignUp = (form) => {
        setUserDetails(form);
        setLoginState(LOGIN_STATE.VERIFY_ACCOUNT);
    }

    const onReturnToLogin = () => setLoginState(LOGIN_STATE.LOG_IN);

    const onPasswordReset = (form) => {
        setUserDetails(form);
        setLoginState(LOGIN_STATE.VERIFY_PASSWORD_RESET);
    }

    return (
        <Box id="login-page-container" style={{ backgroundImage: `url(${bg})` }}>
            <img src={logo} style={{ width: 200, marginBottom: 10 }} alt={"logo"} />
            <Box id="signup-form-container">
                {loginState === LOGIN_STATE.LOG_IN ? <SignUpForm onUserSignUp={onUserSignUp} onForgotPassword={()=>{setLoginState(LOGIN_STATE.FORGOT_PASSWORD)}} /> : ''}
                {loginState === LOGIN_STATE.VERIFY_ACCOUNT ? <VerificationForm userDetails={userDetails} returnToLogin={onReturnToLogin} /> : ''}
                {loginState === LOGIN_STATE.FORGOT_PASSWORD ? <PasswordResetForm returnToLogin={onReturnToLogin} onPasswordReset={onPasswordReset} /> : ''}
                {loginState === LOGIN_STATE.VERIFY_PASSWORD_RESET ? <PasswordResetVerification returnToLogin={onReturnToLogin} userDetails={userDetails}/> : ''}
            </Box>
        </Box>
    )
}
export default LoginPage; 