import { Input, Modal, TextField, Typography, Select, MenuItem, Button, Divider, InputAdornment, Card } from "@mui/material";
import LocationIcon from '@mui/icons-material/LocationOn';
import { Box } from "@mui/system";
import { Close, UploadFile } from '@mui/icons-material';
import PlatformRemoveModal from "./AdminRemovePlatformModal";
import { useReducer, useState } from "react";
import { DefaultReducer } from "../../../../components/DefaultReducer";
import { UpdatePlatform } from "../../../../services/Platforms";
import { Locations as GetLocations } from "../../../../services/Locations";
import { Build } from "@mui/icons-material";
//import  from "@mui/material";
import { useEffect } from "react";

import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import AddPartsToPlatformModal from "./AdminAddPartsToPlatformModal";
import EditPlatformPartModal from "./EditPlatformPartModal";
import AddIcon from '@mui/icons-material/Add';



//import PartsAllCard from "../../../parts/tabs/PartsAllCard";
import PartCard from "../../../platforms/tabs/PartCard";
import Platform from "../../../../models/PlatformModel";

//import { UpdatePlatform } from "../../../../services/Platforms";



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 1200,
    height: 675,
    maxHeight: '90%',
    bgcolor: 'white',
    border: '0px solid #000',
    boxShadow: 4,
    p: 2,
};


const MODAL_STATE = {
    NONE: "no_modal",
    REMOVE_PLATFORM: "remove_platform_modal",
    ADD_PARTS: "add_parts_modal",
    EDIT_PARTS: "edit_parts_modal",
}

const PlatformEditModal = ({ platform, onClose, onUpdate }) => {

    const [formState, formStateDispatch] = useReducer(DefaultReducer, platform);
    const [modalState, setModalState] = useState({modal: MODAL_STATE.NONE, data:null});
    const [locations, setLocations] = useState([]);
    const { onPlatformUpdate, onPlatformRemove } = onUpdate;



    const updatePlatform = () => {
    //    console.log(formState);

       let update = {};
       Object.keys(formState).forEach(k => {
        if(formState[k] !== platform[k]) {
            update[k] = formState[k];
        }
       });

    //    console.log(update);


    //    return;

        UpdatePlatform(platform._id, update)
            .then(r => r.json())
            .then(r => {
                onPlatformUpdate(new Platform(r));
                onClose();
            })
    }

    const isFormChanged = () => {
        let r = false;
        ['name', 'manufacturer', 'model', 'serial', 'status', 'location']
        .forEach(k => {
            if(platform[k] !== formState[k])
                r = true;
        })
        return r;
    }

    const updatePlatformPart = (update) => {
        UpdatePlatform(platform._id, {op:"update_part", data:update})
            .then(r => r.json())
            .then(r => {
//                onPlatformUpdate(r);
                console.log(r);
                onPlatformUpdate(new Platform(r));
//                onClose();
            })

    }

    useEffect(() => {
        GetLocations()
            .then(r => r.json())
            .then(r => setLocations(r));
    }, []);

    const addPartsToPlatform = (part) => {
        console.log(part);
        UpdatePlatform(platform._id, {op:"add_part", data:part})
        .then(r => r.json())
        .then(r => {
            console.log(r);
            onPlatformUpdate(new Platform(r));
        })

    }

    const removePlatformPart = (part) => {
//        console.log(part);
        UpdatePlatform(platform._id, {op:"remove_part", data:part})
        .then(r => r.json())
        .then(r => {
            console.log(r);
            onPlatformUpdate(new Platform(r));
        })
    }
    

    return (<>
        <Modal
            open={true}
            onClose={onClose}
            sx={{ border: '1px solid #000', outline: 'none' }}
            disableEnforceFocus={true}
            disableAutoFocus={true}
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography sx={{ fontSize: 20, marginTop: 0.8, marginLeft: 1 }} color="text.primary">Edit Platform: {platform.name}</Typography>
                    <Close sx={{ marginLeft: 'auto' }} color="text.secondary" onClick={onClose} />
                </Box>

                <Box sx={{ display: 'flex', paddingLeft: 1 }}>
                    <Typography color="text.secondary" sx={{ fontSize: 12 }}>Created: {new Date().toDateString()}</Typography>
                </Box>
                <Box sx={{ display: 'flex', marginBottom: 2, paddingLeft: 1 }}>
                    <Typography color="text.secondary" sx={{ fontSize: 12 }}>Last Update: {new Date().toDateString()}</Typography>
                </Box>


                <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: 1, border: '1px solid #bbb', paddingTop: 2, paddingBottom: 2, borderRadius: 2 }}>

                    <Box sx={{ width: '75%', paddingRight: 1 }}>

                        <TextField
                            label="Name"
                            size="small"
                            sx={{ width: '45%' }}
                            value={formState.name}
                            onChange={(v) => { formStateDispatch({ name: v.target.value }) }}

                        />
                        <TextField
                            label="Model"
                            size="small"
                            sx={{ width: '45%', marginLeft: 1 }}
                            value={formState.model}
                            onChange={(v) => { formStateDispatch({ model: v.target.value }) }}

                        />
                        <TextField
                            label="Manufacturer"
                            size="small"
                            sx={{ width: '45%', marginTop: 2 }}
                            value={formState.manufacturer}
                            onChange={(v) => { formStateDispatch({ manufacturer: v.target.value }) }}
                        />
                        <TextField
                            label="Serial"
                            size="small"
                            sx={{ width: '45%', marginTop: 2, marginLeft: 1 }}
                            value={formState.serial}
                            onChange={(v) => { formStateDispatch({ serial: v.target.value }) }}

                        />
                        <TextField
                            select
                            label="Status"
                            size="small"
                            sx={{ width: '45%', marginTop: 2 }}
                            value={formState.status}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><Build /></InputAdornment>,
                            }}

                            onChange={(v) => { formStateDispatch({ status: v.target.value }) }}

                        >
                            <MenuItem value={"ACTIVE"}>Active</MenuItem>
                            <MenuItem value={"MAINTENANCE"}>Maintenance</MenuItem>
                        </TextField>

                        <TextField
                            select
                            label="Location"
                            id="filterLocation"
                            sx={{ width: '45%', marginTop: 2, marginLeft: 1 }}
                            size="small"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><LocationIcon /></InputAdornment>,
                            }}
                            value={formState.location}
                            onChange={(v) => { formStateDispatch({ location: v.target.value }) }}
                        >
                            {locations.map((v, i) => { return (<MenuItem key={i} value={v.name}>{v.name}</MenuItem>) })}

                        </TextField>

                        {/** 
                         * 
                        <Button variant="outlined" sx={{ width: '45%', marginTop: 2 }} onClick={() => { setModalState(MODAL_STATE.ADD_PARTS) }}>add parts</Button>
                        */}




                    </Box>
                    <Box sx={{ width: '25%', display: 'flex', flexDirection: 'column', justifyContent: 'right', alignItems: 'right', paddingRight: 2 }}>


                        {platform.img === "" ?

                            <Box sx={{ backgroundColor: '#eaeaea', width: 150, height: 150, textAlign: 'center', marginLeft: 'auto' }}>
                                <UploadFile sx={{ paddingTop: 5, color: '#555' }} />
                                <Typography color="text.secondary">Platform image</Typography>
                            </Box>
                            :
                            <img id="project-image-upload" src={platform.img} style={{ width: 170, height: 170, marginLeft: 'auto', border: '1px solid #bbb', borderRadius: 2 }} />
                        }
                        <input type="file" id="project-image-upload-source" style={{ display: 'none' }} onChange={() => { }} />

                        {/** 
                        <Button type="file" variant='outlined' size='small' sx={{ width: 100, marginLeft: 'auto', marginTop: 1 }} onClick={() => { document.getElementById("project-image-upload-source").click() }}>set image</Button>
                         * 
                        */}
                    </Box>
                </Box>


                <Box sx={{ backgroundColor: '#fff', height: 300, overflowY: 'scroll', padding: 0.5,paddingTop:0, borderRadius: 1, marginTop:2, border:'1px solid #ccc' }}>
                    {platform.parts.map((p, i) => <PartCard key={i} part={p} select={()=>{setModalState({modal:MODAL_STATE.EDIT_PARTS, data:p})}}/>)}

                    <Card sx={{ height: 40, display:'flex', justifyContent:'center', alignItems:'center', marginTop:0.75 }} className="cursor-on-hover" onClick={()=>{setModalState({modal: MODAL_STATE.ADD_PARTS})}}>
                        <AddIcon sx={{color:'#666'}}/>
                        <Typography color="text.secondary">Add Part</Typography>
                    </Card>

                </Box>


                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Button type="file" variant='outlined' color='error' size='small' sx={{ marginTop: 1, marginRight: 1 }} onClick={() => { setModalState({modal:MODAL_STATE.REMOVE_PLATFORM}) }}>Remove Platform</Button>
                    <Box sx={{ marginLeft: 'auto', width: 350, marginTop: 1 }}>
                        <Button type="file" variant='outlined' color='secondary' size='small' sx={{ width: '45%', marginLeft: 'auto', marginTop: 1, marginRight: 1 }} onClick={onClose}>close</Button>
                        <Button type="file" variant='contained' size='small' sx={{ width: '45%', marginLeft: 'auto', marginTop: 1 }} onClick={updatePlatform} disabled={!isFormChanged()}>Apply changes</Button>
                    </Box>
                </Box>


            </Box>
        </Modal>
        {/*removeModal ? <PlatformRemoveModal platform={platform} onClose={() => { setRemoveModal(false) }} onRemove={onPlatformRemove} /> : ''*/}
        {modalState.modal === MODAL_STATE.REMOVE_PLATFORM ? <PlatformRemoveModal platform={platform} onClose={() => { setModalState({modal:MODAL_STATE.NONE}) }} onRemove={onPlatformRemove} /> : ''}
        {modalState.modal === MODAL_STATE.ADD_PARTS ? <AddPartsToPlatformModal onClose={() => { setModalState({modal:MODAL_STATE.NONE}) }} onAddPart={addPartsToPlatform}/> : ''}
        {modalState.modal === MODAL_STATE.EDIT_PARTS ? <EditPlatformPartModal part={modalState.data} onClose={() => { setModalState({modal:MODAL_STATE.NONE}) }} updatePlatformPart={updatePlatformPart} removePlatformPart={removePlatformPart}/> : ''}
    </>)
}

export default PlatformEditModal;