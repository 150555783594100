import { Card, Typography } from "@mui/material"
import ScheduleIcon from '@mui/icons-material/DateRange';
import BuildIcon from '@mui/icons-material/Build';
import AddIcon from '@mui/icons-material/Add';
import EyeIcon from '@mui/icons-material/Visibility';
import UpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import LinkIcon from '@mui/icons-material/Link';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import { Box } from "@mui/system";
//import './PlatformTaskCard.css';
import { TASK_TYPE } from "../../../../models/TasksModel";
import { Settings } from "@mui/icons-material";


const getPriority = (p) => {

    return <UpIcon sx={{ fontSize: 15, marginTop: -0.3, marginLeft: 2.5, color: 'green' }} />

    
    if (p === 0)
        return <UpIcon sx={{ fontSize: 15, marginTop: -0.3, marginLeft: 2.5, color: 'green' }} />
    else if (p === 1)
        return <UpIcon sx={{ fontSize: 15, marginTop: -0.3, marginLeft: 2.5, color: 'orange' }} />
    else if (p === 2)
        return <UpIcon sx={{ fontSize: 15, marginTop: -0.3, marginLeft: 2.5, color: 'red' }} />
}

const getPartStatus = (task) => {

    return <Settings sx={{ color: '#b3b3cc', fontSize: 16, marginTop: 0.7, marginRight: 0.8 }} />

    if([TASK_TYPE.PLATFORM_ADD_PART_TASK, TASK_TYPE.PLATFORM_REPLACE_PART_TASK].includes(task.type))
        return <Settings sx={{ color: '#b3b3cc', fontSize: 16, marginTop: 0.7, marginRight: 0.8 }} />
}

const getTypeIcon = (type) => {

    const style = { fontSize: 16, color: 'green' };
    const styleSmall = { fontSize: 13, color: 'green' };


    return <AddIcon sx={style} />

    if(type === TASK_TYPE.PLATFORM_ADD_PART_TASK)
        return <AddIcon sx={style} />
    if(type === TASK_TYPE.PLATFORM_REMOVE_PART_TASK)
        return <BuildIcon sx={styleSmall} />
    if(type === TASK_TYPE.PLATFORM_REPAIR_PART_TASK)
        return <BuildIcon sx={styleSmall} />
    if(type === TASK_TYPE.PLATFORM_REPLACE_PART_TASK)
        return <BuildIcon sx={styleSmall} />
    if(type === TASK_TYPE.PLATFORM_SCHEDULED_PART_TASK)
        return <ScheduleIcon sx={style} />

    return <ScheduleIcon sx={style} />
}

const getDate = (timestamp) => {
    if(timestamp)
    {
        let date = new Date(timestamp);
        let current = new Date();
        let diff_seconds = Math.floor((current - date)/(1000));
        let diff_minutes = Math.floor((current - date)/(1000*60));
        let diff_hours = Math.floor((current - date)/(1000*60*60));
        let diff_days = Math.floor((current - date)/(1000*60*60*24));
        if(diff_seconds < 60)
            return `${diff_seconds} seconds ago`
        if(diff_minutes < 60)
            return `${diff_minutes} mins ago`
        if(diff_hours < 24)
            return `${diff_hours} hours ago`
        else
            return `${diff_days} days ago`
    }
    return "12/12/12";
}


const PlatformTaskHistoryCard = ({ task, onTaskSelect }) => {

    return (
        <Card sx={{}} onClick={() => { onTaskSelect(task) }} className="platformTaskCard">
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Avatar sx={{ bgcolor: '#e0e0e0', width: 25, height: 25, fontSize: 10, margin: 0.5 }}>{getTypeIcon(task.type)}</Avatar>
                <Typography fontSize={13} sx={{ marginTop: 0.8, marginLeft: 0.5 }}>
                    {task.description}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', height: 19 }}>
                <Box>
                    <Typography fontSize={12} sx={{ marginTop: 0.5, marginLeft: 0.5 }} color="text.secondary">
                        {task.subscribers.length} <EyeIcon sx={{ fontSize: 12, marginTop: -0.2 }} />
                    </Typography>
                </Box>
                <Box>
                    {getPriority(task.priority)}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography fontSize={12} sx={{ marginTop: 0.5, marginLeft: 0.5 }} color="text.secondary">
                    {getDate(task.createdAt)}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto', marginRight: 0.5 }}>
                {getPartStatus(task)}
                    <LinkIcon sx={{ color: '#888', fontSize: 16, marginTop: 0.7, marginRight: 0.8 }} />
                    {
                        task.assignee ? <Avatar sx={{ width: 25, height: 25, display: 'flex' }} src={task.assignee.picture} /> :
                            <Avatar sx={{ bgcolor: deepOrange[500], width: 25, height: 25, fontSize: 10, display: 'flex' }}></Avatar>
                    }
                </Box>
            </Box>
        </Card>
    )
}

export default PlatformTaskHistoryCard;
