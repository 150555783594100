import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography, Button, InputAdornment } from '@mui/material';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { useState, useReducer } from 'react';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import { verifyUser, getAccessToken } from './UserPool';
import FormLoading from './FormLoading';
import { useDispatch } from 'react-redux';
import { logInActions } from '../../store';
import theme from './SignUpFormTheme';
import { CreateUser } from '../../services/Users';
import { TOKENS } from "../../config";
import { TextFieldInput } from '../../components/Inputs';
import { FailureNotification, SuccessNotification } from '../../components/Notifications';

const VerificationForm = ({ userDetails, returnToLogin }) => {

    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [verificationComplete, setVerificationComplete] = useState(false);
    const [codeMessage, setCodeMessage] = useState({ show: false, message: '' });
    const CODE_LENGTH = 6;
    const dispatch = useDispatch();

    const loginSuccess = (result) => {

        // console.log(result); 
        // console.log(result.getRefreshToken().jwtToken); 
        localStorage.setItem(TOKENS.AUTH_TOKEN, String(result.getAccessToken().jwtToken));
        localStorage.setItem(TOKENS.REFRESH_TOKEN, String(result.getRefreshToken().jwtToken));

        const id_token = result.getIdToken().jwtToken;
        // console.log(id_token);



        CreateUser({ id: id_token })
            .then(r => r.json())
            .then(user => {
                //                console.log(user);
                dispatch(logInActions.logIn({ token: result.getAccessToken().jwtToken, refreshToken: result.getRefreshToken().token, user: user }));
            });


        //         GetUserProfile(user_id)
        //             .then(r => r.json())
        //             .then(user => {
        //                 console.log(user);
        // //                dispatch(logInActions.logIn({ token: result.getAccessToken().jwtToken, user: user }));
        //             }
        //             );





        // dispatch( logInActions.logIn({ token: result, user: { username: 'test', name: 'test', role: 'Admin' } }) );
        // setLoading(false);
    }

    const loginFailure = (result) => {
        //        console.log(result);
        setCodeMessage({ show: true, message: result });
    }

    const submitVerificationCode = () => {

        setCodeMessage({ show: false, message: '' });
        /*
        if(code.length !== CODE_LENGTH) {
            setCodeMessage({show:true, message:'Code must be 6 digits'});
            return;
        }*/
        setLoading(true);
        verifyUser(userDetails.email.value, code, (err, result) => {
            setLoading(false);
            if (err) {
                setCodeMessage({ show: true, message: err.message });
            }
            else if (result === 'SUCCESS') {
                setVerificationComplete(true);
                getAccessToken(userDetails.email.value, userDetails.password.value, loginSuccess, loginFailure);
                //                setTimeout(()=>{
                //                }, 1000);
            }
        })
    }

    const disableSubmit = () => code.length !== CODE_LENGTH;

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {},
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <ThemeProvider theme={theme}>
                    <Typography variant='h1'>Verify email address</Typography>
                </ThemeProvider>

                <div className="signin-form-line-text">
                    A verification code has been sent to:
                    <div className="highlight">{userDetails.email.value}</div>
                </div>

                <Box className="form-input-field">

                    {/* <TextField
                        required
                        label="Enter verification code"
                        size='small'
                        fullWidth={true}
                        value={code}
                        onChange={(v) => {
                            if (v.target.value.length <= CODE_LENGTH)
                                setCode(v.target.value)
                            setCodeMessage({ show: false, message: '' })
                        }}
                        helperText={codeMessage.message}
                        error={codeMessage.show}
                        InputProps={verificationComplete ? {
                            endAdornment: (<InputAdornment position="end"><CheckCircleTwoToneIcon sx={{ color: 'green' }} /></InputAdornment>)
                        } : {}}
                    /> */}


                    <TextFieldInput
                        name={"Verification code:"}
                        placeholder={"enter code"}
                        onChange={(v) => {
                            if (v.length <= CODE_LENGTH)
                                setCode(v)
                            setCodeMessage({ show: false, message: '' })
                        }}
                        value={code}
                        onEnter={() => {
                            if (!disableSubmit()) {
                                submitVerificationCode();
                            }
                        }}
                        disabled={loading || verificationComplete}
                    />


                    <div className="my-4">
                        {verificationComplete ?    
                            <SuccessNotification text={"Verificiation successful"} /> : null
                        }
                    </div>


                    <div className="my-4">
                        {codeMessage.show && (codeMessage.message !== '') ?
                            <FailureNotification text={codeMessage.message} /> : null
                        }
                    </div>




                </Box>
                {verificationComplete ?
                    <div className="signin-form-line-text success">
                        Verification successful
                    </div> : ''
                }
                <Box className="form-input-field">
                    {/* <Button variant='contained' sx={{ width: '100%' }} size='small' onClick={submitVerificationCode} disabled={(code.length !== CODE_LENGTH) || verificationComplete}>submit</Button> */}
                    <Button variant='contained' sx={{ width: '100%' }} size='small' onClick={submitVerificationCode} disabled={(code.length !== CODE_LENGTH) || verificationComplete}>submit</Button>
                </Box>
                <Box className="form-input-field signin-form-line-text">
                    Resend <span className="highlight-link cursor-on-hover">verification code?</span>
                </Box>
                <Box className="form-input-field signin-form-line-text">
                    Return to<span className="highlight-link cursor-on-hover" onClick={returnToLogin}> login</span>
                </Box>
            </div>
            {loading ? <FormLoading /> : ''}
        </Box>
    );
}

export default VerificationForm;