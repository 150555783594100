//import * as React from 'react';
//import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Logout } from '@mui/icons-material';
import Settings from '@mui/icons-material/Settings';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, CircularProgress, Divider, TextField } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { Key } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useState } from 'react';
import { UpdateUser, User as GetUser, UpdateUserProfile } from '../services/Users';
import { logInActions } from '../store';
import { TOKENS } from '../config';
//import { useAuth } from '../providers/AuthProvider';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 5,
    pt: 1,
    px: 2,
    pb: 3
};

const MODAL_STATE = {
    NONE: "none",
    UPDATE_NAME: "update_name",
    UPDATE_AVATAR: "update_avater",
}


const UpdateNameModal = ({ field, current, onClose, onUpdate }) => {

    const [name, setName] = useState(current);

    return (
        <Modal
            open={true}
            onClose={onClose}
            sx={{ outline: 0 }}
            disableEnforceFocus={true}
            disableAutoFocus={true}
        >
            <Box sx={style}>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography sx={{ textAlign: 'center', marginLeft: 1 }} variant='h6'>Update {field}</Typography>
                    <Box sx={{ marginLeft: 'auto' }}>
                        <Close onClick={onClose} />
                    </Box>
                </Box>
                <Divider />
                <div style={{ paddingTop: 14 }}>
                    <TextField
                        label={field}
                        variant="outlined"
                        required={true}
                        size='small'
                        value={name}
                        onChange={(v) => { setName(v.target.value) }}
                    ></TextField>

                    <Button variant='contained' sx={{ marginLeft: 2 }} onClick={() => { onUpdate(name) }}>Update</Button>
                </div>
            </Box>
        </Modal>
    )
}

const UpdateProfilePicModal = ({ current, onClose, onUpdate }) => {

    const [imageData, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const user = useSelector(state => state.auth.user);

    // const readURL = (input) => {

    //     // console.log(input.target.files[0]);
    //     // return;

    //     if (input.target.files && input.target.files[0]) {

    //         //            const send = (data) => {

    //         const formData = new FormData();
    //         formData.append('image', input.target.files[0]);
    //         fetch('http://localhost:8080/api/users/3d7e6e47-cc6c-4f3d-88a3-7a182976e290/profile', {
    //             method: 'POST',
    //             headers: { 'Authorization': `${localStorage.getItem(TOKENS.AUTH_TOKEN)}` },
    //             body: formData
    //         })
    //             .then(response => response.text())
    //             .then(response => console.log(response))
    //             .catch(error => {
    //                 console.error('Error:', error);
    //             });
    //         //         }

    //         //         send(input.target.files[0])
    //     }
    // }


    const postImage = () => {

        setLoading(true);
        const formData = new FormData();
        formData.append('image', imageData);
        UpdateUserProfile(user.user_id, formData)
            .then(response => response.json())
            .then(response => {
                // console.log(response);
                onUpdate();
            })
            .catch(error => {
                // console.error('Error:', error);
                setLoading(false);
            });
    }



    ////////////////////// WORKING!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // const postImage = () => {

    //     // console.log(input.target.files[0]);
    //     // return;


    //     //            const send = (data) => {
    //     setLoading(true);
    //     const formData = new FormData();
    //     formData.append('image', imageData);
    //     fetch('http://localhost:8080/api/users/3d7e6e47-cc6c-4f3d-88a3-7a182976e290/profile', {
    //         method: 'POST',
    //         headers: { 'Authorization': `${localStorage.getItem(TOKENS.AUTH_TOKEN)}` },
    //         body: formData
    //     })
    //         .then(response => response.json())
    //         .then(response => {
    //             console.log(response);
    //             onUpdate();
    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //             setLoading(false);
    //         });
    // }

    return (
        <Modal
            open={true}
            onClose={onClose}
            sx={{ outline: 0 }}
            disableEnforceFocus={true}
            disableAutoFocus={true}
        >
            <Box sx={style}>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography sx={{ textAlign: 'center', marginLeft: 1 }} variant='h6'>Update avatar</Typography>
                    <Box sx={{ marginLeft: 'auto' }}>
                        <Close onClick={onClose} />
                    </Box>
                </Box>
                <Divider />
                <div style={{ paddingTop: 14, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* <Avatar sx={{ width: 50, height: 50 }} src={imageData ? imageData : current} /> */}
                    <Avatar style={{ width: 50, height: 50 }} src={imageData ? URL.createObjectURL(imageData) : current} />
                    {/* <img style={{ width: 50, height: 50 }} src={imageData ? URL.createObjectURL(imageData) : current} /> */}
                    <input type="file" name="image" id="profile-image-upload-source" style={{ display: 'none' }} onChange={(e) => { setImage(e.target.files[0]) }} />
                    <Button variant='contained' sx={{ marginLeft: 2, height: 30 }} onClick={() => { document.getElementById("profile-image-upload-source").click() }}>Select image</Button>
                </div>
                <Divider sx={{ marginTop: 1.5 }} />

                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}>
                    {loading ? <CircularProgress /> : null}
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}>
                    <Button variant='outlined' sx={{ marginLeft: 1, marginRight: 1 }} color='secondary' onClick={onClose} size="small" disabled={loading}>Cancel</Button>
                    <Button variant='outlined' sx={{ marginLeft: 1, marginRight: 1 }} onClick={postImage} size="small" disabled={loading}>Update</Button>
                </Box>


            </Box>
        </Modal>
    )

}

const ProfileModal = ({ open, onClose }) => {

    const user = useSelector(state => state.auth.user);
    const [modalState, setModal] = useState(MODAL_STATE.NONE);

    const dispatch = useDispatch();

    const updateUser = () => {

        GetUser(user.user_id)
            .then(r => r.json())
            .then(r => {
                // console.log(r);
                dispatch(logInActions.setUser({ user: r }));
            })


    }


    const updateProfile = (doc) => {
        UpdateUser(user.user_id, doc)
            .then(r => r.json())
            .then(r => {
                //            console.log(r);

                updateUser();

                setModal(MODAL_STATE.NONE);
            })
    }

    const updateProfilePic = () => {
        updateUser();
        setModal(MODAL_STATE.NONE);
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                sx={{ outline: 0 }}
                disableEnforceFocus={true}
                disableAutoFocus={true}
            >
                <Box sx={style}>

                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography sx={{ textAlign: 'center', marginLeft: 1 }} variant='h6'>Profile</Typography>
                        <Box sx={{ marginLeft: 'auto' }}>
                            <Close onClick={onClose} />
                        </Box>
                    </Box>
                    <Box sx={{ border: '1px solid #ccc', padding: 2, borderRadius: 2 }}>
                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 0, marginBottom: 20 }}>
                            <Avatar src={user.picture} />
                            <Typography sx={{ textAlign: 'center', marginLeft: 1, color: '#333' }}>{user.name}</Typography>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box sx={{ width: 250 }}>
                                <Typography color="text.secondary" sx={{ fontSize: 13 }} variant='h6'>Role:</Typography>
                                <Typography sx={{ fontSize: 13, color: '#333' }}>{user.roles[0]} </Typography>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 3 }}>
                            <Box sx={{ width: 250 }}>
                                <Typography color="text.secondary" sx={{ fontSize: 13 }} variant='h6'>Email:</Typography>
                                <Typography sx={{ fontSize: 13, color: '#333' }}>{user.email} </Typography>
                            </Box>
                            {/* <Box>
                                <Button variant='outlined' size="small">update</Button>
                            </Box> */}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 3 }}>
                            <Box sx={{ width: 250 }}>
                                <Typography color="text.secondary" sx={{ fontSize: 13 }} variant='h6'>Name:</Typography>
                                <Typography sx={{ fontSize: 13, color: '#333' }}>{user.name}</Typography>
                            </Box>
                            <Box>
                                <Button variant='outlined' size="small" onClick={() => { setModal(MODAL_STATE.UPDATE_NAME) }}>update</Button>

                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 3 }}>
                            <Box sx={{ width: 250 }}>
                                <Typography color="text.secondary" sx={{ fontSize: 13 }} variant='h6'>Nickname:</Typography>
                                <Typography sx={{ fontSize: 13, color: '#333' }}>{user.nickname}</Typography>
                            </Box>
                            <Box>
                                <Button variant='outlined' size="small" onClick={() => { setModal(MODAL_STATE.UPDATE_NICKNAME) }}>update</Button>

                            </Box>
                        </Box>
                    </Box>

                    <Divider sx={{ marginTop: 2 }} />
                    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1, color: '#444', paddingLeft: 1 }} className="cursor-on-hover" onClick={() => { }}>
                        <Key sx={{ fontSize: 18, marginRight: 1 }} /> <Typography sx={{ fontSize: 13 }}>Change password</Typography>
                    </Box>

                    <Divider sx={{ marginTop: 1 }} />
                    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1, color: '#444', paddingLeft: 1 }} className="cursor-on-hover" onClick={() => { setModal(MODAL_STATE.UPDATE_AVATAR) }}>
                        <AccountCircle sx={{ fontSize: 18, marginRight: 1 }} /> <Typography sx={{ fontSize: 13 }}>Change avatar</Typography>
                    </Box>
                    <Divider sx={{ marginTop: 1 }} />

                    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1, color: '#444', paddingLeft: 1 }} className="cursor-on-hover" onClick={() => { dispatch(logInActions.requestLogout(true)); }}>
                        <Logout sx={{ fontSize: 18, marginRight: 1 }} /> <Typography sx={{ fontSize: 13 }}>Log out</Typography>
                    </Box>

                    <Divider sx={{ marginTop: 1 }} />


                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}>
                        <Button variant='outlined' sx={{ marginLeft: 1, marginRight: 1 }} color='secondary' onClick={onClose} size="small">Cancel</Button>
                        <Button variant='outlined' sx={{ marginLeft: 1, marginRight: 1 }} onClick={() => { }} size="small">Save Changes</Button>
                    </Box>
                </Box>
            </Modal>

            {modalState === MODAL_STATE.UPDATE_NAME ? <UpdateNameModal field={"name"} current={user.name} onClose={() => { setModal(MODAL_STATE.NONE) }} onUpdate={(name) => { updateProfile({ name: name }) }} /> : null}
            {modalState === MODAL_STATE.UPDATE_NICKNAME ? <UpdateNameModal field={"nickname"} current={user.nickname} onClose={() => { setModal(MODAL_STATE.NONE) }} onUpdate={(name) => { updateProfile({ nickname: name }) }} /> : null}
            {modalState === MODAL_STATE.UPDATE_AVATAR ? <UpdateProfilePicModal current={user.picture} onClose={() => { setModal(MODAL_STATE.NONE) }} onUpdate={updateProfilePic} /> : null}
        </div>
    );
}

export default ProfileModal;