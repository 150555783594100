import { Input, Modal, TextField, Typography, Select, MenuItem, Button, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { Close, UploadFile } from '@mui/icons-material';
import { RemovePlatform } from "../../../../services/Platforms";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxWidth: 1000,
    height: 150,
    bgcolor: 'white',
    border: '0px solid #000',
    boxShadow: 4,
    p: 2,
};

const PlatformRemoveModal = ({ platform, onClose, onRemove }) => {


    const remove = () => {
        RemovePlatform(platform._id)
        .then(r => r.json())
        .then(r => {
            console.log(r);
            onRemove(r);
        })
    }

    return (<>
        <Modal
            open={true}
            onClose={onClose}
            sx={{ border: '1px solid #000', outline: 'none' }}
            disableEnforceFocus={true}
            disableAutoFocus={true}

        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography sx={{ fontSize: 20, marginTop: 0.8 }} color="text.primary">Remove Platform</Typography>
                    <Close sx={{ marginLeft: 'auto' }} color="text.secondary" onClick={onClose} />
                </Box>

                <Typography sx={{textAlign:'center'}}>Remove <b>{platform.name}</b></Typography>
                <Typography sx={{textAlign:'center'}}>Are you sure?</Typography>

                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ margin: 'auto', width: 350, marginTop: 1 }}>
                        <Button type="file" variant='outlined' color='secondary' sx={{ width: '45%', marginLeft: 'auto', marginTop: 1, marginRight: 1 }} onClick={onClose}>Cancel</Button>
                        <Button type="file" variant='outlined' color='error' sx={{ width: '45%', marginLeft: 'auto', marginTop: 1, marginRight: 1 }} onClick={remove}>Remove</Button>
                    </Box>
                </Box>

            </Box>
        </Modal>
    </>)
}

export default PlatformRemoveModal;