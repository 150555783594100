import { createSlice } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { TOKENS, USER_FIELDS } from "../config";


const InitialAuthState = () => {

    let obj = {};
    USER_FIELDS.forEach(k => {
        obj[k] = localStorage.getItem(`USERFIELD_${k}`);
    });
    obj['roles'] = [localStorage.getItem('USERROLE')];
    return obj;
}


const authSlice = createSlice({
    name: 'authentication',
    /*
        initialState: {
            loggedIn: localStorage.getItem(TOKENS.USER_NAME) ? true : false,
            token: localStorage.getItem(TOKENS.AUTH_TOKEN),
            user: {
                _id:  localStorage.getItem(TOKENS.USER_ID),
                username: localStorage.getItem(TOKENS.USER_USERNAME),
                name: localStorage.getItem(TOKENS.USER_NAME),
                email: localStorage.getItem(TOKENS.USER_EMAIL),
                role: localStorage.getItem(TOKENS.USER_ROLE),
                picture: localStorage.getItem(TOKENS.USER_PROFILE_IMG),
            }
        },
    */
    initialState: {
        loggedIn: localStorage.getItem(TOKENS.AUTH_TOKEN) ? true : false,
        token: localStorage.getItem(TOKENS.AUTH_TOKEN),
        refreshToken: localStorage.getItem(TOKENS.REFRESH_TOKEN),
        user_id: null,
        user: InitialAuthState(),
        requestLogout: false
    },



    reducers: {

        setUser: (state, action) => {
            state.user = { ...action.payload.user };
            state.loggedIn = Boolean(true);

            USER_FIELDS.forEach(k => {
                localStorage.setItem(`USERFIELD_${k}`, String(action.payload.user[k]));
            });
            localStorage.setItem('USERROLE', action.payload.user.roles[0]);

        },

        updateTokens: (state, action) => {
            localStorage.setItem(TOKENS.AUTH_TOKEN, String(action.payload.token));
            localStorage.setItem(TOKENS.REFRESH_TOKEN, String(action.payload.refreshToken));
            state.token = String(action.payload.token);
            
            if(action.payload.user_id)
            {
                state.user_id = String(action.payload.user_id);
            }

        },
        logIn: (state, action) => {


            /*
            {
                "nickname": "luc.dalton",
                "name": "Luc Dalton",
                "picture": "https://s.gravatar.com/avatar/1f5b221a2ae0d9112ecf72523a9a7517?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Flu.png",
                "updated_at": "2023-03-15T10:56:31.077Z",
                "email": "luc.dalton@snc-ms.uk",
                "email_verified": true,
                "sub": "auth0|63eb979817db485112885257",
                "role": "Admin"
            }
            */


            //            console.log(Object.keys(action.payload.user));

            localStorage.setItem(TOKENS.AUTH_TOKEN, String(action.payload.token));
            localStorage.setItem(TOKENS.REFRESH_TOKEN, String(action.payload.refreshToken));

            /*
            localStorage.setItem(TOKENS.USER_USERNAME, String(action.payload.user.username));
            localStorage.setItem(TOKENS.USER_NAME, String(action.payload.user.name));
            localStorage.setItem(TOKENS.USER_PROFILE_IMG, String(action.payload.user.picture));
            localStorage.setItem(TOKENS.USER_EMAIL, String(action.payload.user.email));
            localStorage.setItem(TOKENS.USER_ID, String(action.payload.user._id));
            localStorage.setItem(TOKENS.USER_ROLE, String(action.payload.user.role));
            */

            USER_FIELDS.forEach(k => {
                localStorage.setItem(`USERFIELD_${k}`, String(action.payload.user[k]));
            });

            localStorage.setItem('USERROLE', action.payload.user.roles[0]);

            state.loggedIn = Boolean(true);
            state.token = String(action.payload.token);
            state.user = { ...action.payload.user };

        },
        logOut: (state) => {

            //            console.log('logOut', state);
            /*
            localStorage.removeItem(TOKENS.USER_NAME);
            localStorage.removeItem(TOKENS.USER_PROFILE_IMG);
            localStorage.removeItem(TOKENS.USER_EMAIL);
            localStorage.removeItem(TOKENS.USER_ID);
            localStorage.removeItem(TOKENS.USER_ROLE);
            */

           localStorage.removeItem(TOKENS.AUTH_TOKEN);
           localStorage.removeItem(TOKENS.REFRESH_TOKEN);
           USER_FIELDS.forEach(k => {
               localStorage.removeItem(`USERFIELD_${k}`);
            });
            localStorage.clear();

            state.loggedIn = Boolean(false);
            state.token = '';
            state.requestLogout = false;
        },
        requestLogout: (state, action) => {
            state.requestLogout= Boolean(action.payload);
        }
    }
});

const platformSlice = createSlice({
    name: 'platform',
    initialState: { platform: null, refresh: false, history: null, tasks: [], allPlatforms: [] },
    reducers: {
        setPlatform: (state, action) => {
            state.platform = { ...action.payload };
        },
        setAllPlatforms: (state, action) => {
            state.allPlatforms = { ...action.payload };
        },
        setTasks: (state, action) => {

            state.tasks = [...action.payload];
        },
        setRefresh: (state) => {
            state.refresh = Boolean(!state.refresh);
        },
        setHistory: (state, action) => {
            state.history = { ...action.payload };
        }
    }
});

const tasksSlice = createSlice({
    name: 'tasks',
    initialState: { tasks: null, refresh: false, modalTask: null },
    reducers: {
        setTasks: (state, action) => {
            state.tasks = [...action.payload];
        },
        setRefresh: (state) => {
            state.refresh = Boolean(!state.refresh);
        },
        setModalTask: (state, action) => {
            state.modalTask = { ...action.payload };
        }
    }
});

const partsSlice = createSlice({
    name: 'parts',
    initialState: { parts: null, refresh: false },
    reducers: {
        setParts: (state, action) => {
            state.tasks = { ...action.payload };
        },
        setRefresh: (state) => {
            state.refresh = Boolean(!state.refresh);
        }
    }
});

const locationsSlice = createSlice({
    name: 'locations',
    initialState: { locations: null },
    reducers: {
        setLocations: (state, action) => {
            state.locations = { ...action.payload };
        }
    }
});

const projectsSlice = createSlice({
    name: 'projects',
    initialState: { projects: [], selected: null, refresh: false },
    reducers: {
        setProjects: (state, action) => {
            state.projects = { ...action.payload };
        },
        addProject: (state, action) => {
//            let p = [...state.projects];
//            p.push(action.payload);
            state.projects.push(action.payload)
        },
        setSelectedProject: (state, action) => {
            state.selected = { ...action.payload };
        },
        setRefresh: (state) => {
            state.refresh = Boolean(!state.refresh);
        }
    }
});

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        platform: platformSlice.reducer,
        tasks: tasksSlice.reducer,
        parts: partsSlice.reducer,
        locations: locationsSlice.reducer,
        projects: projectsSlice.reducer,
    }
});

export const logInActions = authSlice.actions;
export const platformActions = platformSlice.actions;
export const tasksActions = tasksSlice.actions;
export const partsActions = partsSlice.actions;
export const locationsActions = locationsSlice.actions;
export const projectsActions = projectsSlice.actions;
export default store;