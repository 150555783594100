import { CheckIcon, FailIcon } from "./Icons"

export const SuccessNotification = ({text}) => {
    return (
        <div className="bg-green-300 rounded-md ml-6 mr-6 pl-4 pr-4 pt-3 pb-3 flex flex-row">
            <div className="bg-green-600 rounded-full w-5 h-5 text-white text-center flex flex-col justify-center align-middle">
                <div className="flex flex-row justify-center">
                    <CheckIcon />
                </div>
            </div>
            <div className="pl-4 text-green-800 font-medium text-sm">
                {text}
            </div>
        </div>
    )
}

export const FailureNotification = ({text}) => {
    return (
        <div className="bg-red-300 rounded-md ml-6 mr-6 pl-4 pr-4 pt-3 pb-3 flex flex-row">
            <div className="bg-red-600 rounded-full w-5 h-5 text-red-300 text-center flex flex-col justify-center align-middle">
                <div className="flex flex-row justify-center">
                    <FailIcon />
                </div>
            </div>
            <div className="pl-4 text-red-900 font-medium text-sm">
                {text}
            </div>
        </div>
    )
}