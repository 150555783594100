import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export const OverviewPlatformsTaskData = ({data, width=300, height=200}) => {

    return (
        <BarChart
          width={width}
          height={height}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="pending" stackId="a" fill="#8884d8" />
          <Bar dataKey="maintenance" stackId="a" fill="#82ca9d" />
        </BarChart>
    );
  }

export const OverviewPlatformsTaskType = ({data, width=300, height=200}) => {

    return (
        <BarChart
          width={width}
          height={height}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="software" stackId="a" fill="#5BC244" />
          <Bar dataKey="scheduled" stackId="a" fill="#425AC2" />
          <Bar dataKey="modification" stackId="a" fill="#C2425A" />
        </BarChart>
    );
  }



// export default OverviewPlatformsTaskData;