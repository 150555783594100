import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from 'react-redux';
import store from './store/index';
import { AuthProvider } from './providers/AuthProvider';
import { CognitoAuth } from './providers/CognitoAuth';

const root = ReactDOM.createRoot(document.getElementById('root'));

//process.env.REACT_APP_REDIRECT_URI
//console.log(process.env.REACT_APP_AUTH0_DOMAIN)
//console.log(process.env.REACT_APP_REDIRECT_URI)
// const CognitoAuthObject = {
//   logout:()=>{console.log('CognitoAuthObject logout')}
// }


root.render(

  <Provider store={store}>
    <AuthProvider value={new CognitoAuth(store)}>
      <App />
    </AuthProvider>
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
