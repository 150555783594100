export const TextFieldInput = ({
  name = "",
  placeholder = "",
  onChange,
  value,
  rows = 1,
  disabled = false,
  onEnter = () => {}
}) => {

  const bgColor = () => disabled ? 'bg-slate-200' : 'bg-white'; 
  const txtColor = () => disabled ? 'text-slate-500' : 'text-slate-800'; 

  return (
    <div>
      <div className="text-xs font-medium pb-2">{name}</div>
      <input
        // className="placeholder:text-slate-500 placeholder:font-medium block placeholder:text-xs bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm "
        className={`placeholder:text-slate-500 placeholder:font-medium block placeholder:text-xs ${bgColor()} ${txtColor()} w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm `}
        placeholder={placeholder}
        type="text"
        name="search"
        rows={rows}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onKeyDown={(evt)=>{
          // console.log(evt, evt.key);
          if(evt.key === "Enter") {
            evt.preventDefault();
            if(!disabled) {
              onEnter();
            }
          }
        }}
      />
    </div>
  );
};

export const TextInputArea = ({
  name = "",
  placeholder = "",
  onChange,
  value,
  rows = 1,
  disabled = false
}) => {

  const bgColor = () => disabled ? 'bg-slate-200' : 'bg-white'; 
  const txtColor = () => disabled ? 'text-slate-500' : 'text-slate-800'; 

  return (
    <div>
      <div className="text-xs font-medium pb-2">{name}</div>
      <textarea
        // className="placeholder:text-slate-500 placeholder:font-medium block placeholder:text-xs bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm "
        className={`placeholder:text-slate-500 placeholder:font-medium block placeholder:text-xs ${bgColor()} ${txtColor()} bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm `}
        placeholder={placeholder}
        type="text"
        name="search"
        rows={rows}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
};

// export const TextFieldInputInactive = ({
//   name = "",
//   placeholder = "",
//   value,
//   rows = 1,
// }) => {
//   return (
//     <div>
//       <div className="text-xs font-medium pb-2">{name}</div>
//       <input
//         className="placeholder:text-slate-200 placeholder:font-medium block placeholder:text-xs w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm bg-gray-400 text-gray-500 "
//         placeholder={placeholder}
//         type="text"
//         name="search"
//         readOnly
//         rows={rows}
//         value={value}
//       />
//     </div>
//   );
// };

// export const TextInputAreaInactive = ({
//   name = "",
//   placeholder = "",

//   value,
//   rows = 1,
// }) => {
//   return (
//     <div>
//       <div className="text-xs font-medium pb-2">{name}</div>
//       <textarea
//         className="placeholder:text-slate-500 placeholder:font-medium block placeholder:text-xs bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm bg-gray-400 text-gray-500 "
//         placeholder={placeholder}
//         type="text"
//         readOnly
//         name="search"
//         rows={rows}
//         value={value}
//       />
//     </div>
//   );
// };
