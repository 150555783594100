import { API } from '../config';
import { GET, POST, FORM_POST, DELETE, PATCH } from './Methods';

const GetDocuments = (q={}) => {
    return GET(API.DOCUMENTS(q));
}

const GetDocument = (id) => {
    return GET(API.DOCUMENT(id));
}

const AddDocument = (data) => {
    return FORM_POST(API.DOCUMENTS(), data);
}

export { GetDocuments, GetDocument, AddDocument }