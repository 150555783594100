import DynamicTable from "../../../../components/DynamicTable";
import { LoadingMain } from "../../../../components/LoadingModal";
import { Button } from "../../../../components/Buttons";
import UserAddSoftwareModal from "./UserAddSoftwareModal";

import UserUpdateSoftwareModal from "./UserUpdateSoftwareModal";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { platformActions } from '../../../../store';

import { CheckIcon, CloseIcon } from "../../../../components/Icons";

import {
  GetSoftware,
  AddSoftware,
  RemoveSoftware,
  // UpdatePlatformSoftware,
} from "../../../../services/Software";
import { UpdatePlatformSoftware, Platform as GetPlatform } from "../../../../services/Platforms";
import { CreateTask } from "../../../../services/Tasks";
import { PlatformAddSoftwareTask, PlatformRemoveSoftwareTask } from "../../../../models/TasksModel";
import { MainContainer } from "../../../../components/Containers";

const MODAL_STATE = {
  NONE: "none",
  ADD_SOFTWARE: "add_software",
  REMOVE_SOFTWARE: "remove_software",
  UPDATE_SOFTWARE: "update_software",
};

const UserSoftware = () => {
  // const [softwareList, setSoftwareList] = useState({ data: [] });
  const [modalState, setModalState] = useState({
    state: MODAL_STATE.NONE,
    data: null,
  });

  const platform = useSelector((state) => state.platform.platform);
  const dispatch = useDispatch();

  const renderTable = () => Boolean(platform.software);

  const Cell = ({ data, onClick }) => <div onClick={onClick}>{data}</div>;

  const selectRow = (r) =>
    setModalState({ state: MODAL_STATE.UPDATE_SOFTWARE, data: r });




  const createDataArray = (data) =>
    data.map((s) => [
      <div
        // data={s.active}
        onClick={() => {
          selectRow(s);
        }}
        className={`${s.active === "true"? "text-green-500" : "text-red-500"}`}
      >
        {s.active === "true" ? <div className="flex"><CheckIcon className="w-4 h-4 mr-1"/>Active</div> : <div className="flex"><CloseIcon className="w-4 h-4 mr-1"/>Inactive</div>}
      </div>,
      <Cell
        data={s.name}
        onClick={() => {
          selectRow(s);
        }}
      />,
      <Cell
        data={s.version}
        onClick={() => {
          selectRow(s);
        }}
      />,
      <Cell
        data={s.company}
        onClick={() => {
          selectRow(s);
        }}
      />,
      <Cell
        data={s.size}
        onClick={() => {
          selectRow(s);
        }}
      />,
      <Cell
        data={`${new Date(s.installationDate).toLocaleDateString()} | ${new Date(s.installationDate).toLocaleTimeString()}`}
        onClick={() => {
          selectRow(s);
        }}
      />,
      <Cell
        data={s.added_by.email}
        onClick={() => {
          selectRow(s);
        }}
      />,
    ]);

  const tableHeader = ["Active", "Name", "Version", "Company", "Size", "Installation date", "Installed by"];

  /**
   * @description helper to refresh the list
   * @description this is only required for the admin page
   */
  // const refreshSoftwareList = () => {
    // GetSoftware()
    //   .then((r) => r.json())
    //   .then((res) => {
    //     setSoftwareList(res);
    //   });
  // };



  const refreshPlatform = () => {
    GetPlatform(platform._id)
    .then( r => r.json())
    .then( r => dispatch(platformActions.setPlatform(r)))
  }

  /**
  * @description creates a task to add software to a platform
  */
  const addSoftware = (doc) => {

    let s = new PlatformAddSoftwareTask(platform._id, doc._id);
    console.log(s);
    // CreateTask()

    // AddSoftware(doc)
    //   .then((r) => r.json())
    //   .then((res) => {
    //     refreshSoftwareList();
    //     setModalState({ state: MODAL_STATE.NONE });
    //   });
  };

  /**
   * @description makes the API call to add software to remvoe software
   */
  const removeSoftware = (doc) => {


    let task = new PlatformRemoveSoftwareTask(platform._id, doc.softwareId);
    task.SetDescription(`Remove ${doc.name} ${doc.version} (${doc.company})`)
    // console.log(task);
    CreateTask(task)
    .then(r => r.json())
    .then(res => {
        refreshPlatform();
        setModalState({ state: MODAL_STATE.NONE });
    })

    // RemoveSoftware(doc._id)
    //   .then((r) => r.json())
    //   .then((res) => {
    //     refreshPlatform();
    //     setModalState({ state: MODAL_STATE.NONE });
    //   });
  };

  /**
   * @description makes the API call to add software to update software
   */
  const updateSoftware = (id, doc) => {

    // return console.log(id, doc);

    UpdatePlatformSoftware(platform._id, id, doc)
      .then((r) => r.json())
      .then((res) => {
        refreshPlatform();
        setModalState({ state: MODAL_STATE.NONE });
      });
  };

  // useEffect(() => {
  //   refreshSoftwareList();
  // }, []);


  return (
    <>
      {!renderTable() ? (
        <LoadingMain />
      ) : (
        <div
          style={{ backgroundColor: "#D9DDDB" }}
          className="pt-8 pb-2 h-screen border-t"
        >


          <MainContainer>

          {/* <div className="platform-individual-document-container"> */}
            <div className="mb-4 mx-2">
              <Button
                label={"Add Software"}
                onClick={() => {
                  setModalState({ state: MODAL_STATE.ADD_SOFTWARE });
                }}
              />
            </div>
            <div className="bg-gray-100 flex flex-row mx-2 rounded-b">
              <DynamicTable
                data={createDataArray(platform.software)}
                headerData={tableHeader}
                handleDownload={() => {}}
                handleViewNotes={() => {
                  setModalState({ state: MODAL_STATE.VIEW_NOTES });
                }}
              />
            </div>
          {/* </div> */}
          </MainContainer>

          {modalState.state === MODAL_STATE.ADD_SOFTWARE ? (
            <UserAddSoftwareModal
              onClose={() => {
                setModalState({ state: MODAL_STATE.NONE });
              }}
              onAdd={addSoftware}
              data={modalState.data}
            />
          ) : null}
          {modalState.state === MODAL_STATE.UPDATE_SOFTWARE ? (
            <UserUpdateSoftwareModal
              onClose={() => {
                setModalState({ state: MODAL_STATE.NONE });
              }}
              onAdd={addSoftware}
              onRemove={removeSoftware}
              onUpdate={updateSoftware}
              data={modalState.data}
            />
          ) : null}
        </div>
      )}
    </>
  );
};

export default UserSoftware;
