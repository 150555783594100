import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useEffect, useState} from "react";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {PATHS} from "../../config";
import PartsAll from './tabs/PartsAll';
import PartsRequests from './tabs/PartsRequests';

import { NavigationTab } from "../../components/NavigationTab";
import { TaskStackIcon, ComputerIcon, CogIcon, WrenchIcon, EllipsisIcon } from "../../components/Icons";

const PARTS_TABS = [
    "requests",
    "all"
];

const PartsTabs = ({activeTab, onTabChange})=> {
    return (
        <>
            <Box sx={{borderBottom: 1, borderColor:'divider'}}>
                <Tabs value={activeTab} onChange={(evt, v)=>{onTabChange(v)}} aria-label="basic tabs example">
                    {PARTS_TABS.map((t, i)=>{ return (<Tab key={i} label={t} {...{ id: `simple-tab-${i}`, 'aria-controls': `simple-tabpanel-${i}`}} />)})}
                </Tabs>
            </Box>
        </>
    )
}
const Parts = ()=>{

    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();



    const changeTab = (tab) => {
        setActiveTab(tab);
//        navigate(`${PATHS.PARTS}/` + PARTS_TABS[tab]);
        navigate(PARTS_TABS[tab]);
    }

    useEffect(()=>{
        const URL = location.pathname.split('/');
        PARTS_TABS.forEach((t,i) => {
            if(URL.includes(t))
                setActiveTab(i);
        });
    }, [location.pathname]);



    return (
        <>
            {/* <PartsTabs activeTab={activeTab} onTabChange={changeTab}/> */}

            <div style={{ backgroundColor: "#F1F1F0" }}>

                <div className="flex flex-row justify-center content-center pt-6">
                    {/* <div className="bg-white flex p-6 border rounded-md shadow-md mr-4 hover:cursor-pointer hover:bg-slate-50 hover:shadow-lg" onClick={() => { navigate(`/`) }}>

                        <div className='text-green-600 flex flex-col justify-center'>
                            <BackIcon className="w-6 h-6" />
                        </div>

                        <div className='font-normal text-slate-800 pl-2 flex flex-col justify-center'>
                            All projects
                        </div>

                    </div> */}
                    <div className="bg-white flex p-6 border rounded-md shadow-md">
                        <WrenchIcon className="w-11 h-11" />
                        <div className="text-3xl text-slate-800 font-semibold ml-2 flex flex-col justify-center">
                            Platform Parts
                        </div>
                    </div>
                </div>

                <div className="max-w-7xl m-auto translate-y-4">
                    <NavigationTab name={"Platform Part Requests"} icon={() => <TaskStackIcon className="w-4 h-4" />} active={activeTab === 0} first={true} onClick={() => { changeTab(0) }} />
                    {/* <NavigationTab name={"All Parts"} icon={() => <ComputerIcon className="w-4 h-4" />} active={activeTab === 1} onClick={() => { changeTab(1) }} /> */}
                    <NavigationTab name={"All Parts"} icon={() => <CogIcon className="w-4 h-4" />} active={activeTab === 1} last={true} onClick={() => { changeTab(1) }} />
                </div>
            </div>

            <Routes>
                <Route path="/" element={<Navigate to={PARTS_TABS[0]} />} />
                <Route path={PARTS_TABS[0]} element={<PartsRequests />} />
                <Route path={PARTS_TABS[1]} element={<PartsAll />} />
            </Routes>
        </>
    )
}
export default Parts;