class StockListing
{
    constructor(s = {}){
        this.location= s.location || "";
        this.items= s.items || [];
    }
}

class ServiceInterval
{
    constructor(s={}){
        this.duration = s.duration || {h:0,d:0,w:0,m:0,y:0};
        this.afterFlightHours = Number(s.afterFlightHours) || 0;
    }
    GetDuration(){return this.duration; }
    GetFlightInterval(){return this.afterFlightHours; }

    SetDuration(d){ this.duration = d; }
    SetFlightInterval(d){ this.afterFlightHours = Number(d); }
}


const PART_TYPE = {
    HARDWARE: "HARDWARE",
    BATTERY: "BATTERY",
    FLIGHT_CONTROLLER: "FLIGHT_CONTROLLER",
    CAMERA: "CAMERA",
};

class Part
{
    constructor(part = {}){

        if(part._id)
            this._id = part._id;

        this.name = part.name || "";
        this.model = part.model || "";
        this.manufacturer = part.manufacturer || "";
        this.type = part.type || "";
        this.serviceInterval = new ServiceInterval(part.serviceInterval || {});
        this.suppliers = part.suppliers || []; 
        this.stock = part.stock || []; 
    }
}

class StockPart extends Part
{
    constructor(part={}){
        super(part);
        this.stock = part.stock || [];
        this.suppliers = part.suppliers || [];
    }
    RemoveStock(){};
    AddSupplier(){};
    RemoveSupplier(){};
}

class PlatformPart
{
    constructor(part = {}){
        this.partID = part.partID || part._id || "";
        this.name = part.name || "";
        this.model = part.model || "";
        this.manufacturer = part.manufacturer || "";
        this.type = part.type || "";
        this.serial = part.serial || "";
        this.isActive = part.isActive || true;
        this.totalFlightHours = part.totalFlightHours || 0;
        this.flightHoursSinceLastService = 0;
        this.dateInstalled = part.dateInstalled || new Date();
        this.lastService = part.lastService || new Date();
//        this.lastService = ;
        this.serviceInterval = new ServiceInterval(part.serviceInterval || {})
    }

    GetName(){ return this.name; }
    GetModel(){ return this.model; }
    GetManufacturer(){ return this.manufacturer; }
    GetType(){ return this.type; }
    GetSerial(){ return this.serial; }
    GetServiceInterval(){ return this.serviceInterval; }
    
    // GetName(){ return this.name; }
    // GetModel(){ return this.model; }
    // GetManufacturer(){ return this.manufacturer; }
    // GetType(){ return this.type; }
    // GetServiceInterval(){ return this.serviceInterval; }
    SetSerial(serial){ this.serial = serial; }
    SetServiceInterval(s){ this.serviceInterval = s; }
}

export {StockListing, Part, PlatformPart, ServiceInterval, PART_TYPE}