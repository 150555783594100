export const NavigationTab = ({
    active= false,
    icon= ()=>{},
    name= "",
    first= false,
    last= false,
    onClick= (e) => {}
}) => {

    const bgColor = () => active ? 'bg-green-500' : 'bg-white hover:bg-slate-200';
    const txtColor = () => active ? 'text-white' : 'text-slate-600 hover:text-slate-700';
    const leftStyling = () => first ? 'rounded-l-full border-l' : '';
    const rightStyling = () => last ? 'rounded-r-full' : '';

    return (
        <div className={`${bgColor()} ${txtColor()} ${leftStyling()} ${rightStyling()} hover:cursor-pointer inline-flex px-4 py-2 text-xs font-semibold border-t border-r border-b border-slate-300`} onClick={onClick}>
            {icon()}
            <div className="pl-2">
            {name}
            </div>
        </div>
    )
}