import { Input, Modal, TextField, Typography, Select, MenuItem, Button, Divider } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';
import AddPartCard from "../../../platforms/tabs/AddPartCard";
import { Locations as GetLocations } from '../../../../services/Locations'
import { useReducer, useState, useEffect } from "react";
import AdminAddPartCard from "./AdminAddPartCard"
import Platform from "../../../../models/PlatformModel";
import { CreatePlatform } from "../../../../services/Platforms";
import AdminAddPartModal from "./AdminAddpartModal";
import { DefaultReducer } from "../../../../components/DefaultReducer";

import PartCard from "../../../platforms/tabs/PartCard";

const formReducer = (state, action) => {
    if (action.input === 'LOCATION_LIST_RECEIVED') {
        console.log(action.data);
        return { ...state, locationListRecieved: true, locations: action.data };
    }
    return { ...state };
};


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 800,
    maxHeight: 510,
    bgcolor: 'white',
    border: '0px solid #000',
    boxShadow: 4,
    p: 2,
};


const PlatformAddModal = ({ onClose, onAddPlatform }) => {

    const [formState, formDispatch] = useReducer(formReducer, { locations: [{ name: '' }], addedParts: [] });
//    const [state, setState] = useState({ Platform_Name: "", Model: "", Manufacturer: "", Serial: "", Status: "", Location: "" });


    const [platformState, platformStateDispatch] = useReducer(DefaultReducer, new Platform());
    const RequiredFields = ["name", "model", "manufacturer", "serial", "status", "location"];
    const [isPartModalOpen, setIsPartModalOpen] = useState(false);


    /*
    const handleInputChange = (event) => {
        console.log(event);
        // update the state with the new value
        const { id, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };*/

    const updateLocationsList = () => {
        GetLocations()
            .then(r => { return r.json() })
            .then(data => {
                formDispatch({ input: 'LOCATION_LIST_RECEIVED', data: data });
            })
    }

//    const [selectedOption, setSelectedOption] = useState('');



/*
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        setState((prevState) => ({
            ...prevState,
            Location: event.target.value,
        }));
    };
*/
    useEffect(() => {
        updateLocationsList();
//        setSelectedOption('')
    }, []);

    const addPart = (part) => {


        platformStateDispatch({parts: [...platformState.parts, part]});

        console.log('help')
        //TODO this
    };

    const handleOpenPartModal = () => {
        setIsPartModalOpen(true);
//        console.log("here");
    };

    const handleClosePartModal = () => {
        setIsPartModalOpen(false);
    };


    const saveNewPlatform = () => {

//        let platform = new Platform(state.Platform_Name, state.Location, state.Model, state.Manufacturer, state.Serial, "", state.Status, [])
/*
        let platform = new Platform(
            platformState.name, 
            platformState.location, 
            platformState.model, 
            platformState.manufacturer, 
            platformState.serial, "", 
            platformState.status
        );*/

//        let platform = new Platform(platformState);

//        console.log(platformState, platform2);

        CreatePlatform(new Platform(platformState))
            .then(r => r.json())
            .then(r => onAddPlatform(r));

        // if new, add platform
        // all new parts/ delete any? 
    };

    const OnApply = () => {
        saveNewPlatform()
    };

    const formValid = () => {
/*
        console.log(platformState);
        const requiredFields = ["Platform_Name", "Model", "Manufacturer", "Serial", "Status", "Location"];
        return requiredFields.every((fieldName) => state[fieldName].trim() !== "");
*/
//        console.log(platformState);
//        const requiredFields = ["name", "model", "manufacturer", "serial", "status", "location"];
        return RequiredFields.every((fieldName) => platformState[fieldName].trim() !== "");

    }

    return (<>
        <Modal
            open={true}
            onClose={onClose}
            sx={{ border: '1px solid #000', outline: 'none' }}
            disableEnforceFocus={true}
            disableAutoFocus={true}

        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography sx={{ fontSize: 20, marginLeft: 1 }} color="text.primary">Add Platform</Typography>
                    <CloseIcon sx={{ marginLeft: 'auto' }} color="text.secondary" onClick={onClose} />
                </Box>

                <Divider />

                <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: 2, paddingBottom: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Box sx={{}}>
                            <TextField
                                id="Platform_Name"
                                label="Platform Name"
                                variant="outlined"
                                size='small'
                                required={true}
                                value={platformState.name}
                                onChange={(v)=>{platformStateDispatch({name: v.target.value})}} 
                            />
                        </Box>
                        <Box sx={{}}>
                            <TextField
                                id="Model"
                                label="model"
                                variant="outlined"
                                required={true}
                                size='small'

//                                onChange={handleInputChange}
                                value={platformState.model}
                                onChange={(v)=>{platformStateDispatch({model: v.target.value})}} 
                                
                                ></TextField>
                        </Box>
                        <Box sx={{}}>
                            <TextField
                                id="Manufacturer"
                                label="manufacturer"
                                variant="outlined"
                                required={true}
                                size='small'
                                value={platformState.manufacturer}
                                onChange={(v)=>{platformStateDispatch({manufacturer: v.target.value})}} 
//                                onChange={handleInputChange}
                                ></TextField>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: 2, justifyContent: 'space-between' }}>
                        <Box sx={{}}>
                            <TextField
                                id="Serial"
                                label="serial"
                                variant="outlined"
                                size='small'
                                required={true}
//                                onChange={handleInputChange}
                                value={platformState.serial}
                                onChange={(v)=>{platformStateDispatch({serial: v.target.value})}} 
                                
                                ></TextField>
                        </Box>

                        {/** 
                         * 
                        <Box sx={{ mx: 'auto' }}>
                            <Select value={selectedOption} onChange={handleSelectChange}>
                                {formState.locations.map((location) => { return <MenuItem key={location.name} value={location.name}> {location.name} </MenuItem> })
                                }
                            </Select>
                        </Box>
                            */}

                        <Box sx={{}}>
                            <TextField
                                select
//                                id="Location"
                                label="Location"
                                variant="outlined"
                                size='small'
//                                required={true}
                                sx={{ width: 220 }}
//                                onChange={handleInputChange}
                                value={platformState.location}
                                onChange={(v)=>{platformStateDispatch({location: v.target.value})}} 
                                >

                                {formState.locations.map((location) => { return <MenuItem key={location.name} value={location.name}> {location.name} </MenuItem> })
                                }

                            </TextField>
                        </Box>
                        <Box sx={{}}>
                            <TextField
                                select
                                id="Status"
                                label="status"
                                variant="outlined"
                                required={true}
                                size='small'
                                sx={{ width: 220 }}
//                                onChange={handleInputChange}
                                value={platformState.status}
                                onChange={(v)=>{platformStateDispatch({status: v.target.value})}} 

                                >
                                <MenuItem value={"ACTIVE"}>Active</MenuItem>
                                <MenuItem value={"MAINTENANCE"}>Maintenance</MenuItem>
                            </TextField>
                        </Box>

                    </Box>


                </Box>
                {/** 
                 * 
                <Box sx={{ height: 300, backgroundColor: '#ccc', overflow: 'scroll', overflowX: 'hidden', paddingBottom: 0.75 }}>
                    {platformState.parts.map(p => {return <div key={p._id}>p.name</div>})}
                    <AdminAddPartCard handleOpenModal={handleOpenPartModal} />
                </Box>
                */}

                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Button sx={{ marginLeft: 'auto', marginTop: 2, marginRight: 1 }} variant="contained" size="small" onClick={OnApply} disabled={!formValid()} >Save</Button>
                </Box>
                {isPartModalOpen && (<AdminAddPartModal visible={isPartModalOpen} addPart={addPart} onClose={handleClosePartModal} />)}
            </Box>
        </Modal>
    </>)
}

export default PlatformAddModal;