import { Routes, Route, Navigate } from "react-router-dom";
import Platforms from '../pages/platforms/Platforms';
import Parts from '../pages/parts/Parts';
import Tasks from '../pages/tasks/Tasks';
import Overview from '../pages/overview/Overview';
import Admin from '../pages/admin/Admin';
import { Box } from "@mui/material";
import './Main.css'

const Main = () => {

    return (
        <Box id="main-content-wrapper">
            <Routes>
                {<Route exact path="/" element={<Navigate to="platforms" />} />}
                <Route path="platforms/*" element={<Platforms />} />
                <Route path="parts/*" element={<Parts />} />
                <Route path="tasks/*" element={<Tasks />} />
                <Route path="overview/*" element={<Overview />} />
                <Route path="admin/*" element={<Admin />} />
            </Routes>
        </Box>
    )
}

export default Main;